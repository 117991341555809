/*LIBRARY*/
import React, { useState } from "react";
import { useDispatch } from "react-redux";

/*COMPONENT*/
import RecoveryTab from "../../Components/recovery/RecoveryTab";
import ProjectSelector from "../../Components/recovery/ProjectSelector";
import RestoreConfirmation from "../../Components/recovery/RestoreConfirmation";
import CreateNewProject from "../../Components/recovery/CreateNewProject";
import ListInRecovery from "../../Components/recovery/ListInRecovery";
import Modal from "../../Components/common_modal/Modal";

/*REDUX*/
import {
  delete_project_permanent,
  get_projects_from_recovery,
  restore_project,
} from "../../App/actions/recoveryProjectActions";
import {
  delete_layer_permanent,
  get_layers_from_recovery,
  restore_layer,
} from "../../App/actions/recoveryLayerActions";

/*PICTURE*/

/*FUNCTION*/
import useWindowDimensions from "../../Components/common_hooks/useWindowDimension";
import useRecovery from "../../Components/recovery/hooks/useRecovery";
import useLanguage from "../../Components/common_hooks/useLanguage";

/*DATA*/

/*CONST*/
const limit = 15;

function Recovery() {
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();
  const { dict, language } = useLanguage();

  /** update jumlah data yang diskip untuk fitur load more */
  const update_skip = (data_length) => {
    let body = structuredClone(skip);
    body[actived_tab] = body?.[actived_tab] + data_length;
    set_skip(body);
  };

  const {
    restore_page,
    set_restore_page,
    is_multiple,
    set_is_multiple,
    actived_tab,
    set_actived_tab,
    mode_view,
    set_mode_view,
    recovery_of_projects,
    set_recovery_of_projects,
    recovery_of_layers,
    set_recovery_of_layers,
    selected,
    set_selected,
    restore_selected_project,
    set_restore_selected_project,
    sort_by,
    set_sort_by,
    skip,
    set_skip,
  } = useRecovery({ limit, update_skip });

  const [modal_restore_confirmation, set_modal_restore_confirmation] =
    useState(false);
  const [modal_delete_confirmation, set_modal_delete_confirmation] =
    useState(false);
  const [recently_created_projects, set_recently_created_projects] = useState(
    []
  );

  /** update ke mode single atau mode multiple */
  const toggle_is_multiple = () => {
    set_is_multiple(!is_multiple);
    set_selected([]);
  };

  /** [HIT API] function yang menjalankan fitur load more */
  const handle_load_more = (actived_tab) => {
    const { attribute, sort_value } = sort_by;
    if (actived_tab === "project") {
      dispatch(
        get_projects_from_recovery({
          limit,
          skip: skip?.[actived_tab],
          attribute,
          sort_value,
        })
      ).then((res) => {
        const first_15_projects = res;
        push_into_recovery_of_projects(first_15_projects);
        let body = structuredClone(skip);
        body[actived_tab] = body?.[actived_tab] + limit;
        set_skip(body);
      });
    } else {
      dispatch(
        get_layers_from_recovery({
          limit,
          skip: skip?.[actived_tab],
          attribute,
          sort_value,
        })
      ).then((res) => {
        const first_15_layers = res;
        push_into_recovery_of_layers(first_15_layers);
        let body = structuredClone(skip);
        body[actived_tab] = body?.[actived_tab] + limit;
        set_skip(body);
      });
    }
  };

  /** sorting */
  const handle_sort = (e) => {
    const value = e.target.value;
    if (value === "newest") {
      set_sort_by({
        attribute: "trash.updated_at",
        sort_value: -1,
      });
    } else if (value === "oldest") {
      set_sort_by({
        attribute: "trash.updated_at",
        sort_value: 1,
      });
    } else if (value === "name_asc") {
      set_sort_by({
        attribute: "name",
        sort_value: 1,
      });
    } else if (value === "name_desc") {
      set_sort_by({
        attribute: "name",
        sort_value: -1,
      });
    }

    set_skip({
      project: 0,
      layer: 0,
    });

    set_selected([]);
  };

  /** function untuk menyimpan project/layer ke dalam variable yang nanti akan dipulihkan/dihapus */
  const push_selected = (value) => {
    if (selected?.some((item) => item?._id === value?._id)) {
      set_selected([...selected.filter((item) => item?._id !== value?._id)]);
    } else {
      set_selected([...selected, value]);
    }
  };

  /** function untuk menyimpan id project yang baru saja dibuat sebagai penanda "Project Baru!" */
  const push_recently_created_projects = (value) => {
    set_recently_created_projects([...recently_created_projects, value?._id]);
  };

  /** menambahakan data hasil request get recovery projects ke dalam var array (load more) */
  const push_into_recovery_of_projects = (array_of_projects) => {
    set_recovery_of_projects([...recovery_of_projects, ...array_of_projects]);
  };

  /** menambahakan data hasil request get recovery layers ke dalam var array (load more) */
  const push_into_recovery_of_layers = (array_of_layers) => {
    set_recovery_of_layers([...recovery_of_layers, ...array_of_layers]);
  };

  /** toggle untuk modal RESTORE confirmation */
  const toggle_modal_restore_confirmation = () => {
    set_modal_restore_confirmation(!modal_restore_confirmation);
  };

  /** toggle untuk modal restore confirmation khusus untuk yg single select */
  const toggle_modal_restore_confirmation_only_for_single = (
    selected_content
  ) => {
    toggle_modal_restore_confirmation();
    if (actived_tab === "layer") {
      set_restore_page(1);
    } else {
      set_restore_page(2);
    }

    if (selected_content) {
      set_selected([selected_content]);
    }
  };

  /** toggle untuk modal DELETE confirmation */
  const toggle_modal_delete_confirmation = () => {
    set_modal_delete_confirmation(!modal_delete_confirmation);
  };

  /** [HIT API] function untuk hit api restore project */
  const hit_api_restore_project = (body) => {
    return dispatch(restore_project(body)).then((res) => {
      return res;
    });
  };

  /** [HIT API] function untuk hit api restore layer */
  const hit_api_restore_layer = (body) => {
    return dispatch(restore_layer(body)).then((res) => {
      return res;
    });
  };

  /** logic utama restore layer */
  const handle_restore_layer = async () => {
    let successed_restore_layers = [];

    for (let item of selected) {
      const body = {
        geo_project_id: restore_selected_project?._id,
        geo_layer_id: item?._id,
      };
      const res = await hit_api_restore_layer(body);
      if (res) {
        successed_restore_layers.push(item?._id);
      }
    }

    const rest_recovery_of_layers = recovery_of_layers?.filter(
      (item) => !successed_restore_layers.includes(item?._id)
    );
    const rest_selected = selected?.filter(
      (item) => !successed_restore_layers.includes(item?._id)
    );
    set_recovery_of_layers(rest_recovery_of_layers);
    set_selected(rest_selected);
  };

  /** logic utama restore project */
  const handle_restore_project = async ({ is_with_layer = true }) => {
    const body = {
      with_layers: is_with_layer,
      geo_project_ids: selected?.map((item) => item?._id),
    };

    const response = await hit_api_restore_project(body);
    let rest_data = [];
    if (is_multiple) {
      rest_data = handle_recovery_project_after_action(response);
    } else {
      rest_data = handle_recovery_project_after_action(
        response,
        selected?.[0]?._id
      );
    }
    set_recovery_of_projects(rest_data);
    set_selected([]);
  };

  /** [HIT API] function request api untuk DELETE project secara permanent  */
  const hit_api_delete_project_permanent = (body) => {
    return dispatch(delete_project_permanent(body)).then((res) => {
      return res;
    });
  };

  /** function untuk membuang project yang sudah dihapus/dipulihkan dari daftar recovery_of_projects */
  const handle_recovery_project_after_action = (res, id) => {
    if (res) {
      if (is_multiple) {
        return recovery_of_projects?.filter(
          (item) => !selected?.some((p) => p?._id === item?._id)
        );
      } else {
        return recovery_of_projects?.filter((item) => item?._id !== id);
      }
    } else {
      return recovery_of_projects;
    }
  };

  /** [HIT API] function request api untuk DELETE layer secara permanent  */
  const hit_api_delete_layer_permanent = (body) => {
    return dispatch(delete_layer_permanent(body)).then((res) => {
      return res;
    });
  };

  /** function untuk membuang layer yang sudah dihapus/dipulihkan dari daftar recovery_of_layers */
  const handle_recovery_layer_after_action = (res, id) => {
    if (res) {
      if (is_multiple) {
        return recovery_of_layers?.filter(
          (item) => !selected?.some((p) => p?._id === item?._id)
        );
      } else {
        return recovery_of_layers?.filter((item) => item?._id !== id);
      }
    } else {
      return recovery_of_layers;
    }
  };

  /** logic utama untuk delete permanent */
  const del_permanent = async ({ with_layers, id }) => {
    if (actived_tab === "project") {
      let body = {
        with_layers: with_layers,
        geo_project_ids: [],
      };
      if (is_multiple) {
        body.geo_project_ids = selected?.map((item) => item?._id) || [];
      } else {
        body.geo_project_ids = [id];
      }
      const response = await hit_api_delete_project_permanent(body);
      const rest_data = handle_recovery_project_after_action(response, id);
      set_recovery_of_projects(rest_data);
    } else {
      let body = {
        geo_layer_ids: [],
      };
      if (is_multiple) {
        body.geo_layer_ids = selected?.map((item) => item?._id) || [];
      } else {
        body.geo_layer_ids = [id];
      }
      const response = await hit_api_delete_layer_permanent(body);
      const rest_data = handle_recovery_layer_after_action(response, id);
      set_recovery_of_layers(rest_data);
    }
    set_selected([]);
  };
  // console.log(recovery_of_layers);
  const recovery_component = (
    <ListInRecovery
      mode_view={mode_view === "card" || width < 630 ? "card" : "list"}
      data={
        actived_tab === "project" ? recovery_of_projects : recovery_of_layers
      }
      toggle_is_multiple={toggle_is_multiple}
      is_multiple={is_multiple}
      push_selected={push_selected}
      selected={selected}
      actived_tab={actived_tab}
      set_recovery_of_projects={set_recovery_of_projects}
      set_recovery_of_layers={set_recovery_of_layers}
      delete_permanent={del_permanent}
      toggle_modal_restore_confirmation={
        toggle_modal_restore_confirmation_only_for_single
      }
    />
  );

  let load_more_button = null;
  if (actived_tab === "project") {
    load_more_button =
      recovery_of_projects?.length % limit === 0 &&
      recovery_of_projects?.length > 0 ? (
        <button
          onClick={(e) => handle_load_more(actived_tab)}
          className="font_20 hover_bigger"
        >
          Load More
        </button>
      ) : null;
  } else {
    load_more_button =
      recovery_of_layers?.length % limit === 0 &&
      recovery_of_layers?.length > 0 ? (
        <button
          onClick={(e) => handle_load_more(actived_tab)}
          className="font_20 hover_bigger"
        >
          Load More
        </button>
      ) : null;
  }

  let restore_content = <></>;
  if (restore_page === 1) {
    restore_content = (
      <ProjectSelector
        width={width}
        selected={selected}
        push_selected={push_selected}
        restore_selected_project={restore_selected_project}
        set_restore_selected_project={set_restore_selected_project}
        set_restore_page={set_restore_page}
        recently_created_projects={recently_created_projects}
      />
    );
  } else if (restore_page === 2) {
    restore_content = (
      <RestoreConfirmation
        actived_tab={actived_tab}
        set_restore_page={set_restore_page}
        toggle_modal_restore_confirmation={toggle_modal_restore_confirmation}
        handle_restore_project={handle_restore_project}
        handle_restore_layer={handle_restore_layer}
      />
    );
  } else if (restore_page === 3) {
    restore_content = (
      <CreateNewProject
        set_restore_page={set_restore_page}
        // recently_created_projects={recently_created_projects}
        push_recently_created_projects={push_recently_created_projects}
      />
    );
  }

  let modal_size = "small";
  if (restore_page === 1) {
    modal_size = "medium";
  }

  const modal_restore_confirmation_content = modal_restore_confirmation && (
    <Modal
      modalSize={modal_size}
      id="modal"
      isOpen={modal_restore_confirmation}
      onClose={toggle_modal_restore_confirmation}
    >
      <main className="center_perfect">{restore_content}</main>
    </Modal>
  );

  const modal_delete_confirmation_content = modal_delete_confirmation && (
    <Modal
      modalSize="small"
      id="modal"
      isOpen={modal_delete_confirmation}
      onClose={toggle_modal_delete_confirmation}
    >
      <main className="center_perfect">
        <div>
          <label className="center_perfect bold font_16 marginBottom_10 text_center">
            {actived_tab === "project"
              ? dict[
                  "Are you sure you want to permanently delete the project and its layers?"
                ][language]
              : dict["Are you sure you want to permanently remove this layer?"][
                  language
                ]}
          </label>
          <section className="center_perfect">
            <div className="flex gap_5">
              <button
                onClick={toggle_modal_delete_confirmation}
                className="bg_darkGrey padding_y_5 padding_x_10 rounded_5 text_white"
              >
                {dict["Cancel"][language]}
              </button>
              <button
                onClick={() => {
                  del_permanent({ with_layers: true });
                  toggle_modal_delete_confirmation();
                }}
                className="background_red padding_y_5 padding_x_10 rounded_5 text_white"
              >
                {dict["Delete"][language]}
                {actived_tab === "project" && (
                  <>
                    {" "}
                    <span className="bold underline">
                      {dict["with"][language]}
                    </span>
                  </>
                )}
                {actived_tab === "project" && " " + dict["Layers"][language]}
              </button>
              {actived_tab === "project" && (
                <button
                  onClick={() => {
                    del_permanent({ with_layers: false });
                    toggle_modal_delete_confirmation();
                  }}
                  className="background_red padding_y_5 padding_x_10 rounded_5 text_white"
                >
                  {dict["Delete"][language]}{" "}
                  <span className="bold underline">
                    {dict["without"][language]}
                  </span>{" "}
                  {dict["Layers"][language]?.toLowerCase()}
                </button>
              )}
            </div>
          </section>
        </div>
      </main>
    </Modal>
  );

  return (
    <main className="main_container footer_margin">
      <div className="flex flex_col gap_10">
        <label className="title_recovery_page marginLeft_10">
          {dict["Recovery"][language]}
        </label>
        <section className="margin_x_10">
          <RecoveryTab
            handle_sort={handle_sort}
            toggle_tab={set_actived_tab}
            actived_tab={actived_tab}
            toggle_view={set_mode_view}
            mode_view={mode_view}
            width={width}
            is_multiple={is_multiple}
            selected={selected}
            set_selected={set_selected}
            recovery_of_projects={recovery_of_projects}
            recovery_of_layers={recovery_of_layers}
            toggle_modal_restore_confirmation={
              toggle_modal_restore_confirmation
            }
            toggle_modal_delete_confirmation={toggle_modal_delete_confirmation}
            set_restore_page={set_restore_page}
          />
        </section>
        <section className="w_full">{recovery_component}</section>
        <section className="center_perfect">{load_more_button}</section>
      </div>
      {modal_restore_confirmation_content}
      {modal_delete_confirmation_content}
    </main>
  );
}

export default Recovery;
