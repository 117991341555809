/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

/*PERSONAL COMPONENT*/
import BI_MENU from "./BI_MENU";

/*REDUX FUNCTION*/

/*PICTURE ASSET*/

/*GENERAL FUNCTION & DATA*/
import dict from "../../Data/dict.json";
import format_date_short from "../../App/validation/format_date_short";

/*NON IMPORT*/

class BI_MODE_LIST extends Component {
  state = {};

  render() {
    //local storage
    const language = localStorage?.language || "ina";

    //local state

    //global props
    const { bi_list } = this.props.bi;

    //content
    let list_content;

    if (bi_list.length === 0) {
      list_content = (
        <section
          className="container_folder background_white background_white outline_red"
          style={{ height: "37px", position: "relative" }}
        >
          <p className="center_child">{dict?.["Empty"]?.[language]}</p>
        </section>
      );
    } else {
      list_content = (
        <section className="container_light background_white outline_grey">
          <table className="full_width">
            <tbody className="text_bold">
              <tr>
                <td>{dict?.["Name"]?.[language]}</td>
                <td>{dict?.["Created"]?.[language]}</td>
                <td>{dict?.["Modified"]?.[language]}</td>
                <td></td>
              </tr>
            </tbody>
            <tbody>
              {bi_list.map((item, idx) => {
                const date_created = item?.date_created
                  ? format_date_short(item.date_created)
                  : "";
                const date_modified = item?.date_modified
                  ? format_date_short(item.date_modified)
                  : "";
                const name_original = item?.name;
                let name = item?.name;
                if (name.length > 70) {
                  const limit = 20;
                  const first = name?.slice(0, limit);
                  const last = name?.slice(name.length - limit, name.length);
                  name = first + "..." + last;
                }
                return (
                  <tr key={idx} className="button_white">
                    <td>
                      <Link
                        to={`/bi/${item?.link}?mode=edit`}
                        className="max_two_lines cursor_pointer full_width"
                        title={name_original}
                      >
                        {name}
                      </Link>
                    </td>
                    <td>
                      <p className="text_small">{date_created}</p>
                    </td>
                    <td>
                      <p className="text_small">{date_modified}</p>
                    </td>
                    <td className="text_right">
                      <BI_MENU id={item._id} />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </section>
      );
    }

    return <main className="margin_bottom">{list_content}</main>;
  }
}

const mapStateToProps = (state) => ({
  bi: state.bi,
});

export default connect(mapStateToProps, {})(BI_MODE_LIST);
