/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

/*COMPONENT*/
import FormListRender from "../../Components/form/FormListRender";
import Modal from "../../Components/common_modal/Modal";

/*REDUX*/
import {
  createLayer,
  getListLayerTypePoint,
} from "../../App/actions/layerNewActions";
import { getProjectListAll } from "../../App/actions/projectActions";

/*PICTURE*/
import ICON_PLUS from "../../Assets/jsx/ICON_PLUS";

/*FUNCTION*/
import isEmpty from "../../App/validation/is-empty";
import sortArray from "../../App/validation/sort-array";
import { select_field_from_default_props } from "../../App/validation/generateGeoJson";

/*DATA*/
import dict from "../../Data/dict.json";

/*CONST*/

class FORM_MENU_PAGE extends Component {
  state = {
    name: "",
    description: "",
    modal_create_form: false,
    project: {},
    skip: 0,
    limit: 6,
  };

  componentDidMount() {
    const screenW = window.screen.width;
    let limit = 6;
    if (screenW > 1719) {
      limit = 15;
    } else if (screenW > 1429) {
      limit = 12;
    } else if (screenW > 1149) {
      limit = 9;
    }
    this.setState({ limit });
    if (this.props.layer.form_list.length === 0) {
      const params = {
        skip: this.state.skip,
        limit: limit,
      };
      this.props.getProjectListAll();
      this.props.getListLayerTypePoint(params);
    }
  }

  toggle_create_form = () => {
    this.setState({ modal_create_form: !this.state.modal_create_form });
  };

  handleChange = (e) => {
    e.preventDefault();
    const target = e.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  };

  setProject = (project) => {
    this.setState({ project });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { user } = this.props.auth;
    const { project } = this.state;
    const { name, description } = this.state;
    const fields = select_field_from_default_props("Point");
    const body = {
      name,
      description,
      type: "Point",
      folder: "",
      user_id: user._id,
      geo_project_id: project._id,
      properties: fields,
      isForm: true,
    };
    this.props.createLayer(body);
  };

  setLoadMoreForm = () => {
    const skip = this.state.skip + this.state.limit;
    const limit = this.state.limit;
    const params = {
      skip,
      limit,
      append: true,
      lastProject: this.props.layer.form_list,
    };
    this.props.getListLayerTypePoint(params);
    this.setState({
      skip,
    });
  };

  render() {
    //local storage
    const language = localStorage?.language || "ina";

    //local state
    const { modal_create_form, name, description, project } = this.state;

    //global props
    const { form_list, loadingProcess, itemLoading } = this.props.layer;
    const { geo_project_list_all } = this.props.project;

    //content
    let button_content;
    if (loadingProcess) {
      button_content = <div className="button_block">Processing...</div>;
    } else if (!name || !description || isEmpty(project)) {
      button_content = (
        <div className="button_block background_grey">
          {dict["Create"][language]}
        </div>
      );
    } else {
      button_content = (
        <button type="submit" className="button_block background_blue">
          {dict["Create"][language]}
        </button>
      );
    }
    const modal_create_form_content = modal_create_form && (
      <Modal
        modalSize="medium"
        id="modal"
        isOpen={modal_create_form}
        onClose={this.toggle_create_form}
      >
        <main className="box-body">
          <section className="header" style={{ textAlign: "center" }}>
            {dict["Create a new form"][language]}
          </section>
          <form onSubmit={this.handleSubmit.bind(this)}>
            <main className="two_container_equal">
              <section style={{ width: "90%" }}>
                <div style={{ marginBottom: "10px" }}>
                  {dict["Form Name/Title"][language]}:
                </div>
                <input
                  type="text"
                  name="name"
                  id="name"
                  value={name}
                  placeholder={dict["Form Name/Title"][language]}
                  onChange={this.handleChange}
                  className="input_square"
                />
                <div style={{ marginTop: "20px", marginBottom: "10px" }}>
                  {dict["Form Description"][language]}:
                </div>
                <textarea
                  type="text"
                  name="description"
                  id="description"
                  value={description}
                  placeholder={dict["Form description"][language]}
                  onChange={this.handleChange}
                  rows="10"
                  className="input_square"
                />
              </section>
              <section>
                <div style={{ marginBottom: "10px" }}>
                  {dict["Choose Project"][language]}:
                </div>
                <div
                  style={{
                    padding: "10px",
                    height: "46.5vh",
                    overflowY: "auto",
                    overflowX: "hidden",
                    border: "1px solid #aeb0b1",
                    borderRadius: "10px",
                  }}
                >
                  {sortArray(geo_project_list_all, "name", true).map(
                    (geo_project) => {
                      const style_id =
                        geo_project._id === project._id
                          ? "list_active"
                          : "list_non_active";
                      return (
                        <div
                          onClick={this.setProject.bind(this, geo_project)}
                          id={style_id}
                          key={geo_project._id}
                          className="button_list"
                        >
                          {geo_project.name}
                        </div>
                      );
                    }
                  )}
                </div>
              </section>
            </main>
            <div style={{ marginTop: "50px" }}>{button_content}</div>
          </form>
        </main>
      </Modal>
    );
    let content;
    if (
      loadingProcess &&
      itemLoading === "get_layer_point" &&
      isEmpty(form_list)
    ) {
      content = (
        <>
          <br />
          <br />
          <div className="container_light">
            {dict["Loading your data"][language]}
          </div>
          <br />
          <br />
        </>
      );
    } else {
      content = (
        <main>
          <button
            onClick={this.toggle_create_form}
            className="button_pill background_white margin_bottom margin_right"
          >
            <span className="badge_circle background_green">
              <ICON_PLUS />
            </span>{" "}
            {dict["Create Form"][language]}
          </button>
          <br />
          <br />
          <h1 className="text_big">Form</h1>
          <FormListRender form_list={form_list} type="personal" />
        </main>
      );
    }
    return (
      <main>
        {modal_create_form_content}
        {content}
        {this.props.project.loadMoreBtn ? (
          <button
            className="button_pill background_black"
            onClick={() => this.setLoadMoreForm()}
            disabled={this.props.project.loadMoreBtnLoading}
          >
            {dict["Load More Form"][language]}
          </button>
        ) : (
          <p>{dict["All Forms Shown"][language]}</p>
        )}
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <Helmet>
          <title>FORM</title>
          <meta name="description" content="FORM" />
        </Helmet>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  layer: state.layer,
  project: state.project,
});

export default connect(mapStateToProps, {
  getListLayerTypePoint,
  getProjectListAll,
  createLayer,
})(FORM_MENU_PAGE);
