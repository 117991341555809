/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*COMPONENT*/
import MAPID_AI_CONTENT from "../../Components/mapid_ai/MAPID_AI_CONTENT";
import MAPID_AI_MAP from "../../Components/mapid_ai/MAPID_AI_MAP";

/*REDUX*/

/*PICTURE*/

/*FUNCTION*/

/*DATA*/

/*CONST*/

class MAPID_AI extends Component {
  state = {};

  render() {
    //local storage

    //local state

    //global props

    //content

    return (
      <main>
        <MAPID_AI_MAP />
        <MAPID_AI_CONTENT />
        <style>{`
      body{
        padding-top: 0px;
        overflow-y: hidden;
      }
      #navbarDefault{ visibility: hidden;}
      .top_nav{ visibility: hidden;}
      .sidebar_home{ visibility: hidden;}
   `}</style>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(MAPID_AI);
