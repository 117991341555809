//Library modul
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

//Personal Component

//Redux function
import {
  setPathname,
  getBankData,
  getCurrentBankData,
  getNotificationList,
} from "../../App/actions/authActions";

//Picture Asset

//General Function
import isEmpty from "../../App/validation/is-empty";
import formatDate from "../../App/validation/format-date";
import dict from "../../Data/dict.json";

class NotificationList extends Component {
  componentDidMount() {
    const { isAuthenticated } = this.props.auth;
    if (isAuthenticated) {
      this.props.getBankData();
      this.props.getCurrentBankData();
      this.props.getNotificationList();
    }
  }

  onClickPathname = (pathname) => {
    this.props.setPathname(pathname);
  };

  render() {
    const language = localStorage?.language ? localStorage?.language : "ina";

    const {
      user,
      current_bank_data,
      isAuthenticated,
      loadingProcess,
      itemLoading,
    } = this.props.auth;
    const notification_list = this.props.auth?.notification_list
      ? this.props.auth?.notification_list
      : [];
    //Step 1. Pisahkan notif log aktifitas project, group dengan lainnya
    const project_type = [
      "layer_create",
      "layer_import",
      "layer_edit_general",
      "layer_push_project_attach",
      "layer_delete_layer_attach",
      "layer_delete_original",
      "project_create",
      "project_edit",
      "layer_ordering_fields",
      "layer_push_feature",
      "layer_push_feature_form",
      "layer_edit_geometry",
      "layer_edit_properties",
      "layer_delete_feature",
      "layer_push_field",
      "layer_clone_fields",
      "layer_edit_field",
      "layer_delete_field",
      "project_push_folder",
      "project_delete_folder",
      "project_push_member",
      "project_push_group",
      "project_edit_member",
      "project_delete_member",
      "project_delete_group",
    ];
    const group_type = [
      "project_delete",
      "group_edit",
      "group_delete_member",
      "group_edit_member",
      "group_add_member",
    ];
    //Step 2. Generate notif_induk
    let notif_induk = notification_list.map((n) => {
      let induk_id;
      let type = n.type;
      if (project_type.includes(n.type)) {
        induk_id = n?.geo_projects[0]?.geo_project?._id
          ? n?.geo_projects[0]?.geo_project?._id
          : "";
        type = "project_notif";
      } else if (group_type.includes(n.type)) {
        induk_id = n?.groups[0]?.group?._id ? n?.groups[0]?.group?._id : "";
        type = "group_notif";
      } else {
        induk_id = n._id;
        type = n.type;
      }
      const final_obj = {
        ...n,
        induk_id,
        type,
      };
      return final_obj;
    });
    //Step 3. Hilangkan duplikat di notif_induk
    notif_induk = notif_induk.filter(
      (thing, index, self) =>
        index === self.findIndex((t) => t.induk_id === thing.induk_id)
    );
    //Step 4. Kelompokan notif array jika project atau group nya sama
    notif_induk = notif_induk.map((n) => {
      let notif_array = [];
      let final_notif = { ...n };
      if (n.type === "project_notif") {
        notif_array = notification_list.filter(
          (f) =>
            f?.geo_projects[0]?.geo_project?._id === n.induk_id &&
            project_type.includes(f.type)
        );
        //filter notif yang sama, pelakunya sama, waktunya berdekatan
        notif_array = notif_array.filter(
          (thing, index, self) =>
            index ===
            self.findIndex(
              (t) =>
                t.type === thing.type &&
                t.users_active?.[0]?.user?._id ===
                  thing.users_active?.[0]?.user?._id &&
                t.gis_layers?.[0]?.gis_layer?._id ===
                  thing.gis_layers?.[0]?.gis_layer?._id
            )
        );
        final_notif = { ...notif_array[0], type: "project_notif", notif_array };
      } else if (n.type === "group_notif") {
        notif_array = notification_list.filter(
          (f) =>
            f?.groups[0]?.group?._id === n.induk_id &&
            group_type.includes(f.type)
        );
        //filter notif yang sama, pelakunya sama, waktunya berdekatan
        notif_array = notif_array.filter(
          (thing, index, self) =>
            index ===
            self.findIndex(
              (t) =>
                t.type === thing.type &&
                t.users_active?.[0]?.user?._id ===
                  thing.users_active?.[0]?.user?._id
            )
        );
        final_notif = { ...notif_array[0], type: "group_notif", notif_array };
      }
      return final_notif;
    });
    let list_content;
    if (isAuthenticated) {
      const notification_list_income = notification_list.filter(
        (e) => e.type === "data_exchange_seller_bill"
      );
      let income_notif;
      if (notification_list_income.length > 0 && isEmpty(current_bank_data)) {
        income_notif = (
          <section className="container_notification">
            <div style={{ marginBottom: "10px" }}>
              {
                dict[
                  "There is an income, enter your bank data to withdraw it."
                ][language]
              }
            </div>
            <br />
            <div onClick={this.onClickPathname.bind(this, "/income_list")}>
              <Link
                to="/income_list"
                className="button background_blue"
                id="blue"
              >
                {dict["Withdraw"][language]}
              </Link>
            </div>
          </section>
        );
      }
      if (
        loadingProcess &&
        itemLoading === "get_notification_list" &&
        notification_list.length === 0
      ) {
        list_content = (
          <div className="text_medium">
            {dict?.["Loading project notifications"]?.[language]}
          </div>
        );
      } else {
        list_content = (
          <main>
            {income_notif}
            {notif_induk.map((notification, idx) => {
              const {
                type,
                users,
                gis_layers,
                date,
                blogs,
                geo_projects,
                groups,
              } = notification;
              const notif_array = notification?.notif_array
                ? notification?.notif_array
                : [];
              let unit_content = null;
              if (type === "layer_comment") {
                const users_filtered = users.filter(
                  (user_element) => user_element.user._id !== user._id
                );
                const last_user =
                  users_filtered[users_filtered.length - 1]?.user;
                const first_user_name = last_user?.full_name
                  ? last_user?.full_name
                  : last_user?.name
                  ? last_user?.name
                  : dict["You"][language];
                let other_user;
                if (users_filtered.length > 1) {
                  other_user = (
                    <div>
                      {dict["and"][language]} <b>{users_filtered.length - 1}</b>{" "}
                      {dict["other people"][language]}
                    </div>
                  );
                }
                const gis_layer = gis_layers?.[0]?.gis_layer;
                unit_content = (
                  <main className="container_notification" key={idx}>
                    <section className="text_inferior">
                      {dict["New comment"][language]}
                    </section>
                    <section className="text_bold">
                      {gis_layer?.name
                        ? gis_layer?.name
                        : `(${dict["layer has been deleted"][language]})`}
                    </section>
                    <section className="text_inferior">
                      {dict["New comment in"][language]}{" "}
                      <Link
                        to={`/editor/${gis_layer?.geo_project?.link}/${gis_layer?.link}/chat`}
                      >
                        <b>
                          {gis_layer?.name
                            ? gis_layer?.name
                            : `(${dict["layer has been deleted"][language]})`}
                        </b>
                      </Link>{" "}
                      {dict["by"][language]} <b>{first_user_name}</b>{" "}
                      {other_user}
                    </section>
                    <Link
                      className="button_very_small"
                      id="blue"
                      to={`/editor/${gis_layer?.geo_project?.link}/${gis_layer?.link}/chat`}
                    >
                      {dict["Open"][language]}
                    </Link>
                    <section className="text_inferior">
                      {formatDate(date)}
                    </section>
                  </main>
                );
              } else if (type === "project_notif" && notif_array.length > 0) {
                const geo_project = geo_projects?.[0]?.geo_project;
                const notif_array_first = notif_array.slice(0, 3);
                const gis_layer = gis_layers?.[0]?.gis_layer;
                unit_content = (
                  <main className="container_notification" key={idx}>
                    <section className="text_inferior">
                      {notif_array.length}{" "}
                      {dict["project activities"][language]}
                    </section>
                    <section className="text_bold">
                      {geo_project?.name
                        ? geo_project?.name
                        : `(${dict["project has been deleted"][language]})`}
                    </section>
                    {notif_array_first.map((n, idx) => {
                      const { users_active, type, date } = n;
                      const user = users_active?.[0]?.user;
                      let content = (
                        <main
                          key={idx}
                          className="text_inferior two_container_auto"
                        >
                          <section>
                            {user?.full_name ? user?.full_name : user?.name}{" "}
                            <b>{dict?.[type]?.[language]}</b> {gis_layer?.name}{" "}
                            {notification?.text}
                          </section>
                          <section style={{ textAlign: "right" }}>
                            {formatDate(date)}
                          </section>
                        </main>
                      );
                      return content;
                    })}
                    <Link
                      className="button_very_small"
                      id="blue"
                      to={`/editor/${geo_project?.link}/no_layer/notif`}
                    >
                      {dict["View more"][language]}
                    </Link>
                    <section className="text_inferior">
                      {formatDate(date)}
                    </section>
                  </main>
                );
              } else if (type === "group_notif" && notif_array.length > 0) {
                const group = groups?.[0]?.group;
                const notif_array_first = notif_array.slice(0, 3);
                unit_content = (
                  <main className="container_notification" key={idx}>
                    <section className="text_inferior">
                      {notif_array.length} {dict["group activities"][language]}
                    </section>
                    <section className="text_bold">
                      {group?.name
                        ? group?.name
                        : `(${dict["group has been deleted"][language]})`}
                    </section>
                    {notif_array_first.map((n, idx) => {
                      const { users_active, type, date } = n;
                      const user = users_active?.[0]?.user;
                      let content = (
                        <main
                          key={idx}
                          className="text_inferior two_container_auto"
                        >
                          <section>
                            {user?.full_name ? user?.full_name : user?.name}{" "}
                            <b>{dict?.[type]?.[language]}</b>
                          </section>
                          <section style={{ textAlign: "right" }}>
                            {formatDate(date)}
                          </section>
                        </main>
                      );
                      return content;
                    })}
                    <Link
                      className="button_very_small"
                      id="blue"
                      to={`/group/${group?._id}/notif`}
                    >
                      {dict["View more"][language]}
                    </Link>
                    <section className="text_inferior">
                      {formatDate(date)}
                    </section>
                  </main>
                );
              } else if (type === "request_free_import") {
                const blog = blogs?.[0]?.blog;
                unit_content = (
                  <section className="container_notification" key={idx}>
                    {
                      dict["Someone requests access to data on your blog"][
                        language
                      ]
                    }
                    <Link to={`blog_list/${blog?.link}`}>
                      <section
                        onClick={this.onClickPathname.bind(
                          this,
                          `blog_list/${blog?.link}`
                        )}
                      >
                        <b>{blog?.title}</b>
                      </section>
                    </Link>
                    <section className="text_inferior">
                      {formatDate(date)}
                    </section>
                  </section>
                );
              } else if (type === "action_request_free_import") {
                const blog = blogs?.[0]?.blog;
                unit_content = (
                  <section className="container_notification" key={idx}>
                    {dict["Your request to access has been accepted"][language]}

                    <Link to={`blog_read/${blog?.link}`}>
                      <section
                        onClick={this.onClickPathname.bind(
                          this,
                          `blog_read/${blog?.link}`
                        )}
                      >
                        <b>{blog?.title}</b>
                      </section>
                    </Link>
                    <section className="text_inferior">
                      {formatDate(date)}
                    </section>
                  </section>
                );
              } else if (type === "data_exchange_buyer") {
                unit_content = (
                  <section className="container_notification" key={idx}>
                    {
                      dict["Your data exchange payment has been successful"][
                        language
                      ]
                    }
                    {blogs.map((e) => {
                      return (
                        <Link to={`blog_read/${e?.blog?.link}`} key={e?._id}>
                          <section
                            onClick={this.onClickPathname.bind(
                              this,
                              `blog_read/${e?.blog?.link}`
                            )}
                            style={{ overflowY: "hidden", maxHeight: "40px" }}
                          >
                            <b>{e?.blog?.title}</b>
                          </section>
                        </Link>
                      );
                    })}
                    <section className="text_inferior">
                      {formatDate(date)}
                    </section>
                  </section>
                );
              } else if (type === "data_exchange_seller_transfer") {
                const amount = notification?.payments?.[0]?.payment?.amount;
                unit_content = (
                  <section className="container_notification" key={idx}>
                    {dict["Balance of"][language]}{" "}
                    <b>
                      Rp{" "}
                      {new Intl.NumberFormat("id-ID", {
                        style: "decimal",
                      }).format(amount)}{" "}
                    </b>
                    {
                      dict["has been transferred to your bank account."][
                        language
                      ]
                    }
                    <section className="text_inferior">
                      {formatDate(date)}
                    </section>
                  </section>
                );
              }
              return unit_content;
            })}
          </main>
        );
      }
    }
    return <main>{list_content}</main>;
  }
}
const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, {
  setPathname,
  getBankData,
  getCurrentBankData,
  getNotificationList,
})(NotificationList);
