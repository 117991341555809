import React, { Component } from "react";
import { connect } from "react-redux";
import markdown_to_html from "../../App/validation/markdown_to_html";

class MARKDOWN_RENDERER extends Component {
  constructor(props) {
    super(props);
    this.state = {
      htmlContent: "",
    };
  }

  componentDidMount() {
    this.updateHtmlContent();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.markdownContent !== this.props.markdownContent) {
      this.updateHtmlContent();
    }
  }

  updateHtmlContent() {
    const { markdownContent } = this.props;
    const htmlContent = markdown_to_html(markdownContent);
    this.setState({ htmlContent }, () => {
      if (window.MathJax) {
        window.MathJax.typesetPromise();

        const formulas = document.querySelectorAll(".formula");
        formulas.forEach((formula) => {
          formula.style.whiteSpace = "nowrap";
          formula.style.overflowX = "auto";
          formula.style.fontSize = "10px";
        });
      }
    });
  }

  render() {
    const { htmlContent } = this.state;
    return (
      <div
        style={{
          whiteSpace: "pre-wrap",
        }}
        className="markdown-content"
        dangerouslySetInnerHTML={{ __html: htmlContent }}
      />
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(MARKDOWN_RENDERER);
