/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";
import { FileUploader } from "react-drag-drop-files";

/*COMPONENT*/
import Modal from "../common_modal/Modal";
import UploadFileGeoOrCsv from "./UploadFileGeoOrCsv";
import GeojsonDetails from "./GeojsonDetails";
import DataTypeConfirmation from "./DataTypeConfirmation";
import NaNEvidence from "./NaNEvidence";

/*REDUX*/
import { importLayer, deleteLayer } from "../../App/actions/layerNewActions";

/*PICTURE*/

/*FUNCTION*/
import { generate_geo_layer } from "../../App/validation/generateGeoJson";
import { is_lng_lat_valid } from "../../App/validation/geojson_validation";
import { handle_upload_geojson } from "../../App/validation/handle_file";

/*DATA*/
import dict from "../../Data/dict.json";

/*CONST*/

class ImportGeojson extends Component {
  constructor(props) {
    super(props);
    this.state = {
      folder: "",
      modal_upload: false,
      is_detail_open: false,
      detail_index: "",
      mode_step: "",
      file: {},
      warning: {
        is_open: false,
        content_name: "",
        message: "",
      },
      selected_color: "#1a8bc0",
      mode_color: "picker",
      files_geojson: [],
      is_not_fly_to_batch_geojson: true,
      is_uploading: false,
      target_data_types: [],
      nan_evidence: {},
    };
  }

  toggleModeColor = (event) => {
    if (event.target.value === "random") {
      this.setState({
        selected_color: "random",
        mode_color: event.target.value,
      });
    } else {
      this.setState({ mode_color: event.target.value });
    }
  };

  handleChangeGeojsonFiles = async (files_object) => {
    const { files_geojson } = this.state;
    let files = [];
    for (let key of Object.keys(files_object)) {
      const file = files_object[key];
      const new_file = await this.handleValidationFiles(file);
      if (new_file) {
        files.push(new_file);
      }
    }

    files.sort((a, b) =>
      a.fileName > b.fileName ? 1 : b.fileName > a.fileName ? -1 : 0
    );

    const files_with_new_format = [...files_geojson, ...files];

    this.setState({
      files_geojson: files_with_new_format,
    });
  };

  set_target_data_types = (value) => {
    this.setState({
      target_data_types: value,
    });
  };

  set_nan_evidence = (value) => {
    this.setState({
      nan_evidence: value,
    });
  };

  handleValidationFiles = async (file) => {
    const filename = file.name.split(".")[0];
    const ext = file.name.split(".").at(-1);
    const { files_geojson } = this.state;
    const is_any = files_geojson.find(
      (element) => element.fileName === filename
    );

    if (is_any === undefined && ext?.toLowerCase() === "geojson") {
      let new_file = await handle_upload_geojson(file);

      if (
        new_file?.validation_result?.is_valid_in_general &&
        new_file?.validation_result?.invalid_number > 0 &&
        new_file?.validation_result?.valid_number > 0
      ) {
        new_file["status"] = "semi-valid";
      } else if (new_file?.validation_result?.is_valid_in_general) {
        new_file["status"] = "valid";
      } else {
        new_file["status"] = "invalid";
      }

      return new_file;
    } else {
      return undefined;
    }
  };

  saveGeojsonFilesToDatabase = async () => {
    const { files_geojson } = this.state;
    const geo_project_id = this.props.project?.geo_project?._id;
    const filtered_files_geojson = files_geojson.filter(
      (file) =>
        file.validation_result?.is_valid_in_general &&
        (file.status === "valid" ||
          file.status === "failed" ||
          file.status === "semi-valid")
    );
    const timer = (ms) => new Promise((res) => setTimeout(res, ms));
    this.setState({ is_uploading: true }, async () => {
      for (let file of filtered_files_geojson) {
        this.setIsBatchGeojsonUploaded(file.fileName, "uploading");
        const { isSuccess, error, layer_id } = await this.handleSaveGeojson(
          file.data,
          file.fileName,
          file.headers
        );
        await timer(5000);
        if (isSuccess) {
          this.setIsBatchGeojsonUploaded(file.fileName, "success");
        } else {
          let body = {
            geo_project_id: geo_project_id,
            geo_layer_id: layer_id,
          };
          await this.props.deleteLayer(body);
          this.setIsBatchGeojsonUploaded(file.fileName, "failed", error);
        }
      }
      this.setState({ is_uploading: false });
    });
  };

  toggleIsFlyToBatchGeojson = () => {
    this.setState({
      is_not_fly_to_batch_geojson: !this.state.is_not_fly_to_batch_geojson,
    });
  };

  removeGeojson = (filename) => {
    const { files_geojson } = this.state;
    const new_files_geojson = files_geojson.filter(
      (file) => file.fileName !== filename
    );
    this.setState({ files_geojson: new_files_geojson });
  };

  handleSaveGeojson = async (file, fileName, headers) => {
    const type = file?.features?.[0]?.geometry?.type || "Polygon";

    const {
      folder,
      selected_color,
      is_not_fly_to_batch_geojson,
      target_data_types,
    } = this.state;
    const { _id } = this.props.auth?.user;
    const geo_project_id = this.props.project?.geo_project?._id;
    const is_any_target_data_types = target_data_types?.length > 0;

    const geojson = generate_geo_layer(
      {
        columns: is_any_target_data_types ? target_data_types : headers,
        is_use_data_type: is_any_target_data_types ? true : false,
        type,
      },
      {
        fileName,
        file: file?.features,
        colorStatus: selected_color,
        user_id: _id,
        geo_project_id,
        folder,
      }
    );

    const response = await this.props.importLayer(
      geojson,
      is_not_fly_to_batch_geojson
    );

    return response;
  };

  setFile = (value) => {
    this.setState({
      file: value,
    });
  };

  validateTheData = async () => {
    const language = localStorage?.language ? localStorage?.language : "ina";
    const latitude = this.state.file?.latitude || "";
    const longitude = this.state.file?.longitude || "";

    if (!latitude || !longitude) {
      this.toggleModalWarning("no_lat_long_header", true);
    } else {
      const { file } = this.state;
      let result;
      for (let row of file?.data) {
        let lat = row[latitude];
        let long = row[longitude];

        result = is_lng_lat_valid(long, lat);
        if (!result) {
          break;
        }
      }

      if (result) {
        await this.handleSaveFile();
        this.handleResetCsv();
        this.toggleUpload(false);
      } else {
        this.toggleModalWarning(
          "invalid_warning",
          true,
          `${dict["The accepted coordinate type is latitude longitude of type WGS84 / CGS in decimal degrees."][language]} ${dict["Decimal must be separated by dot. Ex: 106.8858778"][language]}`
        );
      }
    }
  };

  toggleColor = (event) => {
    this.setState({ selected_color: event.target.value });
  };

  toggleModalWarning = (content_name, value, message) => {
    const { warning } = this.state;
    this.setState({
      warning: {
        is_open: value || !warning.is_open,
        content_name: content_name || "",
        message: message || "",
      },
    });
  };

  setLongitude = (event) => {
    this.setState({
      file: {
        ...this.state.file,
        longitude: event.target.value,
      },
    });
  };

  handleSaveFile = async () => {
    const {
      file,
      folder,
      warning,
      modal_upload,
      selected_color,
      is_fly_to_batch_geojson,
    } = this.state;
    const { _id } = this.props.auth?.user;
    const geo_project_id = this.props.project?.geo_project?._id;

    const geojson = generate_geo_layer(
      {
        columns: file?.headers,
      },
      {
        fileName: file?.fileName,
        file: file?.data,
        colorStatus: selected_color,
        user_id: _id,
        geo_project_id,
        folder,
        latitude: file?.latitude,
        longitude: file?.longitude,
      }
    );
    this.toggleStep("");
    if (warning.is_open || modal_upload) {
      this.setState({
        warning: {
          is_open: false,
          content_name: "",
        },
        modal_upload: false,
      });
    }

    await this.props.importLayer(geojson, is_fly_to_batch_geojson);
  };

  setLatitude = (event) => {
    const { file } = this.state;
    this.setState({
      file: {
        ...file,
        latitude: event.target.value,
      },
    });
  };

  handleResetCsv = () => {
    this.setState({
      file: {},
      files_geojson: [],
      mode_step: "",
      selected_color: "#1a8bc0",
      mode_color: "picker",
    });
  };

  handleClearGeojson = () => {
    this.setState({
      files_geojson: [],
    });
  };

  toggleStep = (value) => {
    this.setState({ mode_step: value });
  };

  toggleUpload = (value) => {
    this.setState({ modal_upload: value || !this.state.modal_upload });
  };

  toggleModalDetail = (value, idx) => {
    this.setState({
      is_detail_open: value || !this.state.is_detail_open,
      detail_index: idx,
    });
  };

  handleChange(e) {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    if (target.type === "button background_blue") {
      this.setState({
        isRandomColor: !this.state.isRandomColor,
      });
    } else {
      this.setState({
        [name]: value,
      });
    }
  }

  setIsBatchGeojsonUploaded = (filename, value, error) => {
    const { files_geojson } = this.state;
    const index = files_geojson.findIndex((file) => file.fileName === filename);
    files_geojson[index]["status"] = value;
    if (value === "success") {
      delete files_geojson?.[index]?.["file"];
    }

    if (error) {
      files_geojson[index]["validation_result"]["error"] = error;
      files_geojson[index]["validation_result"]["is_valid_in_general"] = false;
    }

    this.setState({
      files_geojson: files_geojson,
    });
  };

  handleSingleFile = async () => {
    const { data, fileName, headers } = this.state.file;

    const geo_project_id = this.props.project?.geo_project?._id;
    const { isSuccess, error, layer_id } =
      (await this.handleSaveGeojson(data, fileName, headers)) || {};
    if (!isSuccess) {
      let body = {
        geo_project_id: geo_project_id,
        geo_layer_id: layer_id,
      };
      await this.props.deleteLayer(body);
      this.toggleModalWarning("failed_to_Upload", true, error);
    }
    this.handleResetCsv();
    this.toggleUpload(false);
  };

  render() {
    const language = localStorage?.language || "ina";
    const {
      modal_upload,
      folder,
      mode_step,
      file,
      warning,
      is_detail_open,
      detail_index,
      files_geojson,
      is_not_fly_to_batch_geojson,
      is_uploading,
      target_data_types,
      nan_evidence,
    } = this.state;

    const { headers } = file;

    const { loadingProcess, itemLoading } = this.props.layer;
    const { geo_project, loadingDetail } = this.props.project;
    const { folders } = geo_project;
    let folders_state = [];
    if (!loadingDetail && geo_project.folders) {
      folders_state = folders.slice();
      folders_state.unshift({
        name: dict?.["Outside folders"]?.[language],
        _id: "",
      });
    }

    const button_upload = (
      <button
        onClick={() => this.toggleUpload()}
        className="button background_blue"
        data-mapid="clickUploadFile2"
      >
        {dict?.["Upload file"]?.[language]}
      </button>
    );

    let latlong_setup_ui = (
      <section style={{ display: "grid", gridTemplateColumns: "auto auto" }}>
        <div
          style={{
            marginRight: "20px",
          }}
        >
          <h2
            style={{
              fontSize: "20px",
            }}
          >
            Latitude:
          </h2>
          <span>
            <select onChange={this.setLatitude} data-mapid="selectLatitude">
              <option value="">Select</option>
              {headers?.map((header, idx) => (
                <option key={idx} value={header}>
                  {header}
                </option>
              ))}
            </select>
          </span>
        </div>

        <div>
          <h2
            style={{
              fontSize: "20px",
            }}
          >
            Longitude:
          </h2>
          <span>
            <select onChange={this.setLongitude} data-mapid="selectLongitude">
              <option value="">Select</option>
              {headers?.map((header, idx) => (
                <option key={idx} value={header}>
                  {header}
                </option>
              ))}
            </select>
          </span>
        </div>
      </section>
    );

    let modal_content = (
      <main>
        <section style={{ textAlign: "center" }} className="text_bold">
          {dict?.["Upload File"]?.[language]}
        </section>
        <div style={{ display: "block" }}>
          {dict?.["Folder layer"]?.[language]}
        </div>
        <select
          id="folder"
          name="folder"
          value={folder}
          className="input_new"
          onChange={this.handleChange.bind(this)}
        >
          {folders_state.map(({ _id, name }, idx) => {
            return (
              <option key={idx} value={_id}>
                {name}
              </option>
            );
          })}
        </select>
        <section>
          <UploadFileGeoOrCsv
            file={file}
            setFile={this.setFile}
            toggleStep={this.toggleStep}
            folder={folder}
            toggleUpload={this.toggleUpload}
          />
        </section>
        <div className="button margin_top background_blue">
          <a
            className="text_white"
            href="https://doc.mapid.io/geo/ketentuan_upload_file_pada_platform_geo_mapid"
            target="blank"
          >
            {dict?.["See documentation"]?.[language]}
          </a>
        </div>
      </main>
    );
    if (loadingProcess && itemLoading === "importLayer") {
      modal_content = (
        <section style={{ textAlign: "center" }} className="text_bold">
          {dict["Uploading"][language]}...
        </section>
      );
    }
    if (mode_step === "file-step-1") {
      const { data, headers, latitude, longitude } = this.state.file;
      modal_content = (
        <div>
          {latlong_setup_ui}

          <section className="scroll_auto">
            <table className="table w_full">
              <thead>
                <tr>
                  {headers?.map((header, idx) => (
                    <th key={idx} className="col">
                      {header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data?.slice(0, 5).map((row, idx_row) => (
                  <tr key={idx_row}>
                    {headers?.map((header, idx_col) => (
                      <td key={idx_col}>{row[header]}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </section>
          <section className="marginTop_10 flex justify_between align_center">
            <button
              onClick={this.handleResetCsv}
              className="button background_blue"
            >
              Back
            </button>

            <button
              style={{
                backgroundColor:
                  !latitude || !longitude ? "#777777" : "#0ca5eb",
              }}
              onClick={this.validateTheData}
              className="button background_blue"
              data-mapid="clickNext"
            >
              Next
            </button>
          </section>
        </div>
      );
    } else if (mode_step === "geojson-step-1") {
      modal_content = (
        <div>
          <label className="font_30">{dict["Type data"][language]}</label>
          <section>
            {`total features = ${file?.data?.features?.length}`}
          </section>

          <section className="h_800 w_full">
            <DataTypeConfirmation
              file={file}
              set_target_data_types={this.set_target_data_types}
              target_data_types={target_data_types}
            />
          </section>

          <section className="marginTop_10 flex justify_between align_center">
            <button
              onClick={this.handleResetCsv}
              className="button background_blue"
            >
              Back
            </button>

            <button
              className="button background_blue"
              onClick={() => {
                this.toggleStep("geojson-step-2");
                var myDiv = document.getElementById(
                  "modal_upload_not_a_number"
                );
                myDiv.scrollTop = 0;
              }}
            >
              {dict["Next"][language]}
            </button>
          </section>
        </div>
      );
    } else if (mode_step === "geojson-step-2") {
      modal_content = (
        <div>
          <label className="font_20">NaN Feature</label>
          <section>
            {
              dict?.[
                "If you confirm, these NaN (Not a Number) values will be change to 0"
              ]?.[language]
            }
          </section>
          <section>
            <NaNEvidence
              nan_evidence={nan_evidence}
              set_nan_evidence={this.set_nan_evidence}
              target_data_types={target_data_types}
              file={file}
            />
          </section>
          <section className="margin_top flex justify_between align_center">
            <button
              onClick={() => this.toggleStep("geojson-step-1")}
              className="button background_blue"
            >
              Back
            </button>

            <button
              className="button background_blue"
              onClick={() => {
                this.handleSingleFile();
              }}
            >
              {dict?.["Confirm and start upload"]?.[language]}
            </button>
          </section>
        </div>
      );
    } else if (mode_step === "bulk-step-1") {
      modal_content = (
        <div>
          <section>
            <h1>Batch Upload</h1>
          </section>
          <section className="center_perfect">
            <FileUploader
              classes="container_upload absolute border_dash"
              multiple={true}
              handleChange={this.handleChangeGeojsonFiles}
              name="file"
              types={["GEOJSON"]}
              children={
                <div
                  className="container_upload center_perfect"
                  data-mapid="uploadBulk"
                >
                  <div>Drop geojson files here</div>
                </div>
              }
            />
          </section>
          <section className="w_full flex justify_between margin_top">
            <span>
              <button
                className={`multi_check ${
                  is_not_fly_to_batch_geojson === true
                    ? "bg_grey"
                    : "bg_darkBlue"
                }`}
                onClick={() => {
                  this.toggleIsFlyToBatchGeojson();
                }}
              />
              <label className="w_400">
                {
                  dict?.["Fly to all of these layers after upload complete"]?.[
                    language
                  ]
                }
              </label>
            </span>
            <div>
              <button
                onClick={() => {
                  this.handleClearGeojson();
                }}
                className="button background_blue"
                data-mapid="clickClear"
              >
                {dict["Clear All"][language]}
              </button>
            </div>
          </section>

          <section>
            <ul className="card_parent">
              {files_geojson?.map((file, idx) => {
                let color_status;
                if (file.status === "valid") {
                  color_status = "#0ca5eb";
                } else if (file.status === "semi-valid") {
                  color_status = "#f25a1d";
                } else if (file.status === "invalid") {
                  color_status = "#d41616";
                } else if (file.status === "success") {
                  color_status = "#44a889";
                } else if (file.status === "failed") {
                  color_status = "#d41616";
                } else {
                  color_status = "#616161";
                }

                return (
                  <li
                    className="card_list bg_lightGrey grid grid_15 padding_2"
                    key={idx}
                  >
                    <div className="font_14 center_perfect">{`${
                      idx + 1
                    }.`}</div>
                    <div className="text_left flex align_center span_10">
                      {file.fileName}
                    </div>
                    <div
                      className="span_4"
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        gap: "10px",
                      }}
                    >
                      <div
                        className="button center_perfect background_blue"
                        onClick={() => this.toggleModalDetail(true, idx)}
                      >
                        i
                      </div>
                      <div
                        className="container_card center_perfect"
                        style={{
                          background: color_status,
                          color: "#ffffff",
                        }}
                      >
                        {file.status}
                      </div>
                      <button
                        className="center_perfect"
                        style={{
                          background: !is_uploading ? "#d41616" : "#e4e4e4",
                          color: "#ffffff",
                          height: "35px",
                          width: "35px",
                          borderRadius: "5px",
                          fontSize: "20px",
                          cursor: !is_uploading ? "pointer" : "default",
                        }}
                        onClick={() => {
                          if (!is_uploading) {
                            this.removeGeojson(file.fileName);
                          }
                        }}
                        data-mapid={`clickDelete-${idx}`}
                      >
                        x
                      </button>
                    </div>
                  </li>
                );
              })}
            </ul>
          </section>
          <section className="margin_top flex justify_between align_center">
            <button
              onClick={() => {
                if (!is_uploading) {
                  this.handleResetCsv();
                }
              }}
              style={{
                cursor: is_uploading ? "default" : "pointer",
                background: is_uploading ? "#c4c4c4" : "#0ca5eb",
              }}
              className="button background_blue"
            >
              Back
            </button>

            <button
              onClick={() => {
                if (!is_uploading) {
                  this.saveGeojsonFilesToDatabase();
                }
              }}
              style={{
                cursor: is_uploading ? "default" : "pointer",
                background: is_uploading ? "#c4c4c4" : "#0ca5eb",
              }}
              className="button background_blue"
              data-mapid="clickNext"
            >
              Next
            </button>
          </section>
        </div>
      );
    } else if (mode_step === "invalid-file-content") {
      const { error } = file;

      modal_content = (
        <div>
          <section>
            <h2>{dict["Invalid file content"][language]}</h2>
            {error && <h3>{error}</h3>}
          </section>
          <section className="marginTop_10 flex justify_between align_center">
            <button
              onClick={this.handleResetCsv}
              className="button background_blue"
            >
              Back
            </button>
          </section>
        </div>
      );
    }

    const modalJsonDetailContent = is_detail_open && (
      <Modal
        modalSize="small"
        id="modal"
        isOpen={is_detail_open}
        onClose={this.toggleModalDetail}
      >
        <main className="box-body">
          <GeojsonDetails geojson_list={files_geojson} index={detail_index} />
        </main>
      </Modal>
    );

    const warning_no_long_lat_header_content = (
      <div>
        <p style={{ width: "350px", textAlign: "center" }}>
          {dict?.["Warning latitude longitude"]?.[language]}
        </p>
        <div className="center_perfect">
          <button
            onClick={async () => {
              await this.handleSaveFile();
              this.toggleUpload(false);
            }}
            className="button background_blue"
          >
            Next
          </button>
        </div>
      </div>
    );

    const invalid_warning_content = (
      <div>
        <p style={{ width: "350px", textAlign: "center" }}>
          {warning?.message}
        </p>
      </div>
    );

    const failed_to_upload_content = (
      <main>
        <header className="text_center bold font_20">Error</header>
        <section>
          <label className="bold font_14">Error Message:</label>
          <p className="padding_5 h_full border rounded_5">
            {warning?.message}
          </p>
        </section>
      </main>
    );

    const modalWarning = warning.is_open && (
      <Modal
        modalSize="small"
        id="modal"
        isOpen={warning.is_open}
        onClose={this.toggleModalWarning}
      >
        <main className="box-body">
          {warning.content_name === "no_lat_long_header" &&
            warning_no_long_lat_header_content}
          {warning.content_name === "invalid_warning" &&
            invalid_warning_content}
          {warning.content_name === "failed_to_Upload" &&
            failed_to_upload_content}
        </main>
      </Modal>
    );

    let size = "large";
    if (mode_step === "invalid-file-content") {
      size = "small";
    }

    const modal_upload_content = modal_upload && (
      <Modal
        modalSize={size}
        id="modal"
        isOpen={modal_upload}
        onClose={() => this.toggleUpload(false)}
      >
        <main id="modal_upload_not_a_number" className="box-body">
          {modal_content}
        </main>
      </Modal>
    );
    return (
      <main className="white-link">
        {button_upload}
        {modal_upload_content}
        <br />
        <a
          href="https://doc.mapid.io/geo/ketentuan_upload_file_pada_platform_geo_mapid"
          target="blank"
        >
          {dict["See documentation"][language]}
        </a>
        {modalWarning}
        {modalJsonDetailContent}
      </main>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    project: state.project,
    layer: state.layer,
    auth: state.auth,
  };
};
export default connect(mapStateToProps, {
  importLayer,
  deleteLayer,
})(ImportGeojson);
