/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/
import { set_value_properties } from "../../App/actions/propertiesActions";
import { status_action } from "../../App/actions/mapActions";
import {
  filter_isi_layer,
  reset_filter,
  undo_filter_isi_layer,
} from "../../App/actions/dataActions";

/*PICTURE ASSET*/

/*GENERAL FUNCTION & DATA*/

/*NON IMPORT*/

class LEGEND_TEXT extends Component {
  state = {};

  on_filter_isi_layer = (field_key, value) => {
    const { geo_layer_list, layer_id } = this.props.layer;
    const layer = geo_layer_list.find(
      (item) => item?.geo_layer?._id === layer_id
    )?.geo_layer;
    const fields = layer?.fields || [];
    let field = fields.find((f) => f.key === field_key);
    let array_value_filter = field?.array_value_filter
      ? field?.array_value_filter
      : [];
    const body = {
      geo_layer_id: layer_id,
      field_key,
      value,
    };
    if (array_value_filter.includes(value)) {
      this.props.undo_filter_isi_layer(body);
    } else {
      this.props.filter_isi_layer(body);
    }
  };

  on_reset_filter = () => {
    const { layer_id } = this.props.layer;
    const body = { geo_layer_id: layer_id };
    this.props.reset_filter(body);
  };

  render() {
    //local storage
    // const language = localStorage?.language ? localStorage?.language : "ina";

    //local state

    //global props
    const { geo_layer_list, layer_id } = this.props.layer;

    //content
    const layer = geo_layer_list.find(
      (item) => item?.geo_layer?._id === layer_id
    )?.geo_layer;
    const style_array = layer?.style_array || [];
    const default_style_key = layer?.default_style_key || "";
    const style_object = style_array.find(
      (item) => item.key === default_style_key
    );

    const style_type = style_object?.style_type;
    const paint_object = style_object?.paint_object || {};
    const field_key = style_object?.field_key;

    const fields = layer?.fields || [];
    let field = fields.find((f) => f.key === field_key);
    let array_value_filter = field?.array_value_filter
      ? field?.array_value_filter
      : [];

    let color_key;
    if (style_type === "circle") {
      color_key = "circle-color";
    } else if (style_type === "line") {
      color_key = "line-color";
    } else if (style_type === "fill") {
      color_key = "fill-color";
    }

    let array = paint_object?.[color_key] || [];
    const content = (
      <main style={{ maxHeight: "250px", overflowY: "auto" }}>
        <button
          className="button_small background_black margin_bottom"
          onClick={this.on_reset_filter}
          data-mapid="clickReset"
        >
          Reset
        </button>
        <table className="table full_width">
          <tbody>
            {array.map((item, idx) => {
              if (idx < 2) return null;
              if (idx % 2 === 0 && idx < array.length - 1) {
                return (
                  <tr
                    key={idx}
                    onClick={this.on_filter_isi_layer.bind(
                      this,
                      field_key,
                      item
                    )}
                    className="cursor_pointer"
                  >
                    <td>
                      <button
                        className={
                          array_value_filter.includes(item)
                            ? "checklist_active"
                            : "checklist_not"
                        }
                        data-mapid={`clickFilter-${idx}`}
                      />
                    </td>
                    <td>{item}</td>
                    <td style={{ width: "20px", verticalAlign: "middle" }}>
                      <div
                        style={{
                          backgroundColor: array[idx + 1],
                          width: "20px",
                          height: "20px",
                          border: "1px solid black",
                        }}
                      ></div>
                    </td>
                  </tr>
                );
              } else {
                return null;
              }
            })}
          </tbody>
        </table>
      </main>
    );
    return <main>{content}</main>;
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  layer: state.layer,
  properties: state.properties,
  loading_reducer: state.loading_reducer,
});

export default connect(mapStateToProps, {
  set_value_properties,
  status_action,
  filter_isi_layer,
  reset_filter,
  undo_filter_isi_layer,
})(LEGEND_TEXT);
