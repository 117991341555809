/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";
import centroid from "@turf/centroid";

/*COMPONENT*/
import CHART_TEMPLATE from "../viewer_chart/CHART_TEMPLATE";
import TOOLBOX_DETAIL_DISTANCE from "./TOOLBOX_DETAIL_DISTANCE";
import TOOLBOX_DETAIL_ELEVATION from "./TOOLBOX_DETAIL_ELEVATION";
import TOOLBOX_DETAIL_AREA from "./TOOLBOX_DETAIL_AREA";
import TOOLBOX_DETAIL_RADIUS from "./TOOLBOX_DETAIL_RADIUS";
import TOOLBOX_DETAIL_ISOKRON from "./TOOLBOX_DETAIL_ISOKRON";
import TOOLBOX_GRID from "./TOOLBOX_GRID";
import TOOLBOX_RIVER_SCHEMA from "./TOOLBOX_RIVER_SCHEMA";

/*REDUX*/
import {
  set_value_toolbox,
  change_radius,
  push_value_toolbox,
  generate_isokron,
  status_toolbox,
} from "../../App/actions/toolboxActions";
import { set_value_layer } from "../../App/actions/layerActions";
import { set_value_sini } from "../../App/actions/sini_v2";
import { status_action } from "../../App/actions/mapActions";

/*PICTURE*/

/*FUNCTION*/
import is_not_empty from "../../App/validation/is_not_empty";
import generate_uuid from "../../App/validation/generate_uuid";

/*DATA*/
import st_colors from "../../Data/st_colors.json";
import dict from "../../Data/dict.json";
import { domain_bbws_maluku_river_schema } from "../../App/validation/features_domain";

/*CONST*/
let mode_list = [
  {
    key: "river_schema",
    name: "Skema sungai",
    mode: "",
  },
  {
    key: "features_distance",
    name: "Distance",
    mode: "draw_line_string",
  },
  {
    key: "features_elevation",
    name: "Elevation",
    mode: "draw_line_string",
  },
  {
    key: "features_area",
    name: "Area",
    mode: "draw_polygon",
  },
  {
    key: "features_radius",
    name: "Radius",
    mode: "draw_point",
  },
  {
    key: "features_isokron",
    name: "Isochrone",
    mode: "draw_point",
  },
  {
    key: "scoring_grids",
    name: "Grid count point",
    mode: "",
  },
];

class TOOLBOX_TABLE extends Component {
  state = {
    modal_default_radius: false,
    selected_uuid: " ",
    option_isokron: { status: false, id: "" },
    data_new_isokron: {
      routing_profile: "driving",
      contour_type: "minutes",
      contours_minutes: "10",
      contours_meters: 0,
      distance: false,
    },
  };

  on_change_mode_toolbox = (event) => {
    //ganti props
    const mode_toolbox = event.target.value;
    this.props.set_value_toolbox({
      key: "mode_toolbox",
      value: mode_toolbox,
    });
    this.props.set_value_layer({
      key: "draw_state_platform",
      value: "",
    });
    this.props.set_value_toolbox({
      key: "mode_draw",
      value: "simple_select",
    });
    this.props.status_action();

    //reset draw map
    const { map_object } = this.props.layer;
    if (map_object) {
      map_object.getCanvas().style.cursor = "";
      map_object._controls[1].changeMode("simple_select");
    }
  };

  on_draw = () => {
    //state management draw
    this.props.set_value_layer({
      key: "draw_state_platform",
      value: "toolbox",
    });
    this.props.set_value_layer({
      key: "geometryStatus",
      value: false,
    });
    const { mode_toolbox } = this.props.toolbox;
    const mode_draw = mode_list.find((item) => item.key === mode_toolbox)?.mode;
    this.props.set_value_toolbox({
      key: "mode_draw",
      value: mode_draw,
    });
    this.props.status_action();

    //bagian map
    const { map_object } = this.props.layer;
    if (map_object && mode_draw && map_object?._controls?.[1]) {
      map_object.getCanvas().style.cursor = "crosshair";
      map_object._controls[1].changeMode(mode_draw);
    }
  };

  toggle_default_radius = () => {
    this.setState({ modal_default_radius: !this.state.modal_default_radius });
    this.props.set_value_toolbox({
      key: "uuid_radius",
      value: "",
    });
  };

  on_change_radius = (e) => {
    const id = e.target.id;
    const name = e.target.name;
    const value = e.target.value;
    const body = {
      uuid: id,
      key: name,
      value: value,
    };
    this.props.change_radius(body);
    this.props.status_toolbox();
  };

  on_change_props = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (name === "default_radius_m" || name === "default_radius_km") {
      let default_radius_km, default_radius_m;
      let { features_radius } = this.props.toolbox;
      if (is_not_empty(value)) {
        if (name === "default_radius_m") {
          default_radius_m = parseFloat(value) || 0;
          default_radius_km = parseFloat(value / 1000) || 0;
        } else if (name === "default_radius_km") {
          default_radius_km = parseFloat(value) || 0;
          default_radius_m = parseFloat(value * 1000) || 0;
        }
        features_radius = features_radius.map((item) => {
          item.properties.radius_km = default_radius_km;
          item.properties.radius_m = default_radius_m;
          return item;
        });

        this.props.set_value_toolbox({
          key: "default_radius_m",
          value: default_radius_m,
        });
        this.props.set_value_toolbox({
          key: "default_radius_km",
          value: default_radius_km,
        });
        this.props.set_value_toolbox({
          key: features_radius,
          value: features_radius,
        });
      }
    }
  };

  on_delete_item = (uuid) => {
    //remover marker by DOM
    const marker_to_remove = document.getElementById(`marker_toolbox_${uuid}`);
    if (marker_to_remove) {
      marker_to_remove.remove();
    }

    const { mode_toolbox } = this.props.toolbox;
    let features = this.props.toolbox[mode_toolbox];
    const { status, id } = this.state.option_isokron;
    features = features.filter((item) => item.properties.uuid !== uuid);
    this.props.set_value_toolbox({
      key: mode_toolbox,
      value: features,
    });

    if (mode_toolbox === "features_elevation") {
      let { features_elevation_point, features_elevation_center } =
        this.props.toolbox;
      features_elevation_point = features_elevation_point.filter(
        (item) => item.properties.parent_uuid !== uuid
      );
      features_elevation_center = features_elevation_center.filter(
        (item) => item.properties.uuid !== uuid
      );
      this.props.set_value_toolbox({
        key: "features_elevation_point",
        value: features_elevation_point,
      });
      this.props.set_value_toolbox({
        key: "features_elevation_center",
        value: features_elevation_center,
      });
    } else if (mode_toolbox === "features_distance") {
      let { features_distance_label, features_distance_center } =
        this.props.toolbox;
      features_distance_label = features_distance_label.filter(
        (item) => item.properties.parent_uuid !== uuid
      );
      features_distance_center = features_distance_center.filter(
        (item) => item.properties.uuid !== uuid
      );
      this.props.set_value_toolbox({
        key: "features_distance_label",
        value: features_distance_label,
      });
      this.props.set_value_toolbox({
        key: "features_distance_center",
        value: features_distance_center,
      });
    } else if (mode_toolbox === "features_area") {
      let { features_area_center } = this.props.toolbox;
      features_area_center = features_area_center.filter(
        (item) => item.properties.uuid !== uuid
      );
      this.props.set_value_toolbox({
        key: "features_area_center",
        value: features_area_center,
      });
    } else if (mode_toolbox === "features_isokron") {
      let { features_isokron_center, features_isokron } = this.props.toolbox;
      features_isokron_center = features_isokron_center.filter(
        (item) => item.properties.uuid !== uuid
      );
      features_isokron = features_isokron.filter(
        (item) => item.properties.uuid !== uuid
      );
      this.props.set_value_toolbox({
        key: "features_isokron_center",
        value: features_isokron_center,
      });
      this.props.set_value_toolbox({
        key: "features_isokron",
        value: features_isokron,
      });
    }

    let uuid_name;
    switch (mode_toolbox) {
      case "features_distance":
        uuid_name = "uuid_distance";
        break;
      case "features_area":
        uuid_name = "uuid_area";
        break;
      case "features_elevation":
        uuid_name = "uuid_elevation";
        break;
      case "features_radius":
        uuid_name = "uuid_radius";
        break;
      default:
        uuid_name = "uuid_distance";
        break;
    }
    this.props.set_value_toolbox({
      key: uuid_name,
      value: "",
    });

    if (status && id !== "") {
      this.setState({
        option_isokron: {
          status: false,
          id: "",
        },
      });
    }
    this.props.status_toolbox();
  };

  on_select_uuid = (uuid) => {
    const { mode_toolbox } = this.props.toolbox;
    let uuid_name;
    switch (mode_toolbox) {
      case "features_distance":
        uuid_name = "uuid_distance";
        break;
      case "features_area":
        uuid_name = "uuid_area";
        break;
      case "features_elevation":
        uuid_name = "uuid_elevation";
        break;
      case "features_radius":
        uuid_name = "uuid_radius";
        break;
      case "features_isokron":
        uuid_name = "uuid_isokron";
        break;
      default:
        uuid_name = "uuid_distance";
        break;
    }
    this.props.set_value_toolbox({
      key: uuid_name,
      value: uuid,
    });

    if (uuid) {
      const feature = this.props.toolbox[mode_toolbox].find(
        (item) => item?.properties?.uuid === uuid
      );
      const geojson = {
        type: "FeatureCollection",
        features: [feature],
      };
      const feature_center = centroid(geojson);
      const longitude = feature_center?.geometry?.coordinates?.[0];
      const latitude = feature_center?.geometry?.coordinates?.[1];
      const { map_object } = this.props.layer;
      if (map_object && longitude && latitude) {
        map_object.flyTo({
          center: [longitude, latitude],
          speed: 1,
        });
      }
    }
  };

  handle_open_isokron_opt = (item) => {
    const { status, id } = this.state.option_isokron;
    if (item.properties.contour_type === "meters") {
      this.setState({ distance: true });
      this.setState({ data_new_isokron: { contour_type: "meters" } });
    } else {
      this.setState({ distance: false });
      this.setState({ data_new_isokron: { contour_type: "minutes" } });
    }
    if (!status && id === "") {
      this.setState({
        option_isokron: {
          status: true,
          id: item.properties.uuid,
        },
      });
    }
  };

  on_change_opt_isokron = (event) => {
    const key = event.target.name;
    const value = event.target.value;
    if (value === "meters") {
      this.setState({ distance: true });
    } else if (value === "minutes") {
      this.setState({ distance: false });
    }

    this.setState((prevState) => ({
      data_new_isokron: {
        ...prevState.data_new_isokron,
        [key]: value,
      },
    }));
  };

  handle_create_isokron = (data, item) => {
    const { status, id } = this.state.option_isokron;
    const { features_isokron_center } = this.props.toolbox;
    let feature = {
      ...item,
      properties: {
        ...item.properties,
        name: `I-${features_isokron_center.length + 1}`,
        routing_profile: data.routing_profile,
        contour_type: data.contour_type,
        contours_minutes: data.contours_minutes,
        contours_meters: data.contours_meters,
        uuid: generate_uuid(),
        color: st_colors[features_isokron_center.length % st_colors.length],
        center: item?.properties.center,
      },
      geometry: {
        ...item.geometry,
        coordinates: item?.properties.center,
      },
    };
    this.props.push_value_toolbox({
      key: "features_isokron_center",
      value: [feature],
    });
    this.props.generate_isokron(feature);
    if (status && id !== "") {
      this.setState({
        option_isokron: {
          status: false,
          id: "",
        },
      });
    }
  };

  handle_cancel_create_isokron = (data, item) => {
    const { status, id } = this.state.option_isokron;

    if (status && id !== "") {
      this.setState({
        option_isokron: {
          status: false,
          id: "",
        },
      });
    }
  };

  render() {
    //local storage
    const language = localStorage?.language || "ina";

    //local state
    const { modal_default_radius, option_isokron, distance } = this.state;

    //global props
    const {
      mode_toolbox,
      loading_status,
      loading_item,
      default_radius_km,
      default_radius_m,
      mode_draw,
    } = this.props.toolbox;
    const { draw_state_platform } = this.props.layer;
    const { domain } = this.props.auth;

    //content
    if (!domain_bbws_maluku_river_schema.includes(domain)) {
      mode_list = mode_list.filter((item) => item.key !== "river_schema");
    }
    let features = [];
    if (
      mode_toolbox !== "grouping" &&
      mode_toolbox !== "scoring_grids" &&
      mode_toolbox !== "river_schema"
    ) {
      features = this?.props?.toolbox?.[mode_toolbox] || [];
    }
    let uuid_name, text_button, name_id;

    switch (mode_toolbox) {
      case "features_distance":
        uuid_name = "uuid_distance";
        text_button = "Measure distance";
        name_id = "D-";
        break;
      case "features_area":
        uuid_name = "uuid_area";
        text_button = "Measure area";
        name_id = "A-";
        break;
      case "features_elevation":
        uuid_name = "uuid_elevation";
        text_button = "Draw line elevation";
        name_id = "E-";
        break;
      case "features_radius":
        uuid_name = "uuid_radius";
        text_button = "Create buffer radius";
        name_id = "R-";
        break;
      case "features_isokron":
        uuid_name = "uuid_isokron";
        text_button = "Isochrone";
        name_id = "I-";
        break;
      default:
        uuid_name = "uuid_distance";
        text_button = "Measure distance";
        name_id = "D-";
        break;
    }
    const uuid_selected = this.props.toolbox[uuid_name];

    let chart_item;
    if (mode_toolbox === "features_elevation" && uuid_selected) {
      if (loading_status && loading_item === "generate_elevation") {
        chart_item = (
          <section className="container_light background_grey_light outline_black">
            <p className="text_bold">Measuring elevation...</p>
          </section>
        );
      } else {
        const { elevation_chart_array } = this.props.toolbox;
        const elevation_chart_item = elevation_chart_array.find(
          (item) => item.parent_uuid === uuid_selected
        );

        const chart_js_data = {
          labels: elevation_chart_item.labels,
          datasets: [
            {
              label: "Elevation chart",
              labels: elevation_chart_item.labels,
              data: elevation_chart_item.data,
              backgroundColor: "#0ca5eb",
            },
          ],
        };

        chart_item = (
          <main className="container_light background_grey_light outline_black">
            <p className="text_bold margin_bottom">
              Elevation contour cross section (m)
            </p>
            <section className="container_light background_grey_light outline_black">
              <CHART_TEMPLATE
                type="line"
                height={200}
                style={{
                  width: "100%",
                }}
                options={{
                  hoverBackgroundColor: "#000000",
                  animation: false,
                  maintainAspectRatio: false,
                  responsive: false,
                  legend: {
                    display: false,
                  },
                  indexAxis: "x",
                  title: {
                    display: false,
                  },
                  plugins: {
                    legend: {
                      display: false,
                    },
                  },
                }}
                data={chart_js_data}
                use_legend={false}
                use_generate_array_color={false}
              />
            </section>
          </main>
        );
      }
    }

    let button_create_content;
    if (draw_state_platform === "toolbox" && mode_draw !== "simple_select") {
      //DISABLE BUTTON
      button_create_content = (
        <button
          className="button_pill background_grey margin_bottom"
          style={{ whiteSpace: "break-spaces" }}
        >
          <span className="badge_circle background_white">+</span>{" "}
          {` START DRAW ON MAP  `}
        </button>
      );
    } else if (loading_status && loading_item === "generate_elevation") {
      //LOADING ELEVATION
      button_create_content = (
        <button
          className="button_pill background_grey margin_bottom"
          style={{ whiteSpace: "break-spaces" }}
        >
          <span className="badge_circle background_white">+</span>{" "}
          {` GET ELEVATION DATA...  `}
        </button>
      );
    } else if (mode_toolbox === "grouping") {
      //MODE GROUPING ELEVATION
      button_create_content = (
        <button
          className="button_pill background_grey margin_bottom"
          style={{ whiteSpace: "break-spaces" }}
        >
          <span className="badge_circle background_white">+</span>{" "}
          {` GROUPING ISOCHRONE ACTIVATED  `}
        </button>
      );
    } else {
      //ACTIVE FOR START DRAW
      button_create_content = (
        <button
          className="button_pill background_green margin_bottom"
          onClick={this.on_draw}
          style={{ whiteSpace: "break-spaces" }}
          data-mapid="clickDraw"
        >
          <span className="badge_circle background_white">+</span>{" "}
          {` ${text_button}  `}
        </button>
      );
    }

    const general_toolbox = (
      <main>
        <h1 className="text_header margin_bottom">TOOL BOX</h1>
        <section className="margin_bottom">
          <select
            name="mode_toolbox"
            value={mode_toolbox}
            onChange={this.on_change_mode_toolbox}
            data-mapid="selectMode"
          >
            {mode_list.map((item, idx) => {
              return (
                <option key={idx} value={item.key}>
                  {item.name}
                </option>
              );
            })}
          </select>
        </section>
        <section className="margin_bottom">{button_create_content}</section>
        {mode_toolbox === "features_radius" ? (
          <main>
            <TOOLBOX_DETAIL_RADIUS />
            <section className="container_light margin_bottom background_grey_light outline_black">
              {modal_default_radius ? (
                <>
                  <button
                    className="button background_blue"
                    onClick={this.toggle_default_radius}
                  >
                    ◤ Set default radius
                  </button>
                  <p className="text_medium">
                    Enter default radius for all points
                  </p>

                  <section className="container_light  background_grey_light outline_black margin_bottom">
                    <p className="text_small">Change radius (kilometers)</p>
                    <br />
                    <input
                      name="default_radius_km"
                      value={default_radius_km}
                      onChange={this.on_change_props}
                      type="number"
                      min={1}
                    />
                    <br />
                    <input
                      name="default_radius_km"
                      value={default_radius_km}
                      onChange={this.on_change_props}
                      type="range"
                      min={1}
                      max={100}
                      step={1}
                    />
                  </section>

                  <section className="container_light background_grey_light outline_black">
                    <p className="text_small">Change radius (meters)</p>
                    <br />
                    <input
                      name="default_radius_m"
                      value={default_radius_m}
                      onChange={this.on_change_props}
                      type="number"
                      min={1}
                    />
                    <br />
                    <input
                      name="default_radius_m"
                      value={default_radius_m}
                      onChange={this.on_change_props}
                      type="range"
                      min={1}
                      max={1000}
                      step={1}
                    />
                  </section>
                </>
              ) : (
                <button
                  className="button background_blue"
                  onClick={this.toggle_default_radius}
                >
                  ◣ Set default radius
                </button>
              )}
            </section>
          </main>
        ) : mode_toolbox === "features_area" ? (
          <TOOLBOX_DETAIL_AREA />
        ) : mode_toolbox === "features_distance" ? (
          <TOOLBOX_DETAIL_DISTANCE />
        ) : mode_toolbox === "features_elevation" ? (
          <TOOLBOX_DETAIL_ELEVATION />
        ) : mode_toolbox === "features_isokron" ? (
          <TOOLBOX_DETAIL_ISOKRON />
        ) : (
          <></>
        )}

        {features.length > 0 && mode_toolbox !== "grouping" && (
          <table className="table full_width margin_bottom">
            <thead>
              <tr>
                <th>No</th>
                <th>{dict["Action"][language]}</th>
                <th>{dict["Name"][language]}</th>
                <th>
                  {mode_toolbox === "features_distance"
                    ? "Distance"
                    : mode_toolbox === "features_area"
                    ? "Area"
                    : mode_toolbox === "features_elevation"
                    ? "Elevation start-finish"
                    : mode_toolbox === "features_radius"
                    ? "Lat/long-Radius"
                    : mode_toolbox === "features_isokron"
                    ? dict["Isochrone"][language]
                    : "Distance"}
                </th>
                <th style={{ width: "50px" }}>{dict["Delete"][language]}</th>
              </tr>
            </thead>
            <tbody>
              {features.map((item, idx) => {
                return (
                  <React.Fragment key={idx}>
                    <tr>
                      <td>{idx + 1}</td>
                      <td>
                        {uuid_selected === item.properties.uuid ? (
                          <button
                            className="button_small full_width background_green"
                            onClick={this.on_select_uuid.bind(this, "")}
                          >
                            ⬤
                          </button>
                        ) : (
                          <button
                            className="button_small full_width background_grey"
                            onClick={this.on_select_uuid.bind(
                              this,
                              item.properties.uuid
                            )}
                            data-mapid={`clickMoveGrey-${idx}`}
                          >
                            ⬤
                          </button>
                        )}
                      </td>
                      <td>
                        {name_id}
                        {idx + 1}
                      </td>
                      <td className="text_right">
                        {mode_toolbox === "features_distance" ? (
                          <table className="table_white full_width">
                            <tbody>
                              <tr>
                                <td>
                                  <p className="text_small">
                                    {item?.properties?.length_km}
                                  </p>
                                </td>
                                <td>
                                  <p className="text_small">kilometers</p>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <p className="text_small">
                                    {item?.properties?.length_m}
                                  </p>
                                </td>
                                <td>
                                  <p className="text_small">meters</p>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        ) : mode_toolbox === "features_area" ? (
                          <table
                            className="table_white full_width"
                            style={{ tableLayout: "fixed" }}
                          >
                            <tbody>
                              <tr>
                                <td>{item?.properties?.area_hectare}</td>
                                <td>hectare</td>
                              </tr>
                              <tr>
                                <td>{item?.properties?.area_meter_square}</td>
                                <td>
                                  m<sup>2</sup>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        ) : mode_toolbox === "features_elevation" ? (
                          0
                        ) : mode_toolbox === "features_radius" ? (
                          <>
                            <table className="table_white full_width">
                              <tbody>
                                <tr>
                                  <td className="text_left width_40">
                                    <p className="text_small">Long</p>
                                  </td>
                                  <td>
                                    <p
                                      className="text_small"
                                      style={{ wordBreak: "break-all" }}
                                    >
                                      {item?.geometry?.coordinates?.[0]}
                                    </p>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="text_left width_40">
                                    <p className="text_small">Lat</p>
                                  </td>
                                  <td>
                                    <p
                                      className="text_small"
                                      style={{ wordBreak: "break-all" }}
                                    >
                                      {item?.geometry?.coordinates?.[1]}
                                    </p>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="text_left width_40">
                                    <p className="text_small">Radius (km)</p>
                                  </td>
                                  <td>
                                    <p className="text_small">
                                      {item?.properties?.radius_km}
                                    </p>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            {uuid_selected === item.properties.uuid && (
                              <section className="container_light margin_top background_grey_light outline_black">
                                <p className="text_small">
                                  Change radius (kilometers)
                                </p>
                                <br />
                                <input
                                  name="radius_km"
                                  id={item?.properties?.uuid}
                                  value={item?.properties?.radius_km}
                                  onChange={this.on_change_radius}
                                  type="number"
                                  min={1}
                                  data-mapid="changeRadiusNumber"
                                />
                                <br />
                                <input
                                  name="radius_km"
                                  id={item?.properties?.uuid}
                                  value={item?.properties?.radius_km}
                                  onChange={this.on_change_radius}
                                  type="range"
                                  min={1}
                                  max={100}
                                  step={1}
                                  data-mapid="changeRadiusRange"
                                />

                                <p className="text_small">
                                  Change radius (meters)
                                </p>
                                <br />
                                <input
                                  name="radius_m"
                                  id={item?.properties?.uuid}
                                  value={item?.properties?.radius_m}
                                  onChange={this.on_change_radius}
                                  type="number"
                                  min={1}
                                  data-mapid="changeRadiusMeter"
                                />
                                <br />
                                <input
                                  name="radius_m"
                                  id={item?.properties?.uuid}
                                  value={item?.properties?.radius_m}
                                  onChange={this.on_change_radius}
                                  type="range"
                                  min={1}
                                  max={1000}
                                  step={1}
                                  data-mapid="changeRadiusRangeMeter"
                                />
                              </section>
                            )}
                          </>
                        ) : mode_toolbox === "features_isokron" ? (
                          <table
                            className="table_white full_width"
                            style={{ tableLayout: "fixed" }}
                          >
                            <>
                              <tbody>
                                <tr>
                                  <td>
                                    <p>
                                      {dict["Transportation mode"][language]}
                                    </p>
                                  </td>
                                  <td>{item?.properties.routing_profile}</td>
                                </tr>
                                <tr>
                                  <td>
                                    <p>
                                      {
                                        dict["Distance/time parameters"][
                                          language
                                        ]
                                      }
                                    </p>
                                  </td>
                                  <td>{item?.properties.contour_type}</td>
                                </tr>
                                <tr>
                                  <td>
                                    <p>
                                      {item?.properties.contour_type ===
                                      "meters"
                                        ? dict["Driving distance"][language]
                                        : dict["Driving time"][language]}
                                    </p>
                                  </td>
                                  <td>
                                    {item?.properties.contour_type === "meters"
                                      ? item?.properties.contours_meters
                                      : item?.properties.contours_minutes}
                                  </td>
                                </tr>
                                <tr>
                                  {option_isokron.status === true &&
                                  option_isokron.id ===
                                    item?.properties.uuid ? (
                                    <></>
                                  ) : (
                                    <td colSpan="2">
                                      <button
                                        className={`button_small background_green full_width`}
                                        onClick={() =>
                                          this.handle_open_isokron_opt(item)
                                        }
                                        data-mapid="clickSetLocation"
                                      >
                                        {dict["Set at this location"][language]}
                                      </button>
                                    </td>
                                  )}
                                </tr>
                              </tbody>
                            </>
                          </table>
                        ) : (
                          0
                        )}
                      </td>
                      <td>
                        <button
                          className="button_small background_red margin_right full_width"
                          onClick={this.on_delete_item.bind(
                            this,
                            item.properties.uuid
                          )}
                          data-mapid="clickDelete"
                        >
                          x
                        </button>
                      </td>
                    </tr>
                    {option_isokron.status === true &&
                      option_isokron.id === item?.properties.uuid && (
                        <tr>
                          <td colSpan="5">
                            <table className="table_white full_width">
                              <tbody>
                                <tr>
                                  <td>
                                    <p>
                                      {dict["Transportation mode"][language]}:
                                    </p>
                                  </td>
                                  <td>
                                    <select
                                      name="routing_profile"
                                      onChange={this.on_change_opt_isokron}
                                      value={
                                        this.state.data_new_isokron
                                          .routing_profile
                                      }
                                      data-mapid="selectOption"
                                    >
                                      <option value="driving">Driving</option>
                                      <option value="driving-traffic">
                                        Driving-traffic
                                      </option>
                                      <option value="walking">Walking</option>
                                      <option value="cycling">Cycling</option>
                                    </select>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <p>
                                      {" "}
                                      {
                                        dict["Distance/time parameters"][
                                          language
                                        ]
                                      }
                                      :
                                    </p>
                                  </td>
                                  <td>
                                    <select
                                      name="contour_type"
                                      value={
                                        this.state.data_new_isokron.contour_type
                                      }
                                      onChange={this.on_change_opt_isokron}
                                      data-mapid="selectContour"
                                    >
                                      <option value="minutes">minutes</option>
                                      <option value="meters">meters</option>
                                    </select>
                                  </td>
                                </tr>
                                {distance ? (
                                  <tr>
                                    <td>
                                      <p> {dict["Driving time"][language]}:</p>
                                    </td>
                                    <td>
                                      <input
                                        name="contours_meters"
                                        value={
                                          this.state.data_new_isokron
                                            .contours_meters
                                            ? this.state.data_new_isokron
                                                .contours_meters
                                            : 1000
                                        }
                                        onChange={this.on_change_opt_isokron}
                                        type="number"
                                        min={1}
                                        data-mapid="inputContoursMeter"
                                      />
                                      <input
                                        name="contours_meters"
                                        value={
                                          this.state.data_new_isokron
                                            .contours_meters
                                            ? this.state.data_new_isokron
                                                .contours_meters
                                            : 1000
                                        }
                                        onChange={this.on_change_opt_isokron}
                                        type="range"
                                        min={1000}
                                        max={6000}
                                        step={1}
                                        data-mapid="inputContoursDistance"
                                      />
                                    </td>
                                  </tr>
                                ) : (
                                  <tr>
                                    <td>
                                      <p> {dict["Driving time"][language]}:</p>
                                    </td>
                                    <td>
                                      <input
                                        name="contours_minutes"
                                        value={
                                          this.state.data_new_isokron
                                            .contours_minutes
                                            ? this.state.data_new_isokron
                                                .contours_minutes
                                            : 10
                                        }
                                        onChange={this.on_change_opt_isokron}
                                        type="number"
                                        min={1}
                                        data-mapid="inputContoursMinutes"
                                      />
                                      <input
                                        name="contours_minutes"
                                        value={
                                          this.state.data_new_isokron
                                            .contours_minutes
                                            ? this.state.data_new_isokron
                                                .contours_minutes
                                            : 60
                                        }
                                        onChange={this.on_change_opt_isokron}
                                        type="range"
                                        min={10}
                                        max={60}
                                        step={1}
                                      />
                                    </td>
                                  </tr>
                                )}
                                <tr>
                                  <td>
                                    <button
                                      className={`button_small background_green full_width`}
                                      onClick={() =>
                                        this.handle_create_isokron(
                                          this.state.data_new_isokron,
                                          item
                                        )
                                      }
                                      data-mapid="clickFinish"
                                    >
                                      {dict["Finish"][language]}
                                    </button>
                                  </td>
                                  <td>
                                    <button
                                      className={`button_small background_red full_width `}
                                      style={{ height: "100%" }}
                                      onClick={() =>
                                        this.handle_cancel_create_isokron(item)
                                      }
                                    >
                                      {dict["Cancel"][language]}
                                    </button>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      )}
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>
        )}
        {chart_item}
      </main>
    );

    let content;
    if (mode_toolbox === "scoring_grids") {
      content = <TOOLBOX_GRID />;
    } else if (mode_toolbox === "river_schema") {
      content = <TOOLBOX_RIVER_SCHEMA />;
    } else {
      content = general_toolbox;
    }

    return <main>{content}</main>;
  }
}

const mapStateToProps = (state) => ({
  toolbox: state.toolbox,
  map: state.map,
  layer: state.layer,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  set_value_toolbox,
  set_value_layer,
  change_radius,
  set_value_sini,
  push_value_toolbox,
  generate_isokron,
  status_action,
  status_toolbox,
})(TOOLBOX_TABLE);
