/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*COMPONENT*/
import Modal from "../common_modal/Modal";
import ProgressBar from "../common_spinner/ProgressBar";
import BI_ADD_CARD from "./BI_ADD_CARD";
import BI_DELETE_CARD from "./BI_DELETE_CARD";
import BI_ADD_GROUP_CARD from "./BI_ADD_GROUP_CARD";
import BI_EDIT_GROUP from "./BI_EDIT_GROUP";
import BI_CARD_LIST_MODE_BUTTON from "./BI_CARD_LIST_MODE_BUTTON";
import BI_CARD_LIST_MODE_SELECTION from "./BI_CARD_LIST_MODE_SELECTION";
import BI_CARD_LIST_MODE_EXPAND from "./BI_CARD_LIST_MODE_EXPAND";
import BI_CARD_LIST from "./BI_CARD_LIST";
import BI_ADD_SETTING from "../bi_map/BI_ADD_SETTING";

/*REDUX*/
import { set_value_bi } from "../../App/actions/biActions";
import { get_layer_detail_bi_many } from "../../App/actions/biLayerActions";

/*PICTURE*/
import ICON_PLUS from "../../Assets/jsx/ICON_PLUS";

/*FUNCTION*/
import get_url_query from "../../App/validation/get_url_query";

/*DATA*/
import dict from "../../Data/dict.json";
import card_add_object_constant from "../../Data/card_add_object_constant.json";
import group_card_add_object_constant from "../../Data/group_card_add_object_constant.json";
import setting_bi_constant from "../../Data/setting_bi_constant.json";

/*CONST*/

class BI_CARD_CONTAINER extends Component {
  state = {
    modal_warn_no_setting: false,
    modal_add_setting: false,
  };

  componentDidMount() {
    this.on_get_layer_detail_bi_many();
  }

  toggle_add_setting = () => {
    this.setState({
      modal_add_setting: !this.state.modal_add_setting,
      modal_warn_no_setting: false,
    });
    this.props.set_value_bi({
      key: "setting_add_object",
      value: {
        ...setting_bi_constant,
      },
    });
  };

  toggle_warn_no_setting = () => {
    this.setState({
      modal_warn_no_setting: !this.state.modal_warn_no_setting,
    });
  };

  toggle_edit_group_card = () => {
    this.props.set_value_bi({
      key: "modal_edit_group_card",
      value: !this.props.bi.modal_edit_group_card,
    });

    const { bi_object, project_object_selected } = this.props.bi;
    const setting_list = bi_object?.setting_list || [];
    const setting_object = setting_list.find(
      (item) => item?.project_id === project_object_selected?._id
    );
    this.props.set_value_bi({
      key: "setting_add_object",
      value: setting_object,
    });
  };

  toggle_add_group_card = () => {
    this.props.set_value_bi({
      key: "group_card_add_object",
      value: {
        ...group_card_add_object_constant,
      },
    });
    this.props.set_value_bi({
      key: "modal_add_group_card",
      value: !this.props.bi.modal_add_group_card,
    });
  };

  on_get_layer_detail_bi_many = () => {
    const { bi_object, project_object_selected } = this.props.bi;
    if (project_object_selected?._id && bi_object?._id) {
      const setting_list = bi_object?.setting_list || [];
      const setting_object = setting_list.find(
        (item) => item?.project_id === project_object_selected?._id
      );
      const layer_load_list = setting_object?.layer_load_list || [];
      const layer_view_map_list = setting_object?.layer_view_map_list || [];
      let layer_final_list = [...layer_load_list];
      layer_view_map_list.forEach((item) => {
        if (!layer_load_list.includes(item)) layer_final_list.push(item);
      });
      if (layer_final_list.length > 0) {
        const layer_list = project_object_selected?.layer_list || [];
        const query_array = layer_final_list.map((item) => {
          const layer = layer_list.find(
            (element) => element?.geo_layer?._id === item
          );
          return {
            geo_project_id: project_object_selected?._id,
            geo_layer_id: item,
            folder_id: layer?.folder,
            capex_value: this.props.properties.capex_value,
            geo_layer_item: layer?.geo_layer,
          };
        });
        const query = { query_array, project_id: project_object_selected?._id };

        this.props.get_layer_detail_bi_many(query);
      } else {
        this.toggle_warn_no_setting();
      }
    }
  };

  toggle_add_card = () => {
    this.props.set_value_bi({
      key: "card_add_object",
      value: {
        ...card_add_object_constant,
      },
    });
    this.props.set_value_bi({
      key: "modal_add_card",
      value: !this.props.bi.modal_add_card,
    });
    this.props.set_value_bi({
      key: "add_card_current_step",
      value: 0,
    });
  };

  toggle_edit_card = () => {
    this.props.set_value_bi({
      key: "modal_add_card",
      value: !this.props.bi.modal_add_card,
    });
    this.props.set_value_bi({
      key: "add_card_current_step",
      value: 0,
    });
  };

  toggle_delete_card = () => {
    this.props.set_value_bi({
      key: "modal_delete_card",
      value: !this.props.bi.modal_delete_card,
    });
  };

  render() {
    //local storage
    const language = localStorage?.language || "ina";
    const mode = get_url_query("mode");

    //local state
    const { modal_warn_no_setting, modal_add_setting } = this.state;

    //global props
    let {
      modal_add_card,
      modal_delete_card,
      modal_add_group_card,
      modal_edit_group_card,
      bi_object,
      project_object_selected,
      total_progress_layers,
      current_progress_layers,
      loading_item_array,
      group_card_id_selected,
    } = this.props.bi;

    //content
    const setting_list = bi_object?.setting_list || [];
    const setting_object = setting_list.find(
      (item) => item?.project_id === project_object_selected?._id
    );

    const group_card_mode = setting_object?.group_card_mode;
    let group_card_list = setting_object?.group_card_list || [];

    let card_list = bi_object?.card_list || [];
    card_list = card_list.filter(
      (item) => item.project_id === project_object_selected?._id
    );
    if (group_card_id_selected && group_card_list.length > 0) {
      const group_card_object_selected = group_card_list.find(
        (item) => item?._id === group_card_id_selected
      );
      const card_id_list = group_card_object_selected?.card_id_list || [];
      card_list = card_list.filter((item) => card_id_list.includes(item?._id));
    }

    const layer_load_list = setting_object?.layer_load_list || [];
    const layer_view_map_list = setting_object?.layer_view_map_list || [];
    let layer_final_list = [...layer_load_list];
    layer_view_map_list.forEach((item) => {
      if (!layer_load_list.includes(item)) layer_final_list.push(item);
    });

    let card_list_content;

    if (!project_object_selected?._id) {
      card_list_content = (
        <section
          className="container_light margin_bottom background_white"
          style={{ maxWidth: "200px" }}
        >
          <p className="text_medium">
            {dict?.["Please select a project to show"]?.[language]}
          </p>
        </section>
      );
    } else if (layer_final_list.length === 0) {
      card_list_content = (
        <section
          className="container_light margin_bottom background_white"
          style={{ maxWidth: "200px" }}
        >
          <p className="text_medium">
            {
              dict?.[
                "Layer setup has not been done. Adding a data card cannot be done yet."
              ]?.[language]
            }
          </p>
          <button
            className="button_small margin_bottom inline margin_right background_black"
            onClick={this.toggle_add_setting}
          >
            {dict?.["Data initiation settings"]?.[language]}
          </button>
        </section>
      );
    } else if (card_list.length === 0 && group_card_list.length === 0) {
      card_list_content = (
        <section
          className="container_light margin_bottom background_white"
          style={{ maxWidth: "200px" }}
        >
          <p className="text_medium">
            {
              dict?.["There is no card yet, please add an information card"]?.[
                language
              ]
            }
          </p>
        </section>
      );
    } else if (group_card_list.length === 0) {
      card_list_content = <BI_CARD_LIST card_list={card_list} />;
    } else {
      if (group_card_mode === "expand") {
        card_list_content = <BI_CARD_LIST_MODE_EXPAND />;
      } else if (group_card_mode === "selection") {
        card_list_content = <BI_CARD_LIST_MODE_SELECTION />;
      } else {
        card_list_content = <BI_CARD_LIST_MODE_BUTTON />;
      }
    }

    const modal_add_card_content = modal_add_card && (
      <Modal
        modalSize="large"
        isOpen={modal_add_card}
        onClose={this.toggle_add_card}
        backgroundColor="rgb(235, 235, 235)"
      >
        <div className="box-body" id="box-body">
          <BI_ADD_CARD toggle_add_card={this.toggle_add_card} />
        </div>
      </Modal>
    );

    const modal_delete_card_content = modal_delete_card && (
      <Modal
        modalSize="small"
        isOpen={modal_delete_card}
        onClose={this.toggle_delete_card}
      >
        <div className="box-body" id="box-body">
          <BI_DELETE_CARD toggle_delete_card={this.toggle_delete_card} />
        </div>
      </Modal>
    );

    const modal_add_group_card_content = modal_add_group_card && (
      <Modal
        modalSize="medium"
        isOpen={modal_add_group_card}
        onClose={this.toggle_add_group_card}
        backgroundColor="rgb(235, 235, 235)"
      >
        <div className="box-body" id="box-body" style={{ overflowY: "hidden" }}>
          <BI_ADD_GROUP_CARD />
        </div>
      </Modal>
    );

    const modal_edit_group_card_content = modal_edit_group_card && (
      <Modal
        modalSize="medium"
        isOpen={modal_edit_group_card}
        onClose={this.toggle_edit_group_card}
      >
        <div className="box-body" id="box-body">
          <BI_EDIT_GROUP />
        </div>
      </Modal>
    );

    const modal_warn_no_setting_content = modal_warn_no_setting && (
      <Modal
        modalSize="small"
        isOpen={modal_warn_no_setting}
        onClose={this.toggle_warn_no_setting}
      >
        <div className="box-body" id="box-body">
          <p className="text_bold">
            {
              dict?.["Data cannot be loaded, settings are not complete"]?.[
                language
              ]
            }
          </p>
          <br />
          <br />
          <button
            className="button background_blue"
            onClick={this.toggle_add_setting}
          >
            {dict?.["Complete the setup now"]?.[language]}
          </button>
        </div>
      </Modal>
    );

    let content = (
      <>
        <h1 className="text_bold margin_bottom margin_right inline">
          {project_object_selected?.name || "-"}
        </h1>
        {project_object_selected?._id && mode === "edit" && (
          <button
            className="button_small margin_bottom inline margin_right background_black"
            onClick={this.toggle_add_setting}
          >
            {dict?.["Data initiation settings"]?.[language]}
          </button>
        )}
        {project_object_selected?._id && (
          <>
            <button
              className="button_small margin_right background_black"
              onClick={this.on_get_layer_detail_bi_many}
            >
              {dict?.["Reload data"]?.[language]}
            </button>
            {!!project_object_selected?.is_data_loaded ? (
              <button className="badge_circle background_green" />
            ) : (
              <button className="badge_circle background_red" />
            )}
            {mode === "edit" && layer_final_list.length > 0 && (
              <>
                <br />
                <button
                  onClick={this.toggle_add_card}
                  className="button_pill background_white margin_bottom margin_right margin_top margin_bottom"
                >
                  <span className="badge_circle background_green margin_right">
                    <ICON_PLUS />
                  </span>
                  {dict?.["Add data"]?.[language]}
                </button>
                <button
                  onClick={this.toggle_add_group_card}
                  className="button_pill background_white margin_bottom margin_right margin_top margin_bottom"
                >
                  <span className="badge_circle background_green margin_right">
                    <ICON_PLUS />
                  </span>
                  {dict?.["Add group"]?.[language]}
                </button>
                <button
                  onClick={this.toggle_edit_group_card}
                  className="button_pill margin_bottom margin_right margin_top margin_bottom background_white"
                >
                  <span className="badge_circle background_green margin_right">
                    <ICON_PLUS />
                  </span>
                  Group settings
                </button>
              </>
            )}
          </>
        )}
        {loading_item_array.includes("get_layer_detail_bi_many") && (
          <>
            <br />
            <div
              className="container_light background_white"
              style={{ width: "180px" }}
            >
              <p className="text_bold">{dict?.["Loading data"]?.[language]}</p>
              <br />
              <ProgressBar
                current_number={current_progress_layers}
                total_number={total_progress_layers}
              />
            </div>
          </>
        )}
        <br />
        <br />
        {card_list_content}
      </>
    );
    if (loading_item_array.includes("get_list_layer_bi")) {
      content = (
        <div className="container_light background_white">
          {dict?.["Loading list layer"]?.[language]}
        </div>
      );
    }

    const modal_add_setting_content = modal_add_setting && (
      <Modal
        modalSize="large"
        isOpen={modal_add_setting}
        onClose={this.toggle_add_setting}
      >
        <div className="box-body" id="box-body">
          <BI_ADD_SETTING toggle_add_setting={this.toggle_add_setting} />
        </div>
      </Modal>
    );

    return (
      <main>
        {modal_add_card_content}
        {modal_delete_card_content}
        {modal_add_group_card_content}
        {modal_edit_group_card_content}
        {modal_warn_no_setting_content}
        {modal_add_setting_content}
        {content}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  bi: state.bi,
  properties: state.properties,
});

export default connect(mapStateToProps, {
  set_value_bi,
  get_layer_detail_bi_many,
})(BI_CARD_CONTAINER);
