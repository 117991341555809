/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*COMPONENT*/
import M_S_DEMOGRAPHY_NEIGHBOR from "../libre_marker_sini/M_S_DEMOGRAPHY_NEIGHBOR";

/*REDUX*/

/*PICTURE*/

/*FUNCTION*/

/*DATA*/

/*CONST*/
const color_line = "#d57494";
const color_fill = "#7dd42b";

class LAYER_S_DEMOGRAPHY_NEIGHBOR extends Component {
  componentDidUpdate(prevProps) {
    const map_object_string = this.props.map_object_string || "map_object";
    const map_object = this.props.layer[map_object_string];
    const state_update_after = this.props.sini.state_update;
    const state_update_before = prevProps.sini.state_update;
    const basemap_used_after = this.props.properties.basemap_used;
    const basemap_used_before = prevProps.properties.basemap_used;
    if (
      state_update_after !== state_update_before ||
      basemap_used_after !== basemap_used_before
    ) {
      if (map_object && map_object.isStyleLoaded()) {
        this.on_render_layer();
      } else if (map_object) {
        map_object.once("styledata", this.on_render_layer);
      }
    }
  }

  on_render_layer = () => {
    const map_object_string = this.props.map_object_string || "map_object";
    const map_object = this.props.layer[map_object_string];
    const {
      sini_v2_list,
      ai_parent_detail_list,
      sini_map_show,
      sini_polygon_fill_opacity,
      sini_polygon_line_width,
      is_sini_visible,
    } = this.props.sini;
    const visibility =
      sini_map_show.includes("demography_neighbor") && is_sini_visible
        ? "visible"
        : "none";
    const merge_array = [...sini_v2_list, ...ai_parent_detail_list];
    if (map_object !== null) {
      merge_array.forEach((item) => {
        const request_id = item?.request_id;
        const layer_id_fill = `geojson_demography_neighbor_${request_id}`;
        const layer_id_line = `geojson_demography_neighbor_line_${request_id}`;

        const demography_neighbor = item?.demography_neighbor || [];
        if (demography_neighbor.length > 0) {
          const geojson_demography_neighbor = {
            type: "FeatureCollection",
            features: demography_neighbor,
          };

          // Add source
          if (!map_object.getSource(layer_id_fill)) {
            map_object.addSource(layer_id_fill, {
              type: "geojson",
              data: geojson_demography_neighbor,
            });
          } else {
            map_object
              .getSource(layer_id_fill)
              .setData(geojson_demography_neighbor);
          }

          // Add fill layer
          if (!map_object.getLayer(layer_id_fill)) {
            map_object.addLayer({
              id: layer_id_fill,
              source: layer_id_fill,
              type: "fill",
              paint: {
                "fill-color": color_fill,
                "fill-opacity": 0.5,
              },
              layout: {
                visibility: visibility,
              },
            });
          } else {
            map_object.setLayoutProperty(
              layer_id_fill,
              "visibility",
              visibility
            );
            map_object.setPaintProperty(
              layer_id_fill,
              "fill-opacity",
              Number(sini_polygon_fill_opacity)
            );
          }

          // Add line layer
          if (!map_object.getLayer(layer_id_line)) {
            map_object.addLayer({
              id: layer_id_line,
              source: layer_id_fill,
              type: "line",
              paint: {
                "line-color": color_line,
                "line-width": 1,
                "line-opacity": 1,
                "line-gap-width": 0,
              },
              layout: {
                visibility: visibility,
              },
            });
          } else {
            map_object.setLayoutProperty(
              layer_id_line,
              "visibility",
              visibility
            );
            map_object.setPaintProperty(
              layer_id_line,
              "line-width",
              Number(sini_polygon_line_width)
            );
          }
        }
      });
    }
  };

  render() {
    return (
      <main>
        <M_S_DEMOGRAPHY_NEIGHBOR />
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  sini: state.sini,
  layer: state.layer,
  properties: state.properties,
});

export default connect(mapStateToProps, {})(LAYER_S_DEMOGRAPHY_NEIGHBOR);
