/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/
import ChartTemplate from "../viewer_chart/ChartTemplate";

/*REDUX FUNCTION*/
import {
  aggregate_train,
  set_value_properties,
} from "../../App/actions/propertiesActions";

/*PICTURE ASSET*/

/*GENERAL*/

/*NON IMPORT*/

class TimeseriesHour extends Component {
  state = {
    start_time: "",
    end_time: "",
    field_name_train: "NAMA",
  };

  componentDidMount() {
    this.props.set_value_properties({
      key: "chart_js_aggregate_train",
      value: {},
    });
  }

  on_change = (e) => {
    e.preventDefault();
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value });
  };

  on_submit = () => {
    const { start_time, end_time, field_name_train } = this.state;
    if (start_time && end_time && field_name_train) {
      const { layer_id, geo_layer_list } = this.props.layer;
      const geo_layer =
        geo_layer_list.find((e) => e?.geo_layer?._id === layer_id)?.geo_layer ||
        {};
      const convert_time_to_digit = (time) => {
        const array = time.split(":");
        return Number(array[0]) + Number(array[1]) / 60;
      };
      const start = convert_time_to_digit(start_time);
      const end = convert_time_to_digit(end_time);
      const fields = geo_layer?.fields || [];
      const field_key = fields.find(
        (item) => item.name === field_name_train
      )?.key;
      const body = {
        geo_layer,
        start,
        end,
        field_key,
      };
      this.props.aggregate_train(body);
    }
  };

  render() {
    //local storage

    //local state
    const { start_time, end_time, field_name_train } = this.state;

    //global props
    const { layer_id, geo_layer_list } = this.props.layer;
    const { chart_js_aggregate_train } = this.props.properties;

    //content
    const geo_layer =
      geo_layer_list.find((e) => e?.geo_layer?._id === layer_id)?.geo_layer ||
      {};

    //pengambilan atribut di dalam geo_layer
    const chart_js_data_passenger =
      geo_layer?.hour_list?.chart_js_data_passenger;
    const chart_js_data_train = geo_layer?.hour_list?.chart_js_data_train;
    const fields = geo_layer?.fields || [];

    return (
      <main>
        {chart_js_data_passenger && (
          <section className="container_light margin_bottom">
            <h2 className="badge_pill" id="orange">
              Passenger
            </h2>
            <br />
            <div style={{ overflowX: "scroll" }} className="margin_bottom">
              <ChartTemplate
                type="bar"
                width={600}
                height={200}
                style={{ width: "600px" }}
                options={{
                  hoverBackgroundColor: "#f38026",
                  animation: false,
                  maintainAspectRatio: true,
                  responsive: false,
                  legend: {
                    display: false,
                  },
                  indexAxis: "x",
                  title: {
                    display: false,
                  },
                  plugins: {
                    legend: {
                      display: false,
                    },
                  },
                }}
                data={chart_js_data_passenger}
              />
            </div>
            <table className="table">
              <tbody>
                <tr>
                  <td>y_max</td>
                  <td>{chart_js_data_passenger?.y_max}</td>
                </tr>
                <tr>
                  <td>x_max</td>
                  <td>{chart_js_data_passenger?.x_max}</td>
                </tr>
              </tbody>
            </table>
          </section>
        )}

        {chart_js_data_train && (
          <section className="container_light margin_bottom">
            <h2 className="badge_pill" id="orange">
              Train
            </h2>
            <br />
            <div style={{ overflowX: "scroll" }} className="margin_bottom">
              <ChartTemplate
                type="bar"
                width={600}
                height={200}
                style={{ width: "600px" }}
                options={{
                  hoverBackgroundColor: "#f38026",
                  animation: false,
                  maintainAspectRatio: true,
                  responsive: false,
                  legend: {
                    display: false,
                  },
                  indexAxis: "x",
                  title: {
                    display: false,
                  },
                  plugins: {
                    legend: {
                      display: false,
                    },
                  },
                }}
                data={chart_js_data_train}
              />
            </div>
            <table className="table">
              <tbody>
                <tr>
                  <td>y_max</td>
                  <td>{chart_js_data_train?.y_max}</td>
                </tr>
                <tr>
                  <td>x_max</td>
                  <td>{chart_js_data_train?.x_max}</td>
                </tr>
              </tbody>
            </table>
          </section>
        )}
        <section className="container_light">
          <h2 className="text_bold margin_bottom">
            Jumlah kereta terbanyak pada rentang waktu tertentu
          </h2>
          <table className="table full_width margin_bottom">
            <tbody>
              <tr>
                <td>
                  <p className="text_small">Jam awal</p>
                  <input
                    type="time"
                    name="start_time"
                    value={start_time}
                    onChange={this.on_change}
                  />
                </td>
                <td>
                  <p className="text_small">Jam akhir</p>
                  <input
                    type="time"
                    name="end_time"
                    value={end_time}
                    onChange={this.on_change}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <p className="text_small">Pilih kolom nama kereta</p>
          <select
            name="field_name_train"
            value={field_name_train}
            onChange={this.on_change}
          >
            <option value="">Pilih kolom nama kereta</option>
            {fields.map((item, idx) => {
              return (
                <option key={idx} value={item.name}>
                  {item.name}
                </option>
              );
            })}
          </select>
          <br />
          {start_time && end_time && field_name_train ? (
            <button className="button background_blue" onClick={this.on_submit}>
              Submit
            </button>
          ) : (
            <div className="button background_black">
              Select range before submit
            </div>
          )}

          {chart_js_aggregate_train?.labels && (
            <ChartTemplate
              use_legend={true}
              type="pie"
              options={{
                hoverBackgroundColor: "#f38026",
                animation: false,
                maintainAspectRatio: true,
                responsive: false,
                legend: {
                  display: false,
                },
                indexAxis: "x",
                title: {
                  display: false,
                },
                plugins: {
                  legend: {
                    display: false,
                  },
                },
              }}
              data={chart_js_aggregate_train}
            />
          )}
        </section>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  layer: state.layer,
  properties: state.properties,
});

export default connect(mapStateToProps, {
  aggregate_train,
  set_value_properties,
})(TimeseriesHour);
