import React, { Component } from "react";

export default class DataTypesSelectionSub extends Component {
  render() {
    const {
      sub_title,
      data_type,
      handleDataTypeOnClick,
      field,
      isDataTypeCalculateDimensionAndLayerTypeIsPoint,
      geo_layer_type,
      className,
    } = this.props;
    return (
      <main
        className={`background_white margin_bottom rounded_20 padding_5 ${className || ""}`}
      >
        <section>
          <h2 className="marginLeft_10 marginTop_10 font_14">{sub_title}</h2>
        </section>
        <section>
          {data_type.map(({ name, value, icon }) => {
            let class_button = "background_grey_light outline_white";
            if (value === field.type) {
              class_button = "background_grey_light outline_black";
            } else if (
              isDataTypeCalculateDimensionAndLayerTypeIsPoint(
                value,
                geo_layer_type
              )
            ) {
              class_button = "button_disable";
            }
            return (
              <div
                onClick={() => {
                  handleDataTypeOnClick(value, geo_layer_type);
                }}
                key={value}
                className={`button_box cursor_pointer ${class_button}`}
              >
                <div className="center_perfect w_full h_full">
                  <div>
                    <img
                      src={icon}
                      alt={name}
                      className="w_20 h_20 margin_auto"
                    />
                    <div className="font_11 marginTop_5">{name}</div>
                  </div>
                </div>
              </div>
            );
          })}
        </section>
      </main>
    );
  }
}
