/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*COMPONENT*/

/*REDUX*/
import { set_value_bi, edit_inside_object } from "../../App/actions/biActions";
import { get_layer_detail_bi } from "../../App/actions/biLayerActions";

/*PICTURE*/

/*FUNCTION*/

/*DATA*/
import dict from "../../Data/dict.json";

/*CONST*/

class BI_ADD_STEP_2_FIELD_KEY_VALUE extends Component {
  state = {};

  componentDidMount() {
    const { project_object_selected, card_add_object, add_card_current_step } =
      this.props.bi;
    const layer_list = project_object_selected?.layer_list || [];
    const layer_id = card_add_object?.layer_id;
    if (layer_id && add_card_current_step === 2) {
      const layer = layer_list.find(
        (item) => item?.geo_layer?._id === layer_id
      );
      const features = layer?.geo_layer?.geojson?.features || [];
      if (features.length === 0) {
        const query = {
          geo_project_id: project_object_selected?._id,
          geo_layer_id: layer_id,
          folder_id: layer.folder,
          capex_value: this.props.properties.capex_value,
          geo_layer_item: layer?.geo_layer,
        };
        this.props.get_layer_detail_bi(query);
      } else {
      }
    }
  }

  on_next_step = () => {
    const { add_card_current_step } = this.props.bi;
    this.props.set_value_bi({
      key: "add_card_current_step",
      value: add_card_current_step + 1,
    });
  };

  on_prev_step = () => {
    const { add_card_current_step } = this.props.bi;
    this.props.set_value_bi({
      key: "add_card_current_step",
      value: add_card_current_step - 1,
    });
  };

  on_change_card_add_object = (id_key, value) => {
    this.props.edit_inside_object({
      key: "card_add_object",
      id_key: id_key,
      value: value,
    });
  };

  render() {
    //local storage
    const language = localStorage?.language || "ina";

    //local state

    //global props
    const {
      project_object_selected,
      card_add_object,
      loading_status,
      loading_item,
    } = this.props.bi;

    //content
    const layer_list = project_object_selected?.layer_list || [];
    const layer_id = card_add_object?.layer_id;
    const layer = layer_list.find((item) => item?.geo_layer?._id === layer_id);
    const geo_layer = layer?.geo_layer || {};
    const fields = geo_layer?.fields || [];
    const field_key_value = card_add_object?.field_key_value;

    let content;

    if (loading_status && loading_item === "get_layer_detail_bi") {
      content = (
        <section className="container_light margin_bottom">
          {dict?.["Loading data"]?.[language]}...
        </section>
      );
    } else {
      content = (
        <section>
          <h1 className="text_bold margin_bottom">
            {dict?.["Select the value field"]?.[language]}
          </h1>
          <section className="container_light margin_bottom">
            <div style={{ maxHeight: "calc(85vh - 400px)", overflowY: "auto" }}>
              <table className="full_width margin_bottom">
                <tbody>
                  {fields.map((item, idx) => {
                    let is_open = item?.key === field_key_value;
                    return (
                      <tr
                        className="cursor_pointer"
                        key={idx}
                        onClick={this.on_change_card_add_object.bind(
                          this,
                          "field_key_value",
                          item?.key
                        )}
                      >
                        <td>
                          <div
                            className={
                              is_open ? "checklist_active" : "checklist_not"
                            }
                          />
                        </td>
                        <td>{item?.name}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </section>
        </section>
      );
    }

    return (
      <main>
        {content}
        <button
          onClick={this.on_prev_step}
          className="button margin_bottom margin_right inline background_blue"
        >
          {dict?.["Previous step"]?.[language]}
        </button>
        {field_key_value ? (
          <button
            onClick={this.on_next_step}
            className="button background_blue"
          >
            {dict?.["Next step"]?.[language]}
          </button>
        ) : (
          <button className="button background_grey">
            {dict?.["Next step"]?.[language]}
          </button>
        )}
        {!field_key_value && (
          <>
            <br />
            <p className="text_small text_red">
              {dict?.["Select the value field first"]?.[language]}
            </p>
          </>
        )}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  bi: state.bi,
  layer: state.layer,
  properties: state.properties,
  project: state.project,
});

export default connect(mapStateToProps, {
  set_value_bi,
  get_layer_detail_bi,
  edit_inside_object,
})(BI_ADD_STEP_2_FIELD_KEY_VALUE);
