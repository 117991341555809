import React from "react";
import useLanguage from "../common_hooks/useLanguage";

function RestoreLayerPage2(props) {
  const {
    actived_tab,
    handle_restore_project,
    handle_restore_layer,
    toggle_modal_restore_confirmation,
  } = props;

  const { dict, language } = useLanguage();

  let text = "";
  let first_button_text = "";
  let second_button_text = "";
  if (actived_tab === "project") {
    text = dict["Do you want to recover the project and its layers?"][language];
    first_button_text = (
      <div>
        {dict["Recover"][language]}{" "}
        <span className="bold underline">{dict["with"][language]}</span>{" "}
        {dict["Layers"][language]?.toLowerCase()}
      </div>
    );
    second_button_text = (
      <div>
        {dict["Recover"][language]}{" "}
        <span className="bold underline">{dict["without"][language]}</span>{" "}
        {dict["Layers"][language]?.toLowerCase()}
      </div>
    );
  } else {
    text = dict["Are you sure you want to recover this layer?"][language];
    first_button_text = dict["Yes"][language];
    second_button_text = "";
  }

  return (
    <div>
      <p className="center_perfect text_center">{text}</p>
      <section className="center_perfect">
        <div className="flex gap_5">
          <button className="bg_darkGrey padding_y_5 padding_x_10 rounded_5 text_white button_inactive">
            {dict["Cancel"][language]}
          </button>

          <button
            onClick={() => {
              if (actived_tab === "project") {
                handle_restore_project({ is_with_layer: true });
                toggle_modal_restore_confirmation();
              } else {
                handle_restore_layer();
                toggle_modal_restore_confirmation();
              }
            }}
            className="background_red padding_y_5 padding_x_10 rounded_5 text_white pointer button_inactive"
          >
            {first_button_text}
          </button>

          {second_button_text && (
            <button
              onClick={() => {
                if (actived_tab === "project") {
                  handle_restore_project({ is_with_layer: false });
                  toggle_modal_restore_confirmation();
                } else {
                  handle_restore_layer();
                  toggle_modal_restore_confirmation();
                }
              }}
              className="background_red padding_y_5 padding_x_10 rounded_5 text_white pointer button_inactive"
            >
              {second_button_text}
            </button>
          )}
        </div>
      </section>
    </div>
  );
}

export default RestoreLayerPage2;
