/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/
import Modal from "../common_modal/Modal";
import CountingCustomTable from "./CountingCustomTable";

/*REDUX FUNCTION*/

/*PICTURE ASSET*/

/*GENERAL*/

class CountingCustomEditor extends Component {
  state = {
    modal_open_detail: false,
    feature_key_array: [],
    custom_counting_id: "",
    custom_counting_layer: {},
    custom_counting_fields: [],
    feature_array: [],
  };

  generate_counting_custom_detail = () => {
    const { params } = this.props;
    const field_uuid = params?.field;

    let feature_array = [];
    let custom_counting_fields = [];

    /*
    yang perlu didapatkan untuk render table detail feature pada layer yang dipilih:
    1. feature_key_array --> feature titik yang berada pada radius
    2. custom_counting_id --> layer_id untuk mengambil geojson detail tersimpan di dalam field_uuid
    */

    //STEP 1 mendapatkan feature_key_array
    const counting_custom_array = params?.row?.counting_custom_array || [];
    const feature_key_array =
      counting_custom_array.find((e) => e?.field_uuid === field_uuid)
        ?.feature_key_array || [];

    //STEP 2 mendapatkam custom_counting_id
    const { layer_id, geo_layer_list } = this.props.layer;
    const fields =
      geo_layer_list.find((item) => item?.geo_layer?._id === layer_id)
        ?.geo_layer?.fields || [];
    const custom_counting_id = fields.find(
      (item) => item?.uuid === field_uuid
    )?.custom_counting_id;

    //STEP 3 get data geojson layer custom_counting_id jika belum di get
    const custom_counting_layer =
      geo_layer_list.find((item) => item?.geo_layer?._id === custom_counting_id)
        ?.geo_layer || {};

    const custom_counting_features =
      custom_counting_layer?.geojson?.features || [];

    if (custom_counting_features.length === 0 && custom_counting_id) {
    } else {
      //STEP 4 masukkan detail geojson ke dalam feature_array
      feature_array = [];
      feature_key_array.forEach((feature_key) => {
        feature_array.push(
          custom_counting_features.find(
            (feature) => feature.key === feature_key
          )
        );
      });

      //STEP 5 get fields dari counting custom layer supaya hanya digenerate ketika toggle, lebih ringan
      custom_counting_fields = custom_counting_layer?.fields || [];
      custom_counting_fields = custom_counting_fields.filter(
        (item) => !item.isStyle && !item.parent_uuid
      );

      //STEP 5 setState masing-masing variable
    }

    this.setState({
      feature_key_array,
      custom_counting_id,
      custom_counting_layer,
      custom_counting_fields,
      feature_array,
    });
  };

  toggle_open_detail = () => {
    this.setState({ modal_open_detail: !this.state.modal_open_detail }, () => {
      if (this.state.modal_open_detail) {
        this.generate_counting_custom_detail();
      }
    });
  };

  render() {
    //local storage

    //local state
    const {
      modal_open_detail,
      feature_key_array,
      custom_counting_id,
      custom_counting_layer,
      custom_counting_fields,
      feature_array,
    } = this.state;

    //global props
    const { params } = this.props;

    //content
    const modal_open_detail_content = modal_open_detail && (
      <Modal
        modalSize="large"
        isOpen={modal_open_detail}
        onClose={this.toggle_open_detail}
      >
        <div className="box-body">
          <CountingCustomTable
            feature_key_array={feature_key_array}
            custom_counting_id={custom_counting_id}
            custom_counting_layer={custom_counting_layer}
            custom_counting_fields={custom_counting_fields}
            feature_array={feature_array}
          />
        </div>
      </Modal>
    );

    return (
      <span>
        {modal_open_detail_content}
        {params?.value} titik{" "}
        <button
          className="button background_blue"
          onClick={this.toggle_open_detail}
        >
          Buka
        </button>
      </span>
    );
  }
}

const mapStateToProps = (state) => ({
  layer: state.layer,
  project: state.project,
});

export default connect(mapStateToProps, {
  // getDetailLayerById
})(CountingCustomEditor);
