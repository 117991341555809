/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/
import {
  set_value_sini,
  fly_init_update,
  get_ai_parent_list,
  get_ai_child_list,
  get_ai_parent_object,
} from "../../App/actions/sini_v2";

/*PICTURE ASSET*/

/*GENERAL FUNCTION & DATA*/

/*NON IMPORT*/

class SINI_HISTORY_FLY extends Component {
  state = {};

  componentDidMount() {
    this.props.get_ai_parent_list();
  }

  on_click_request_id = (request_id) => {
    this.props.toggle_history();

    this.props.set_value_sini({
      key: "request_id_active",
      value: request_id,
    });
    const { ai_parent_child_list, ai_parent_detail_list } = this.props.sini;
    const ai_child_list =
      ai_parent_child_list.find((item) => item?.request_id === request_id)
        ?.ai_child_list || [];
    const sini_db_object_test = ai_parent_detail_list.find(
      (item) => item.request_id === request_id
    )?.request_id;
    const params = {
      request_id: request_id,
    };
    if (ai_child_list.length === 0) {
      this.props.get_ai_child_list(params);
    }
    if (!sini_db_object_test) {
      this.props.get_ai_parent_object(params);
    } else {
      this.props.fly_init_update();
    }

    this.props.set_value_sini({
      key: "is_sini_visible",
      value: true,
    });
  };

  render() {
    //local storage

    //local state

    //global props
    const { request_id_active, ai_parent_list, sini_v2_list } = this.props.sini;

    //content
    return (
      <main>
        <div className="badge_pill margin_bottom">On the spot SINI</div>
        <br />
        <section className="container_light margin_bottom outline_green">
          {sini_v2_list.length === 0 ? (
            <p className="text_small">Belum melakukan SINI on the spot</p>
          ) : (
            <>
              {sini_v2_list.map((item, idx) => {
                return (
                  <section
                    key={idx}
                    className="margin_bottom"
                    onClick={this.on_click_request_id.bind(
                      this,
                      item.request_id
                    )}
                  >
                    <div className="button_pill background_white margin_right outline_black">
                      <table className="full_width">
                        <tbody>
                          <tr>
                            <td className="text_left">
                              <span
                                className={`badge_circle margin_right ${
                                  request_id_active === item.request_id
                                    ? "background_green"
                                    : "background_grey"
                                }`}
                              />
                            </td>
                            <td className="text_left">{item?.title_1}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <br />
                    <p className="text_small margin_bottom">{item?.title_2}</p>
                    <hr />
                  </section>
                );
              })}
            </>
          )}
        </section>
        <div className="badge_pill margin_bottom">SINI AI history</div>
        <br />
        <section className="container_light outline_green">
          {ai_parent_list.length === 0 ? (
            <p className="text_small">Belum melakukan chat SINI AI</p>
          ) : (
            <>
              {ai_parent_list.map((item, idx) => {
                return (
                  <section
                    key={idx}
                    className="margin_bottom"
                    onClick={this.on_click_request_id.bind(
                      this,
                      item.request_id
                    )}
                  >
                    <div className="button_pill background_white margin_right outline_black">
                      <table className="full_width">
                        <tbody>
                          <tr>
                            <td className="text_left">
                              <span
                                className={`badge_circle margin_right ${
                                  request_id_active === item.request_id
                                    ? "background_green"
                                    : "background_grey"
                                }`}
                              />
                            </td>
                            <td className="text_left">{item?.title_1}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <br />
                    <p className="text_small margin_bottom">{item?.title_2}</p>
                    <hr />
                  </section>
                );
              })}
            </>
          )}
        </section>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  map: state.map,
  license_reducer: state.license_reducer,
  sini: state.sini,
  loading_reducer: state.loading_reducer,
});

export default connect(mapStateToProps, {
  set_value_sini,
  fly_init_update,
  get_ai_parent_list,
  get_ai_child_list,
  get_ai_parent_object,
})(SINI_HISTORY_FLY);
