/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/
import Modal from "../common_modal/Modal";
import CheckBox from "../common_input/CheckBox";
import SelectDefaultLayers from "./SelectDefaultLayers";
import CLONE_PROJECT from "./CLONE_PROJECT";

/*REDUX FUNCTION*/
import {
  editProject,
  get_pinned_project_list,
} from "../../App/actions/projectActions";
import {
  set_data_premium_bulk,
  undo_data_premium_bulk,
} from "../../App/actions/moneyActions";
import { get_categories } from "../../App/actions/blogAction";
import { initApi } from "../../App/actions/authActions";

/*PICTURE ASSET*/

/*GENERAL*/
import dict from "../../Data/dict.json";
import sort_array from "../../App/validation/sort_array";

/*NON PROJECT*/

class EDIT_PROJECT extends Component {
  constructor(props) {
    super(props);
    this.state = {
      geo_project: {
        name: "",
        description: "",
        isPublic: false,
      },
      modal_layer: false,
      layer_id: "",
      price: 5_000,
      category_id: "",
      modal_data_premium: false,
      modal_undo_data_premium: false,
      modal_select_layers: false,
      modal_clone_project: false,
    };
  }

  componentDidMount() {
    this.on_start();
  }

  componentDidUpdate(prevProps) {
    const project_before = prevProps?.project?.geo_project?._id;
    const project_after = this?.props?.project?.geo_project?._id;
    const length_prev = prevProps?.layer?.geo_layer_list?.length;
    const length_after = this?.props?.layer?.geo_layer_list?.length;
    if (length_prev !== length_after || project_before !== project_after) {
      this.on_start();
    }
  }

  toggle_clone_project = () => {
    this.setState({ modal_clone_project: !this.state.modal_clone_project });
  };

  toggle_select_layers = () => {
    this.setState({ modal_select_layers: !this.state.modal_select_layers });
  };

  on_start = () => {
    const { geo_project } = this.props.project;
    if (geo_project?._id) {
      this.setState({
        geo_project,
      });
    }
    const { categories } = this.props.blog;
    if (categories.length === 0) {
      this.props.get_categories();
    }
  };

  on_set_data_premium_bulk = () => {
    const { geo_layer_list } = this.props.layer;
    const geo_project_id = this.props.project.geo_project._id;
    const layer_id_array = geo_layer_list.map((item) => item?.geo_layer?._id);
    const { category_id, price } = this.state;
    const body = { geo_project_id, layer_id_array, price, category_id };
    this.props.set_data_premium_bulk(body);
    this.setState({ modal_data_premium: false });
  };

  on_undo_data_premium_bulk = () => {
    const { geo_layer_list } = this.props.layer;
    const geo_project_id = this.props.project.geo_project._id;
    const layer_id_array = geo_layer_list.map((item) => item?.geo_layer?._id);
    const body = { geo_project_id, layer_id_array };
    this.props.undo_data_premium_bulk(body);
    this.setState({ modal_undo_data_premium: false });
  };

  toggle_data_premium = () => {
    this.setState({ modal_data_premium: !this.state.modal_data_premium });
  };

  toggle_undo_data_premium = () => {
    this.setState({
      modal_undo_data_premium: !this.state.modal_undo_data_premium,
    });
  };

  set_layer_id = (layer_id) => {
    this.setState({ layer_id });
  };

  clear_layer_id = () => {
    this.setState({ layer_id: "" });
  };

  initApi = () => {
    const body = {};
    this.props.initApi(body);
  };

  on_change = (e) => {
    e.preventDefault();
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    if (["name", "description", "mode_folder"].includes(name)) {
      let geo_project = this.state.geo_project;
      geo_project[name] = value;
      this.setState({
        geo_project,
      });
    } else {
      this.setState({
        [name]: value,
      });
    }
  };

  handleToggle = (e) => {
    const target = e.target;
    const name = target.name;
    let geo_project = this.state.geo_project;
    geo_project[name] = !geo_project[name];
    this.setState({
      geo_project,
    });
  };

  on_submit = async () => {
    const { geo_project } = this.state;
    const { _id, name, description, isPublic, mode_folder } = geo_project;
    let { layer_list_load_first } = this.props.project;
    layer_list_load_first = layer_list_load_first.filter((item) => item !== "");

    const { folder_id_selected } = this.props.project;
    let key;

    if (this.props.title === "Pinned") {
      key = "geo_project_pinned_list";
    } else if (!!folder_id_selected) {
      key = "geo_project_list_child";
    } else {
      key = "geo_project_list";
    }

    const body = {
      key,
      geo_project_id: _id,
      name,
      description,
      isPublic,
      layer_list_load_first: layer_list_load_first,
      mode_folder,
    };

    await this.props.editProject(body);
    // await this.props.get_pinned_project_list();
    this.props.toggle_edit_project();
  };

  render() {
    //local storage
    const language = localStorage?.language ? localStorage?.language : "ina";

    //local state
    const {
      geo_project,
      price,
      category_id,
      modal_data_premium,
      modal_undo_data_premium,
      modal_select_layers,
      modal_clone_project,
    } = this.state;

    //global props
    const { user } = this.props.auth;
    const { loadingProcess } = this.props.project;
    const { loading_item_array } = this.props.loading_reducer;

    //content
    const api_keys = user?.api_keys ? user.api_keys : [];
    const project_props = this.props.project.geo_project;
    let { categories } = this.props.blog;

    if (language === "ina") {
      categories = sort_array(categories, "idn", true);
    } else {
      categories = sort_array(categories, "eng", true);
    }

    let open_api_content = (
      <main className="container_flat">
        <section className="text_bold">Open API</section>
        <br />
        <section className="text_inferior">
          {dict["You don't have an API key yet"][language]}
        </section>
        <button
          className="button background_blue"
          id="blue"
          onClick={this.initApi}
        >
          {dict["Create a new API key"][language]}
        </button>
      </main>
    );

    if (api_keys.length > 0) {
      open_api_content = (
        <main className="container_flat">
          <section className="text_bold">Open API</section>
          <br />
          <textarea
            disabled
            value={`https://geoserver.mapid.io/layers_new/get_layer_list?api_key=${api_keys[0]?.key}&project_id=${geo_project?._id}`}
            rows="5"
          />
        </main>
      );
    }

    let content;
    let button_content;
    if (loadingProcess) {
      button_content = (
        <button
          className="button background_blue"
          id="blue"
          style={{ marginTop: "1rem" }}
        >
          {dict["Saving"][language]}...
        </button>
      );
    } else {
      button_content = (
        <button
          className="button background_blue"
          style={{ marginTop: "1rem" }}
          onClick={this.on_submit}
          data-mapid="clickSave"
        >
          {dict["Save"][language]}
        </button>
      );
    }

    content = (
      <div>
        <main
          style={{
            maxHeight: "calc(( 85vh - 100px))",
            overflowY: "scroll",
            overflowX: "hidden",
            paddingRight: "10px",
          }}
        >
          <h1 className="text_header">{dict["Edit project"][language]}</h1>
          <br />

          <section className="container_flat">
            <p className="text_bold">General</p>
            <br />
            <p className="text_small">Nama proyek</p>
            <input
              className="input_square"
              type="text"
              name="name"
              id="name"
              placeholder={dict["Project name"][language]}
              value={geo_project.name}
              onChange={this.on_change}
              data-mapid="editNameProject"
            />
            <br />
            <br />
            <p className="text_small">Deskripsi proyek</p>
            <textarea
              className="input_square"
              type="text"
              name="description"
              id="description"
              placeholder={dict["Project description"][language]}
              value={geo_project.description}
              onChange={this.on_change}
              rows="4"
              data-mapid="editDeskriptionProject"
            />
            <br />
            <br />
            <p className="text_small">Status publik proyek</p>
            <CheckBox
              text="isPublic"
              title={dict["Public data"][language]}
              value={geo_project.isPublic}
              handle={this.handleToggle.bind(this)}
              data-mapid="clickToggle"
            />
            <br />
            <br />
            <p className="text_small">Mode folder</p>
            <select
              onChange={this.on_change}
              name="mode_folder"
              value={geo_project.mode_folder}
              data-mapid="clickMode"
            >
              <option value="">Pilih mode</option>
              <option value="expand">Expand</option>
              <option value="button background_blue">Button</option>
            </select>
            <br />
            <br />
            <p className="text_small">Duplikat proyek</p>
            <br />
            <button
              className="button background_blue"
              onClick={this.toggle_clone_project}
              data-mapid="clickDuplikat"
            >
              Mulai duplikat proyek
            </button>
          </section>

          <br />

          <section className="container_flat">
            <p className="text_bold">Opsi memuat data awal</p>
            <p className="text_small">
              Pilih data layer yang akan ditampilkan ketika pertama kali memuat
              peta.
            </p>
            <br />
            <div>
              <button
                className="button background_blue"
                onClick={this.toggle_select_layers}
              >
                Pilih layer
              </button>
              <br />
              <p className="text_small">
                Jumlah layer terpilih:{" "}
                {geo_project?.layer_list_load_first?.filter(
                  (item) => item !== ""
                )?.length || 0}
              </p>
            </div>
          </section>

          <br />

          <section className="container_flat">
            <p className="text_bold">Monetisasi data premium</p>
            <br />
            {project_props?.is_premium ? (
              <>
                <div className="button_small background_blue" id="white">
                  ✓ Harga monetisasi tersimpan
                </div>
                <br />
                <br />
                <button
                  className="button background_blue"
                  id="red"
                  onClick={this.toggle_undo_data_premium}
                  type="button background_blue"
                  data-mapid="clickBalik"
                >
                  Undo monetisasi
                </button>
              </>
            ) : (
              <button
                className="button background_blue"
                id="blue"
                onClick={this.toggle_data_premium}
                type="button background_blue"
                data-mapid="clickAtur"
              >
                Atur harga
              </button>
            )}
            {loading_item_array.includes("set_data_premium_bulk") && (
              <div className="container_light margin_top">
                Mengajukan data premium ...
              </div>
            )}

            {loading_item_array.includes("undo_data_premium_bulk") && (
              <div className="container_light margin_top">
                Mengurungkan pengajuan data premium ...
              </div>
            )}
          </section>

          <br />

          {open_api_content}
        </main>

        {button_content}
      </div>
    );

    const modal_data_premium_content = modal_data_premium && (
      <Modal
        modalSize="small"
        isOpen={modal_data_premium}
        onClose={this.toggle_data_premium}
      >
        <div className="box-body">
          <h1 className="text_header">Data premium</h1>
          <p className="text_inferior">
            Jadikan semua layer di dalam proyek ini menjadi data premium dan
            atur harganya
          </p>
          <br />
          <input
            className="input_square"
            type="number"
            name="price"
            id="price"
            value={price}
            onChange={this.on_change}
            data-mapid="inputPremium"
          />
          <section className="container_flat">
            <p className="text_inferior">
              Desimal: Rp
              {new Intl.NumberFormat("id-ID", {
                style: "decimal",
              }).format(price)}
            </p>
          </section>
          <br />
          <select
            name="category_id"
            value={category_id}
            onChange={this.on_change}
            data-mapid="changeKategori"
          >
            <option value="">Pilih kategori data</option>
            {categories.map((item, idx) => {
              return (
                <option key={idx} value={item._id}>
                  {language === "ina" ? item.idn : item.eng}
                </option>
              );
            })}
          </select>
          <br />
          <br />
          {category_id ? (
            <button
              className="button background_blue"
              onClick={this.on_set_data_premium_bulk}
              data-mapid="clickAjukan"
            >
              Ajukan
            </button>
          ) : (
            <button className="button background_grey">Ajukan</button>
          )}
        </div>
      </Modal>
    );

    const modal_undo_data_premium_content = modal_undo_data_premium && (
      <Modal
        modalSize="small"
        isOpen={modal_undo_data_premium}
        onClose={this.toggle_undo_data_premium}
      >
        <div className="box-body">
          <h1 className="text_header">Data premium</h1>
          <p className="text_inferior">
            Undo monetisasi semua layer di dalam proyek ini menjadi data premium
            dan atur harganya
          </p>
          <br />
          <br />
          <button
            className="button background_blue"
            id="blue"
            onClick={this.on_undo_data_premium_bulk}
            data-mapid="clickMonetasi"
          >
            Undo monetisasi
          </button>
        </div>
      </Modal>
    );

    const modal_select_layers_content = modal_select_layers && (
      <Modal
        modalSize="large"
        isOpen={modal_select_layers}
        onClose={this.toggle_select_layers}
      >
        <div className="box-body">
          <SelectDefaultLayers
            mode={this.props.mode}
            layer_list_load_first={this.state.layer_list_load_first}
            toggle_layer={this.toggle_layer}
          />
        </div>
      </Modal>
    );

    const modal_clone_project_content = modal_clone_project && (
      <Modal
        modalSize="small"
        isOpen={modal_clone_project}
        onClose={this.toggle_clone_project}
      >
        <div className="box-body">
          <CLONE_PROJECT
            toggle_clone_project={this.toggle_clone_project}
            toggle_edit_project={this.props.toggle_edit_project}
          />
        </div>
      </Modal>
    );

    return (
      <div>
        {modal_clone_project_content}
        {modal_select_layers_content}
        {modal_data_premium_content}
        {modal_undo_data_premium_content}
        {content}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  project: state.project,
  auth: state.auth,
  layer: state.layer,
  blog: state.blog,
  loading_reducer: state.loading_reducer,
});

export default connect(mapStateToProps, {
  editProject,
  initApi,
  set_data_premium_bulk,
  undo_data_premium_bulk,
  get_categories,
  get_pinned_project_list,
})(EDIT_PROJECT);
