export const convertGeoJsonToSheetPembanding = ({ features = [], geo_layer_id, feature_key, field_key }) => {
  const result = [];

  // Mapping properties to their corresponding template positions  
  const propertyMapping = {
    "E7": "Tipe Properti",
    "E9": "Alamat",
    "E11": "Luas Tanah",
    "E15": "Bentuk Tanah",
    "E17": "Dokumen Tanah",
    "E19": "Posisi Tanah",
    "E21": "Topografi",
    "E23": "Luas Bangunan",
    "E25": "Tipe Bangunan",
    "E27": "Jumlah Lantai",
    "E29": "Tahun Dibangun",
    "E31": "Kondisi Bangunan",
    "E33": "Frontage/Lebar Depan",
    "E35": "Elevasi",
    "E37": "Lebar Jalan",
    "E39": "Aksesibilitas",
    "E41": "Kondisi Lingkungan",
    "E43": "Jarak ke properti subjek",
    "E45": "Jarak terhadap pusat keramaian",
    // "E47": "Peraturan Zonasi",
    // "E49": "KLB",
    "E52": "Zonasi",
    "E54": "Karakteristik ekonomi (untuk properti komersial)",
    "E57": "Komponen non-realty",
    "E59": "Harga Penawaran/Transaksi",
    "E61": "Syarat Pembayaran",
    "E62": "Pengeluaran yang Tertunda",
    "E64": "Kondisi Penawaran/Transaksi",
    "E66": "Kondisi Pasar (Waktu Penjualan)",
    "E68": "Sumber Data",
    "E70": "Telepon",
    "E72": "Catatan",
    "G47": "KDB (%)",
    "M47": "KTB",
    "G49": "KLB",
    "M49": "GSB"
  };

  // features.forEach((feature, index) => {
  for (const [index, feature] of features.entries()) {
    const newPembanding = JSON.parse(JSON.stringify(templatePembanding)); // Clone the template
    newPembanding.geo_layer_id = geo_layer_id;
    newPembanding.field_key = field_key;
    newPembanding.feature_key = feature_key;
    newPembanding.metadata.name = `Data Pembanding ${index + 1}`;
    newPembanding.metadata.id_ori = `Data Pembanding ${index + 1}`;
    newPembanding.metadata.order = index + 2; // Order starts from 2  
    newPembanding._id = feature?._id

    // Set values in the template based on the mapping  
    Object.entries(propertyMapping).forEach(([cell, property]) => {
      const value = feature.properties[property] || "";
      newPembanding.value.forEach(item => {
        if (item.p === cell) {
          item.v = value;
        }
      });
    });

    result.push(newPembanding);
  };

  return result;
}


export const templatePembanding = {
  "_id": "",
  "feature_key": "",
  "field_key": "",
  "geo_layer_id": "",
  "metadata": {
    "name": "Data Pembanding 1",
    "id_ori": "Data Pembanding 1",
    "order": 2 //2,3,4
  },
  "value": [
    {
      "p": "C2",
      "d": {
        "fontColor": null,
        "fontSize": 12,
        "fontName": "Calibri",
        "bold": true,
        "italic": false,
        "underline": false,
        "fillColor": "#0070C0",
        "border": {
          "top": null,
          "right": null,
          "bottom": null,
          "left": null
        },
        "decimal": null
      },
      "v": "DATA PEMBANDING",
      "w": "DATA PEMBANDING",
      "t": "s",
      "r": "<t>DATA PEMBANDING</t>",
      "h": "DATA PEMBANDING"
    },
    {
      "p": "C5",
      "d": {
        "fontColor": "#ffffff",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": "#0070C0",
        "border": {
          "top": "thin",
          "right": null,
          "bottom": "thin",
          "left": null
        },
        "decimal": null
      },
      "v": "PEMBANDING 1",
      "w": "PEMBANDING 1",
      "t": "s",
      "r": "<t>PEMBANDING 1</t>",
      "h": "PEMBANDING 1"
    },
    {
      "p": "C7",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": null,
        "border": {
          "top": null,
          "right": null,
          "bottom": null,
          "left": null
        },
        "decimal": null
      },
      "v": "Tipe Properti",
      "w": "Tipe Properti",
      "t": "s",
      "r": "<t>Tipe Properti</t>",
      "h": "Tipe Properti"
    },
    {
      "p": "E7",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": "#FFC000",
        "border": {
          "top": "thin",
          "right": null,
          "bottom": "thin",
          "left": null
        },
        "decimal": null
      }
    },
    {
      "p": "C9",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": null,
        "border": {
          "top": null,
          "right": null,
          "bottom": null,
          "left": null
        },
        "decimal": null
      },
      "v": "Alamat",
      "w": "Alamat",
      "t": "s",
      "r": "<t>Alamat</t>",
      "h": "Alamat"
    },
    {
      "p": "E9",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": "#FFC000",
        "border": {
          "top": "thin",
          "right": null,
          "bottom": "thin",
          "left": null
        },
        "decimal": null
      }
    },
    {
      "p": "C10",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": null,
        "border": {
          "top": null,
          "right": null,
          "bottom": null,
          "left": null
        },
        "decimal": null
      }
    },
    {
      "p": "C11",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": null,
        "border": {
          "top": null,
          "right": null,
          "bottom": null,
          "left": null
        },
        "decimal": null
      },
      "v": "Luas Tanah",
      "w": "Luas Tanah",
      "t": "s",
      "r": "<t>Luas Tanah</t>",
      "h": "Luas Tanah"
    },
    {
      "p": "E11",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": "#FFC000",
        "border": {
          "top": "thin",
          "right": null,
          "bottom": "thin",
          "left": null
        },
        "decimal": null
      }
    },
    {
      "p": "F11",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": null,
        "border": {
          "top": null,
          "right": null,
          "bottom": null,
          "left": null
        },
        "decimal": null
      },
      "v": "m2",
      "w": "m2",
      "t": "s",
      "r": "<t>m2</t>",
      "h": "m2"
    },
    {
      "p": "C13",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": null,
        "border": {
          "top": null,
          "right": null,
          "bottom": null,
          "left": null
        },
        "decimal": null
      },
      "v": "Jenis Tanah",
      "w": "Jenis Tanah",
      "t": "s",
      "r": "<t>Jenis Tanah</t>",
      "h": "Jenis Tanah"
    },
    {
      "p": "E13",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": "#FFC000",
        "border": {
          "top": "thin",
          "right": null,
          "bottom": "thin",
          "left": null
        },
        "decimal": null
      }
    },
    {
      "p": "C15",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": null,
        "border": {
          "top": null,
          "right": null,
          "bottom": null,
          "left": null
        },
        "decimal": null
      },
      "v": "Bentuk Tanah",
      "w": "Bentuk Tanah",
      "t": "s",
      "r": "<t>Bentuk Tanah</t>",
      "h": "Bentuk Tanah"
    },
    {
      "p": "E15",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": "#FFC000",
        "border": {
          "top": "thin",
          "right": null,
          "bottom": "thin",
          "left": null
        },
        "decimal": null
      }
    },
    {
      "p": "C17",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": null,
        "border": {
          "top": null,
          "right": null,
          "bottom": null,
          "left": null
        },
        "decimal": null
      },
      "v": "Dokumen Tanah",
      "w": "Dokumen Tanah",
      "t": "s",
      "r": "<t>Dokumen Tanah</t>",
      "h": "Dokumen Tanah"
    },
    {
      "p": "E17",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": "#FFC000",
        "border": {
          "top": "thin",
          "right": null,
          "bottom": "thin",
          "left": null
        },
        "decimal": null
      }
    },
    {
      "p": "C19",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": null,
        "border": {
          "top": null,
          "right": null,
          "bottom": null,
          "left": null
        },
        "decimal": null
      },
      "v": "Posisi Tanah",
      "w": "Posisi Tanah",
      "t": "s",
      "r": "<t>Posisi Tanah</t>",
      "h": "Posisi Tanah"
    },
    {
      "p": "E19",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": "#FFC000",
        "border": {
          "top": "thin",
          "right": null,
          "bottom": "thin",
          "left": null
        },
        "decimal": null
      }
    },
    {
      "p": "C21",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": null,
        "border": {
          "top": null,
          "right": null,
          "bottom": null,
          "left": null
        },
        "decimal": null
      },
      "v": "Topografi",
      "w": "Topografi",
      "t": "s",
      "r": "<t>Topografi</t>",
      "h": "Topografi"
    },
    {
      "p": "E21",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": "#FFC000",
        "border": {
          "top": "thin",
          "right": null,
          "bottom": "thin",
          "left": null
        },
        "decimal": null
      }
    },
    {
      "p": "C23",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": null,
        "border": {
          "top": null,
          "right": null,
          "bottom": null,
          "left": null
        },
        "decimal": null
      },
      "v": "Luas Bangunan",
      "w": "Luas Bangunan",
      "t": "s",
      "r": "<t>Luas Bangunan</t>",
      "h": "Luas Bangunan"
    },
    {
      "p": "E23",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": "#FFC000",
        "border": {
          "top": "thin",
          "right": null,
          "bottom": "thin",
          "left": null
        },
        "decimal": null
      }
    },
    {
      "p": "F23",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": null,
        "border": {
          "top": null,
          "right": null,
          "bottom": null,
          "left": null
        },
        "decimal": null
      },
      "v": "m2",
      "w": "m2",
      "t": "s",
      "r": "<t>m2</t>",
      "h": "m2"
    },
    {
      "p": "C25",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": null,
        "border": {
          "top": null,
          "right": null,
          "bottom": null,
          "left": null
        },
        "decimal": null
      },
      "v": "Tipe Bangunan",
      "w": "Tipe Bangunan",
      "t": "s",
      "r": "<t>Tipe Bangunan</t>",
      "h": "Tipe Bangunan"
    },
    {
      "p": "E25",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": "#FFC000",
        "border": {
          "top": "thin",
          "right": null,
          "bottom": "thin",
          "left": null
        },
        "decimal": null
      }
    },
    {
      "p": "C27",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": null,
        "border": {
          "top": null,
          "right": null,
          "bottom": null,
          "left": null
        },
        "decimal": null
      },
      "v": "Jumlah Lantai",
      "w": "Jumlah Lantai",
      "t": "s",
      "r": "<t>Jumlah Lantai</t>",
      "h": "Jumlah Lantai"
    },
    {
      "p": "E27",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": "#FFC000",
        "border": {
          "top": "thin",
          "right": null,
          "bottom": "thin",
          "left": null
        },
        "decimal": null
      }
    },
    {
      "p": "C29",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": null,
        "border": {
          "top": null,
          "right": null,
          "bottom": null,
          "left": null
        },
        "decimal": null
      },
      "v": "Tahun Dibangun",
      "w": "Tahun Dibangun",
      "t": "s",
      "r": "<t>Tahun Dibangun</t>",
      "h": "Tahun Dibangun"
    },
    {
      "p": "E29",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": "#FFC000",
        "border": {
          "top": "thin",
          "right": null,
          "bottom": "thin",
          "left": null
        },
        "decimal": null
      }
    },
    {
      "p": "C31",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": null,
        "border": {
          "top": null,
          "right": null,
          "bottom": null,
          "left": null
        },
        "decimal": null
      },
      "v": "Kondisi Bangunan",
      "w": "Kondisi Bangunan",
      "t": "s",
      "r": "<t>Kondisi Bangunan</t>",
      "h": "Kondisi Bangunan"
    },
    {
      "p": "E31",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": "#FFC000",
        "border": {
          "top": "thin",
          "right": null,
          "bottom": "thin",
          "left": null
        },
        "decimal": null
      }
    },
    {
      "p": "C33",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": null,
        "border": {
          "top": null,
          "right": null,
          "bottom": null,
          "left": null
        },
        "decimal": null
      },
      "v": "Frontage/Lebar Depan",
      "w": "Frontage/Lebar Depan",
      "t": "s",
      "r": "<t>Frontage/Lebar Depan</t>",
      "h": "Frontage/Lebar Depan"
    },
    {
      "p": "E33",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": "#FFC000",
        "border": {
          "top": "thin",
          "right": null,
          "bottom": "thin",
          "left": null
        },
        "decimal": null
      }
    },
    {
      "p": "F33",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": null,
        "border": {
          "top": null,
          "right": null,
          "bottom": null,
          "left": null
        },
        "decimal": null
      },
      "v": "m",
      "w": "m",
      "t": "s",
      "r": "<t>m</t>",
      "h": "m"
    },
    {
      "p": "C35",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": null,
        "border": {
          "top": null,
          "right": null,
          "bottom": null,
          "left": null
        },
        "decimal": null
      },
      "v": "Elevasi ",
      "w": "Elevasi ",
      "t": "s",
      "r": "<t xml:space=\"preserve\">Elevasi </t>",
      "h": "Elevasi "
    },
    {
      "p": "E35",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": "#FFC000",
        "border": {
          "top": "thin",
          "right": null,
          "bottom": "thin",
          "left": null
        },
        "decimal": null
      }
    },
    {
      "p": "C37",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": null,
        "border": {
          "top": null,
          "right": null,
          "bottom": null,
          "left": null
        },
        "decimal": null
      },
      "v": "Lebar Jalan",
      "w": "Lebar Jalan",
      "t": "s",
      "r": "<t>Lebar Jalan</t>",
      "h": "Lebar Jalan"
    },
    {
      "p": "E37",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": "#FFC000",
        "border": {
          "top": "thin",
          "right": null,
          "bottom": "thin",
          "left": null
        },
        "decimal": null
      }
    },
    {
      "p": "F37",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": null,
        "border": {
          "top": null,
          "right": null,
          "bottom": null,
          "left": null
        },
        "decimal": null
      },
      "v": "m",
      "w": "m",
      "t": "s",
      "r": "<t>m</t>",
      "h": "m"
    },
    {
      "p": "C39",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": null,
        "border": {
          "top": null,
          "right": null,
          "bottom": null,
          "left": null
        },
        "decimal": null
      },
      "v": "Aksesibilitas",
      "w": "Aksesibilitas",
      "t": "s",
      "r": "<t>Aksesibilitas</t>",
      "h": "Aksesibilitas"
    },
    {
      "p": "E39",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": "#FFC000",
        "border": {
          "top": "thin",
          "right": null,
          "bottom": "thin",
          "left": null
        },
        "decimal": null
      }
    },
    {
      "p": "C41",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": null,
        "border": {
          "top": null,
          "right": null,
          "bottom": null,
          "left": null
        },
        "decimal": null
      },
      "v": "Kondisi Lingkungan",
      "w": "Kondisi Lingkungan",
      "t": "s",
      "r": "<t>Kondisi Lingkungan</t>",
      "h": "Kondisi Lingkungan"
    },
    {
      "p": "E41",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": "#FFC000",
        "border": {
          "top": "thin",
          "right": null,
          "bottom": "thin",
          "left": null
        },
        "decimal": null
      }
    },
    {
      "p": "C43",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": null,
        "border": {
          "top": null,
          "right": null,
          "bottom": null,
          "left": null
        },
        "decimal": null
      },
      "v": "Jarak ke properti subyek",
      "w": "Jarak ke properti subyek",
      "t": "s",
      "r": "<t>Jarak ke properti subyek</t>",
      "h": "Jarak ke properti subyek"
    },
    {
      "p": "E43",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": "#FFC000",
        "border": {
          "top": "thin",
          "right": null,
          "bottom": "thin",
          "left": null
        },
        "decimal": null
      }
    },
    {
      "p": "F43",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": null,
        "border": {
          "top": null,
          "right": null,
          "bottom": null,
          "left": null
        },
        "decimal": null
      },
      "v": "m",
      "w": "m",
      "t": "s",
      "r": "<t>m</t>",
      "h": "m"
    },
    {
      "p": "C45",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": null,
        "border": {
          "top": null,
          "right": null,
          "bottom": null,
          "left": null
        },
        "decimal": null
      },
      "v": "Jarak terhadap Pusat Keramaian",
      "w": "Jarak terhadap Pusat Keramaian",
      "t": "s",
      "r": "<t>Jarak terhadap Pusat Keramaian</t>",
      "h": "Jarak terhadap Pusat Keramaian"
    },
    {
      "p": "E45",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": "#FFC000",
        "border": {
          "top": "thin",
          "right": null,
          "bottom": "thin",
          "left": null
        },
        "decimal": null
      }
    },
    {
      "p": "F45",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": null,
        "border": {
          "top": null,
          "right": null,
          "bottom": null,
          "left": null
        },
        "decimal": null
      },
      "v": "m",
      "w": "m",
      "t": "s",
      "r": "<t>m</t>",
      "h": "m"
    },
    {
      "p": "C47",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": null,
        "border": {
          "top": null,
          "right": null,
          "bottom": null,
          "left": null
        },
        "decimal": null
      },
      "v": "Peraturan Zonasi",
      "w": "Peraturan Zonasi",
      "t": "s",
      "r": "<t>Peraturan Zonasi</t>",
      "h": "Peraturan Zonasi"
    },
    {
      "p": "E47",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": null,
        "border": {
          "top": null,
          "right": null,
          "bottom": null,
          "left": null
        },
        "decimal": null
      },
      "v": "KDB",
      "w": "KDB",
      "t": "s",
      "r": "<t>KDB</t>",
      "h": "KDB"
    },
    {
      "p": "G47",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": null,
        "border": {
          "top": "thin",
          "right": "thin",
          "bottom": "thin",
          "left": null
        },
        "decimal": null
      }
    },
    {
      "p": "K47",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": null,
        "border": {
          "top": null,
          "right": null,
          "bottom": null,
          "left": null
        },
        "decimal": null
      },
      "v": "KTB",
      "w": "KTB",
      "t": "s",
      "r": "<t>KTB</t>",
      "h": "KTB"
    },
    {
      "p": "M47",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": null,
        "border": {
          "top": "thin",
          "right": "thin",
          "bottom": "thin",
          "left": null
        },
        "decimal": null
      }
    },
    {
      "p": "E49",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": null,
        "border": {
          "top": null,
          "right": null,
          "bottom": null,
          "left": null
        },
        "decimal": null
      },
      "v": "KLB",
      "w": "KLB",
      "t": "s",
      "r": "<t>KLB</t>",
      "h": "KLB"
    },
    {
      "p": "G49",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": null,
        "border": {
          "top": "thin",
          "right": "thin",
          "bottom": "thin",
          "left": null
        },
        "decimal": null
      }
    },
    {
      "p": "K49",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": null,
        "border": {
          "top": null,
          "right": null,
          "bottom": null,
          "left": null
        },
        "decimal": null
      },
      "v": "GSB",
      "w": "GSB",
      "t": "s",
      "r": "<t>GSB</t>",
      "h": "GSB"
    },
    {
      "p": "M49",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": null,
        "border": {
          "top": "thin",
          "right": "thin",
          "bottom": "thin",
          "left": null
        },
        "decimal": null
      }
    },
    {
      "p": "C52",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": null,
        "border": {
          "top": null,
          "right": null,
          "bottom": null,
          "left": null
        },
        "decimal": null
      },
      "v": "Zonasi",
      "w": "Zonasi",
      "t": "s",
      "r": "<t>Zonasi</t>",
      "h": "Zonasi"
    },
    {
      "p": "E52",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": null,
        "border": {
          "top": "thin",
          "right": null,
          "bottom": "thin",
          "left": null
        },
        "decimal": null
      }
    },
    {
      "p": "C54",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": null,
        "border": {
          "top": null,
          "right": null,
          "bottom": null,
          "left": null
        },
        "decimal": null
      },
      "v": "Karakteristik ekonomi",
      "w": "Karakteristik ekonomi",
      "t": "s",
      "r": "<t>Karakteristik ekonomi</t>",
      "h": "Karakteristik ekonomi"
    },
    {
      "p": "E54",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": true,
        "italic": false,
        "underline": false,
        "fillColor": null,
        "border": {
          "top": "thin",
          "right": "thin",
          "bottom": "thin",
          "left": null
        },
        "decimal": null
      }
    },
    {
      "p": "C55",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": null,
        "border": {
          "top": null,
          "right": null,
          "bottom": null,
          "left": null
        },
        "decimal": null
      },
      "v": "(untuk properti komersial)",
      "w": "(untuk properti komersial)",
      "t": "s",
      "r": "<t>(untuk properti komersial)</t>",
      "h": "(untuk properti komersial)"
    },
    {
      "p": "C57",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": null,
        "border": {
          "top": null,
          "right": null,
          "bottom": null,
          "left": null
        },
        "decimal": null
      },
      "v": "Komponen non-realty",
      "w": "Komponen non-realty",
      "t": "s",
      "r": "<t>Komponen non-realty</t>",
      "h": "Komponen non-realty"
    },
    {
      "p": "E57",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": true,
        "italic": false,
        "underline": false,
        "fillColor": null,
        "border": {
          "top": "thin",
          "right": "thin",
          "bottom": "thin",
          "left": null
        },
        "decimal": null
      }
    },
    {
      "p": "C59",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": null,
        "border": {
          "top": null,
          "right": null,
          "bottom": null,
          "left": null
        },
        "decimal": null
      },
      "v": "Harga Penawaran/ Transaksi",
      "w": "Harga Penawaran/ Transaksi",
      "t": "s",
      "r": "<t>Harga Penawaran/ Transaksi</t>",
      "h": "Harga Penawaran/ Transaksi"
    },
    {
      "p": "E59",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": "#FFC000",
        "border": {
          "top": "thin",
          "right": "thin",
          "bottom": "thin",
          "left": null
        },
        "decimal": "_(\"IDR\"* #,##0_);_(\"IDR\"* (#,##0);_(\"IDR\"* \"-\"_);_(@_)"
      }
    },
    {
      "p": "C61",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": null,
        "border": {
          "top": null,
          "right": null,
          "bottom": null,
          "left": null
        },
        "decimal": null
      },
      "v": "Syarat Pembayaran",
      "w": "Syarat Pembayaran",
      "t": "s",
      "r": "<t>Syarat Pembayaran</t>",
      "h": "Syarat Pembayaran"
    },
    {
      "p": "E61",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": true,
        "italic": false,
        "underline": false,
        "fillColor": null,
        "border": {
          "top": "thin",
          "right": "thin",
          "bottom": "thin",
          "left": null
        },
        "decimal": null
      }
    },
    {
      "p": "C62",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": null,
        "border": {
          "top": null,
          "right": null,
          "bottom": null,
          "left": null
        },
        "decimal": null
      },
      "v": "Pengeluaran yang tertunda",
      "w": "Pengeluaran yang tertunda",
      "t": "s",
      "r": "<t>Pengeluaran yang tertunda</t>",
      "h": "Pengeluaran yang tertunda"
    },
    {
      "p": "E62",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": true,
        "italic": false,
        "underline": false,
        "fillColor": null,
        "border": {
          "top": "thin",
          "right": "thin",
          "bottom": "thin",
          "left": null
        },
        "decimal": null
      }
    },
    {
      "p": "C64",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": null,
        "border": {
          "top": null,
          "right": null,
          "bottom": null,
          "left": null
        },
        "decimal": null
      },
      "v": "Kondisi Penawaran/ Transaksi",
      "w": "Kondisi Penawaran/ Transaksi",
      "t": "s",
      "r": "<t>Kondisi Penawaran/ Transaksi</t>",
      "h": "Kondisi Penawaran/ Transaksi"
    },
    {
      "p": "E64",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": true,
        "italic": false,
        "underline": false,
        "fillColor": null,
        "border": {
          "top": "thin",
          "right": "thin",
          "bottom": "thin",
          "left": null
        },
        "decimal": null
      }
    },
    {
      "p": "C66",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": null,
        "border": {
          "top": null,
          "right": null,
          "bottom": null,
          "left": null
        },
        "decimal": null
      },
      "v": "Kondisi Pasar (Waktu Penjualan)",
      "w": "Kondisi Pasar (Waktu Penjualan)",
      "t": "s",
      "r": "<t>Kondisi Pasar (Waktu Penjualan)</t>",
      "h": "Kondisi Pasar (Waktu Penjualan)"
    },
    {
      "p": "E66",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": true,
        "italic": false,
        "underline": false,
        "fillColor": null,
        "border": {
          "top": "thin",
          "right": "thin",
          "bottom": "thin",
          "left": null
        },
        "decimal": null
      }
    },
    {
      "p": "C68",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": null,
        "border": {
          "top": null,
          "right": null,
          "bottom": null,
          "left": null
        },
        "decimal": null
      },
      "v": "Sumber Data",
      "w": "Sumber Data",
      "t": "s",
      "r": "<t>Sumber Data</t>",
      "h": "Sumber Data"
    },
    {
      "p": "E68",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": null,
        "border": {
          "top": "thin",
          "right": "thin",
          "bottom": "thin",
          "left": null
        },
        "decimal": null
      }
    },
    {
      "p": "C70",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": null,
        "border": {
          "top": null,
          "right": null,
          "bottom": null,
          "left": null
        },
        "decimal": null
      },
      "v": "Telepon",
      "w": "Telepon",
      "t": "s",
      "r": "<t>Telepon</t>",
      "h": "Telepon"
    },
    {
      "p": "E70",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": "#FFC000",
        "border": {
          "top": "thin",
          "right": "thin",
          "bottom": "thin",
          "left": null
        },
        "decimal": null
      }
    },
    {
      "p": "C72",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": null,
        "border": {
          "top": null,
          "right": null,
          "bottom": null,
          "left": null
        },
        "decimal": null
      },
      "v": "Catatan :",
      "w": "Catatan :",
      "t": "s",
      "r": "<t>Catatan :</t>",
      "h": "Catatan :"
    },
    {
      "p": "E72",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": null,
        "border": {
          "top": "thin",
          "right": null,
          "bottom": null,
          "left": null
        },
        "decimal": null
      }
    },
  ],
  "__v": 0
}

export const data_object_terisi = {
  "metadata": {
    "name": "Data Objek",
    "id_ori": "Data Objek",
    "order": 1
  },
  "value": [
    {
      "p": "C2",
      "d": {
        "fontColor": null,
        "fontSize": 12,
        "fontName": "Calibri",
        "bold": true,
        "italic": false,
        "underline": false,
        "fillColor": "#0070C0",
        "border": {
          "top": null,
          "right": null,
          "bottom": null,
          "left": null
        },
        "decimal": null
      },
      "v": "DATA OBJEK PENILAIAN",
      "w": "DATA OBJEK PENILAIAN",
      "t": "s",
      "r": "<t>DATA OBJEK PENILAIAN</t>",
      "h": "DATA OBJEK PENILAIAN"
    },
    {
      "p": "C4",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": null,
        "border": {
          "top": null,
          "right": null,
          "bottom": null,
          "left": null
        },
        "decimal": null
      },
      "v": "Jenis Properti (Bangunan)",
      "w": "Jenis Properti (Bangunan)",
      "t": "s",
      "r": "<t>Jenis Properti (Bangunan)</t>",
      "h": "Jenis Properti (Bangunan)"
    },
    {
      p: "E4",
      v: "Rumah Tinggal Menengah"
    },
    {
      "p": "C6",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": null,
        "border": {
          "top": null,
          "right": null,
          "bottom": null,
          "left": null
        },
        "decimal": null
      },
      "v": "Tipe Properti",
      "w": "Tipe Properti",
      "t": "s",
      "r": "<t>Tipe Properti</t>",
      "h": "Tipe Properti"
    },
    {
      "p": "E6",
      "v": "Kantor"
    },
    {
      "p": "C8",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": null,
        "border": {
          "top": null,
          "right": null,
          "bottom": null,
          "left": null
        },
        "decimal": null
      },
      "v": "Alamat",
      "w": "Alamat",
      "t": "s",
      "r": "<t>Alamat</t>",
      "h": "Alamat"
    },
    {
      "p": "E8",
      "v": "Semarang"
    },
    {
      "p": "C10",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": null,
        "border": {
          "top": null,
          "right": null,
          "bottom": null,
          "left": null
        },
        "decimal": null
      },
      "v": "Luas Tanah",
      "w": "Luas Tanah",
      "t": "s",
      "r": "<t>Luas Tanah</t>",
      "h": "Luas Tanah"
    },
    {
      "p": "E10",
      "v": 1500
    },
    {
      "p": "F10",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": null,
        "border": {
          "top": null,
          "right": null,
          "bottom": null,
          "left": null
        },
        "decimal": null
      },
      "v": "m2",
      "w": "m2",
      "t": "s",
      "r": "<t>m2</t>",
      "h": "m2"
    },
    {
      "p": "C12",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": null,
        "border": {
          "top": null,
          "right": null,
          "bottom": null,
          "left": null
        },
        "decimal": null
      },
      "v": "Jenis Tanah",
      "w": "Jenis Tanah",
      "t": "s",
      "r": "<t>Jenis Tanah</t>",
      "h": "Jenis Tanah"
    },
    {
      "p": "E12",
      "v": "Matang"
    },
    {
      "p": "C14",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": null,
        "border": {
          "top": null,
          "right": null,
          "bottom": null,
          "left": null
        },
        "decimal": null
      },
      "v": "Bentuk Tanah",
      "w": "Bentuk Tanah",
      "t": "s",
      "r": "<t>Bentuk Tanah</t>",
      "h": "Bentuk Tanah"
    },
    {
      "p": "E14",
      "v": "Beraturan"
    },
    {
      "p": "C16",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": null,
        "border": {
          "top": null,
          "right": null,
          "bottom": null,
          "left": null
        },
        "decimal": null
      },
      "v": "Dokumen Tanah",
      "w": "Dokumen Tanah",
      "t": "s",
      "r": "<t>Dokumen Tanah</t>",
      "h": "Dokumen Tanah"
    },
    {
      "p": "E16",
      "v": "SHM"
    },
    {
      "p": "C18",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": null,
        "border": {
          "top": null,
          "right": null,
          "bottom": null,
          "left": null
        },
        "decimal": null
      },
      "v": "Posisi Tanah",
      "w": "Posisi Tanah",
      "t": "s",
      "r": "<t>Posisi Tanah</t>",
      "h": "Posisi Tanah"
    },
    {
      "p": "E18",
      "v": "Tengah"
    },
    {
      "p": "C20",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": null,
        "border": {
          "top": null,
          "right": null,
          "bottom": null,
          "left": null
        },
        "decimal": null
      },
      "v": "Topografi",
      "w": "Topografi",
      "t": "s",
      "r": "<t>Topografi</t>",
      "h": "Topografi"
    },
    {
      "p": "E20",
      "v": "Datar"
    },
    {
      "p": "C22",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": null,
        "border": {
          "top": null,
          "right": null,
          "bottom": null,
          "left": null
        },
        "decimal": null
      },
      "v": "Luas Bangunan",
      "w": "Luas Bangunan",
      "t": "s",
      "r": "<t>Luas Bangunan</t>",
      "h": "Luas Bangunan"
    },
    {
      "p": "E22",
      "v": 3000
    },
    {
      "p": "F22",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": null,
        "border": {
          "top": null,
          "right": null,
          "bottom": null,
          "left": null
        },
        "decimal": null
      },
      "v": "m2",
      "w": "m2",
      "t": "s",
      "r": "<t>m2</t>",
      "h": "m2"
    },
    {
      "p": "C24",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": null,
        "border": {
          "top": null,
          "right": null,
          "bottom": null,
          "left": null
        },
        "decimal": null
      },
      "v": "Jumlah Lantai",
      "w": "Jumlah Lantai",
      "t": "s",
      "r": "<t>Jumlah Lantai</t>",
      "h": "Jumlah Lantai"
    },
    {
      "p": "E24",
      "v": 3
    },
    {
      "p": "C26",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": null,
        "border": {
          "top": null,
          "right": null,
          "bottom": null,
          "left": null
        },
        "decimal": null
      },
      "v": "Tahun Dibangun",
      "w": "Tahun Dibangun",
      "t": "s",
      "r": "<t>Tahun Dibangun</t>",
      "h": "Tahun Dibangun"
    },
    {
      "p": "E26",
      "v": 2004
    },
    {
      "p": "C28",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": null,
        "border": {
          "top": null,
          "right": null,
          "bottom": null,
          "left": null
        },
        "decimal": null
      },
      "v": "Tahun Renovasi",
      "w": "Tahun Renovasi",
      "t": "s",
      "r": "<t>Tahun Renovasi</t>",
      "h": "Tahun Renovasi"
    },
    {
      "p": "C30",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": null,
        "border": {
          "top": null,
          "right": null,
          "bottom": null,
          "left": null
        },
        "decimal": null
      },
      "v": "Kondisi Bangunan",
      "w": "Kondisi Bangunan",
      "t": "s",
      "r": "<t>Kondisi Bangunan</t>",
      "h": "Kondisi Bangunan"
    },

    {
      "p": "E30",
      "v": "Rusak Ringan"
    },

    {
      "p": "C32",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": null,
        "border": {
          "top": null,
          "right": null,
          "bottom": null,
          "left": null
        },
        "decimal": null
      },
      "v": "Frontage/Lebar Depan",
      "w": "Frontage/Lebar Depan",
      "t": "s",
      "r": "<t>Frontage/Lebar Depan</t>",
      "h": "Frontage/Lebar Depan"
    },
    {
      "p": "E32",
      "v": 10
    },
    {
      "p": "F32",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": null,
        "border": {
          "top": null,
          "right": null,
          "bottom": null,
          "left": null
        },
        "decimal": null
      },
      "v": "m",
      "w": "m",
      "t": "s",
      "r": "<t>m</t>",
      "h": "m"
    },
    {
      "p": "C34",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": null,
        "border": {
          "top": null,
          "right": null,
          "bottom": null,
          "left": null
        },
        "decimal": null
      },
      "v": "Elevasi ",
      "w": "Elevasi ",
      "t": "s",
      "r": "<t xml:space=\"preserve\">Elevasi </t>",
      "h": "Elevasi "
    },
    {
      "p": "E34",
      "v": "+0.2 m dari permukaan jalan"
    },
    {
      "p": "C36",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": null,
        "border": {
          "top": null,
          "right": null,
          "bottom": null,
          "left": null
        },
        "decimal": null
      },
      "v": "Lebar Jalan",
      "w": "Lebar Jalan",
      "t": "s",
      "r": "<t>Lebar Jalan</t>",
      "h": "Lebar Jalan"
    },
    {
      "p": "E36",
      "v": 12
    },
    {
      "p": "F36",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": null,
        "border": {
          "top": null,
          "right": null,
          "bottom": null,
          "left": null
        },
        "decimal": null
      },
      "v": "m",
      "w": "m",
      "t": "s",
      "r": "<t>m</t>",
      "h": "m"
    },
    {
      "p": "C38",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": null,
        "border": {
          "top": null,
          "right": null,
          "bottom": null,
          "left": null
        },
        "decimal": null
      },
      "v": "Aksesibilitas",
      "w": "Aksesibilitas",
      "t": "s",
      "r": "<t>Aksesibilitas</t>",
      "h": "Aksesibilitas"
    },
    {
      "p": "E38",
      "v": "Mudah"
    },
    {
      "p": "C40",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": null,
        "border": {
          "top": null,
          "right": null,
          "bottom": null,
          "left": null
        },
        "decimal": null
      },
      "v": "Kondisi Lingkungan",
      "w": "Kondisi Lingkungan",
      "t": "s",
      "r": "<t>Kondisi Lingkungan</t>",
      "h": "Kondisi Lingkungan"
    },
    {
      "p": "E40",
      "v": "Baik"
    },
    {
      "p": "C42",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": null,
        "border": {
          "top": null,
          "right": null,
          "bottom": null,
          "left": null
        },
        "decimal": null
      },
      "v": "Jarak ke properti subyek",
      "w": "Jarak ke properti subyek",
      "t": "s",
      "r": "<t>Jarak ke properti subyek</t>",
      "h": "Jarak ke properti subyek"
    },
    {
      "p": "E42",
      "v": 0
    },
    {
      "p": "F42",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": null,
        "border": {
          "top": null,
          "right": null,
          "bottom": null,
          "left": null
        },
        "decimal": null
      },
      "v": "m",
      "w": "m",
      "t": "s",
      "r": "<t>m</t>",
      "h": "m"
    },
    {
      "p": "C44",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": null,
        "border": {
          "top": null,
          "right": null,
          "bottom": null,
          "left": null
        },
        "decimal": null
      },
      "v": "Jarak terhadap Pusat Keramaian",
      "w": "Jarak terhadap Pusat Keramaian",
      "t": "s",
      "r": "<t>Jarak terhadap Pusat Keramaian</t>",
      "h": "Jarak terhadap Pusat Keramaian"
    },
    {
      "p": "E44",
      "v": 0
    },
    {
      "p": "F44",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": null,
        "border": {
          "top": null,
          "right": null,
          "bottom": null,
          "left": null
        },
        "decimal": null
      },
      "v": "m",
      "w": "m",
      "t": "s",
      "r": "<t>m</t>",
      "h": "m"
    },
    {
      "p": "C46",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": null,
        "border": {
          "top": null,
          "right": null,
          "bottom": null,
          "left": null
        },
        "decimal": null
      },
      "v": "Peraturan Zonasi",
      "w": "Peraturan Zonasi",
      "t": "s",
      "r": "<t>Peraturan Zonasi</t>",
      "h": "Peraturan Zonasi"
    },
    {
      "p": "E46",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": null,
        "border": {
          "top": null,
          "right": null,
          "bottom": null,
          "left": null
        },
        "decimal": null
      },
      "v": "KDB",
      "w": "KDB",
      "t": "s",
      "r": "<t>KDB</t>",
      "h": "KDB"
    },
    {
      "p": "G46",
      "v": 60
    },
    {
      "p": "K46",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": null,
        "border": {
          "top": null,
          "right": null,
          "bottom": null,
          "left": null
        },
        "decimal": null
      },
      "v": "KTB",
      "w": "KTB",
      "t": "s",
      "r": "<t>KTB</t>",
      "h": "KTB"
    },
    {
      "p": "M46",
      "v": 19
    },
    {
      "p": "E48",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": null,
        "border": {
          "top": null,
          "right": null,
          "bottom": null,
          "left": null
        },
        "decimal": null
      },
      "v": "KLB",
      "w": "KLB",
      "t": "s",
      "r": "<t>KLB</t>",
      "h": "KLB"
    },
    {
      "p": "G48",
      "v": 5
    },
    {
      "p": "K48",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": null,
        "border": {
          "top": null,
          "right": null,
          "bottom": null,
          "left": null
        },
        "decimal": null
      },
      "v": "GSB",
      "w": "GSB",
      "t": "s",
      "r": "<t>GSB</t>",
      "h": "GSB"
    },
    {
      "p": "M48",
      "v": 8
    },
    {
      "p": "C51",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": null,
        "border": {
          "top": null,
          "right": null,
          "bottom": null,
          "left": null
        },
        "decimal": null
      },
      "v": "Zonasi",
      "w": "Zonasi",
      "t": "s",
      "r": "<t>Zonasi</t>",
      "h": "Zonasi"
    },
    {
      "p": "E51",
      "v": "Pertokoan"
    },
    {
      "p": "C53",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": null,
        "border": {
          "top": null,
          "right": null,
          "bottom": null,
          "left": null
        },
        "decimal": null
      },
      "v": "Karakteristik ekonomi",
      "w": "Karakteristik ekonomi",
      "t": "s",
      "r": "<t>Karakteristik ekonomi</t>",
      "h": "Karakteristik ekonomi"
    },
    {
      "p": "C54",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": null,
        "border": {
          "top": null,
          "right": null,
          "bottom": null,
          "left": null
        },
        "decimal": null
      },
      "v": "(untuk properti komersial)",
      "w": "(untuk properti komersial)",
      "t": "s",
      "r": "<t>(untuk properti komersial)</t>",
      "h": "(untuk properti komersial)"
    },
    {
      "p": "C56",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": null,
        "border": {
          "top": null,
          "right": null,
          "bottom": null,
          "left": null
        },
        "decimal": null
      },
      "v": "Komponen non-realty",
      "w": "Komponen non-realty",
      "t": "s",
      "r": "<t>Komponen non-realty</t>",
      "h": "Komponen non-realty"
    },
    {
      "p": "C58",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": null,
        "border": {
          "top": null,
          "right": null,
          "bottom": null,
          "left": null
        },
        "decimal": null
      },
      "v": "Harga Penawaran/ Transaksi",
      "w": "Harga Penawaran/ Transaksi",
      "t": "s",
      "r": "<t>Harga Penawaran/ Transaksi</t>",
      "h": "Harga Penawaran/ Transaksi"
    },
    {
      "p": "C60",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": null,
        "border": {
          "top": null,
          "right": null,
          "bottom": null,
          "left": null
        },
        "decimal": null
      },
      "v": "Syarat Pembayaran",
      "w": "Syarat Pembayaran",
      "t": "s",
      "r": "<t>Syarat Pembayaran</t>",
      "h": "Syarat Pembayaran"
    },
    {
      "p": "C61",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": null,
        "border": {
          "top": null,
          "right": null,
          "bottom": null,
          "left": null
        },
        "decimal": null
      },
      "v": "Pengeluaran yang tertunda",
      "w": "Pengeluaran yang tertunda",
      "t": "s",
      "r": "<t>Pengeluaran yang tertunda</t>",
      "h": "Pengeluaran yang tertunda"
    },
    {
      "p": "C63",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": null,
        "border": {
          "top": null,
          "right": null,
          "bottom": null,
          "left": null
        },
        "decimal": null
      },
      "v": "Kondisi Penawaran/ Transaksi",
      "w": "Kondisi Penawaran/ Transaksi",
      "t": "s",
      "r": "<t>Kondisi Penawaran/ Transaksi</t>",
      "h": "Kondisi Penawaran/ Transaksi"
    },
    {
      "p": "C65",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": null,
        "border": {
          "top": null,
          "right": null,
          "bottom": null,
          "left": null
        },
        "decimal": null
      },
      "v": "Kondisi Pasar (Waktu Penjualan)",
      "w": "Kondisi Pasar (Waktu Penjualan)",
      "t": "s",
      "r": "<t>Kondisi Pasar (Waktu Penjualan)</t>",
      "h": "Kondisi Pasar (Waktu Penjualan)"
    },
    {
      "p": "C67",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": null,
        "border": {
          "top": null,
          "right": null,
          "bottom": null,
          "left": null
        },
        "decimal": null
      },
      "v": "Sumber Data",
      "w": "Sumber Data",
      "t": "s",
      "r": "<t>Sumber Data</t>",
      "h": "Sumber Data"
    },
    {
      "p": "C69",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": null,
        "border": {
          "top": null,
          "right": null,
          "bottom": null,
          "left": null
        },
        "decimal": null
      },
      "v": "Telepon",
      "w": "Telepon",
      "t": "s",
      "r": "<t>Telepon</t>",
      "h": "Telepon"
    },
    {
      "p": "C71",
      "d": {
        "fontColor": "#000",
        "fontSize": 10,
        "fontName": "Calibri",
        "bold": false,
        "italic": false,
        "underline": false,
        "fillColor": null,
        "border": {
          "top": null,
          "right": null,
          "bottom": null,
          "left": null
        },
        "decimal": null
      },
      "v": "Catatan :",
      "w": "Catatan :",
      "t": "s",
      "r": "<t>Catatan :</t>",
      "h": "Catatan :"
    },






















  ],
  "__v": 24
}