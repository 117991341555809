/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*COMPONENT*/

/*REDUX*/
import { delete_folder } from "../../App/actions/biActions";

/*PICTURE*/

/*FUNCTION*/

/*DATA*/
import dict from "../../Data/dict.json";

/*CONST*/

class BI_DELETE_FOLDER extends Component {
  state = {};

  on_delete_folder = () => {
    const { bi_object, folder_object_selected } = this.props.bi;
    const query = {
      link: bi_object?.link,
      _id: bi_object?._id,
      folder_id: folder_object_selected?._id,
    };
    this.props.delete_folder(query);
    this.props.toggle_delete_folder();
  };

  render() {
    //local storage
    const language = localStorage?.language || "ina";

    //local state

    //global props
    const { folder_object_selected } = this.props.bi;

    //content
    return (
      <main>
        <p className="text_medium margin_bottom">
          {
            dict?.[
              "Remove this folder from BI dashboard? The original folder will not be deleted."
            ]?.[language]
          }
        </p>
        <h1 className="text_bold margin_top margin_bottom">
          {folder_object_selected?.name}
        </h1>
        <br />
        <br />
        <button
          className="button background_red"
          onClick={this.on_delete_folder}
        >
          {dict?.["Delete"]?.[language]}
        </button>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  bi: state.bi,
});

export default connect(mapStateToProps, { delete_folder })(BI_DELETE_FOLDER);
