/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*COMPONENT*/
import BI_ADD_STEP_0_TITLE from "./BI_ADD_STEP_0_TITLE.jsx";
import BI_ADD_STEP_1_LAYER_CARD from "./BI_ADD_STEP_1_LAYER_CARD";
import BI_ADD_STEP_2_FIELD_KEY_VALUE from "./BI_ADD_STEP_2_FIELD_KEY_VALUE";
import BI_ADD_STEP_3_FIELD_KEY_SELECTION from "./BI_ADD_STEP_3_FIELD_KEY_SELECTION";
import BI_ADD_STEP_4_FEATURE_KEY from "./BI_ADD_STEP_4_FEATURE_KEY";
import BI_ADD_STEP_5_DEFAULT_VALUE from "./BI_ADD_STEP_5_DEFAULT_VALUE";
import BI_ADD_STEP_6_LOGIC from "./BI_ADD_STEP_6_LOGIC.jsx";

/*REDUX*/
import {
  edit_inside_object,
  get_list_layer_bi,
  set_value_bi,
} from "../../App/actions/biActions";

/*PICTURE*/

/*FUNCTION*/

/*DATA*/
import dict from "../../Data/dict.json";

/*CONST*/

class BI_ADD_CARD extends Component {
  state = {
    modal_edit: false,
  };

  componentDidMount() {
    const { project_object_selected, bi_object } = this.props.bi;
    const card_list = bi_object?.card_list || [];
    const setting_add_object =
      card_list.find(
        (item) => item.project_id === project_object_selected?._id
      ) || {};
    if (setting_add_object?._id) {
      this.props.set_value_bi({
        key: "setting_add_object",
        value: setting_add_object,
      });
    }
    const layer_list = project_object_selected?.layer_list || [];
    if (layer_list.length === 0) {
      const query = {
        _id: project_object_selected._id,
        link: project_object_selected.link,
      };
      this.props.get_list_layer_bi(query);
    }
  }

  on_set_menu = (idx) => {
    const { card_add_object } = this.props.bi;
    const { _id } = card_add_object;
    if (_id) {
      this.props.set_value_bi({
        key: "add_card_current_step",
        value: idx,
      });
    }
  };

  toggle_edit = (add_card_current_step) => {
    this.setState({
      modal_edit: !this.state.modal_edit,
    });
    this.props.set_value_bi({
      key: "add_card_current_step",
      value: add_card_current_step,
    });
  };

  render() {
    //local storage
    const language = localStorage?.language || "ina";

    //local state

    //global props
    const { card_add_object, add_card_current_step, project_object_selected } =
      this.props.bi;

    //content
    const {
      name,
      layer_id,
      field_key_value,
      field_key_selection,
      feature_key_list,
    } = card_add_object;
    const layer_list = project_object_selected?.layer_list || [];
    const layer = layer_list.find((item) => item?.geo_layer?._id === layer_id);
    const geo_layer = layer?.geo_layer || {};
    const features = geo_layer?.geojson?.features || [];
    const features_filtered = feature_key_list.map((feature_key) => {
      const feature = features.find((item) => item.key === feature_key);
      return feature;
    });

    const step_array = [
      {
        name: dict?.["Title"]?.[language],
        value: "name",
      },
      {
        name: dict?.["Choose layer"]?.[language],
        value: "layer_id",
      },
      {
        name: dict?.["Select the value field"]?.[language],
        value: "field_key_value",
      },
      {
        name: dict?.["Select the description field"]?.[language],
        value: "field_key_selection",
      },
      {
        name: dict?.["Select row"]?.[language],
        value: "feature_key",
      },
      {
        name: dict?.["Enter default value"]?.[language],
        value: "default_value",
      },
      {
        name: "Syarat kelayakan",
        value: "conditional_statement_list",
      },
    ];

    const content =
      add_card_current_step === 0 ? (
        <BI_ADD_STEP_0_TITLE />
      ) : add_card_current_step === 1 ? (
        <BI_ADD_STEP_1_LAYER_CARD />
      ) : add_card_current_step === 2 ? (
        <BI_ADD_STEP_2_FIELD_KEY_VALUE />
      ) : add_card_current_step === 3 ? (
        <BI_ADD_STEP_3_FIELD_KEY_SELECTION />
      ) : add_card_current_step === 4 ? (
        <BI_ADD_STEP_4_FEATURE_KEY />
      ) : add_card_current_step === 5 ? (
        <BI_ADD_STEP_5_DEFAULT_VALUE />
      ) : (
        <BI_ADD_STEP_6_LOGIC />
      );

    let value_content;

    if (features_filtered.length === 0) {
      value_content = (
        <section>
          <div className="margin_bottom text_right">
            <p className="text_small">2021 (sample)</p>
            <p className="text_bold">23497232 (sample)</p>
          </div>
          <div className="margin_bottom text_right">
            <p className="text_small">2022 (sample)</p>
            <p className="text_bold">44497232 (sample)</p>
          </div>
        </section>
      );
    } else {
      value_content = features_filtered.map((feature, idx) => {
        let value = feature?.properties?.[field_key_value];
        const selection = feature?.properties?.[field_key_selection];
        if (!isNaN(value)) {
          value = new Intl.NumberFormat("id-ID", { style: "decimal" }).format(
            value
          );
        }
        return (
          <section key={idx} className="margin_bottom text_right">
            <p className="text_small">{selection}</p>
            <p className="text_bold">{value}</p>
          </section>
        );
      });
    }

    return (
      <main className="max_height">
        <table>
          <tbody>
            <tr>
              {step_array.map((item, idx) => {
                return (
                  <td
                    key={idx}
                    className="text_center"
                    onClick={this.on_set_menu.bind(this, idx)}
                  >
                    <div
                      className={`badge_pill outline_black ${
                        add_card_current_step === idx
                          ? "background_black"
                          : "background_white"
                      }`}
                    >
                      <button className="badge_circle background_green margin_right inline">
                        {idx + 1}
                      </button>
                      <p className="margin_right inline">{item?.name}</p>
                    </div>
                  </td>
                );
              })}
            </tr>
          </tbody>
        </table>
        <h1 className="text_header margin_bottom">
          {dict?.["Create a new card"]?.[language]}
        </h1>
        <br />
        <p className="badge_pill background_orange margin_bottom">
          {dict?.["Preview"]?.[language]}
        </p>
        <br />
        <div className="bi_card_item margin_right margin_bottom">
          <p className="bi_card_title text_left text_medium max_two_lines">
            {name || "Sample info name"}
          </p>
          <section className="bi_card_value">{value_content}</section>
        </div>
        <br />
        {content}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({ auth: state.auth, bi: state.bi });

export default connect(mapStateToProps, {
  edit_inside_object,
  get_list_layer_bi,
  set_value_bi,
})(BI_ADD_CARD);
