/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*COMPONENT*/
import BI_PROJECT_LIST from "./BI_PROJECT_LIST";

/*REDUX*/

/*PICTURE*/
import ICON_EXPAND from "../../Assets/jsx/ICON_EXPAND";
import ICON_COLLAPSE from "../../Assets/jsx/ICON_COLLAPSE";

/*FUNCTION*/

/*DATA*/

/*CONST*/

class BI_FOLDER_CHILD extends Component {
  state = {};

  render() {
    //local storage

    //local state

    //global props
    const folder_list_child = this.props.folder_list_child;
    const folder_list_all = this.props.folder_list_all;
    const folder_id_open_list = this.props.folder_id_open_list;

    //content

    return (
      <main>
        {folder_list_child.map((item, idx) => {
          const open_status = folder_id_open_list.includes(item?._id);
          const project_id_list = item?.project_id_list || [];
          const folder_list_nested = folder_list_all.filter(
            (child) => child?.folder_parent_id === item?._id
          );
          return (
            <div key={idx}>
              <div
                className="two_container_button_right_small cursor_pointer background_white margin_bottom"
                style={{ borderRadius: "5px", padding: "5px" }}
                onClick={this.props.on_push_folder_id_open_list.bind(
                  this,
                  item?._id
                )}
              >
                <p className="text_bold max_one_lines">{item?.name}</p>
                {!open_status ? (
                  <ICON_EXPAND width={15} height={15} />
                ) : (
                  <ICON_COLLAPSE width={15} height={15} />
                )}
              </div>
              {open_status && (
                <>
                  <BI_PROJECT_LIST
                    project_id_list={project_id_list}
                    folder_list_child={folder_list_nested}
                  />
                  {folder_list_child?.length > 0 && (
                    <BI_FOLDER_CHILD
                      folder_parent_id={item?._id}
                      folder_list_all={folder_list_all}
                      folder_list_child={folder_list_nested}
                      folder_id_open_list={folder_id_open_list}
                      on_push_folder_id_open_list={
                        this.props.on_push_folder_id_open_list
                      }
                    />
                  )}
                </>
              )}
            </div>
          );
        })}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(BI_FOLDER_CHILD);
