/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*COMPONENT*/

/*REDUX*/
import { reorder_group, edit_inside_object } from "../../App/actions/biActions";

/*PICTURE*/

/*FUNCTION*/

/*DATA*/
import dict from "../../Data/dict.json";

/*CONST*/

class BI_REORDER_GROUP extends Component {
  state = {};

  move_up = (idx) => {
    const { setting_add_object } = this.props.bi;
    let group_card_order = setting_add_object?.group_card_order || [];
    if (idx !== 0) {
      const value = group_card_order[idx];
      const value_before = group_card_order[idx - 1];
      group_card_order[idx - 1] = value;
      group_card_order[idx] = value_before;
    } else {
      const value = group_card_order[idx];
      const value_before = group_card_order[group_card_order.length - 1];
      group_card_order[group_card_order.length - 1] = value;
      group_card_order[idx] = value_before;
    }
    this.props.edit_inside_object({
      key: "group_card_add_object",
      id_key: "group_card_order",
      value: group_card_order,
    });
  };

  move_down = (idx) => {
    const { setting_add_object } = this.props.bi;
    let group_card_order = setting_add_object?.group_card_order || [];
    if (idx !== group_card_order.length - 1) {
      const value = group_card_order[idx];
      const value_after = group_card_order[idx + 1];
      group_card_order[idx + 1] = value;
      group_card_order[idx] = value_after;
    } else {
      const value = group_card_order[idx];
      const value_after = group_card_order[0];
      group_card_order[0] = value;
      group_card_order[idx] = value_after;
    }
    this.props.edit_inside_object({
      key: "group_card_add_object",
      id_key: "group_card_order",
      value: group_card_order,
    });
  };

  on_save = () => {
    const { bi_object, project_object_selected, setting_add_object } =
      this.props.bi;
    const group_card_order = setting_add_object?.group_card_order || [];
    const body = {
      _id: bi_object._id,
      project_id: project_object_selected?._id,
      group_card_order: group_card_order,
    };
    this.props.reorder_group(body);
    this.props.toggle_reorder();
  };

  render() {
    //local storage
    const language = localStorage?.language || "ina";

    //local state

    //global props
    const { setting_add_object } = this.props.bi;

    //content
    const group_card_order = setting_add_object?.group_card_order || [];
    let group_card_list = setting_add_object?.group_card_list || [];
    if (group_card_list.length > 0) {
      group_card_list = group_card_order.map((group_id) => {
        return group_card_list.find((element) => element?._id === group_id);
      });
    }

    return (
      <main>
        <div style={{ maxHeight: "calc(85vh - 400px)", overflowY: "auto" }}>
          <table className="table margin_bottom">
            <tbody>
              {group_card_list.map((item, idx) => {
                return (
                  <tr key={idx}>
                    <td style={{ verticalAlign: "middle" }}>
                      <button
                        className="button_icon background_black margin_right"
                        onClick={this.move_up.bind(this, idx)}
                      >
                        ↑
                      </button>
                      <button
                        className="button_icon background_black"
                        onClick={this.move_down.bind(this, idx)}
                      >
                        ↓
                      </button>
                    </td>

                    <td style={{ verticalAlign: "middle" }}>
                      <span className="button_small background_orange">
                        {item.name}
                      </span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <button className="button background_blue" onClick={this.on_save}>
          {dict?.["Save"]?.[language]}
        </button>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  bi: state.bi,
});

export default connect(mapStateToProps, { reorder_group, edit_inside_object })(
  BI_REORDER_GROUP
);
