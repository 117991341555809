/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/
import { deleteProject } from "../../App/actions/projectActions";

/*PICTURE ASSET*/

/*GENERAL FUNCTION & DATA*/
import dict from "../../Data/dict.json";

/*NON IMPORT*/

class DeleteProject extends Component {
  state = {
    geo_project: {},
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.project.geo_project._id !== prevState.geo_project._id) {
      return {
        geo_project: nextProps.project.geo_project,
      };
    } else return null;
  }

  handleSubmit = () => {
    const { folder_id_selected, geo_project_pinned_list } = this.props.project;
    const { geo_project } = this.state;

    const is_project_inside_pinned = geo_project_pinned_list?.some(
      (pinned_project) => pinned_project?._id === geo_project?._id
    );
    console.log("folder_id_selected outside", folder_id_selected);
    console.log("folder_id_selected", folder_id_selected);

    let key;
    if (is_project_inside_pinned && !!folder_id_selected) {
      key = "geo_project_list_child&geo_project_pinned_list";
    } else if (is_project_inside_pinned) {
      key = "geo_project_pinned_list";
    } else if (!!folder_id_selected) {
      key = "geo_project_list_child";
    } else {
      key = "geo_project_list";
    }

    const body = {
      geo_project_id: geo_project?._id,
      key,
    };
    this.props.deleteProject(body);
    this.props.toggleDelete();
  };

  render() {
    //local storage
    const language = localStorage?.language || "ina";

    //local state
    const { geo_project } = this.state;

    //global props
    const { loadingProcess } = this.props.project;

    //content
    let itemContent;
    let buttonContent;
    if (loadingProcess) {
      buttonContent = (
        <button
          className="button background_blue"
          id="deleteBright"
          style={{ marginTop: "1rem" }}
        >
          {dict["Deleting"][language]}...
        </button>
      );
    } else {
      buttonContent = (
        <button
          className="button background_blue"
          id="deleteBright"
          style={{ marginTop: "1rem" }}
          onClick={this.handleSubmit}
          data-mapid="clickDeleteModal"
        >
          {dict["Delete"][language]}
        </button>
      );
    }
    itemContent = (
      <div style={{ textAlign: "center" }}>
        <div className="header">
          {dict["Are you sure you will delete this project?"][language]}
          <br />
        </div>
        <p>
          {
            dict[
              "This Project will be kept in the Recovery place for 30 days before being permanently deleted automatically"
            ][language]
          }
        </p>

        <div className="header">{geo_project.name}</div>
        {buttonContent}
      </div>
    );
    return <div>{itemContent}</div>;
  }
}

const mapStateToProps = (state) => ({
  project: state.project,
  auth: state.auth,
});

export default connect(mapStateToProps, { deleteProject })(DeleteProject);
