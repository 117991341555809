/*LIBRARY*/
import React, { Component, createRef } from "react";
import { connect } from "react-redux";

/*COMPONENT*/
import TEXT_AREA_CHAT from "./TEXT_AREA_CHAT";
import MARKDOWN_RENDERER from "./MARKDOWN_RENDERER";
import ProgressBar from "../common_spinner/ProgressBar";

/*REDUX*/
import {
  set_value_sini,
  get_ai_child_list,
  get_ai_parent_object,
  get_ai_parent_status,
} from "../../App/actions/sini_v2";
import { post_ai_chat } from "../../App/actions/post_ai_chat";

/*PICTURE*/
import expand from "../../Assets/svg/expand.svg";
import ICON_BACK from "../../Assets/jsx/ICON_BACK";

/*FUNCTION*/

/*DATA*/
import dict from "../../Data/dict.json";

/*CONST*/

class SINI_CHAT_FULL extends Component {
  state = {
    content_render: null,
  };

  // Create a ref for the scroll container
  scrollContainerRef = createRef();

  componentDidMount() {
    const { request_id_active, ai_parent_child_list, ai_parent_detail_list } =
      this.props.sini;
    const params = {
      request_id: request_id_active,
    };
    this.props.get_ai_parent_status(params);
    const ai_child_list =
      ai_parent_child_list.find(
        (item) => item?.request_id === request_id_active
      )?.ai_child_list || [];
    const sini_db_object_test = ai_parent_detail_list.find(
      (item) => item.request_id === request_id_active
    )?.request_id;
    if (ai_child_list.length === 0) {
      this.props.get_ai_child_list(params);
    }
    if (!sini_db_object_test) {
      this.props.get_ai_parent_object(params);
    }
  }

  toggle_ai_page = () => {
    this.props.set_value_sini({
      key: "modal_ai_page",
      value: !this.props.sini.modal_ai_page,
    });
    this.props.set_value_sini({
      key: "sini_menu_active",
      value: "",
    });
  };

  on_set_sini_menu_active = () => {
    this.props.set_value_sini({
      key: "sini_menu_active",
      value: "",
    });
    this.props.set_value_sini({
      key: "sini_map_show",
      value: ["poi", "isochrone"],
    });
  };

  on_scroll_down = () => {
    if (this.scrollContainerRef.current) {
      this.scrollContainerRef.current.scrollTop =
        this.scrollContainerRef.current.scrollHeight;
    }
  };

  on_post_ai_chat = () => {
    const {
      sini_v2_list,
      request_id_active,
      user_message,
      ai_parent_detail_list,
    } = this.props.sini;
    const merge_array = [...sini_v2_list, ...ai_parent_detail_list];
    const sini_object = merge_array.find(
      (item) => item.request_id === request_id_active
    );
    const body = {
      request_id: request_id_active,
      user_message,
      sini_object,
    };
    this.props.post_ai_chat(body);
    this.props.set_value_sini({
      key: "user_message",
      value: "",
    });
    this.props.set_value_sini({
      key: "rows_text_area",
      value: 1,
    });
    this.on_scroll_down();
  };

  render() {
    //local storage
    const language = localStorage?.language || "ina";

    //local state

    //global props
    const {
      request_id_active,
      user_message,
      ai_parent_child_list,
      ai_parent_detail_list,
    } = this.props.sini;
    const { loading_item_array } = this.props.loading_reducer;

    //content
    const quota_ai_chat = this.props.auth.quota_ai_chat || 0;
    const ai_child_list =
      ai_parent_child_list.find(
        (item) => item?.request_id === request_id_active
      )?.ai_child_list || [];
    const ai_parent = ai_parent_detail_list.find(
      (item) => item?.request_id === request_id_active
    );
    const { is_beta_test, max_dollar_sini_ai, total_bill_dollar } = ai_parent;

    return (
      <main className="sini_full_parent">
        <section className="sini_full_header">
          <table className="full_width">
            <tbody>
              <tr>
                <td
                  style={{ width: "30px" }}
                  onClick={this.on_set_sini_menu_active}
                >
                  <ICON_BACK />
                </td>
                <td className="text_left">
                  <p className="badge background_green margin_bottom">
                    SINI AI
                  </p>
                </td>
                <td className="text_right">
                  <img
                    className="cursor_pointer"
                    alt="expand"
                    src={expand}
                    style={{ height: "20px" }}
                    onClick={this.toggle_ai_page}
                    data-mapid="clickExpand"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </section>

        <section
          className="sini_full_scroll_area"
          ref={this.scrollContainerRef}
        >
          {!ai_child_list.length && (
            <section className="container_light outline_transparent margin_bottom background_dark_blue">
              Mulai <i>brainstorm</i> dengan SINI AI tentang ide Anda dengan
              mengetikkan <i>prompt</i> di bawah
            </section>
          )}
          {ai_child_list.map((item, idx) => {
            let final_content;
            if (item.role === "assistant" && item.content) {
              final_content = (
                <section key={idx} id={`ai_sini_${idx}`}>
                  <div className="container_light outline_transparent margin_bottom background_dark_blue">
                    <MARKDOWN_RENDERER markdownContent={item.content} />
                  </div>
                </section>
              );
            } else {
              final_content = (
                <section key={idx} id={`ai_sini_${idx}`}>
                  <div
                    className="container_light outline_transparent margin_bottom max_width_300 background_grey_light"
                    style={{ right: 0, whiteSpace: "pre-wrap" }}
                  >
                    {item.content}
                  </div>
                </section>
              );
            }

            return final_content;
          })}
          {loading_item_array.includes("post_ai_chat") && (
            <main>
              <div className="sini_container_animation">
                <div className="sini_shape_animation"></div>
                <div className="sini_shape_animation"></div>
                <div className="sini_shape_animation"></div>
              </div>
              <br />
              <div
                className={`container_light outline_transparent margin_bottom background_dark_blue`}
              >
                {dict?.["SINI AI is answering"]?.[language]}...
              </div>
            </main>
          )}
        </section>
        <section className="ai_input_text_area_sidebar">
          <TEXT_AREA_CHAT
            scrollContainerRef={this.scrollContainerRef}
            on_scroll_down={this.on_scroll_down}
          />
          <table>
            <tbody>
              <tr>
                <td>
                  {user_message.length > 0 ? (
                    <button
                      className="button margin_right background_blue"
                      onClick={this.on_post_ai_chat}
                      data-mapid="clickAsking"
                    >
                      {dict?.["Start asking"]?.[language]}
                    </button>
                  ) : (
                    <div className="button background_grey margin_right">
                      {dict?.["Start asking"]?.[language]}
                    </div>
                  )}
                </td>
                <td>
                  {is_beta_test ? (
                    <div className="badge background_grey margin_right">
                      {quota_ai_chat} free chat
                    </div>
                  ) : (
                    <div style={{ width: "100px" }}>
                      <ProgressBar
                        current_number={total_bill_dollar}
                        total_number={max_dollar_sini_ai}
                        is_hide_text={true}
                      />
                    </div>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </section>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  sini: state.sini,
  loading_reducer: state.loading_reducer,
});

export default connect(mapStateToProps, {
  set_value_sini,
  post_ai_chat,
  get_ai_child_list,
  get_ai_parent_object,
  get_ai_parent_status,
})(SINI_CHAT_FULL);
