/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";
import { Map } from "maplibre-gl";
import maplibregl from "maplibre-gl";
import "maplibre-gl/dist/maplibre-gl.css";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";

/*COMPONENT*/

/*REDUX*/
import { set_value_layer } from "../../App/actions/layerActions";
import { set_value_sini } from "../../App/actions/sini_v2";

/*FUNCTION*/

/*DATA*/

/*CONST*/
const basemap_url = process.env.REACT_APP_MAPID_BASEMAP;
const basemap_key = process.env.REACT_APP_BASEMAP_KEY;

class MAP_COMPONENT extends Component {
  componentDidMount() {
    const { set_value_layer } = this.props;
    const map_object = new Map({
      container: "map_object",
      style: `${basemap_url}/styles/street-2d-building/style.json?key=${basemap_key}`,
      center: [118.72762419354456, -0.2055368192132505],
      zoom: 3.1,
      maxPitch: 85,
      projection: "globe",
      attributionControl: false,
    });
    map_object.addControl(
      new maplibregl.AttributionControl({
        compact: true,
      })
    );
    set_value_layer({
      key: "map_object",
      value: map_object,
    });
  }

  componentWillUnmount() {
    const { set_value_layer, set_value_sini } = this.props;
    set_value_layer({
      key: "map_object",
      value: null,
    });
    set_value_sini({
      key: "sini_v2_list",
      value: [],
    });
    set_value_sini({
      key: "ai_parent_detail_list",
      value: [],
    });
    set_value_sini({
      key: "ai_parent_list",
      value: [],
    });
  }

  render() {
    const { height } = this.props.mapid_ai_reducer;

    return (
      <>
        <main
          id="map_object"
          style={{
            width: "100vw",
            height: `calc( 100vh - ${height}px - 35px)`,
            position: "fixed",
            top: 0,
            left: 0,
          }}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  layer: state.layer,
  mapid_ai_reducer: state.mapid_ai_reducer,
});

export default connect(mapStateToProps, { set_value_layer, set_value_sini })(
  MAP_COMPONENT
);
