//Library Modul
import React, { Component } from "react";
import { connect } from "react-redux";
import GeoJsonGeometriesLookup from "geojson-geometries-lookup";

//Personal Component
import Modal from "../common_modal/Modal";

//Redux Function
import {
  push_field_bulk,
  replace_features,
} from "../../App/actions/siniActions";

//Picture Asset

//General Function

class SpatialJoin extends Component {
  state = { modal_done_spatial_join: false, total_features: 0 };

  on_spatial_join = (geo_layer_polygon) => {
    const { geo_layer_list, layer_id } = this.props.layer;
    const geo_layer_point = geo_layer_list.find(
      (item) => item.geo_layer._id === layer_id
    );
    const fields_polygon = geo_layer_polygon?.geo_layer?.fields;

    const body_push_field_bulk = {
      geo_layer_id: geo_layer_point?.geo_layer?._id,
      fields: fields_polygon,
    };
    this.props.push_field_bulk(body_push_field_bulk);

    const geojson_polygon = geo_layer_polygon?.geo_layer?.geojson;
    let features_point = geo_layer_point?.geo_layer?.geojson?.features || [];
    const glookup = new GeoJsonGeometriesLookup(geojson_polygon);
    features_point = features_point.map((item) => {
      const geojson_filtered = glookup.getContainers(item.geometry);
      const properties_new = geojson_filtered?.features?.[0]?.properties || {};
      const properties = { ...item.properties, ...properties_new };
      item.properties = properties;
      return item;
    });

    const body_replace_features = {
      geo_layer_id: geo_layer_point?.geo_layer?._id,
      features: features_point,
    };
    this.props.replace_features(body_replace_features);

    this.setState({
      total_features: features_point.length,
      modal_done_spatial_join: true,
    });
  };

  toggle_done_spatial_join = () => {
    this.setState({
      modal_done_spatial_join: !this.state.modal_done_spatial_join,
    });
  };

  render() {
    //local storage

    //local state
    const { modal_done_spatial_join, total_features } = this.state;

    //global props
    const { geo_layer_list, layer_id } = this.props.layer;

    //content
    const geo_layer = geo_layer_list.find(
      (item) => item.geo_layer._id === layer_id
    );

    const modal_done_spatial_join_content = modal_done_spatial_join && (
      <Modal
        modalSize="small"
        id="modal_done_spatial_join"
        isOpen={modal_done_spatial_join}
        onClose={this.toggle_done_spatial_join}
      >
        <div className="box-body">
          <p className="text_header">
            Penggabungan spasial berhasil dilakukan pada {total_features} baris
            data.
          </p>
        </div>
      </Modal>
    );

    let content;

    if (geo_layer?.geo_layer?.type === "Point") {
      content = (
        <main>
          {geo_layer_list.map((item, idx) => {
            const features_length = item?.geo_layer?.geojson?.features?.length;
            return (
              <main
                key={idx}
                className="container_light margin_bottom"
                id="outline_grey"
              >
                {features_length > 0 ? (
                  <button className="badge_pill inline margin_right" id="grey">
                    Data sudah diambil
                  </button>
                ) : (
                  <button className="badge_pill inline margin_right" id="blue">
                    Data belum diambil
                  </button>
                )}

                <p className="text_bold inline">{item?.geo_layer?.name}</p>

                <br />
                <br />

                {features_length > 0 && item?.geo_layer?.type === "Polygon" ? (
                  <button
                    className="button background_blue"
                    id="blue"
                    onClick={this.on_spatial_join.bind(this, item)}
                  >
                    Spatial join
                  </button>
                ) : (
                  <button className="button background_blue" id="grey">
                    Hanya bisa untuk poligon
                  </button>
                )}
              </main>
            );
          })}
        </main>
      );
    } else {
      content = <main>Sementara hanya bisa untuk layer point</main>;
    }

    return (
      <main className="max_hight">
        {modal_done_spatial_join_content}
        <h1 className="text_header">Spatial Join</h1>
        <p className="text_inferior">
          Pilih data layer yang akan digabungkan secara spasial
        </p>
        <p>Layer titik: {geo_layer?.geo_layer?.name}</p>
        <br />
        {content}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  layer: state.layer,
});

export default connect(mapStateToProps, { push_field_bulk, replace_features })(
  SpatialJoin
);
