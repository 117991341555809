/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*COMPONENT*/
import FOLDER_CHILD from "../folder_project/FOLDER_CHILD";

/*REDUX*/
import { push_folder, set_value_bi } from "../../App/actions/biActions";
import { get_all_folder_project_list_by_user_id } from "../../App/actions/folderProjectAction";
import { set_value_project } from "../../App/actions/projectActions";

/*PICTURE*/

/*FUNCTION*/

/*DATA*/
import dict from "../../Data/dict.json";

/*CONST*/

class ADD_FOLDER_BI extends Component {
  state = {};

  componentDidMount() {
    this.props.get_all_folder_project_list_by_user_id();
  }

  on_click_folder = (folder_id) => {
    const { folder_id_selected } = this.props.project;
    if (folder_id !== folder_id_selected) {
      this.props.set_value_project({
        key: "folder_id_selected_move",
        value: folder_id,
      });
    }
  };

  on_push_folder = () => {
    const { bi_object } = this.props.bi;
    const { folder_id_selected_move, folder_project_list_all } =
      this.props.project;
    const folder_object = folder_project_list_all.find(
      (item) => item?._id === folder_id_selected_move
    );
    const body = {
      link: bi_object?.link,
      _id: bi_object?._id,
      folder_id: folder_id_selected_move,
      folder_object,
    };
    this.props.push_folder(body);
    this.props.toggle_add_folder();
    this.props.set_value_bi({
      key: "modal_add_parent",
      value: !this.props.bi.modal_add_parent,
    });
  };

  render() {
    //local storage
    const language = localStorage?.language || "ina";

    //local state

    //global props
    let { folder_project_list_all, folder_id_selected_move } =
      this.props.project;

    //content
    const folder_parent_list = folder_project_list_all.filter(
      (item) => !item?.folder_parent_id
    );

    return (
      <main>
        <h1 className="text_bold margin_bottom">
          {dict?.["Add folders into the BI dashboard"]?.[language]}
        </h1>
        <section
          className="margin_bottom"
          style={{
            backgroundColor: "#fff",
            borderRadius: "10px",
            padding: "20px",
          }}
        >
          <div
            style={{
              overflowY: "scroll",
              maxHeight: "calc(( 70vh - 200px ))",
              height: "calc(( 70vh - 200px ))",
            }}
          >
            {folder_parent_list.map((item, idx) => {
              const folder_list_child = folder_project_list_all.filter(
                (child) => child?.folder_parent_id === item?._id
              );
              return (
                <div key={idx} className="margin_bottom">
                  <div
                    className={`container_folder background_white background_white cursor_pointer ${
                      item._id === folder_id_selected_move
                        ? "outline_blue"
                        : "outline_grey"
                    }`}
                    onClick={this.on_click_folder.bind(this, item?._id)}
                  >
                    <table className="full_width">
                      <tbody>
                        <tr>
                          <td>
                            <p
                              className="max_one_lines"
                              style={{
                                marginTop: "auto",
                                marginBottom: "auto",
                              }}
                            >
                              {item?.name}
                            </p>
                          </td>
                          {folder_id_selected_move === item?._id && (
                            <td className="text_right">
                              <span className="badge_circle_small background_green" />
                            </td>
                          )}
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  {folder_list_child?.length > 0 && (
                    <FOLDER_CHILD
                      folder_id_selected=""
                      folder_parent_id={item?._id}
                      folder_list_child={folder_list_child}
                      folder_project_list_all={folder_project_list_all}
                      on_click_folder={this.on_click_folder}
                      folder_id_selected_move={folder_id_selected_move}
                    />
                  )}
                </div>
              );
            })}
          </div>
        </section>
        <button
          className="button background_blue"
          onClick={this.on_push_folder}
        >
          {dict?.["Save"]?.[language]}
        </button>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  bi: state.bi,
  project: state.project,
});

export default connect(mapStateToProps, {
  push_folder,
  get_all_folder_project_list_by_user_id,
  set_value_project,
  set_value_bi,
})(ADD_FOLDER_BI);
