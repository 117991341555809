import React, { Component } from "react";
import { Dropdown } from "../../../reuseable";
import Modal from "../../../common_modal/Modal";
import ColumnsSelectionChildTable from "../ColumnsSelectionChildTable";

export default class SetupTableC10Rumah extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal_columns_selection: false,
      modal_columns_child_table_selection: false,
    };
  }

  toggleModalColumnsSelection = () => {
    const { modal_columns_selection } = this.state;
    this.setState({
      modal_columns_selection: !modal_columns_selection,
    });
  };

  toggleModalColumnsChildTableSelection = () => {
    const { modal_columns_child_table_selection } = this.state;
    this.setState({
      modal_columns_child_table_selection: !modal_columns_child_table_selection,
    });
  };

  render() {
    const { fields, placeholder = "Pilih", body } = this.props;

    const { id, ...rest_props } = this.props;

    const { modal_columns_selection, modal_columns_child_table_selection } =
      this.state;

    const setup_child_id = "c10_setup_child_bangunan_rumah_pdf";

    const modal_columns_child_table_selection_content =
      modal_columns_child_table_selection && (
        <Modal
          modalSize="large"
          id="modal"
          isOpen={modal_columns_child_table_selection}
          onClose={this.toggleModalColumnsChildTableSelection}
        >
          <table>
            <tbody>
              <tr>
                <td>
                  <p>Jumlah Ruangan</p>
                </td>
                <td>
                  <ColumnsSelectionChildTable
                    id="c10_jumlah_ruangan"
                    column_parent_uuid={body[setup_child_id]}
                    {...rest_props}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <p>Garasi / Car Port</p>
                </td>
                <td>
                  <ColumnsSelectionChildTable
                    id="c10_garasi_car_port"
                    column_parent_uuid={body[setup_child_id]}
                    {...rest_props}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <p>Ruang Tamu</p>
                </td>
                <td>
                  <ColumnsSelectionChildTable
                    id="c10_ruang_tamu"
                    column_parent_uuid={body[setup_child_id]}
                    {...rest_props}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <p>Ruang Kantor</p>
                </td>
                <td>
                  <ColumnsSelectionChildTable
                    id="c10_ruang_kantor"
                    column_parent_uuid={body[setup_child_id]}
                    {...rest_props}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <p>Kamar Tidur</p>
                </td>
                <td>
                  <ColumnsSelectionChildTable
                    id="c10_kamar_tidur"
                    column_parent_uuid={body[setup_child_id]}
                    {...rest_props}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <p>Kamar Pembantu</p>
                </td>
                <td>
                  <ColumnsSelectionChildTable
                    id="c10_kamar_pembantu"
                    column_parent_uuid={body[setup_child_id]}
                    {...rest_props}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <p>Kamar Mandi</p>
                </td>
                <td>
                  <ColumnsSelectionChildTable
                    id="c10_kamar_mandi"
                    column_parent_uuid={body[setup_child_id]}
                    {...rest_props}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <p>Dapur</p>
                </td>
                <td>
                  <ColumnsSelectionChildTable
                    id="c10_dapur"
                    column_parent_uuid={body[setup_child_id]}
                    {...rest_props}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <p>Gudang</p>
                </td>
                <td>
                  <ColumnsSelectionChildTable
                    id="c10_gudang"
                    column_parent_uuid={body[setup_child_id]}
                    {...rest_props}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <p>Lainnya</p>
                </td>
                <td>
                  <ColumnsSelectionChildTable
                    id="c10_lainnya"
                    column_parent_uuid={body[setup_child_id]}
                    {...rest_props}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <p>Luas per Lantai (m² )</p>
                </td>
                <td>
                  <ColumnsSelectionChildTable
                    id="c10_luas_per_lantai_m2"
                    column_parent_uuid={body[setup_child_id]}
                    {...rest_props}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    Penyesuaian Peraturan (GSB dan Ketinggian Lantai Bangunan)
                  </p>
                </td>
                <td>
                  <ColumnsSelectionChildTable
                    id="c10_penyesuaian_peraturan_gsb_dan_ketinggian_lantai_bangunan_pdf"
                    column_parent_uuid={body[setup_child_id]}
                    {...rest_props}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </Modal>
      );

    const modal_columns_selection_content = modal_columns_selection && (
      <Modal
        modalSize="large"
        id="modal"
        isOpen={modal_columns_selection}
        onClose={this.toggleModalColumnsSelection}
      >
        <table className="table_pdf">
          <thead>
            <tr>
              <td>
                <div className="flex">
                  <ColumnsSelectionChildTable
                    id="c10_setup_child_bangunan_rumah_pdf"
                    column_parent_uuid={body[id]}
                    {...rest_props}
                  />
                  {body?.["c10_setup_child_bangunan_rumah_pdf"] && (
                    <button
                      onClick={() => {
                        this.toggleModalColumnsChildTableSelection();
                      }}
                      className="button background_blue"
                    >
                      Fill the Table
                    </button>
                  )}
                </div>
              </td>
            </tr>
            <tr className="tr_border_bottom_pdf">
              <td>
                <p className="subtitle_pdf">SPESIFIKASI BANGUNAN</p>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Pondasi</td>
              <td>
                <ColumnsSelectionChildTable
                  id="c10_pondasi_pdf"
                  column_parent_uuid={body[id]}
                  {...rest_props}
                />
                {/* <DropdownOrData id="pondasi_pdf" {...this.props} /> */}
              </td>
            </tr>
            <tr>
              <td>Struktur</td>
              <td>
                <ColumnsSelectionChildTable
                  id="c10_struktur_pdf"
                  column_parent_uuid={body[id]}
                  {...rest_props}
                />
                {/* <DropdownOrData id="struktur_pdf" {...this.props} /> */}
              </td>
            </tr>
            <tr>
              <td>Rangka Atap</td>
              <td>
                <ColumnsSelectionChildTable
                  id="c10_rangka_atap_pdf"
                  column_parent_uuid={body[id]}
                  {...rest_props}
                />
                {/* <DropdownOrData id="rangka_atap_pdf" {...this.props} /> */}
              </td>
            </tr>
            <tr>
              <td>Penutup Atap</td>
              <td>
                <ColumnsSelectionChildTable
                  id="c10_penutup_atap_pdf"
                  column_parent_uuid={body[id]}
                  {...rest_props}
                />
                {/* <DropdownOrData id="penutup_atap_pdf" {...this.props} /> */}
              </td>
            </tr>
            <tr>
              <td>Plafon</td>
              <td>
                <ColumnsSelectionChildTable
                  id="c10_plafon_pdf"
                  column_parent_uuid={body[id]}
                  {...rest_props}
                />
                {/* <DropdownOrData id="plafon_pdf" {...this.props} /> */}
              </td>
            </tr>
            <tr>
              <td>Dinding</td>
              <td>
                <ColumnsSelectionChildTable
                  id="c10_dinding_pdf"
                  column_parent_uuid={body[id]}
                  {...rest_props}
                />
                {/* <DropdownOrData id="dinding_pdf" {...this.props} /> */}
              </td>
            </tr>
            <tr>
              <td>Pintu</td>
              <td>
                <ColumnsSelectionChildTable
                  id="c10_pintu_pdf"
                  column_parent_uuid={body[id]}
                  {...rest_props}
                />
                {/* <DropdownOrData id="pintu_pdf" {...this.props} /> */}
              </td>
            </tr>
            <tr>
              <td>Jendela</td>
              <td>
                <ColumnsSelectionChildTable
                  id="c10_jendela_pdf"
                  column_parent_uuid={body[id]}
                  {...rest_props}
                />
                {/* <DropdownOrData id="jendela_pdf" {...this.props} /> */}
              </td>
            </tr>
            <tr>
              <td>Lantai</td>
              <td>
                <ColumnsSelectionChildTable
                  id="c10_lantai_pdf"
                  column_parent_uuid={body[id]}
                  {...rest_props}
                />
                {/* <DropdownOrData id="lantai_pdf" {...this.props} /> */}
              </td>
            </tr>
            <tr>
              <td>Utilitas</td>
              <td>
                <ColumnsSelectionChildTable
                  id="c10_utilitas_pdf"
                  column_parent_uuid={body[id]}
                  {...rest_props}
                />
                {/* <DropdownOrData id="utilitas_pdf" {...this.props} /> */}
              </td>
            </tr>
            <tr>
              <td>KONDISI BANGUNAN</td>
              <td>
                <ColumnsSelectionChildTable
                  id="c10_kondisi_bangunan_pdf"
                  column_parent_uuid={body[id]}
                  {...rest_props}
                />
                {/* <DropdownOrData id="kondisi_bangunan_pdf" {...this.props} /> */}
              </td>
            </tr>
          </tbody>
        </table>
      </Modal>
    );

    return (
      <div>
        <table className="table_pdf side_border">
          <thead>
            <tr className="tr_border_bottom_pdf">
              <td colSpan={12}>
                <div className="center_perfect gap_10">
                  <p>Rumah 1</p>
                  <Dropdown
                    className="w_100"
                    placeholder={placeholder}
                    filter_on
                    is_enable_empty
                    value={body?.[id] || ""}
                    onChange={(value) => {
                      if (this.props.onChange) {
                        this.props.onChange(id, value);
                      }
                    }}
                  >
                    {fields &&
                      fields.map((field) => (
                        <option key={field.key} value={field.key}>
                          {field.name}
                        </option>
                      ))}
                  </Dropdown>
                  {body?.["c10_setup_kumpulan_rumah_pdf"] && (
                    <button
                      onClick={() => {
                        this.toggleModalColumnsSelection();
                      }}
                      className="button background_blue"
                    >
                      Fill the Table
                    </button>
                  )}
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <p>No.</p>
              </td>
              <td>
                <p>Jumlah Ruangan</p>
              </td>
              <td>
                <p>Garasi / Car Port</p>
              </td>
              <td>
                <p>Ruang Tamu</p>
              </td>
              <td>
                <p>Ruang Kantor</p>
              </td>
              <td>
                <p>Kamar Tidur</p>
              </td>
              <td>
                <p>Kamar Pembantu</p>
              </td>
              <td>
                <p>Kamar Mandi</p>
              </td>
              <td>
                <p>Dapur</p>
              </td>
              <td>
                <p>Gudang</p>
              </td>
              <td>
                <p>Lainnya</p>
              </td>
              <td>
                <p>Luas per Lantai (m² )</p>
              </td>
            </tr>
          </thead>
          {/* <tbody id="c10_body_tabel_rumah_pdf"> 
           <tr>
              <td>
                <p>1</p>
              </td>
              <td>
                <p>Lantai 1</p>
              </td>
              <td>
                <p>1</p>
              </td>
              <td>
                <p>1</p>
              </td>
              <td>
                <p>1</p>
              </td>
              <td>
                <p>1</p>
              </td>
              <td>
                <p>1</p>
              </td>
              <td>
                <p>1</p>
              </td>
              <td>
                <p>1</p>
              </td>
              <td>
                <p>1</p>
              </td>
              <td>
                <p>1</p>
              </td>
              <td>
                <p>100,100</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>2</p>
              </td>
              <td>
                <p>Lantai 2</p>
              </td>
              <td>
                <p>1</p>
              </td>
              <td>
                <p>1</p>
              </td>
              <td>
                <p>1</p>
              </td>
              <td>
                <p>1</p>
              </td>
              <td>
                <p>1</p>
              </td>
              <td>
                <p>1</p>
              </td>
              <td>
                <p>1</p>
              </td>
              <td>
                <p>1</p>
              </td>
              <td>
                <p>1</p>
              </td>
              <td>
                <p>100,100</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>1</p>
              </td>
              <td>
                <p>Lantai 3</p>
              </td>
              <td>
                <p>1</p>
              </td>
              <td>
                <p>1</p>
              </td>
              <td>
                <p>1</p>
              </td>
              <td>
                <p>1</p>
              </td>
              <td>
                <p>1</p>
              </td>
              <td>
                <p>1</p>
              </td>
              <td>
                <p>1</p>
              </td>
              <td>
                <p>1</p>
              </td>
              <td>
                <p>1</p>
              </td>
              <td>
                <p>100,100</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>1</p>
              </td>
              <td>
                <p>Lantai 4</p>
              </td>
              <td>
                <p>1</p>
              </td>
              <td>
                <p>1</p>
              </td>
              <td>
                <p>1</p>
              </td>
              <td>
                <p>1</p>
              </td>
              <td>
                <p>1</p>
              </td>
              <td>
                <p>1</p>
              </td>
              <td>
                <p>1</p>
              </td>
              <td>
                <p>1</p>
              </td>
              <td>
                <p>1</p>
              </td>
              <td>
                <p>100,100</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>1</p>
              </td>
              <td>
                <p>Lantai 5</p>
              </td>
              <td>
                <p>1</p>
              </td>
              <td>
                <p>1</p>
              </td>
              <td>
                <p>1</p>
              </td>
              <td>
                <p>1</p>
              </td>
              <td>
                <p>1</p>
              </td>
              <td>
                <p>1</p>
              </td>
              <td>
                <p>1</p>
              </td>
              <td>
                <p>1</p>
              </td>
              <td>
                <p>1</p>
              </td>
              <td>
                <p>100,100</p>
              </td>
            </tr>
            <tr>
              <td colSpan={11}>
                <p>
                  Penyesuaian Peraturan (GSB &amp; Ketinggian Lantai Bangunan)
                </p>
              </td>
              <td>
                <p>
                  <br />
                </p>
              </td>
            </tr>
            <tr>
              <td colSpan={11}>
                <p>TOTAL LUAS BANGUNAN (m²)</p>
              </td>
              <td>
                <p>100</p>
              </td>
            </tr>
          </tbody> */}
        </table>
        {modal_columns_selection_content}
        {modal_columns_child_table_selection_content}
      </div>
    );
  }
}
