import axios from "axios";
import circle from "@turf/circle";

import convex from "@turf/convex";
import buffer from "@turf/buffer";
import polygonToLine from "@turf/polygon-to-line";

import pointsWithinPolygon from "@turf/points-within-polygon";
import calculate_area from "@turf/area";

import difference from "@turf/difference";
import union from "@turf/union";
import generate_geojson_grid from "../../App/validation/point_to_grid";
import polygonSmooth from "@turf/polygon-smooth";
import GeoJsonGeometriesLookup from "geojson-geometries-lookup";

import { geoServerBaseUrl } from "./baseUrl";

//const SERVER_URL = geoServerBaseUrl;
const SERVER_URL = "https://tile-dem.mapid.io";
const SERVER_URL2 = geoServerBaseUrl;
const member_per_group = 1_000;

export const recursiveFloodPolygon = (
  geojson_contour_flooded,
  geojson_polygon_not_flooded,
  geo_radius_5_meter,
  genangan_mdpl,
  feature_key_params_genangan,
  latitude_params_genangan,
  longitude_params_genangan,
  genangan_cm_params_genangan,
  index_max,
  radius_start_propagation,
  overwrite
) => {
  return async (dispatch) => {
    let index_iterasi = 0;
    const concavity = 1.1;
    dispatch({
      type: "set_loading_action",
      payload: "geojson_polygon_convex",
    });

    // Step 1: Dapatkan titik yang tergenang
    const titik_tergenang = pointsWithinPolygon(
      geojson_contour_flooded,
      geo_radius_5_meter
    );

    // Step 2: Dapatkan polygon pertama menggunakan convex
    const polygon_pertama = convex(titik_tergenang);

    // Fungsi rekursif untuk perhitungan buffer
    let feature_polygon_convex;
    const rekursif_polygon = async (polygon) => {
      if (index_iterasi < index_max) {
        index_iterasi++;

        let progress = index_iterasi * (100 / index_max);

        // Update progress menggunakan dispatch
        dispatch({
          type: "set_value_genangan",
          payload: {
            key: "current_progress_genangan",
            value: progress,
          },
        });

        // Step 1: Buat buffer dari polygon
        const polygon_buffer = buffer(polygon, radius_start_propagation, {
          units: "meters",
        });

        // Step 2: Ambil titik yang berada dalam polygon buffer
        const point_flood_new = pointsWithinPolygon(
          geojson_contour_flooded,
          polygon_buffer
        );

        // Step 3: Buat polygon convex dari titik-titik baru
        feature_polygon_convex = convex(point_flood_new, {
          concavity: concavity,
        });

        const geojson_polygon_convex = {
          type: "FeatureCollection",
          features: [feature_polygon_convex],
        };

        const center = [longitude_params_genangan, latitude_params_genangan];
        const radius = 299; // dalam meter
        const options = { steps: 64, units: "meters" };

        //buat polygon donat
        const centercircle = circle(center, radius, options);
        const line = polygonToLine(centercircle);

        const bufferDistance = 1; // dalam meter

        const donutPolygon = buffer(line, bufferDistance, { units: "meters" });

        //cari apakah sudah mencapai titik
        const pointsInDonut = pointsWithinPolygon(
          point_flood_new,
          donutPolygon
        );

        // hentikan looping
        if (pointsInDonut.features.length > 0) {
          // difference
          let feature_polygon_convex_final;
          // feature_polygon_convex_final = feature_polygon_convex;
          if (geojson_polygon_not_flooded === null) {
            feature_polygon_convex_final = feature_polygon_convex;
          } else {
            const geojson_polygon_convex = {
              type: "Feature",
              features: [feature_polygon_convex],
            };
            function convertMultiPolygonToPolygon(multiPolygon) {
              if (
                multiPolygon.type === "MultiPolygon" &&
                multiPolygon.coordinates.length === 1
              ) {
                return {
                  type: "Polygon",
                  coordinates: multiPolygon.coordinates[0],
                };
              }
              return multiPolygon; // Sudah Polygon
            }

            feature_polygon_convex_final = difference([
              geojson_polygon_convex.features[0],
              convertMultiPolygonToPolygon(geojson_polygon_not_flooded),
            ]);
          }

          // Step 4: Finalisasi
          const raw_date = new Date(Date.now());
          const date_run = `${raw_date.getFullYear()}-${String(
            raw_date.getMonth() + 1
          ).padStart(2, "0")}-${String(raw_date.getDate()).padStart(2, "0")}`;
          const time_run = `${String(raw_date.getHours()).padStart(
            2,
            "0"
          )}:${String(raw_date.getMinutes()).padStart(2, "0")}`;
          // const area_meter_square_final = parseFloat(
          //   calculate_area(feature_polygon_convex_final).toFixed(0)
          // );
          // const area_hectare_final = parseFloat(
          //   (area_meter_square_final / 10000).toFixed(4)
          // );
          feature_polygon_convex_final.properties = {
            date_run,
            time_run,
            longitude: longitude_params_genangan,
            latitude: latitude_params_genangan,
            // area_meter_square: area_meter_square_final,
            // area_hectare: area_hectare_final,
            genangan_cm: genangan_cm_params_genangan,
            feature_key: feature_key_params_genangan,
            index_max,
            genangan_absolute_m: genangan_mdpl,
            radius_start: 1.415,
            delta_sample_perimeter_m: radius_start_propagation,
          };
          feature_polygon_convex_final.feature_key =
            feature_key_params_genangan;
          if (overwrite) {
            dispatch({
              type: "find_and_replace_value_genangan",
              payload: {
                key: "features_polygon_convex",
                properties: feature_key_params_genangan,
                value: feature_polygon_convex_final,
              },
            });
            dispatch({
              type: "genangan_update",
            });
            const body = {
              geo_layer_id: "65b810d00ffdc02d99fd495d",
              feature: feature_polygon_convex_final,
            };
            const config = {
              headers: {
                accesstoken: localStorage.token_mapid,
              },
            };

            await axios.delete(
              SERVER_URL2 +
                `/algoritma_cilicis/delete_poligon_genangan?feature_key=${feature_key_params_genangan}`
            );
            await axios.post(
              SERVER_URL2 + "/layers/v2/push_feature",
              body,
              config
            );
          } else {
            dispatch({
              type: "push_value_genangan",
              payload: {
                key: "features_polygon_convex",
                value: feature_polygon_convex_final.features[0],
              },
            });
            dispatch({
              type: "genangan_update",
            });
            // const body = {
            //   geo_layer_id: "65b810d00ffdc02d99fd495d",
            //   feature: feature_polygon_convex_final,
            // };
            // const config = {
            //   headers: {
            //     accesstoken: localStorage.token_mapid,
            //   },
            // };
            // console.log(body);
            // await axios.post(
            //   SERVER_URL2 + "/layers/v2/push_feature",
            //   body,
            //   config
            // );
          }
          dispatch({
            type: "set_value_genangan",
            payload: {
              key: "geojson_polygon_convex",
              value: {
                type: "FeatureCollection",
                features: [],
              },
            },
          });

          dispatch({
            type: "clear_loading_action",
            payload: "geojson_polygon_convex",
          });
        }

        // Delay 1 detik sebelum melakukan rekursi
        setTimeout(() => {
          dispatch({
            type: "set_value_genangan",
            payload: {
              key: "geojson_polygon_convex",
              value: geojson_polygon_convex,
            },
          });
          dispatch({
            type: "genangan_update",
          });

          rekursif_polygon(feature_polygon_convex);
        }, 100); // 100 ms
      } else {
        // difference
        let feature_polygon_convex_final;
        feature_polygon_convex_final = feature_polygon_convex;
        // if (geojson_polygon_not_flooded === null) {
        //   console.log("masuk sini ");
        //   feature_polygon_convex_final = feature_polygon_convex;
        // } else {
        //   console.log("masuk sini2");
        //   const geojson_polygon_convex = {
        //     type: "Feature",
        //     features: [feature_polygon_convex],
        //   };
        //   feature_polygon_convex_final = difference([
        //     geojson_polygon_convex.features[0],
        //     geojson_polygon_not_flooded.features[0],
        //   ]);
        //   console.log(feature_polygon_convex_final);
        // }
        // Step 4: Finalisasi
        const raw_date = new Date(Date.now());
        const date_run = `${raw_date.getFullYear()}-${String(
          raw_date.getMonth() + 1
        ).padStart(2, "0")}-${String(raw_date.getDate()).padStart(2, "0")}`;
        const time_run = `${String(raw_date.getHours()).padStart(
          2,
          "0"
        )}:${String(raw_date.getMinutes()).padStart(2, "0")}`;
        const area_meter_square_final = parseFloat(
          calculate_area(feature_polygon_convex_final).toFixed(0)
        );
        const area_hectare_final = parseFloat(
          (area_meter_square_final / 10000).toFixed(4)
        );
        feature_polygon_convex_final.properties = {
          date_run,
          time_run,
          longitude: longitude_params_genangan,
          latitude: latitude_params_genangan,
          area_meter_square: area_meter_square_final,
          area_hectare: area_hectare_final,
          genangan_cm: genangan_cm_params_genangan,
          feature_key: feature_key_params_genangan,
          index_max,
          genangan_absolute_m: genangan_mdpl,
          radius_start: 1.415,
          delta_sample_perimeter_m: radius_start_propagation,
        };
        feature_polygon_convex_final.feature_key = feature_key_params_genangan;
        if (overwrite) {
          dispatch({
            type: "find_and_replace_value_genangan",
            payload: {
              key: "features_polygon_convex",
              properties: feature_key_params_genangan,
              value: feature_polygon_convex_final,
            },
          });
          dispatch({
            type: "genangan_update",
          });
          const body = {
            geo_layer_id: "65b810d00ffdc02d99fd495d",
            feature: feature_polygon_convex_final,
          };
          const config = {
            headers: {
              accesstoken: localStorage.token_mapid,
            },
          };

          await axios.delete(
            SERVER_URL2 +
              `/algoritma_cilicis/delete_poligon_genangan?feature_key=${feature_key_params_genangan}`
          );
          await axios.post(
            SERVER_URL2 + "/layers/v2/push_feature",
            body,
            config
          );
        } else {
          // dispatch({
          //   type: "push_value_genangan",
          //   payload: {
          //     key: "features_polygon_convex",
          //     value: feature_polygon_convex_final,
          //   },
          // });
          dispatch({
            type: "genangan_update",
          });
          // const body = {
          //   geo_layer_id: "65b810d00ffdc02d99fd495d",
          //   feature: feature_polygon_convex_final,
          // };
          // const config = {
          //   headers: {
          //     accesstoken: localStorage.token_mapid,
          //   },
          // };
          // console.log(body);
          // await axios.post(
          //   SERVER_URL2 + "/layers/v2/push_feature",
          //   body,
          //   config
          // );
        }
        dispatch({
          type: "set_value_genangan",
          payload: {
            key: "geojson_polygon_convex",
            value: {
              type: "FeatureCollection",
              features: [],
            },
          },
        });

        dispatch({
          type: "clear_loading_action",
          payload: "geojson_polygon_convex",
        });
      }
    };

    // Mulai rekursi dengan polygon pertama
    rekursif_polygon(polygon_pertama);
  };
};

export const not_recursiveFloodPolygon = (
  geojson_contour_flooded,
  geojson_polygon_not_flooded,
  geo_radius_5_meter,
  genangan_mdpl,
  feature_key_params_genangan,
  latitude_params_genangan,
  longitude_params_genangan,
  genangan_cm_params_genangan,
  index_max,
  radius_start_propagation,
  overwrite,
  layer_id
) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: "set_loading_action",
        payload: "get_FloodPolygon",
      });

      // step 1 : buat lingkaran dengan turf circle
      const radius2 = 2.7; //meter
      // const options2 = {
      //   steps: 4,
      //   units: "meters",
      //   properties: { foo: "bar" },
      // };
      const circles = geojson_contour_flooded.features.map((feature) => {
        // return circle(feature.geometry.coordinates, radius2, options2);
        return generate_geojson_grid(feature, radius2);
      });

      let feature_polygon_convex = {};
      let features_temp = [];
      const total_union = circles.length;

      let total_steps = Math.ceil(total_union / member_per_group);
      let last_group_size = total_union % member_per_group;
      if (last_group_size === 1 && total_steps > 1) {
        total_steps -= 1;
        last_group_size += member_per_group;
      }

      const array_steps = [];
      for (let i = 0; i < total_steps; i++) {
        const start = i * member_per_group;
        let finish = (i + 1) * member_per_group;
        if (i === total_steps - 1 && last_group_size > 0) {
          finish = start + last_group_size;
        }
        array_steps.push({ start, finish });
      }

      dispatch({
        type: "set_value_genangan",
        payload: {
          key: "total_union",
          value: array_steps.length,
        },
      });

      const delay_milisecond_constant = 0;
      const delay_promise = () =>
        new Promise((res) => setTimeout(res, delay_milisecond_constant));

      const parent_function = () => {
        return array_steps.reduce(
          (last_promise, object, idx) =>
            last_promise.then((result_sum) =>
              child_function(object, idx).then((result_current) => [
                ...result_sum,
                result_current,
              ])
            ),
          Promise.resolve([])
        );
      };

      const child_function = async (item, idx) => {
        return delay_promise().then(() => {
          const core_function = async () => {
            try {
              // Slice the array_original for the current step range
              const sliced_data = circles.slice(item.start, item.finish);
              const geojson_circles = {
                type: "FeatureCollection",
                features: sliced_data,
              };
              //melakukan union untuk semua grid
              const feature_i = union(geojson_circles);
              features_temp.push(feature_i);
              dispatch({
                type: "set_value_genangan",
                payload: {
                  key: "current_union",
                  value: idx + 1,
                },
              });
            } catch (error) {
              console.error(error);
            }
          };
          return core_function();
        });
      };

      parent_function().then(async () => {
        try {
          const geojson_circles = {
            type: "FeatureCollection",
            features: features_temp,
          };
          feature_polygon_convex = union(geojson_circles);

          const geojson_polygon_convex = {
            type: "FeatureCollection",
            features: [feature_polygon_convex],
          };

          const geojson_polygon = geojson_polygon_convex;

          const glookup = new GeoJsonGeometriesLookup(geojson_polygon);

          const geojson_point = {
            type: "Point",
            coordinates: [longitude_params_genangan, latitude_params_genangan],
          };

          const geojson_filtered = glookup.getContainers(geojson_point);
          const geojson_filtered_fix = {
            type: "FeatureCollection",
            features: geojson_filtered.features,
          };
          const smoothed = polygonSmooth(geojson_filtered_fix, {
            iterations: 5,
          });

          dispatch({
            type: "set_value_genangan",
            payload: {
              key: "geojson_polygon_convex",
              value: smoothed,
            },
          });
          dispatch({
            type: "genangan_update",
          });
          dispatch({
            type: "clear_loading_action",
            payload: "get_FloodPolygon",
          });
          let feature_polygon_convex_final;
          feature_polygon_convex_final = smoothed;
          // Step 4: Finalisasi
          const raw_date = new Date(Date.now());
          const date_run = `${raw_date.getFullYear()}-${String(
            raw_date.getMonth() + 1
          ).padStart(2, "0")}-${String(raw_date.getDate()).padStart(2, "0")}`;
          const time_run = `${String(raw_date.getHours()).padStart(
            2,
            "0"
          )}:${String(raw_date.getMinutes()).padStart(2, "0")}`;
          const area_meter_square_final = parseFloat(
            calculate_area(feature_polygon_convex_final).toFixed(0)
          );
          const area_hectare_final = parseFloat(
            (area_meter_square_final / 10000).toFixed(4)
          );

          feature_polygon_convex_final.features[0].properties = {
            date_run,
            time_run,
            longitude: longitude_params_genangan,
            latitude: latitude_params_genangan,
            area_meter_square: area_meter_square_final,
            area_hectare: area_hectare_final,
            genangan_cm: genangan_cm_params_genangan,
            feature_key: feature_key_params_genangan,
            index_max,
            genangan_absolute_m: genangan_mdpl,
            radius_start: 1.415,
            delta_sample_perimeter_m: radius_start_propagation,
          };
          feature_polygon_convex_final.feature_key =
            feature_key_params_genangan;

          if (overwrite) {
            dispatch({
              type: "find_and_replace_value_genangan",
              payload: {
                key: "features_polygon_convex",
                feature_key: feature_key_params_genangan,
                value: feature_polygon_convex_final.features[0],
              },
            });
            dispatch({
              type: "set_value_genangan",
              payload: {
                key: "geojson_polygon_convex",
                value: {
                  type: "FeatureCollection",
                  features: [],
                },
              },
            });
            dispatch({
              type: "genangan_update",
            });
            const body = {
              geo_layer_id: layer_id,
              feature: feature_polygon_convex_final.features[0],
            };
            const config = {
              headers: {
                accesstoken: localStorage.token_mapid,
              },
            };

            await axios.delete(
              SERVER_URL2 +
                `/algoritma_cilicis/delete_poligon_genangan?feature_key=${feature_key_params_genangan}&geo_layer_id=${layer_id}`
            );
            await axios.post(
              SERVER_URL2 + "/layers/v2/push_feature",
              body,
              config
            );
          } else {
            dispatch({
              type: "push_value_genangan",
              payload: {
                key: "features_polygon_convex",
                value: feature_polygon_convex_final.features[0],
              },
            });
            dispatch({
              type: "genangan_update",
            });
            const body = {
              geo_layer_id: layer_id,
              feature: feature_polygon_convex_final.features[0],
            };

            const config = {
              headers: {
                accesstoken: localStorage.token_mapid,
              },
            };

            await axios.post(
              SERVER_URL2 + "/layers/v2/push_feature",
              body,
              config
            );
          }
        } catch (error) {
          console.log("error parent=", error);
        }
      });
    } catch (error) {
      console.log("error general=", error);
    }
  };
};
export const set_value_genangan = ({ key, value }) => {
  return {
    type: "set_value_genangan",
    payload: { key, value },
  };
};
export const find_and_replace_value_genangan = ({ key, properties, value }) => {
  return {
    type: "find_and_replace_value_genangan",
    payload: { key, properties, value },
  };
};
export const push_value_genangan = ({ key, value }) => {
  return {
    type: "push_value_genangan",
    payload: { key, value },
  };
};
export const genangan_update = () => {
  return {
    type: "genangan_update",
  };
};

export const loading_rambatan = (value) => async (dispatch) => {
  dispatch({
    type: "set_loading_action",
    payload: value, //geojson_polygon_convex
  });
};

/*POST METHODE*/

/**
 * @name : get_contour_genangan
 * @endpoint : /v2
 * @body : longitud, latitude, radiusInMeters
 * @query : -
 * @methode : post
 */
export const get_contour_genangan = (body, callback) => async (dispatch) => {
  try {
    const genangan_cmtoNumber = Number(body.genangan_cm);
    if (isNaN(genangan_cmtoNumber)) {
      dispatch({
        type: "push_value_genangan",
        payload: {
          key: "feature_key_failed_genangan",
          value: body.feature_key_params_genangan,
        },
      });

      dispatch({
        type: "genangan_update",
      });
    } else {
      dispatch({
        type: "set_loading_action",
        payload: "get_contour_genangan",
      });
      const lat = body.body.centroid.latitude;
      const long = body.body.centroid.longitude;

      const center = [long, lat];
      const radius = 1.415;
      const options = {
        steps: 15,
        units: "meters",
        properties: { foo: "bar" },
      };
      const feature = circle(center, radius, options);

      const geo_circle = {
        type: "FeatureCollection",
        features: [feature],
      };
      const res = await axios.post(SERVER_URL + "/v2", body.body);

      const center_geojson = pointsWithinPolygon(res.data, geo_circle);

      const ketinggian_center = center_geojson.features[0].properties.VALUE;
      let geojson_contour_flooded;
      let geojson_contour_notflooded;
      let genangan_mdpl;
      let contour_flooded;
      let contour_notflooded;

      genangan_mdpl = body.genangan_cm / 100 + ketinggian_center;
      contour_flooded = res.data.features.filter(
        (feature) => feature.properties.VALUE <= genangan_mdpl
      );

      contour_notflooded = res.data.features.filter(
        (feature) => feature.properties.VALUE > genangan_mdpl
      );
      geojson_contour_flooded = {
        type: "FeatureCollection",
        features: contour_flooded,
      };
      geojson_contour_notflooded = {
        type: "FeatureCollection",
        features: contour_notflooded,
      };

      // console.log(contour_notflooded[0].geometry.coordinates);

      // membuat polygon geojson_contour_notflooded

      // step 1 : buat lingkaran dengan turf circle
      // const radius2 = 2.5;
      // const options2 = {
      //   steps: 4,
      //   units: "meters",
      //   properties: { foo: "bar" },
      // };
      // const circles = contour_notflooded.map((feature) => {
      //   // return circle(feature.geometry.coordinates, radius2, options2);

      //   return generate_geojson_grid(feature, radius2);
      // });

      // const circles = generate_geojson_grid(contour_notflooded, radius2, 4);
      // const geojson_circles = {
      //   type: "FeatureCollection",
      //   features: circles,
      // };
      // let geojson_polygon_not_flooded;
      // if (circles.length > 0) {
      //   geojson_polygon_not_flooded = union(geojson_circles);
      // } else {
      //   geojson_polygon_not_flooded = null;
      // }
      // dispatch({
      //   type: "set_value_genangan",
      //   payload: {
      //     key: "geojson_polygon_not_flooded",
      //     value: geojson_polygon_not_flooded,
      //   },
      // });

      dispatch({
        type: "set_value_genangan",
        payload: {
          key: "feature_key_params_genangan",
          value: body.feature_key_params_genangan,
        },
      });

      dispatch({
        type: "set_value_genangan",
        payload: {
          key: "geojson_contour_all",
          value: res.data,
        },
      });
      dispatch({
        type: "set_value_genangan",
        payload: {
          key: "geojson_contour_notflooded",
          value: geojson_contour_notflooded,
        },
      });
      if (res.data.features.length < 1) {
        dispatch({
          type: "push_value_genangan",
          payload: {
            key: "feature_key_failed_genangan",
            value: body.feature_key_params_genangan,
          },
        });
      }

      dispatch({
        type: "set_value_genangan",
        payload: {
          key: "geojson_contour_flooded",
          value: geojson_contour_flooded,
        },
      });
      // callback(geojson_contour_flooded, geojson_polygon_not_flooded);
      callback(geojson_contour_flooded);
      dispatch({
        type: "set_value_genangan",
        payload: {
          key: "genangan_mdpl",
          value: genangan_mdpl,
        },
      });

      dispatch({
        type: "genangan_update",
      });

      dispatch({
        type: "clear_loading_action",
        payload: "get_contour_genangan",
      });

      dispatch({
        type: "set_value_genangan",
        payload: {
          key: "contourCreated",
          value: true,
        },
      });
    }
  } catch (error) {
    dispatch({
      type: "clear_loading_action",
      payload: "get_contour_genangan",
    });
    dispatch({
      type: "push_value_genangan",
      payload: {
        key: "feature_key_failed_genangan",
        value: body.feature_key_params_genangan,
      },
    });
    if (error.response) {
      console.error("Error Status:", error.response.status);
    } else if (error.request) {
      console.error("No response received:", error.request);
    } else {
      console.error("Axios Error:", error);
    }
  }
};
/**
 * @name : get_DEM_debug
 * @endpoint : /v2
 * @body : longitud, latitude, radiusInMeters
 * @query : -
 * @methode : post
 */
export const get_DEM_debug = (body, callback) => async (dispatch) => {
  try {
    dispatch({
      type: "set_loading_action",
      payload: "get_DEM_debug",
    });

    const res = await axios.post(SERVER_URL + "/v2", body.body);

    const genangan_mdpl = body.genangan_mdpl;

    const contour_flooded = res.data.features.filter(
      (feature) => feature.properties.VALUE <= genangan_mdpl
    );
    const contour_notflooded = res.data.features.filter(
      (feature) => feature.properties.VALUE > genangan_mdpl
    );
    const geojson_contour_flooded = {
      type: "FeatureCollection",
      features: contour_flooded,
    };
    const geojson_contour_notflooded = {
      type: "FeatureCollection",
      features: contour_notflooded,
    };

    //membuat polygon geojson_contour_notflooded

    // step 1 : buat lingkaran dengan turf circle
    const radius2 = 2;
    const options2 = { steps: 4, units: "meters", properties: { foo: "bar" } };
    const circles = contour_notflooded.map((feature) => {
      return circle(feature.geometry.coordinates, radius2, options2);
    });

    const geojson_circles = {
      type: "FeatureCollection",
      features: circles,
    };
    let geojson_polygon_not_flooded;
    if (circles.length > 0) {
      geojson_polygon_not_flooded = union(geojson_circles);
    } else {
      geojson_polygon_not_flooded = null;
      dispatch({
        type: "set_value_genangan",
        payload: {
          key: "geojson_polygon_not_flooded",
          value: geojson_polygon_not_flooded,
        },
      });
    }

    dispatch({
      type: "set_value_genangan",
      payload: {
        key: "feature_key_params_genangan",
        value: body.feature_key_params_genangan,
      },
    });

    dispatch({
      type: "set_value_genangan",
      payload: {
        key: "geojson_contour_all",
        value: res.data,
      },
    });
    dispatch({
      type: "set_value_genangan",
      payload: {
        key: "geojson_contour_notflooded",
        value: geojson_contour_notflooded,
      },
    });
    if (res.data.features.length < 1) {
      dispatch({
        type: "push_value_genangan",
        payload: {
          key: "feature_key_failed_genangan",
          value: body.feature_key_params_genangan,
        },
      });
    }

    dispatch({
      type: "set_value_genangan",
      payload: {
        key: "geojson_contour_flooded",
        value: geojson_contour_flooded,
      },
    });
    callback(geojson_contour_flooded, geojson_polygon_not_flooded);
    dispatch({
      type: "set_value_genangan",
      payload: {
        key: "genangan_mdpl",
        value: genangan_mdpl,
      },
    });

    dispatch({
      type: "genangan_update",
    });

    dispatch({
      type: "clear_loading_action",
      payload: "get_DEM_debug",
    });

    dispatch({
      type: "set_value_genangan",
      payload: {
        key: "contourCreated",
        value: true,
      },
    });
  } catch (error) {
    dispatch({
      type: "clear_loading_action",
      payload: "get_DEM_debug",
    });
    dispatch({
      type: "push_value_genangan",
      payload: {
        key: "feature_key_failed_genangan",
        value: body.feature_key_params_genangan,
      },
    });
    if (error.response) {
      console.error("Error Status:", error.response.status);
    } else if (error.request) {
      console.error("No response received:", error.request);
    } else {
      console.error("Axios Error:", error.message);
    }
  }
};

export const find_polygon_genangan =
  (feature, layer_id, callback) => async (dispatch) => {
    const find_feature = await axios.get(
      SERVER_URL2 +
        `/algoritma_cilicis/get_polygon_result?feature_key=${feature}&geo_layer_id=${layer_id}`
    );

    if (find_feature.data === null) {
      dispatch({
        type: "set_value_genangan",
        payload: {
          key: "contourCreated",
          value: false,
        },
      });

      callback(find_feature.data);
    } else {
      dispatch({
        type: "set_value_genangan",
        payload: {
          key: "contourCreated",
          value: true,
        },
      });

      callback(find_feature.data);
    }
  };
