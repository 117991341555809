import axios from "axios";

// const bun_server = "http://localhost:4004";
const bun_server = "https://geoserver.mapid.io";

export const set_value_mapid_ai = ({ key, value }) => {
  return {
    type: "set_value_mapid_ai",
    payload: { key, value },
  };
};

export const push_value_mapid_ai = ({ key, value }) => {
  return {
    type: "push_value_mapid_ai",
    payload: { key, value },
  };
};

export const push_value_many_mapid_ai = ({ key, value }) => {
  return {
    type: "push_value_many_mapid_ai",
    payload: { key, value },
  };
};

/**
 * @route :
 * @endpoint :
 * @methode : get
 * @query : -
 */
export const function_name = () => async (dispatch) => {
  try {
    dispatch({
      type: "set_loading_action",
      payload: "get_list_tipe_1",
    });
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    const res = await axios.get(
      bun_server + `/sini_v2/insight_hirarki_poi/get_list_tipe_1`,
      config
    );
    const value = res.data;
    dispatch({
      type: "set_value_mapid_ai",
      payload: {
        key: "list_tipe_1",
        value,
      },
    });
    dispatch({
      type: "clear_loading_action",
      payload: "get_list_tipe_1",
    });
  } catch (error) {
    dispatch({
      type: "clear_loading_action",
      payload: "get_list_tipe_1",
    });
  }
};
