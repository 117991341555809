/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*COMPONENT*/

/*REDUX*/
import { set_value_properties } from "../../App/actions/propertiesActions";
import { status_action } from "../../App/actions/mapActions";

/*PICTURE*/

/*FUNCTION*/
import { generate_paint } from "../../Functions/map_style/generate_paint";
import { generate_colors } from "../../Functions/map_style/generate_colors";

/*DATA*/
import dict from "../../Data/dict.json";

/*CONST*/
const color_scheme_array = [
  {
    name: "Random",
    eng: "Random",
    ina: "Acak",
    key: "random",
    color_sample: generate_colors(5, "random"),
  },
  {
    name: "Red to Green",
    eng: "Red to Green",
    ina: "Merah ke Hijau",
    key: "red_to_green",
    color_sample: generate_colors(5, "red_to_green"),
  },
  {
    name: "Green to Red",
    eng: "Green to Red",
    ina: "Hijau ke Merah",
    key: "green_to_red",
    color_sample: generate_colors(5, "green_to_red"),
  },
  {
    name: "Blue to Yellow",
    eng: "Blue to Yellow",
    ina: "Biru ke Kuning",
    key: "blue_to_yellow",
    color_sample: generate_colors(5, "blue_to_yellow"),
  },
  {
    name: "Purple to Orange",
    eng: "Purple to Orange",
    ina: "Ungu ke Jingga",
    key: "purple_to_orange",
    color_sample: generate_colors(5, "purple_to_orange"),
  },
  {
    name: "Grayscale",
    eng: "Grayscale",
    ina: "Skala Abu-Abu",
    key: "grayscale",
    color_sample: generate_colors(5, "grayscale"),
  },
  {
    name: "Red to Blue",
    eng: "Red to Blue",
    ina: "Merah ke Biru",
    key: "red_to_blue",
    color_sample: generate_colors(5, "red_to_blue"),
  },
  {
    name: "Red Dark to Red Bright",
    eng: "Red Dark to Red Bright",
    ina: "Merah Gelap ke Merah Terang",
    key: "black_to_red",
    color_sample: generate_colors(5, "black_to_red"),
  },
  {
    name: "Red Bright to Red Dark",
    eng: "Red Bright to Red Dark",
    ina: "Merah Terang ke Merah Gelap",
    key: "red_to_black",
    color_sample: generate_colors(5, "red_to_black"),
  },
  {
    name: "Rainbow",
    eng: "Rainbow",
    ina: "Pelangi",
    key: "rainbow",
    color_sample: generate_colors(5, "rainbow"),
  },
  {
    name: "Light Red to Dark Red",
    eng: "Light Red to Dark Red",
    ina: "Merah Terang ke Merah Gelap",
    key: "light_red_to_dark_red",
    color_sample: generate_colors(5, "light_red_to_dark_red"),
  },
  {
    name: "Dark Red to Light Red",
    eng: "Dark Red to Light Red",
    ina: "Merah Gelap ke Merah Terang",
    key: "dark_red_to_light_red",
    color_sample: generate_colors(5, "dark_red_to_light_red"),
  },
  {
    name: "Blues",
    eng: "Blues",
    ina: "Biru",
    key: "blues",
    color_sample: generate_colors(5, "blues"),
  },
  {
    name: "Greens",
    eng: "Greens",
    ina: "Hijau",
    key: "greens",
    color_sample: generate_colors(5, "greens"),
  },
  {
    name: "Reds",
    eng: "Reds",
    ina: "Merah",
    key: "reds",
    color_sample: generate_colors(5, "reds"),
  },
  {
    name: "Oranges",
    eng: "Oranges",
    ina: "Jingga",
    key: "oranges",
    color_sample: generate_colors(5, "oranges"),
  },
  {
    name: "RdBu",
    eng: "RdBu",
    ina: "Merah Biru",
    key: "rd_bu",
    color_sample: generate_colors(5, "rd_bu"),
  },
  {
    name: "PuOr",
    eng: "PuOr",
    ina: "Ungu Jingga",
    key: "pu_or",
    color_sample: generate_colors(5, "pu_or"),
  },
  {
    name: "Spectral",
    eng: "Spectral",
    ina: "Spektral",
    key: "spectral",
    color_sample: generate_colors(5, "spectral"),
  },
  {
    name: "Viridis",
    eng: "Viridis",
    ina: "Viridis",
    key: "viridis",
    color_sample: generate_colors(5, "viridis"),
  },
  {
    name: "Plasma",
    eng: "Plasma",
    ina: "Plasma",
    key: "plasma",
    color_sample: generate_colors(5, "plasma"),
  },
  {
    name: "YlGnBu",
    eng: "YlGnBu",
    ina: "Kuning Hijau Biru",
    key: "yl_gn_bu",
    color_sample: generate_colors(5, "yl_gn_bu"),
  },
  {
    name: "MAPID Colors",
    eng: "MAPID Colors",
    ina: "Warna MAPID",
    key: "mapid_color",
    color_sample: generate_colors(5, "mapid_color"),
  },
];

class RANGE_NUMBER_GRADIENT extends Component {
  state = {};

  on_change = (e) => {
    const name = e.target.name;
    let value = e.target.value;
    const type = e.target.type;
    if (type === "number" || type === "range") {
      value = Number(value);
    }
    if (name === "color_logic_state") {
      this.setState({ [name]: value });
    } else {
      const { style_parameter } = this.props.properties;
      style_parameter[name] = value;
      this.props.set_value_properties({
        key: "style_parameter",
        value: style_parameter,
      });
    }
  };

  on_regenerate_paint = () => {
    const { style_parameter, paint_object_edited } = this.props.properties;
    const style_parameter_new = {
      ...style_parameter,
      paint_object_edited,
    };
    const paint_object_new = generate_paint(style_parameter_new);
    this.props.set_value_properties({
      key: "paint_object_edited",
      value: paint_object_new,
    });
    this.props.status_action();
  };

  render_scale = (min, max, step) => {
    const numbers_array = [];
    for (let i = min; i <= max; i += step) {
      let number = i;
      if (!Number.isInteger(i)) {
        number = i.toFixed(1);
      }
      numbers_array.push(number);
    }
    return (
      <div className="scale_range text_small">
        {numbers_array.map((number) => (
          <span key={number} className="scale_range_number">
            {number}
          </span>
        ))}
      </div>
    );
  };

  render() {
    //local storage
    const language = localStorage?.language || "ina";

    //local state

    //global props
    const { style_parameter } = this.props.properties;

    //content
    const { min, max, steps, color_mode } = style_parameter;
    const color_selected = color_scheme_array.find(
      (item) => item.key === color_mode
    );
    return (
      <main>
        <section className="margin_bottom">
          <p className="text_medium">
            {dict?.["Ganti jumlah range"]?.[language]}
          </p>
          <input
            className="margin_bottom"
            type="number"
            name="steps"
            value={steps}
            onChange={this.on_change}
          />
          <p className="text_medium">{dict?.["Change min"]?.[language]}</p>
          <input
            className="margin_bottom"
            type="number"
            name="min"
            value={min}
            onChange={this.on_change}
          />
          <p className="text_medium">{dict?.["Change max"]?.[language]}</p>
          <input
            className="margin_bottom"
            type="number"
            name="max"
            value={max}
            onChange={this.on_change}
          />
          <button
            className="button_small background_blue"
            onClick={this.on_regenerate_paint}
          >
            {dict?.["Parameter replacement process"]?.[language]}
          </button>
        </section>
        <section>
          <div
            className="margin_bottom"
            style={{
              border: "1px solid black",
              height: "20px",
              background: `linear-gradient(to right, ${color_selected?.color_sample.join(
                ", "
              )})`,
            }}
          />
          <br />
          {this.render_scale(min, max, (max - min) / 5)}
        </section>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  layer: state.layer,
  properties: state.properties,
  loading_reducer: state.loading_reducer,
});

export default connect(mapStateToProps, {
  set_value_properties,
  status_action,
})(RANGE_NUMBER_GRADIENT);
