/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*COMPONENT*/

/*REDUX*/
import { set_value_insight } from "../../App/actions/insight_v2";

/*PICTURE*/

/*GENERAL*/

/*DATA*/

/*CONST*/

class INPUT_FORMULA extends Component {
  state = {};

  toggle_open_formula = () => {
    this.props.set_value_insight({
      key: "sidebar_content",
      value: "formula",
    });
    this.props.set_value_insight({
      key: "step_formula",
      value: 0,
    });
  };

  render() {
    //local storage

    //local state

    //global props
    const { features_grid } = this.props.insight_reducer;

    //content
    let button_content;

    if (features_grid.length > 0) {
      button_content = (
        <button
          className="button background_blue"
          onClick={this.toggle_open_formula}
          data-mapid="clickFormula"
        >
          Atur Formula
        </button>
      );
    } else {
      button_content = (
        <button className="button background_grey">Belum generate grid</button>
      );
    }

    return (
      <main className="margin_bottom_extra">
        <section className="container_light outline_transparent background_grey_light">
          <section className="margin_bottom">
            <div className="badge_pill background_white">
              Step 3: Atur Formula
            </div>
          </section>
          {button_content}
        </section>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  insight_reducer: state.insight_reducer,
});

export default connect(mapStateToProps, { set_value_insight })(INPUT_FORMULA);
