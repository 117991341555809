/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*COMPONENT*/
import Modal from "../common_modal/Modal";
import { Dropdown } from "../reuseable";
import EXAMPLE_NOT_USE_Y from "./EXAMPLE_NOT_USE_Y";
import EXAMPLE_USE_Y from "./EXAMPLE_USE_Y";

/*REDUX*/
import {
  push_chart_list,
  delete_chart_list,
  edit_chart_list,
} from "../../App/actions/propertiesActions";
import { getDetailLayerById } from "../../App/actions/layerNewActions";

/*PICTURE*/

/*FUNCTION*/

/*DATA*/
import domain_list from "../../Data/domain_list";

/*CONST*/

class MANAGE_CHART extends Component {
  state = {
    mode_input_chart: "add", //add || edit
    modal_input_chart: false,
    modal_delete_chart: false,
    modal_example_not_use_y: false,
    modal_example_use_y: false,

    chart_id_delete: "",
    chart_name_delete: "",

    ina: "",
    eng: "",
    x_axis_field_key: "",
    is_use_y_axis: true,
    y_axis_field_key: "",
    chart_type: "line", //line, bar, pie
    is_use_custom_order: false,
    x_element_custom_order: [],
    color_default: "#373784",
    color_hover: "#f38026",
    is_npv_kai: false,
    wacc_field_key: "",
  };

  toggle_example_not_use_y = () => {
    this.setState({
      modal_example_not_use_y: !this.state.modal_example_not_use_y,
    });
  };

  toggle_example_use_y = () => {
    this.setState({
      modal_example_use_y: !this.state.modal_example_use_y,
    });
  };

  on_get_layer = () => {
    const { layer_id, geo_layer_list } = this.props.layer;
    const { geo_project } = this.props.project;
    const layer = geo_layer_list.find(
      (item) => item.geo_layer._id === layer_id
    );
    const geo_layer_item = layer.geo_layer;
    const body = {
      geo_layer_id: geo_layer_item._id,
      geo_project_id: geo_project._id,
      flyStatus: true,
      folder_id: layer?.folder,
      capex_value: this.props.properties.capex_value,
      geo_layer_item,
    };
    this.props.getDetailLayerById(body);
  };

  toggle_edit_chart = (chart_object) => {
    this.setState(
      {
        modal_input_chart: !this.state.modal_input_chart,
        mode_input_chart: "edit",
      },
      () => {
        if (chart_object) {
          this.setState({ ...this.state, ...chart_object });
        }
      }
    );
  };

  toggle_add_chart = () => {
    this.setState({
      modal_input_chart: !this.state.modal_input_chart,
      mode_input_chart: "add",
    });
  };

  move_up = (idx) => {
    let { x_element_custom_order } = this.state;
    if (idx !== 0) {
      const value = x_element_custom_order[idx];
      const value_before = x_element_custom_order[idx - 1];
      x_element_custom_order[idx - 1] = value;
      x_element_custom_order[idx] = value_before;
    } else {
      const value = x_element_custom_order[idx];
      const value_before =
        x_element_custom_order[x_element_custom_order.length - 1];
      x_element_custom_order[x_element_custom_order.length - 1] = value;
      x_element_custom_order[idx] = value_before;
    }
    this.setState({ x_element_custom_order });
  };

  move_down = (idx) => {
    let { x_element_custom_order } = this.state;
    if (idx !== x_element_custom_order.length - 1) {
      const value = x_element_custom_order[idx];
      const value_after = x_element_custom_order[idx + 1];
      x_element_custom_order[idx + 1] = value;
      x_element_custom_order[idx] = value_after;
    } else {
      const value = x_element_custom_order[idx];
      const value_after = x_element_custom_order[0];
      x_element_custom_order[0] = value;
      x_element_custom_order[idx] = value_after;
    }
    this.setState({ x_element_custom_order });
  };

  on_get_x_element_custom_order = () => {
    const { x_axis_field_key } = this.state;
    const { layer_id, geo_layer_list } = this.props.layer;
    const layer_object = geo_layer_list.find(
      (item) => item.geo_layer._id === layer_id
    ).geo_layer;
    const features = layer_object?.geojson?.features || [];
    let x_element_custom_order = [];
    features.forEach((item) => {
      const value = item?.properties?.[x_axis_field_key];
      if (!x_element_custom_order.includes(value))
        x_element_custom_order.push(value);
    });
    this.setState({ x_element_custom_order });
  };

  on_submit_add = () => {
    const {
      ina,
      eng,
      x_axis_field_key,
      is_use_y_axis,
      y_axis_field_key,
      chart_type,
      color_default,
      color_hover,
      wacc_field_key,
      is_npv_kai,
      is_use_custom_order,
      x_element_custom_order,
    } = this.state;
    const { layer_id } = this.props.layer;
    const body = {
      geo_layer_id: layer_id,
      chart_object: {
        ina,
        eng,
        x_axis_field_key,
        is_use_y_axis,
        y_axis_field_key,
        chart_type,
        color_default,
        color_hover,
        is_npv_kai,
        is_use_custom_order,
        x_element_custom_order,
        wacc_field_key,
      },
    };
    this.props.push_chart_list(body);
    this.setState({ modal_input_chart: false });
  };

  on_submit_edit = () => {
    const {
      _id,
      ina,
      eng,
      x_axis_field_key,
      is_use_y_axis,
      y_axis_field_key,
      chart_type,
      color_default,
      color_hover,
      wacc_field_key,
      is_npv_kai,
      is_use_custom_order,
      x_element_custom_order,
    } = this.state;
    const { layer_id } = this.props.layer;
    const body = {
      geo_layer_id: layer_id,
      chart_object: {
        _id,
        ina,
        eng,
        x_axis_field_key,
        is_use_y_axis,
        y_axis_field_key,
        chart_type,
        color_default,
        color_hover,
        is_npv_kai,
        is_use_custom_order,
        x_element_custom_order,
        wacc_field_key,
      },
    };
    this.props.edit_chart_list(body);
    this.setState({ modal_input_chart: false });
  };

  on_change = (e) => {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  };

  on_toggle = (e) => {
    this.setState({ [e.target.name]: !this.state[e.target.name] });
  };

  toggle_delete_chart = (chart_id_delete, chart_name_delete) => {
    this.setState({ modal_delete_chart: !this.state.modal_delete_chart });
    if (chart_id_delete) {
      this.setState({ chart_id_delete, chart_name_delete });
    }
  };

  on_delete_chart_list = () => {
    const { chart_id_delete } = this.state;
    const { layer_id } = this.props.layer;
    const params = {
      geo_layer_id: layer_id,
      chart_id: chart_id_delete,
    };
    this.props.delete_chart_list(params);
    this.setState({ modal_delete_chart: false });
  };

  render() {
    //local storage

    //local state
    const {
      mode_input_chart,
      modal_input_chart,
      modal_delete_chart,
      modal_example_not_use_y,
      modal_example_use_y,

      chart_id_delete,
      chart_name_delete,
      ina,
      eng,
      x_axis_field_key,
      is_use_y_axis,
      y_axis_field_key,
      chart_type,
      is_npv_kai,
      wacc_field_key,
      is_use_custom_order,
      x_element_custom_order,
      color_default,
      color_hover,
    } = this.state;

    //global props
    const { layer_id, geo_layer_list } = this.props.layer;
    const { domain } = this.props.auth;

    //content
    const is_domain_kai = domain_list?.[domain]?.is_domain_kai;
    const layer_object = geo_layer_list.find(
      (item) => item.geo_layer._id === layer_id
    ).geo_layer;
    const fields = layer_object?.fields || [];
    const chart_list = layer_object?.chart_list || [];
    const features = layer_object?.geojson?.features || [];

    const modal_add_chart_content = modal_input_chart && (
      <Modal
        modalSize="medium"
        id="modal_input_chart"
        isOpen={modal_input_chart}
        onClose={this.toggle_add_chart}
      >
        <div className="box-body">
          <main className="max_height">
            <h1 className="text_header">
              {mode_input_chart === "add"
                ? "Tambahkan Grafik Baru"
                : "Edit Grafik"}
            </h1>
            <section
              style={{
                maxHeight: "calc(( 100vh - 250px ))",
                overflowY: "auto",
                paddingRight: "10px",
              }}
            >
              <section className="container_light margin_bottom">
                <h2 className="text_bold">Judul</h2>
                <br />
                <p className="text_small">Judul Bahasa Indonesia</p>
                <input
                  value={ina}
                  name="ina"
                  onChange={this.on_change}
                  type="text"
                  placeholder="Tulisan judul grafik"
                />
                <br />
                <p className="text_small">Judul Bahasa Inggris</p>
                <input
                  value={eng}
                  name="eng"
                  onChange={this.on_change}
                  type="text"
                  placeholder="Tulisan judul grafik"
                />
              </section>

              <section className="container_light margin_bottom">
                <h2 className="text_bold">Pengaturan grafik</h2>
                <br />
                <section className="margin_bottom">
                  <p className="text_small">Kolom untuk sumbu x (label)</p>
                  <Dropdown
                    placeholder="Pilih kolom untuk sumbu x"
                    filter_on
                    is_enable_empty={false}
                    value={x_axis_field_key}
                    onChange={(value) => {
                      this.setState({
                        x_axis_field_key: value,
                      });
                    }}
                  >
                    {fields.map((field) => {
                      return (
                        <option key={field.key} value={field.key}>
                          {field.name}
                        </option>
                      );
                    })}
                  </Dropdown>
                </section>

                <br />

                <section className="margin_bottom">
                  <button
                    className={`${
                      is_use_y_axis ? "checklist_active" : "checklist_not"
                    }`}
                    name="is_use_y_axis"
                    onClick={this.on_toggle}
                  />
                  <p className="text_small margin_left">Gunakan sumbu y</p>
                </section>

                {is_use_y_axis ? (
                  <section className="margin_bottom">
                    <p className="text_small">Kolom untuk sumbu y (angka)</p>
                    <p className="container_light outline_green">
                      Pastikan pilih kolom dengan isian angka{" "}
                      <span
                        style={{ textDecoration: "underline" }}
                        className="cursor_pointer"
                        onClick={this.toggle_example_use_y}
                      >
                        contoh
                      </span>
                    </p>
                    <Dropdown
                      placeholder="Pilih kolom untuk sumbu y"
                      filter_on
                      is_enable_empty={false}
                      value={y_axis_field_key}
                      onChange={(value) => {
                        this.setState({
                          y_axis_field_key: value,
                        });
                      }}
                    >
                      {fields.map((field) => {
                        return (
                          <option key={field.key} value={field.key}>
                            {field.name}
                          </option>
                        );
                      })}
                    </Dropdown>
                  </section>
                ) : (
                  <p className="container_light outline_green">
                    Nilai dari grafik akan dihitung dengan pengelompokan nilai{" "}
                    <span
                      style={{ textDecoration: "underline" }}
                      className="cursor_pointer"
                      onClick={this.toggle_example_not_use_y}
                    >
                      contoh
                    </span>
                  </p>
                )}

                <br />

                <section>
                  <>
                    <button
                      className={
                        is_use_custom_order
                          ? "checklist_active"
                          : "checklist_not"
                      }
                      name="is_use_custom_order"
                      onClick={this.on_toggle}
                    />
                    <p className="text_small margin_left">
                      Kustom urutan label/sumbu x
                    </p>
                  </>
                  {is_use_custom_order && (
                    <>
                      {features.length === 0 && (
                        <button
                          className="button_small background_blue margin_left"
                          onClick={this.on_get_layer}
                        >
                          Ambil data terlebih dahulu
                        </button>
                      )}
                      {!x_axis_field_key ? (
                        <div className="container_light outline_red">
                          Pilih sumbu x terlebih dahulu
                        </div>
                      ) : (
                        <>
                          <button
                            className="button_small background_blue margin_left"
                            onClick={this.on_get_x_element_custom_order}
                          >
                            Generate list
                          </button>
                          <br />
                          <section className="container_light background_white">
                            <table className="table">
                              <tbody>
                                {x_element_custom_order.map((item, idx) => {
                                  return (
                                    <tr key={idx}>
                                      <td>
                                        <button
                                          className="button_icon background_black margin_bottom"
                                          onClick={this.move_up.bind(this, idx)}
                                        >
                                          ↑
                                        </button>
                                        <br />
                                        <button
                                          className="button_icon background_black"
                                          onClick={this.move_down.bind(
                                            this,
                                            idx
                                          )}
                                        >
                                          ↓
                                        </button>
                                      </td>
                                      <td style={{ verticalAlign: "middle" }}>
                                        <span className="button_small background_orange">
                                          {item}
                                        </span>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </section>
                        </>
                      )}
                    </>
                  )}
                </section>

                <br />

                <section>
                  <p className="text_small">Tipe chart</p>
                  <select
                    value={chart_type}
                    name="chart_type"
                    onChange={this.on_change}
                  >
                    <option value="line">Line chart (Diagram baris)</option>
                    <option value="bar">Bar chart (Diagram batang)</option>
                    <option value="pie">Pie chart (Diagram lingkaran)</option>
                  </select>
                </section>

                <br />

                <section>
                  <p className="text_small">Warna utama</p>
                  <input
                    type="color"
                    value={color_default}
                    name="color_default"
                    onChange={this.on_change}
                  />
                </section>

                <br />

                <section>
                  <p className="text_small">Warna ketika ditinjau/diklik</p>
                  <input
                    type="color"
                    value={color_hover}
                    name="color_hover"
                    onChange={this.on_change}
                  />
                </section>
              </section>
              {is_domain_kai && (
                <section className="container_light margin_bottom">
                  <h2 className="text_bold">Pengaturan khusus data NPV</h2>
                  <br />
                  <p className="text_small">Aktifkan NPV</p>
                  <select
                    value={is_npv_kai}
                    name="is_npv_kai"
                    onChange={this.on_change}
                  >
                    <option value={false}>Tidak aktif</option>
                    <option value={true}>Aktif</option>
                  </select>
                  <br />
                  <p className="text_small">Kolom wacc</p>
                  <Dropdown
                    placeholder="Pilih kolom untuk wacc"
                    filter_on
                    is_enable_empty={false}
                    value={wacc_field_key}
                    onChange={(value) => {
                      this.setState({
                        wacc_field_key: value,
                      });
                    }}
                  >
                    {fields.map((field) => {
                      return (
                        <option key={field.key} value={field.key}>
                          {field.name}
                        </option>
                      );
                    })}
                  </Dropdown>
                </section>
              )}
              <br />
            </section>

            <section className="text_center">
              {mode_input_chart === "add" ? (
                <button
                  className="button background_blue"
                  onClick={this.on_submit_add}
                >
                  Simpan
                </button>
              ) : (
                <button
                  className="button background_blue"
                  onClick={this.on_submit_edit}
                >
                  Simpan perubahan
                </button>
              )}
            </section>
          </main>
        </div>
      </Modal>
    );

    const modal_delete_chart_content = modal_delete_chart && (
      <Modal
        modalSize="small"
        id="toggle_delete_chart"
        isOpen={modal_delete_chart}
        onClose={this.toggle_delete_chart}
      >
        <div className="box-body">
          <h1 className="text_header">Konfirmasi hapus grafik</h1>
          <table className="table">
            <tbody>
              <tr>
                <td>Nama</td>
                <td>{chart_name_delete}</td>
              </tr>
              <tr>
                <td>_id</td>
                <td>{chart_id_delete}</td>
              </tr>
            </tbody>
          </table>
          <p className="text_small">
            Grafik yang sudah dihapus tidak akan bisa dikembalikan.
          </p>
          <br />
          <br />
          <button
            className="button background_red"
            onClick={this.on_delete_chart_list}
          >
            Hapus grafik
          </button>
        </div>
      </Modal>
    );

    const modal_example_not_use_y_content = modal_example_not_use_y && (
      <Modal
        modalSize="medium"
        id="modal_example_not_use_y"
        isOpen={modal_example_not_use_y}
        onClose={this.toggle_example_not_use_y}
      >
        <div className="box-body">
          <EXAMPLE_NOT_USE_Y />
        </div>
      </Modal>
    );

    const modal_example_use_y_content = modal_example_use_y && (
      <Modal
        modalSize="medium"
        id="modal_example_use_y"
        isOpen={modal_example_use_y}
        onClose={this.toggle_example_use_y}
      >
        <div className="box-body">
          <EXAMPLE_USE_Y />
        </div>
      </Modal>
    );

    return (
      <main className="max_height">
        {modal_delete_chart_content}
        {modal_add_chart_content}
        {modal_example_not_use_y_content}
        {modal_example_use_y_content}
        <h1 className="text_header">Grafik</h1>
        <section
          style={{
            maxHeight: "calc(( 100vh - 250px ))",
            overflowY: "auto",
            paddingRight: "10px",
          }}
        >
          {chart_list.length === 0 ? (
            <div className="container_light">Belum ada grafik di layer ini</div>
          ) : (
            <div>
              {chart_list.map((item, idx) => {
                return (
                  <div key={idx} className="container_light margin_bottom">
                    <span className="badge_pill background_orange margin_bottom">
                      Grafik {idx + 1}
                    </span>
                    <br />
                    <h3 className="text_bold">{item.ina}</h3>
                    <br />
                    <section>
                      <button
                        className="button_small background_blue margin_right margin_bottom"
                        onClick={this.toggle_edit_chart.bind(this, item)}
                      >
                        Edit
                      </button>
                      <button
                        className="button_small background_red margin_right margin_bottom"
                        onClick={this.toggle_delete_chart.bind(
                          this,
                          item._id,
                          item.ina
                        )}
                      >
                        Hapus
                      </button>
                    </section>
                  </div>
                );
              })}
            </div>
          )}
        </section>
        <section className="text_center margin_top">
          <button
            className="button background_blue"
            onClick={this.toggle_add_chart}
          >
            Tambah grafik
          </button>
        </section>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  layer: state.layer,
  project: state.project,
  properties: state.properties,
});

export default connect(mapStateToProps, {
  push_chart_list,
  delete_chart_list,
  edit_chart_list,
  getDetailLayerById,
})(MANAGE_CHART);
