/*LIBRARY*/
import { useEffect, useState } from "react"; // useState untuk state management, useEffect untuk mengatur side effects.
import { useSelector } from "react-redux"; // Mengambil data dari state Redux global.
import { FormatRupiah } from "@arismun/format-rupiah"; // Format nilai ke dalam format rupiah.
import { useDispatch } from "react-redux"; // Dispatch action ke Redux.
import Pagination from "@mui/material/Pagination"; // Pagination UI dari material-ui.
import { Link } from "react-router-dom"; // Navigasi Link dari react-router.
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  List,
  ListItem,
  Radio,
  RadioGroup,
} from "@mui/material"; // Komponen Material-UI untuk formulir seperti checkbox dan radio button.

/*COMPONENT*/
import UploadImage from "../custom_cell/UploadImage"; // Komponen khusus untuk mengunggah gambar.
import UploadFile from "../custom_cell/UploadFile"; // Komponen khusus untuk mengunggah file.
import Modal from "../reuseable/Modal"; // Komponen modal yang dapat digunakan kembali.

/*REDUX*/
import { set_value_properties } from "../../App/actions/propertiesActions"; // Action untuk mengatur properti.
import {
  deleteFeature,
  editProperties,
  editPropertiesV2,
  set_value_layer,
} from "../../App/actions/layerActions"; // Action terkait layer di Redux.

/*PICTURE*/
import icon_type_date from "../../Assets/svg/icon_type_date.svg"; // Ikon untuk tipe tanggal.
import icon_download from "../../Assets/svg/icon_import.svg"; // Ikon untuk tombol unduh.
import icon_delete_2 from "../../Assets/svg/icon_delete_2.svg"; // Ikon untuk tombol hapus.

/*FUNCTION*/
import useLanguage from "../common_hooks/useLanguage"; // Hook custom untuk mengelola bahasa.
import { dot_every_3_digits } from "../../App/validation/format_number"; // Fungsi untuk format angka.
import { convertUnit } from "../../App/validation/geometry_to_area_or_length"; // Fungsi konversi unit geometri.
import format_date_formal from "../../App/validation/format_date_formal"; // Format tanggal formal.
import dict from "../../Data/dict.json"; // Kamus atau terjemahan.
import { is_valid_url } from "../../App/validation/is_valid_url"; // Fungsi validasi URL.
import { getFeatureByKey } from "../../App/reduxHelper/layer"; // Mengambil fitur berdasarkan kunci layer.
import { TABLE_NESTED_CELL } from "../custom_cell"; // Komponen tabel nested.
import { Dropdown } from "../reuseable"; // Komponen dropdown yang dapat digunakan kembali.
import is_not_empty from "../../App/validation/is_not_empty"; // Fungsi validasi apakah suatu nilai tidak kosong.

/*DATA*/

/*CONST*/
const WAIT_INTERVAL = 1000; // Interval tunggu untuk debounce.

/**
 * Keterangan:
 *
 * PopupValue ini merupakan Component popup yang terdiri Title dan value, komponen ini TIDAK ADA fitur editor. Component ini akan muncul ketika component POPUP_EDITOR berjalan.
 */
const PopupValue = (params) => {
  const language = useState("ina")[0]; // Inisialisasi state bahasa dengan default "ina".
  const { data, properties_selected } = params;
  const { value, unit } = convertUnit(
    properties_selected["_geometry"]?.value,
    data?.unit
  ); // Mengonversi nilai properti geometri yang dipilih.
  let final_render_value = properties_selected[data?.key];
  if (!is_not_empty(final_render_value)) {
    final_render_value = "-"; // Jika value kosong, ganti dengan tanda "-".
  }

  return (
    <div className="valueNewPopup">
      {
        <>
          <p>{data?.name}</p>
          <div className="border_grey rounded_5 padding_10">
            {data?.type === "text" ? ( // Render text
              <h3>{final_render_value}</h3>
            ) : data?.type === "textarea" ? ( // Render textarea
              <h3 className="popup_textarea">{final_render_value}</h3>
            ) : data?.type === "number" ? ( // Render number
              <h3>{final_render_value}</h3>
            ) : data?.type === "phone_number" ? ( // Render nomor telepon
              <h3>+{final_render_value}</h3>
            ) : data?.type === "currency" ? ( // Format sebagai mata uang
              <h3>
                <FormatRupiah value={final_render_value} />
              </h3>
            ) : data?.type === "year" ? ( // Format tahun
              <h3>{final_render_value}</h3>
            ) : data?.type === "date" ? ( // Format tanggal
              <h3>{format_date_formal(final_render_value)}</h3>
            ) : data?.type === "time" ? ( // Format waktu
              <h3>{final_render_value}</h3>
            ) : data?.type === "selection" ? ( // Format pilihan
              <h3>{final_render_value}</h3>
            ) : data?.type === "radio_button" ? ( // Render radio button
              <h3>{final_render_value}</h3>
            ) : data?.type === "checklist" ? ( // Render checklist
              <h3>{final_render_value?.split(" ")}</h3>
            ) : data?.type === "image" ? ( // Render gambar
              <img
                src={properties_selected[data.key]}
                alt={data?.name}
                className="photo_popup"
              />
            ) : data?.type === "document" ? ( // Render dokumen dengan validasi link
              <a
                href={
                  final_render_value.includes("https://")
                    ? final_render_value
                    : `https://${final_render_value}`
                }
                className={`noselect rounded_5 padding_5 ${
                  final_render_value ? "bg_blue pointer" : "bg_grey"
                }`}
                style={{
                  color: final_render_value ? "white" : "black",
                }}
              >
                {final_render_value
                  ? dict?.["Download"]?.[language]
                  : dict?.["No document"]?.[language]}
              </a>
            ) : data?.type === "link" ? ( // Render link
              <a
                href={
                  final_render_value.includes("https://")
                    ? final_render_value
                    : `https://${final_render_value}`
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                {final_render_value}
              </a>
            ) : data?.type === "auto_address" ? ( // Render auto address
              <h3>{final_render_value}</h3>
            ) : data?.type === "auto_address_poi" ? ( // Render POI address
              <h3>{final_render_value}</h3>
            ) : data?.type === "table" ? ( // Render tabel
              <h3>{final_render_value}</h3>
            ) : data?.type === "calculate_dimension" ? ( // Render hasil kalkulasi dimensi
              <h3>{`${dot_every_3_digits(value)} ${unit}`}</h3>
            ) : (
              <h3>{final_render_value}</h3>
            )}
          </div>
        </>
      }
    </div>
  );
};

const render_like_text = ["text", "phone_number"]; // Tipe yang di-render sebagai text.
const render_like_number = ["number", "currency", "year"]; // Tipe yang di-render sebagai number.
const render_uneditable = [
  "kelurahan",
  "kecamatan",
  "kota",
  "provinsi",
  "auto_address",
  "auto_address_poi",
  "counting_poi",
  "counting_custom",
  "simple_referring",
  "2d_referring",
  "math_operators",
  "conditional_statement",
  "auto_generate_user_name",
]; // Tipe yang tidak bisa diubah (uneditable).

/**
 * Keterangan:
 *
 * PopupFormEdit ini merupakan Component popup yang terdiri Title dan value, namun untuk kasus tertentu, value dapat diubah melalui komponen ini. Component ini akan muncul ketika component POPUP_EDITOR berjalan.
 */
const PopupFormEdit = ({
  data,
  properties_selected, //properties
  is_mode_edit,
  on_change_callback,
  index,
  page,
  geo_layer_selected,
  geo_layer_id,
}) => {
  const [timer, setTimer] = useState(null); // Mengatur timer untuk debounce.
  const [textfield, set_textfield] = useState(properties_selected[data?.key]); // Menyimpan nilai field.
  const language = useState(localStorage?.language || "ina")[0]; // Inisialisasi state bahasa.
  const handle_checkbox =
    ({ value, field_key }) =>
    () => {
      const splitted_list = textfield?.split("\n") || []; // Memecah textfield menjadi array berdasarkan newline.
      const currentIndex = splitted_list?.indexOf(value); // Mencari index dari nilai checkbox.
      const newChecked = structuredClone(splitted_list); // Membuat salinan dari list yang sudah di-split.
      if (currentIndex === -1) {
        newChecked.push(value); // Jika belum dipilih, tambahkan.
      } else {
        newChecked.splice(currentIndex, 1); // Jika sudah dipilih, hapus dari list.
      }
      set_textfield(newChecked.join("\n")); // Set textfield dengan nilai baru.
      on_change_callback(field_key, newChecked.join("\n")); // Panggil callback perubahan.
    };

  const handle_stright_forward = (value, field_key) => {
    set_textfield(value); // Set langsung nilai textfield.
    on_change_callback(field_key, value); // Panggil callback perubahan.
  };

  const handle_on_change_timer = (field_key, value) => {
    set_textfield(value); // Set nilai textfield.
    clearTimeout(timer); // Hapus timer sebelumnya.
    const newTimer = setTimeout(() => {
      on_change_callback(field_key, value); // Set nilai setelah interval tertentu (debounce).
    }, WAIT_INTERVAL);
    setTimer(newTimer); // Set timer baru.
  };

  // Render konten sesuai dengan tipe field.
  let content = <></>;
  if (render_like_text?.includes(data?.type)) {
    content = (
      <input
        className={`w_full ${is_mode_edit ? "bg_white" : "bg_lightGrey"}`}
        key={data?.key}
        type="text"
        value={textfield}
        onChange={(e) => {
          handle_on_change_timer(data?.key, e?.target?.value); // Panggil debounce onChange.
        }}
        disabled={!is_mode_edit} // Disabled jika mode edit mati.
      />
    );
  } else if (render_like_number?.includes(data?.type)) {
    content = (
      <input
        className={`w_full ${is_mode_edit ? "bg_white" : "bg_lightGrey"}`}
        key={data?.key}
        type="number"
        value={textfield}
        onChange={(e) => handle_on_change_timer(data?.key, e?.target?.value)} // Panggil debounce onChange.
        disabled={!is_mode_edit}
      />
    );
  } else if (data?.type === "textarea") {
    content = (
      <textarea
        className={`w_full ${
          is_mode_edit ? "bg_white" : "bg_lightGrey"
        } min_h_200 max_h_500`}
        key={data?.key}
        type="text"
        value={textfield}
        onChange={(e) => handle_on_change_timer(data?.key, e?.target?.value)} // Panggil debounce onChange.
        disabled={!is_mode_edit}
      />
    );
  } else if (data?.type === "range") {
    content = (
      <div>
        <label className="flex justify_end">{textfield || 0}</label>
        <input
          className="w_full"
          key={data?.key}
          type="range"
          value={textfield}
          onChange={(e) => handle_on_change_timer(data?.key, e?.target?.value)} // Panggil debounce onChange.
          disabled={!is_mode_edit}
          min={data?.min || 0}
          max={data?.max || 100}
          step={data?.step || 1}
        />
      </div>
    );
  } else if (data?.type === "date") {
    const converted_date = textfield ? format_date_formal(textfield) : ""; // Format tanggal.
    content = (
      <main className="center_perfect w_full">
        <div
          className={`w_full h_full flex justify_between border_grey rounded_5 padding_5 ${
            is_mode_edit ? "bg_white" : "bg_lightGrey"
          }`}
        >
          <span title="DD-MM-YYYY">{converted_date}</span>
          {is_mode_edit && (
            <button className="datepicker-native-button bg_grey rounded_5 pointer button_inactive">
              <img alt="date" src={icon_type_date} className="w_15 h_15" />
              <input
                className="datepicker-native-button-input"
                value={textfield || ""}
                type="date"
                onChange={(e) =>
                  handle_stright_forward(e.target.value, data?.key)
                }
                disabled={!is_mode_edit}
              />
            </button>
          )}
        </div>
      </main>
    );
  } else if (data?.type === "time") {
    content = (
      <input
        className={is_mode_edit ? "bg_white" : "bg_lightGrey"}
        id="time_input"
        type="time"
        value={textfield || ""}
        onChange={(e) => handle_stright_forward(e.target.value, data?.key)} // Set langsung tanpa debounce.
        disabled={!is_mode_edit}
      />
    );
  } else if (data?.type === "checklist") {
    content = (
      <List style={{ paddingTop: 0, paddingBottom: 0, margin: 0 }}>
        {data?.array_templates?.map((item) => (
          <ListItem
            key={item?._id}
            value={item?._id}
            style={{ paddingTop: 0, paddingBottom: 0, margin: 0, height: 30 }}
            secondaryAction={
              <Checkbox
                disabled={!is_mode_edit}
                onChange={handle_checkbox({
                  value: item?.name,
                  field_key: data?.key,
                })} // Panggil handle_checkbox saat checklist berubah.
                checked={textfield?.includes(item?.name) || false} // Cek apakah nilai ada di checklist.
              />
            }
          >
            {item?.name}
          </ListItem>
        ))}
      </List>
    );
  } else if (data?.type === "radio_button") {
    content = (
      <FormControl>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue="female"
          name="radio-buttons-group"
          onChange={(e, value) => handle_stright_forward(value, data?.key)} // Set nilai langsung tanpa debounce.
          value={textfield || ""}
        >
          <div className="flex flex_col">
            {data?.array_templates?.map((item) => (
              <FormControlLabel
                disabled={!is_mode_edit}
                key={item?._id}
                value={item?.name}
                control={
                  <Radio
                    style={{
                      paddingTop: 0,
                      paddingBottom: 0,
                    }}
                  />
                }
                label={
                  <p className="text_black font_12 text_grey">{item?.name}</p>
                }
                style={{
                  paddingTop: 0,
                  paddingBottom: 0,
                  margin: 0,
                  wordBreak: "break-word",
                }}
              />
            ))}
            {!data?.array_templates?.some(
              (template) => template?.name === textfield
            ) &&
              is_not_empty(textfield) && (
                <FormControlLabel
                  disabled={!is_mode_edit}
                  value={textfield}
                  control={
                    <Radio
                      style={{
                        paddingTop: 0,
                        paddingBottom: 0,
                      }}
                    />
                  }
                  label={
                    <p className="text_black font_12 text_grey">{textfield}</p>
                  }
                  style={{
                    paddingTop: 0,
                    paddingBottom: 0,
                    margin: 0,
                    wordBreak: "break-word",
                  }}
                />
              )}
          </div>
        </RadioGroup>
      </FormControl>
    );
  } else if (data?.type === "selection") {
    const merged_option = data?.array_templates?.map((item) => (
      <option key={item?.name} value={item?.name}>
        {<p className="text_black font_12">{item?.name}</p>}
      </option>
    ));
    if (
      !data?.array_templates?.some(
        (template) => template?.name === textfield
      ) &&
      is_not_empty(textfield)
    ) {
      merged_option.push(
        <option key={textfield} value={textfield}>
          {textfield}
        </option>
      );
    }
    if (is_mode_edit) {
      content = (
        <Dropdown
          placeholder={dict["Select"][language]}
          filter_on
          is_enable_empty
          value={textfield}
          onChange={(value) => {
            handle_stright_forward(value, data?.key); // Set nilai langsung saat berubah.
          }}
        >
          {merged_option}
        </Dropdown>
      );
    } else {
      content = <div>{textfield ? textfield : "-"}</div>;
    }
  } else if (data?.type === "image") {
    content = (
      <div>
        <img
          src={textfield}
          style={{
            width: "100%",
            objectFit: "cover",
          }}
          alt=""
        />
        {is_mode_edit && (
          <div className="flex justify_end">
            <UploadImage
              imageUrl={textfield}
              geo_layer_id={geo_layer_id}
              feature_key={properties_selected?.key}
              properties_key={data?.key}
              set_textfield={handle_stright_forward} // Panggil callback upload.
            />
          </div>
        )}
      </div>
    );
  } else if (data?.type === "document") {
    const fileNameOnServer = textfield?.split("/")?.at(-1); // Mengambil nama file dari URL.
    const arrayName = fileNameOnServer?.split("_%25"); // Mengambil nama asli file.
    const extension = textfield?.split(".").at(-1); // Mendapatkan ekstensi file.
    const originalName =
      arrayName?.at(0)?.split("_")?.at(1) === "doc"
        ? arrayName?.at(1)?.replaceAll("%20", " ") +
          (extension ? `.${extension}` : "")
        : "";
    content = (
      <div>
        {textfield ? (
          <div title={originalName}>
            <p>{originalName}</p>
          </div>
        ) : (
          <div
            style={{
              fontSize: "10px",
              width: 80,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: 5,
            }}
          >
            {dict["No document"][language]}
          </div>
        )}
        <div className="flex justify_between">
          <div className="flex justify_end">
            {is_mode_edit && (
              <UploadFile
                imageUrl={textfield}
                geo_layer_id={geo_layer_id}
                feature_key={properties_selected?.key}
                properties_key={data?.key}
                set_textfield={handle_stright_forward} // Panggil callback upload file.
              />
            )}
          </div>
          <Link
            className="hover_darkGreen hover_bigger pointer"
            style={{
              display: "flex",
              alignItems: "center",
              fontSize: "10px",
              width: 15,
              height: 15,
              backgroundColor: "#e6eef3",
              padding: "5px",
              border: "1px solid #9ca8bb",
              borderRadius: "99px",
            }}
            target="_blank"
            to={textfield || "https://mapid.io"}
          >
            <img
              alt="download"
              src={icon_download}
              className="h_15 w_15 rounded"
            />
          </Link>
        </div>
      </div>
    );
  } else if (data?.type === "link") {
    let href = textfield;
    if (is_valid_url(textfield)) {
      if (textfield?.includes("https://") || textfield?.includes("http://")) {
        // do nothing
      } else {
        href = `https://${textfield}`; // Tambahkan https jika URL tidak valid.
      }
    }
    content = is_mode_edit ? (
      <input
        className={`w_full`}
        key={data?.key}
        type="text"
        value={textfield}
        onChange={(e) => {
          handle_on_change_timer(data?.key, e.target.value); // Panggil debounce onChange.
        }}
        disabled={!is_mode_edit}
      />
    ) : (
      <a className="url" rel="noopener noreferrer" target="_blank" href={href}>
        {textfield || "-"}
      </a>
    );
  } else if (render_uneditable.includes(data?.type)) {
    content = <div>{textfield ? textfield : "-"}</div>; // Render uneditable field.
  } else if (data?.type === "calculate_dimension") {
    const { unit } = convertUnit(textfield["_geometry"]?.value, data.unit); // Konversi dimensi.
    content = <div>{`${textfield} ${unit}`}</div>; // Render hasil konversi.
  } else if (data?.type === "table") {
    const feature = getFeatureByKey({
      geo_layer: geo_layer_selected,
      feature_key: properties_selected?.["key"],
    });
    content = (
      <TABLE_NESTED_CELL
        layer_id={geo_layer_id}
        row={feature}
        column={data}
        is_from_first_gen_table={true}
      />
    );
  } else {
    content = <div>{textfield ? textfield : "-"}</div>;
  }
  return (
    <div className="flex w_full bold_border_grey rounded_5 padding_10">
      <p>{index + 1}.</p>
      <div className="w_full">
        <section className="flex justify_between">
          <p className="bold font_16 text_blue">{data?.name}</p>
          <p className="font_16 text_orange underline">
            {data?.type
              ?.split("_")
              .map((word) => word?.[0].toUpperCase() + word?.slice(1))
              .join(" ")}
          </p>
        </section>
        {content}
      </div>
    </div>
  );
};

/**
 * Keterangan
 *
 * POPUP_EDITOR merupakan suatu component popup yang akan muncul ketika suatu geometry ditekan/diklik. Component ini memiliki 2 children. PopupFormEdit (bisa diedit) dan PopupValue (tidak bisa diedit). PopupFormEdit akan dirender hanya ketika mode fullscreen saja sehingga secara default akan merender PopupValue.
 */
const POPUP_EDITOR = ({
  longitude_selected,
  latitude_selected,
  fields_selected,
  properties_selected,
  type_2_selected,
  feature_key_selected,
  genangan_cm_selected,
  geo_layer_selected,
  is_editable,
}) => {
  const dispatch = useDispatch(); // Dispatch action Redux.
  const { language } = useLanguage(); // Menggunakan hook bahasa.
  const [is_mode_edit, set_is_mode_edit] = useState(false); // State mode edit.
  const [data_popup, set_data_popup] = useState(properties_selected); // Data yang akan ditampilkan di popup.
  const [rendered_input_field, set_rendered_input_field] = useState([]); // Field yang di-render dalam popup.
  const [page, set_page] = useState(1); // State untuk pagination.
  const [field_key_that_changed, set_field_key_that_changed] = useState(""); // Menyimpan field yang berubah.
  const [modal_delete_feature, set_modal_delete_feature] = useState(false); // State untuk modal hapus.

  useEffect(() => {
    let fieldsMap = render_list_attributes() || []; // Merender semua atribut.
    set_rendered_input_field(fieldsMap);
    // eslint-disable-next-line
  }, [data_popup, Object.keys(data_popup).length]);

  useEffect(() => {
    set_data_popup(properties_selected); // Memperbarui data popup saat properti yang dipilih berubah.
    // eslint-disable-next-line
  }, [feature_key_selected]);

  useEffect(() => {
    let fieldsMap = render_list_attributes() || []; // Merender ulang saat data popup atau mode edit berubah.
    set_rendered_input_field(fieldsMap);
    // eslint-disable-next-line
  }, [data_popup, is_mode_edit, data_popup?.[field_key_that_changed]]);

  const modal_edit_feature_properties = useSelector(
    (state) => state.layer.modal_edit_feature_properties
  ); // Mengambil state modal edit feature dari Redux.

  const popup_dom = useSelector((state) => state.layer.popup_dom);

  const toggle_delete_feature = (value) => {
    if (value === true || value === false) {
      set_modal_delete_feature(value); // Set state modal hapus berdasarkan nilai yang diberikan.
    } else {
      set_modal_delete_feature(!modal_delete_feature); // Toggle modal hapus.
    }
  };

  const close_modal_fullscreen = () => {
    dispatch(
      set_value_layer({
        key: "modal_edit_feature_properties",
        value: false,
      })
    ); // Tutup modal fullscreen edit feature.
  };

  const on_change_page = (event, value) => {
    set_page(value); // Set halaman pada pagination.
  };

  const toggle_mode_edit = () => {
    set_is_mode_edit(!is_mode_edit); // Toggle mode edit.
  };

  const on_change_callback = (field_key, value) => {
    let new_data = structuredClone(data_popup); // Salin data popup yang ada.
    new_data[field_key] = value; // Perbarui field yang berubah.
    submit(geo_layer_selected?._id, properties_selected?.key, field_key, value); // Submit perubahan data.
    set_data_popup({ ...new_data }); // Set data popup dengan nilai baru.
    set_field_key_that_changed(field_key); // Simpan field yang berubah.
  };

  const submit = (
    geo_layer_id,
    feature_key_selected,
    properties_key,
    properties_value
  ) => {
    const body = {
      geo_layer_id,
      feature_key: feature_key_selected,
      properties_key,
      properties_value,
    };
    if (geo_layer_selected?.architecture === "v2") {
      dispatch(editPropertiesV2(body)); // Dispatch action editPropertiesV2.
    } else if (geo_layer_selected?.architecture === "api") {
      // do nothing for now.
    } else {
      dispatch(editProperties(body)); // Dispatch action editProperties.
    }
  };

  const render_list_attributes = () => {
    let fieldsMap = [];
    if (modal_edit_feature_properties) {
      let index = 0;
      let subFieldMap = [];
      let section_counter = 0;
      for (const data of fields_selected) {
        if (data.isStyle !== true && data?.type !== "section") {
          const element = (
            <div key={index} className="marginTop_5 marginBottom_5">
              <PopupFormEdit
                index={index}
                data={data}
                properties_selected={data_popup}
                is_mode_edit={is_mode_edit}
                on_change_callback={on_change_callback}
                page={section_counter}
                geo_layer_id={geo_layer_selected?._id}
                geo_layer_selected={
                  data?.type === "table" ? geo_layer_selected : undefined
                }
              />
            </div>
          );
          index++;
          subFieldMap.push(element);
        } else if (data?.type === "section") {
          if (subFieldMap?.length > 0) {
            fieldsMap.push(subFieldMap); // Tambahkan subFieldMap ke fieldsMap.
          }
          const element = (
            <div
              key={`section-${index}`}
              className="marginTop_5 marginBottom_5"
            >
              <label className="font_30 bold">{`Section: ${data?.name}`}</label>
            </div>
          );
          subFieldMap = [element];
          section_counter++;
        }
      }
      if (subFieldMap?.length > 0) {
        fieldsMap.push(subFieldMap); // Tambahkan subFieldMap terakhir jika ada.
      }
    } else {
      let index = 0;
      fields_selected.forEach((data) => {
        const element = (
          <div key={index}>
            {data.isStyle !== true && (
              <PopupValue
                data={data}
                properties_selected={data_popup}
                feature_key_selected={feature_key_selected}
              />
            )}
          </div>
        );
        index++;
        fieldsMap.push(element);
      });
    }
    return fieldsMap;
  };

  const on_get_genangan = () => {
    dispatch(
      set_value_properties({
        key: "popupInfo",
        value: null,
      })
    );
    dispatch(
      set_value_properties({
        key: "modal_pop_up_layer",
        value: false,
      })
    );
    dispatch(
      set_value_properties({
        key: "modal_pop_up_genangan",
        value: true,
      })
    );
    dispatch(
      set_value_properties({
        key: "feature_key_params_genangan",
        value: feature_key_selected,
      })
    );
    dispatch(
      set_value_properties({
        key: "latitude_params_genangan",
        value: latitude_selected,
      })
    );
    dispatch(
      set_value_properties({
        key: "longitude_params_genangan",
        value: longitude_selected,
      })
    );
    dispatch(
      set_value_properties({
        key: "genangan_cm_params_genangan",
        value: genangan_cm_selected,
      })
    );
    dispatch(
      set_value_properties({
        key: "success_status",
        value: false,
      })
    );
    dispatch(
      set_value_properties({
        key: "error_status",
        value: false,
      })
    );
  };

  const toggle_share = () => {
    dispatch(
      set_value_properties({
        key: "feature_will_be_shared_key",
        value: feature_key_selected,
      })
    );
    dispatch(
      set_value_properties({
        key: "modal_share",
        value: true,
      })
    );
  };

  const handle_delete_feature = () => {
    const content = {
      feature_key: feature_key_selected,
      geo_layer_id: geo_layer_selected?._id,
    };
    dispatch(deleteFeature(content)).then(() => {
      toggle_delete_feature(false); // Tutup modal hapus.
      close_modal_fullscreen(); // Tutup modal fullscreen.
      if (popup_dom) {
        popup_dom.remove();
        set_value_layer({
          key: "popup_dom",
          value: null,
        });
      }
    });
  };

  const modal_delete_feature_content = modal_delete_feature && (
    <Modal
      modalSize="small"
      id="modal"
      isOpen={modal_delete_feature}
      onClose={toggle_delete_feature}
    >
      <main>
        <p className="text_bold">
          {dict?.["Are you sure to delete this feature?"]?.[language]}
        </p>
        <br />
        <p className="text_bold">
          {dict?.["This process can't be undone."]?.[language]}
        </p>
        <br />
        <br />
        <br />
        <button
          className="button background_red"
          style={{ marginTop: "1rem" }}
          onClick={handle_delete_feature}
        >
          {dict?.["Delete"]?.[language]}
        </button>
      </main>
    </Modal>
  );

  const modal_fullscreen_content = modal_edit_feature_properties && (
    <Modal
      title="Edit detail"
      modalSize="large"
      id="modal_edit_feature_properties"
      isOpen={modal_edit_feature_properties}
      onClose={close_modal_fullscreen}
      content={
        <main className="marginRight_10 min_h_500 center_perfect">
          <div className="modal_content flex flex_col gap_10">
            {type_2_selected === "banjir" && (
              <>
                <button
                  className="button margin_bottom background_blue"
                  onClick={on_get_genangan}
                >
                  Luas genangan banjir
                </button>
                <button
                  className="button margin_bottom background_blue"
                  onClick={toggle_share}
                >
                  Share
                </button>
              </>
            )}
            {rendered_input_field?.length > 0 &&
              rendered_input_field?.[page - 1]}
          </div>
        </main>
      }
      footer={
        <footer className="flex justify_between padding_5 marginLeft_20 marginRight_20 border_top_grey w_full">
          <Pagination
            count={rendered_input_field?.length || 0}
            page={page}
            onChange={on_change_page}
            showFirstButton
            showLastButton
          />
          {is_editable && (
            <div className="flex gap_20">
              <button
                onClick={toggle_delete_feature}
                className="center_perfect pointer hover_bigger"
              >
                <img src={icon_delete_2} alt="Delete Feature" />
              </button>
              <button
                onClick={toggle_mode_edit}
                className="bg_blue text_white button_inactive rounded_5 padding_5"
              >
                Edit : {is_mode_edit ? "ON" : "OFF"}
              </button>
            </div>
          )}
        </footer>
      }
    />
  );
  return (
    <>
      {modal_fullscreen_content}
      {modal_delete_feature_content}
    </>
  );
};

export default POPUP_EDITOR;
