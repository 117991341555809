import React, { useEffect, useState } from "react";
import icon_refresh from "../../Assets/svg/icon_restore.svg";
import icon_delete from "../../Assets/svg/icon_delete_red_outline_white.svg";
import Modal from "../common_modal/Modal";
import useLanguage from "../common_hooks/useLanguage";

function RestoreAndDeleteButton(props) {
  const {
    actived_tab,
    delete_permanent,
    content,
    toggle_modal_restore_confirmation,
  } = props;

  const { dict, language } = useLanguage();

  const [modal_delete_confirmation, set_modal_delete_confirmation] =
    useState(false);

  /** toggle untuk modal DELETE confirmation */
  const toggle_modal_delete_confirmation = (value) => {
    if (value === false || value === true) {
      set_modal_delete_confirmation(value);
    } else {
      set_modal_delete_confirmation(!modal_delete_confirmation);
    }
  };

  /** Component will unmount - untuk memastikan modal tertutup */
  useEffect(() => {
    return () => {
      toggle_modal_delete_confirmation(false);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const modal_delete_confirmation_content = modal_delete_confirmation && (
    <Modal
      modalSize="small"
      id="modal"
      isOpen={modal_delete_confirmation}
      onClose={toggle_modal_delete_confirmation}
    >
      <main className="center_perfect">
        <div>
          <label className="center_perfect bold font_16 marginBottom_10 text_center">
            {actived_tab === "project"
              ? dict[
                  "Are you sure you want to permanently delete the project and its layers?"
                ][language]
              : dict["Are you sure you want to permanently remove this layer?"][
                  language
                ]}
          </label>
          <section className="center_perfect">
            <div className="flex gap_5">
              <button
                onClick={toggle_modal_delete_confirmation}
                className="bg_darkGrey padding_y_5 padding_x_10 rounded_5 text_white"
              >
                {dict["Cancel"][language]}
              </button>
              <button
                onClick={async () => {
                  await delete_permanent({
                    with_layers: true,
                    id: content?._id,
                  });
                  toggle_modal_delete_confirmation();
                }}
                className="background_red padding_y_5 padding_x_10 rounded_5 text_white"
              >
                {dict["Delete"][language]}
                {actived_tab === "project" && (
                  <>
                    {" "}
                    <span className="bold underline">
                      {dict["with"][language]}
                    </span>
                  </>
                )}
                {actived_tab === "project" && " " + dict["Layers"][language]}
              </button>
              {actived_tab === "project" && (
                <button
                  onClick={async () => {
                    await delete_permanent({
                      with_layers: false,
                      id: content?._id,
                    });
                    toggle_modal_delete_confirmation();
                  }}
                  className="background_red padding_y_5 padding_x_10 rounded_5 text_white"
                >
                  {dict["Delete"][language]}{" "}
                  <span className="bold underline">
                    {dict["without"][language]}
                  </span>{" "}
                  {dict["Layers"][language]?.toLowerCase()}
                </button>
              )}
            </div>
          </section>
        </div>
      </main>
    </Modal>
  );

  return (
    <>
      <div className="flex gap_5 align_start">
        <button
          onClick={() => {
            toggle_modal_restore_confirmation(content);
          }}
          className="hover_bigger"
          title="Recover"
        >
          <img src={icon_refresh} alt="Recover" className="h_20 w_20" />
        </button>
        <button
          onClick={toggle_modal_delete_confirmation}
          className="hover_bigger"
        >
          <img src={icon_delete} alt="Delete" className="h_20 w_20" />
        </button>
      </div>
      {modal_delete_confirmation_content}
    </>
  );
}

export default RestoreAndDeleteButton;
