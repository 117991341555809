/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

/*COMPONENT*/
import Login from "../auth/Login";
import Register from "../auth/Register";
import CREATE_PROJECT from "../project_dashboard/CREATE_PROJECT";

/*REDUX*/
import {
  clone_layer,
  set_value_properties,
} from "../../App/actions/propertiesActions";
import { attachLayer } from "../../App/actions/layerNewActions";

/*PICTURE*/

/*GENERAL*/

/*DATA*/
import dict from "../../Data/dict.json";

/*CONST*/
const WAIT_INTERVAL = 1000;
const ENTER_KEY = 13;

class IMPORT_LAYER_VIA_SEARCH extends Component {
  state = {
    project_id: "",
    project_link: "",
    create_project_status: false,
    import_progress: false,
    project_filtered: [],
    layer_filtered: [],
  };

  toggleLoginRegister = () => {
    this.setState({ login_status: !this.state.login_status });
  };

  toggle_create_project = () => {
    this.setState({ create_project_status: !this.state.create_project_status });
  };

  onClickProject = (project_id, project_link) => {
    this.setState({ project_id, project_link });
  };

  handlePushProject = () => {
    this.setState({ import_progress: true });
    const { project_id } = this.state;
    const { layer_id } = this.props.layer;
    const geo_layer = {
      _id: layer_id,
    };
    const body = {
      geo_project_id: project_id,
      geo_layer,
    };
    this.props.attachLayer(body);
  };

  handleChangeProject = (e) => {
    e.preventDefault();
    const target = e.target;
    const value = target.value;
    const name = target.name;
    clearTimeout(this.timer);
    this.setState({
      [name]: value,
    });
    this.timer = setTimeout(this.triggerProject(), WAIT_INTERVAL);
  };

  handleKeyDownProject(e) {
    if (e.keyCode === ENTER_KEY) {
      this.triggerProject();
    }
  }

  on_clone_layer = () => {
    this.setState({ import_progress: true });
    const { project_id } = this.state;
    const { geo_layer_list, layer_id } = this.props.layer;
    const { user } = this.props.auth;
    const geo_layer = geo_layer_list.find(
      (item) => item?.geo_layer?._id === layer_id
    )?.geo_layer;

    const geo_layer_id = geo_layer?._id;
    const new_name = geo_layer?.name;
    const geo_project_id = project_id;
    const user_id = user?._id;

    if (geo_layer_id && new_name && geo_project_id && user_id) {
      const body = {
        geo_layer_id,
        new_name,
        geo_project_id,
        user_id,
      };
      this.props.clone_layer(body);
    }
  };

  triggerProject = () => {
    const search_params_project = this?.state?.search_params_project || "";
    const { geo_project_list } = this.props.project;
    const project_filtered = geo_project_list.filter(
      (e) =>
        e.name.toLowerCase().includes(search_params_project.toLowerCase()) ||
        e.description
          .toLowerCase()
          .includes(search_params_project.toLowerCase())
    );
    this.setState({ project_filtered });
  };

  render() {
    //local storage
    const language = localStorage?.language || "ina";

    //local state
    const {
      login_status,
      project_id,
      project_link,
      create_project_status,
      import_progress,
      search_params_project,
      project_filtered,
    } = this.state;

    //global props
    const { geo_project_list } = this.props.project;
    const { geo_layer_list, layer_id } = this.props.layer;
    const { isRegistered, isAuthenticated } = this.props.auth;
    const { loading_item, loading_status } = this.props.properties;

    //content

    const geo_layer = geo_layer_list.find(
      (item) => item?.geo_layer?._id === layer_id
    )?.geo_layer;
    let project_final = project_filtered;
    if (!search_params_project) {
      project_final = geo_project_list;
    }

    let modal_import_content = (
      <main>
        <section className="inline_block margin_bottom margin_right container_light background_grey_light">
          <div className="button_pill_normal margin_bottom" id="blue_light">
            {dict["From"][language]}
          </div>
          <p className="text_bold">Data layer yang akan diimport:</p>
          <div className="badge_circle margin_right checklist_active" />
          <p className="text_medium inline">{geo_layer?.name}</p>
        </section>
        <section
          className="inline_block container_light margin_bottom background_grey_light"
          style={{ overflowY: "hidden" }}
        >
          <div className="button_pill_normal margin_bottom" id="blue_light">
            {dict["To"][language]}
          </div>
          <p className="text_bold">
            Pilih lokasi proyek penyimpanan data layer:
          </p>
          <input
            id="search_params_project"
            name="search_params_project"
            type="text"
            value={search_params_project}
            onChange={this.handleChangeProject}
            onKeyDown={this.handleKeyDownProject}
            placeholder={
              dict["Filter list of project by name or description"][language]
            }
            autoComplete="off"
            style={{
              marginTop: "5px",
            }}
          />
          <div
            className="container_light background_white"
            style={{
              overflowY: "auto",
              overflowX: "hidden",
              height: "calc(( 85vh - 350px ))",
              minHeight: "100px",
            }}
          >
            {project_final.map(({ _id, name, link }, idx) => {
              const item_id = project_id === _id ? "" : _id;
              const item_link = project_id === _id ? "" : link;
              return (
                <main
                  key={idx}
                  onClick={this.onClickProject.bind(this, item_id, item_link)}
                  className="margin_bottom"
                >
                  <button className="two_container_auto">
                    <div
                      className={
                        project_id === _id
                          ? "checklist_active"
                          : "checklist_not"
                      }
                    />
                    <p className="text_medium text_left margin_left">{name}</p>
                  </button>
                </main>
              );
            })}
          </div>
          <br />
          <p className="text_bold margin_top margin_bottom">
            Atau buat proyek baru:
          </p>
          <button
            onClick={this.toggle_create_project}
            className="button margin_bottom background_black"
          >
            Buat proyek baru
          </button>
        </section>
        <br />
        <main style={{ display: "grid" }}>
          {project_id ? (
            <section style={{ margin: "auto" }}>
              <div className="inline_block container_light margin_right margin_bottom background_grey_light">
                <button
                  onClick={this.on_clone_layer}
                  className="button margin_bottom background_black"
                >
                  Impor layer
                </button>
                <p className="text_inferior text_left">
                  {dict?.["import_definition"]?.[language]}
                </p>
              </div>

              <div className="inline_block container_light margin_bottom background_grey_light">
                <button
                  onClick={this.handlePushProject}
                  className="button margin_bottom background_black"
                >
                  Lampirkan layer
                </button>
                <p className="text_inferior text_left">
                  {dict?.["attach_definition"]?.[language]}
                </p>
              </div>
            </section>
          ) : (
            <div
              className="button background_blue"
              id="red"
              style={{ margin: "auto" }}
            >
              {
                dict["Please select the storage destination project first"][
                  language
                ]
              }
            </div>
          )}
        </main>
      </main>
    );

    if (!isAuthenticated) {
      modal_import_content = (
        <main>
          {login_status || isRegistered ? <Login isNav={true} /> : <Register />}
          {login_status || isRegistered ? (
            <div style={{ display: "block", marginTop: "30px" }}>
              <div className="button_account">
                {dict["Don't have an account?"][language]}
              </div>
              <div
                className="button_register"
                onClick={this.toggleLoginRegister}
              >
                {dict["Register here"][language]}
              </div>
            </div>
          ) : (
            <div style={{ display: "block", marginTop: "30px" }}>
              <div className="button_account">
                {dict["Aready have an account?"][language]}
              </div>
              <div
                className="button_register"
                onClick={this.toggleLoginRegister}
              >
                {dict["Login instead"][language]}
              </div>
            </div>
          )}
        </main>
      );
    } else if (create_project_status) {
      modal_import_content = (
        <main className="text_left">
          <div
            style={{
              width: "500px",
              maxWidth: "calc( 100vw - 30px)",
              margin: "auto",
            }}
          >
            <button
              onClick={this.toggle_create_project}
              className="button background_blue"
              id="blue"
            >
              {dict?.["Back"]?.[language]}
            </button>
            <br />
            <br />
            <br />
            <br />
            <CREATE_PROJECT
              toggle_create_project={this.toggle_create_project}
            />
          </div>
        </main>
      );
    } else if (import_progress) {
      modal_import_content = (
        <main
          style={{
            width: "500px",
            maxWidth: "calc( 100vw - 30px)",
            margin: "auto",
          }}
        >
          <h1 className="text_header margin_bottom">Proses impor</h1>

          {loading_status && loading_item === "clone_layer" ? (
            <div className="container_light margin_bottom">
              Mengimpor layer ke proyek Anda...
            </div>
          ) : (
            <div>
              <p className="container_light margin_bottom">Impor selesai</p>
              <Link
                to={`/editor/${project_link}`}
                className="button background_blue"
                target="_blank"
                rel="noopener noreferrer"
              >
                Edit Data Sekarang
              </Link>
            </div>
          )}
        </main>
      );
    }

    return (
      <main className="text_center max_height">{modal_import_content}</main>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  layer: state.layer,
  project: state.project,
  map: state.map,
  properties: state.properties,
});

export default connect(mapStateToProps, {
  clone_layer,
  set_value_properties,
  attachLayer,
})(IMPORT_LAYER_VIA_SEARCH);
