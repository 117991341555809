import React, { Component } from "react";
import { FileUploader } from "react-drag-drop-files";
import dict from "../../Data/dict.json";
import { handle_upload_geojson } from "../../App/validation/handle_file";
import { StyledDataGrid } from "../../Style/trivial/StyledDataGrid";
import Modal from "../common_modal/Modal";
import GeojsonDetails from "../editor_upload_data/GeojsonDetails";
import { push_features } from "../../App/actions/layerNewActions";
import { pushField } from "../../App/actions/layerActions";
import {
  match_the_fields_and_features,
  standardize_fields_key_as_name,
  standardize_fields_key_as_uuid,
  standardize_geojson_features,
} from "../../App/validation/generateGeoJson";
import CheckBox from "../common_input/CheckBox";
import { connect } from "react-redux";
import icon_download from "../../Assets/svg/icon_download.svg";
import icon_full_screen_active from "../../Assets/svg/icon_full_screen_active.svg";

const limit = 100;

class AddRowsBulkMultipleFiles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      refresh: false,
      is_detail_open: false,
      is_uploading: false,
      current_fields: [],
      match_key: [],
      doesnt_match_key: [],
      detail_index: "",
      is_add_unselected_columns: false,
      modal_fullscreen: false,
    };
  }

  componentDidMount = () => {
    let { match_key } = this.state;
    const current_fields = this.get_current_fields();
    if (match_key?.length === 0) {
      match_key = current_fields?.map((item) => {
        return {
          uuid: item?.uuid,
          current_field: item?.name,
          current_field_key: item?.key,
          upload_field_name: "",
        };
      });
    }
    // kalo kosong toggle aktif
    if (current_fields?.length === 0) {
      this.toggle_add_unselected_columns();
    }

    this.setState({
      current_fields,
      match_key,
    });
  };

  get_current_fields = () => {
    const { geo_layer } = this.props;
    const current_fields = this.convertFieldsToDataGrid(geo_layer?.fields);
    return current_fields;
  };

  toggle_refresh = () => {
    const { refresh } = this.state;
    this.setState({
      refresh: !refresh,
    });
  };

  toggle_fullscreen = () => {
    const { modal_fullscreen } = this.state;
    this.setState({
      modal_fullscreen: !modal_fullscreen,
    });
  };

  get_field_that_similar_name = (row, uploaded_file, callback) => {
    // const { uploaded_file } = this.state;
    const result = uploaded_file?.headers?.find(
      (field) =>
        field?.trim().toLowerCase() === row?.current_field?.trim().toLowerCase()
    );
    if (callback) {
      callback(row?.current_field_key, result);
    }
  };

  convertFieldsToDataGrid(fields) {
    const data_list = fields?.map((feature, index) => {
      return {
        ...feature,
        id: index,
        no: `${index + 1}.`,
        currentFields: feature?.["name"],
      };
    });
    return data_list;
  }

  handleChangeGeojsonFiles = async (files_object) => {
    const { match_key } = this.state;
    const { files_geojson, set_files_geojson } = this.props;
    let files = [];
    for (let key of Object.keys(files_object)) {
      const file = files_object[key];
      const new_file = await this.handleValidationFiles(file);
      if (new_file) {
        files.push(new_file);
      }
    }

    files.sort((a, b) =>
      a.fileName > b.fileName ? 1 : b.fileName > a.fileName ? -1 : 0
    );

    set_files_geojson([...files_geojson, ...files]);

    this.setState(
      {
        doesnt_match_key: files?.[0]?.headers || [],
      },
      () => {
        if (
          match_key?.length > 0 &&
          files_geojson?.length === 0 &&
          files?.length > 0
        ) {
          for (let field of match_key) {
            this.get_field_that_similar_name(
              field,
              files[0],
              this.set_selected_field
            );
          }
        }
      }
    );
  };

  handleValidationFiles = async (file) => {
    const filename = file.name.split(".")[0];
    const ext = file.name.split(".").at(-1);
    const { files_geojson } = this.props;
    const is_any = files_geojson.find(
      (element) => element.fileName === filename
    );

    if (is_any === undefined && ext?.toLowerCase() === "geojson") {
      let new_file = await handle_upload_geojson(file);

      if (
        new_file?.validation_result?.is_valid_in_general &&
        new_file?.validation_result?.invalid_number > 0 &&
        new_file?.validation_result?.valid_number > 0
      ) {
        new_file["status"] = "semi-valid";
      } else if (new_file?.validation_result?.is_valid_in_general) {
        new_file["status"] = "valid";
      } else {
        new_file["status"] = "invalid";
      }

      return new_file;
    } else {
      return undefined;
    }
  };

  set_selected_field = (key, upload_field_name) => {
    let { match_key } = this.state;
    const index = match_key?.findIndex(
      (field) => field.current_field_key === key
    );
    if (index > -1) {
      if (upload_field_name) {
        this.set_doesnt_match_key("", index);
      }

      match_key[index]["upload_field_name"] = upload_field_name || "";
      this.setState(
        {
          match_key,
        },
        () => {
          this.set_doesnt_match_key();
        }
      );
    }
  };

  set_doesnt_match_key = () => {
    const { match_key } = this.state;
    const { files_geojson } = this.props;
    const filled = match_key
      ?.filter((item) => item?.upload_field_name)
      .map((item) => item?.upload_field_name);
    const doesnt_match_key = files_geojson?.headers?.filter(
      (header) => !filled?.includes(header)
    );
    this.setState({
      doesnt_match_key,
    });
  };

  removeGeojson = (filename) => {
    const { files_geojson } = this.props;
    const new_files_geojson = files_geojson.filter(
      (file) => file?.fileName !== filename
    );
    this.props.set_files_geojson(new_files_geojson);
    // this.setState({ files_geojson: new_files_geojson });
  };

  saveGeojsonFilesToDatabase = async () => {
    const { files_geojson } = this.props;

    this.setState({ is_uploading: true }, async () => {
      const filtered_files_geojson = files_geojson.filter(
        (file) =>
          file.validation_result?.is_valid_in_general &&
          (file.status === "valid" ||
            file.status === "failed" ||
            file.status === "semi-valid")
      );
      await this.loop_every_file(filtered_files_geojson);
    });
  };

  loop_every_file = async (filtered_files_geojson) => {
    const { user_id, geo_layer, is_key_uuid } = this.props;
    const {
      match_key,
      is_add_unselected_columns,
      doesnt_match_key = [],
      current_fields,
    } = this.state;

    let other_fields = [];
    if (is_add_unselected_columns) {
      if (is_key_uuid) {
        other_fields = standardize_fields_key_as_uuid(doesnt_match_key);
      } else {
        other_fields = standardize_fields_key_as_name(doesnt_match_key);
      }

      // bila field baru punya key yang sama dengan field lama,
      // maka field baru dibuang (biar gak bikin error)
      other_fields = other_fields.filter((el) => {
        return !current_fields.some((f) => {
          return f.key === el.key;
        });
      });

      for (let field of other_fields) {
        const body = {
          geo_layer_id: geo_layer?._id,
          field: field,
        };

        await this.props.pushField(body);
      }

      this.setState({
        current_fields: this.get_current_fields(),
      });
    }

    for (let file of filtered_files_geojson) {
      let features = [];
      if (is_add_unselected_columns) {
        features = match_the_fields_and_features(
          [...match_key, ...other_fields],
          file?.data?.features
        );
      } else {
        features = match_the_fields_and_features(
          [...match_key],
          file?.data?.features
        );
      }

      features = standardize_geojson_features({
        file: features,
        user_id: user_id,
      });

      await this.batch_upload_features(file, features);
    }

    this.setState({ is_uploading: false });
  };

  get_limit_by_geometry_type(geometry_type, n_features, default_limit) {
    let current_limit = default_limit;
    if (geometry_type === "Point" || geometry_type === "MultiPoint") {
      if (n_features < 100) {
        current_limit = Math.floor(default_limit / 2);
      }
    } else if (
      geometry_type === "Polygon" ||
      geometry_type === "MultiPolygon"
    ) {
      if (n_features > 1000) {
        current_limit = Math.floor(default_limit / 2);
      } else if (1000 >= n_features && n_features > 100) {
        current_limit = Math.floor(default_limit / 4);
      } else if (100 >= n_features && n_features > 5) {
        current_limit = 10;
      } else if (n_features <= 5) {
        current_limit = 1;
      }
    } else {
      if (n_features > 1000) {
        current_limit = default_limit;
      } else if (1000 >= n_features && n_features > 100) {
        current_limit = Math.floor(default_limit / 2);
      } else if (100 >= n_features && n_features > 5) {
        current_limit = Math.floor(default_limit / 4);
      } else if (n_features <= 5) {
        current_limit = 10;
      }
    }

    return current_limit;
  }

  batch_upload_features = async (file, features) => {
    const { geo_layer } = this.props;
    const n_features = features?.length;
    const geometry_type = features?.[0].type;
    const current_limit = this.get_limit_by_geometry_type(
      geometry_type,
      n_features,
      limit
    );
    let error_features = [];
    let success_upload = 0;

    // looping upload sebanyak limit
    for (let index = 0; index < n_features; index += current_limit) {
      const batch_features = features?.slice(index, index + current_limit);
      if (batch_features?.length === 0) break;

      const body = {
        geo_layer_id: geo_layer?._id,
        features: batch_features,
      };

      const progressPercent = parseInt((index / n_features) * limit);
      const loading_props = {
        title: file?.fileName,
        progressPercent,
        index,
        total_length: n_features,
      };
      const res = await this.props.push_features(body, loading_props);
      if (res?.status !== 200) {
        error_features.push(...batch_features);
      } else if (res?.data?.features_failed?.length > 0) {
        error_features.push(...res?.data?.features_failed);
      }

      if (res?.status === 200) {
        success_upload =
          success_upload +
          batch_features?.length -
          res?.data?.features_failed?.length;
      }
    }

    file.success_upload = success_upload;
    file.data.features = [...error_features];

    if (error_features?.length === 0) {
      this.setIsBatchGeojsonUploaded(file.fileName, "success");
    } else {
      this.setIsBatchGeojsonUploaded(file.fileName, "failed");
    }
  };

  setIsBatchGeojsonUploaded = (filename, value) => {
    const { files_geojson } = this.props;
    const index = files_geojson.findIndex((file) => file.fileName === filename);
    if (index !== -1 && files_geojson?.length > 0) {
      files_geojson[index]["status"] = value;
      if (value === "success") {
        delete files_geojson?.[index]?.["file"];
      }
    }
    this.props.set_files_geojson(files_geojson);
    this.toggle_refresh();
  };

  toggleModalDetail = (value, idx) => {
    this.setState({
      is_detail_open: value || !this.state.is_detail_open,
      detail_index: idx,
    });
  };

  toggle_add_unselected_columns = () => {
    const { is_add_unselected_columns } = this.state;
    this.setState({ is_add_unselected_columns: !is_add_unselected_columns });
  };

  clear_files_geojson = () => {
    this.props.set_files_geojson([]);
    this.setState({
      success_upload: 0,
    });
  };

  get_columns = (uploaded_file, match_key, set_selected_field_callback) => {
    const language = localStorage?.language ? localStorage?.language : "ina";

    return [
      {
        field: "no",
        headerName: "No.",
        editable: false,
        flex: 1,
        type: "number",
      },
      {
        field: "currentFields",
        headerName: "Current Fields",
        editable: false,
        flex: 6,
      },
      {
        field: "newFields",
        headerName: "New Fields",
        editable: false,
        flex: 6,
        renderCell: (params) => {
          const value = match_key?.find(
            (f) => f?.current_field_key === params?.row?.key
          )?.["upload_field_name"];

          const selection = uploaded_file?.headers?.length > 0 && (
            <select
              className="dropdown_in_table"
              value={value}
              onChange={(e) => {
                set_selected_field_callback(params?.row?.key, e?.target?.value);
              }}
            >
              <option value="">{dict["Select"][language]}</option>
              {uploaded_file?.headers?.map((option, index) => (
                <option key={index} value={option}>
                  {option}
                </option>
              ))}
            </select>
          );
          return selection;
        },
      },
    ];
  };

  download_rest_of_features = (features, fileName) => {
    const data = {
      type: "FeatureCollection",
      features: features?.map((feature) => {
        const { type, properties, geometry } = feature;
        return {
          type,
          properties,
          geometry,
        };
      }),
    };

    // create file in browser
    fileName = `(FAILED) ${fileName}`;
    const json = JSON.stringify(data, null, 2);
    const blob = new Blob([json], { type: "application/json" });
    const href = URL.createObjectURL(blob);

    // create "a" HTLM element with href to file
    const link = document.createElement("a");
    link.href = href;
    link.download = fileName + ".geojson";
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  };

  render() {
    const language = localStorage?.language ? localStorage?.language : "ina";
    const {
      is_uploading,
      // files_geojson,
      current_fields,
      match_key,
      is_detail_open,
      detail_index,
      is_add_unselected_columns,
      modal_fullscreen,
    } = this.state;
    const { files_geojson } = this.props;

    const total_features = files_geojson?.reduce((accumulator, file) => {
      return accumulator + file.validation_result?.valid_number || 0;
    }, 0);

    const total_success = files_geojson?.reduce((accumulator, file) => {
      return accumulator + file.success_upload || 0;
    }, 0);

    const geojson_list_content = (
      <ul className="card_parent w_full">
        {files_geojson?.map((file, idx) => {
          let color_status;
          if (file.status === "valid") {
            color_status = "#0ca5eb";
          } else if (file.status === "semi-valid") {
            color_status = "#f25a1d";
          } else if (file.status === "invalid") {
            color_status = "#d41616";
          } else if (file.status === "success") {
            color_status = "#44a889";
          } else if (file.status === "failed") {
            color_status = "#d41616";
          } else {
            color_status = "#616161";
          }

          return (
            <li
              className="card_list bg_lightGrey grid grid_15 padding_2"
              key={idx}
            >
              <div className="font_14 center_perfect">{`${idx + 1}.`}</div>
              <div className="text_left flex align_center span_10">
                {file.fileName}
              </div>
              <div
                className="span_4"
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "10px",
                }}
              >
                <div className="center_perfect">
                  {file?.data?.features?.length}
                </div>
                <div
                  className="button center_perfect bg_darkYellow"
                  onClick={() => {
                    this.toggleModalDetail(true, idx);
                  }}
                >
                  i
                </div>
                <div
                  className="container_card center_perfect"
                  style={{
                    background: color_status,
                    color: "#ffffff",
                  }}
                >
                  {file.status}
                </div>
                {file?.status === "failed" && (
                  <div
                    className="pointer"
                    onClick={() =>
                      this.download_rest_of_features(
                        [
                          ...file?.data?.features,
                          ...file?.validation_result?.invalid_features,
                        ],
                        file?.fileName
                      )
                    }
                  >
                    <img
                      color="none"
                      alt="download"
                      src={icon_download}
                      className="w_full h_full"
                    />
                  </div>
                )}
                <button
                  className="center_perfect"
                  style={{
                    background: !is_uploading ? "#d41616" : "#e4e4e4",
                    color: "#ffffff",
                    height: "35px",
                    width: "35px",
                    borderRadius: "5px",
                    fontSize: "20px",
                    cursor: !is_uploading ? "pointer" : "default",
                  }}
                  onClick={() => {
                    if (!is_uploading) {
                      this.removeGeojson(file.fileName);
                    }
                  }}
                >
                  x
                </button>
              </div>
            </li>
          );
        })}
      </ul>
    );

    const modal_fullscreen_content = modal_fullscreen && (
      <Modal
        modalSize="large"
        id="modal_fullscreen"
        isOpen={modal_fullscreen}
        onClose={this.toggle_fullscreen}
      >
        <main className="box-body h_vh scroll_y_auto">
          {modal_fullscreen && geojson_list_content}
        </main>
      </Modal>
    );

    const modalJsonDetailContent = is_detail_open && (
      <Modal
        modalSize="small"
        id="modal"
        isOpen={is_detail_open}
        onClose={this.toggleModalDetail}
      >
        <main className="box-body center_perfect">
          <div>
            <div>
              <GeojsonDetails
                geojson_list={files_geojson}
                index={detail_index}
              />
            </div>
          </div>
        </main>
      </Modal>
    );

    return (
      <main className="paddingBottom_20 paddingRight_20 paddingLeft_20">
        <section>
          <h1>Batch Upload</h1>
        </section>
        <section className="center_perfect marginBottom_10">
          <FileUploader
            classes="container_upload absolute border_dash"
            multiple={true}
            handleChange={(e) => this.handleChangeGeojsonFiles(e)}
            name="file"
            types={["GEOJSON"]}
            children={
              <div className="container_upload center_perfect">
                <div>Drop geojson files here</div>
              </div>
            }
          />
        </section>
        <section className="h_300">
          <StyledDataGrid
            rows={current_fields || []}
            columns={this.get_columns(
              files_geojson?.[0],
              match_key,
              this.set_selected_field
            )}
            getRowHeight={() => 35}
            rowsPerPageOptions={[25, 50, 100, 500, 1000]}
            disableColumnMenu
          />
        </section>
        <section className="w_full flex justify_between marginTop_10 marginBottom_10">
          <div className="flex justify_between">
            <CheckBox
              text="isIncludeAnotherColumns"
              title={dict["Also add unselected columns"][language]}
              value={is_add_unselected_columns}
              handle={this.toggle_add_unselected_columns}
            />
          </div>
          <div className="flex gap_5">
            <div className="no_wrap center_perfect">{`Success Uploaded: ${total_success} | Total Features: ${total_features}`}</div>
            {files_geojson?.length > 0 && (
              <button
                className="button_inactive bg_blue rounded_5 text_white center_perfect flex"
                onClick={this.toggle_fullscreen}
              >
                <img
                  src={icon_full_screen_active}
                  color="#ffffff"
                  alt="fullscreen"
                  className="w_20 h_20 margin_5"
                />
              </button>
            )}
          </div>
        </section>
        <section className="max_h_300 scroll_y_auto">
          {!modal_fullscreen && geojson_list_content}
        </section>
        <section className="flex justify_between marginTop_5">
          <div></div>
          {files_geojson?.length > 0 && (
            <button
              className="button_inactive background_red rounded_5 text_white padding_5"
              onClick={this.clear_files_geojson}
            >
              Clear All
            </button>
          )}
        </section>
        <section className="marginTop_10 center_perfect align_center">
          {files_geojson?.length > 0 && (
            <button
              onClick={() => {
                if (!is_uploading) {
                  this.saveGeojsonFilesToDatabase();
                }
              }}
              style={{
                cursor: is_uploading ? "default" : "pointer",
                background: is_uploading ? "#c4c4c4" : "#0ca5eb",
              }}
              className="button background_blue"
            >
              Upload
            </button>
          )}
        </section>

        {modalJsonDetailContent}
        {modal_fullscreen_content}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {
  pushField,
  push_features,
})(AddRowsBulkMultipleFiles);
