/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*COMPONENT*/

/*REDUX*/
import {
  push_layer_sidebar_config_list,
  edit_layer_sidebar_config,
  edit_inside_object,
} from "../../App/actions/biActions";

/*PICTURE*/

/*FUNCTION*/

/*DATA*/
import dict from "../../Data/dict.json";

/*CONST*/

class BI_EDIT_LAYER_CONFIG extends Component {
  state = {};

  on_toggle = (key) => {
    const { layer_sidebar_config_object } = this.props.bi;
    this.props.edit_inside_object({
      key: "layer_sidebar_config_object",
      id_key: key,
      value: !layer_sidebar_config_object[key],
    });
  };

  on_change = (e) => {
    const id_key = e.target.name;
    let value = e.target.value;
    if (id_key === "n_field_to_show") {
      value = Number(value);
    }
    this.props.edit_inside_object({
      key: "layer_sidebar_config_object",
      id_key: id_key,
      value: value,
    });
  };

  on_toggle_array_item = (item, value) => {
    const { layer_sidebar_config_object } = this.props.bi;
    let array = layer_sidebar_config_object[item] || [];
    if (array.includes(value)) {
      array = array.filter((element) => element !== value);
    } else {
      array.push(value);
    }
    this.props.edit_inside_object({
      key: "layer_sidebar_config_object",
      id_key: item,
      value: array,
    });
  };

  on_submit = () => {
    const { layer_sidebar_config_object, bi_object } = this.props.bi;
    const { layer_id } = layer_sidebar_config_object;
    const layer_id_edit = this.props.bi.layer_id;
    let body = {
      _id: bi_object._id,
      layer_sidebar_config: layer_sidebar_config_object,
    };
    if (!layer_id) {
      body.layer_sidebar_config.layer_id = layer_id_edit;
      delete body.layer_sidebar_config._id;
      this.props.push_layer_sidebar_config_list(body);
    } else {
      body = { ...body, layer_id: layer_id_edit };
      this.props.edit_layer_sidebar_config(body);
    }
  };

  render() {
    //local storage
    const language = localStorage?.language || "ina";

    //local state

    //global props
    const { layer_sidebar_config_object } = this.props.bi;
    const fields = this.props.fields || [];
    const { loading_item_array } = this.props.loading_reducer;

    //content
    const {
      is_use_image,
      array_image_field_to_show,
      field_show_mode,
      n_field_to_show,
      array_text_to_show,
      mode_layout,
    } = layer_sidebar_config_object;

    const fields_text = fields.filter((item) => item.type !== "image");
    const fields_image = fields.filter((item) => item.type === "image");

    const mode_array = [
      {
        name: dict?.["Show the first few columns"]?.[language],
        key: "only_first_n_field",
      },
      {
        name: dict?.["Custom"]?.[language],
        key: "custom",
      },
    ];
    const mode_layout_array = [
      {
        name: dict?.["Table"]?.[language],
        key: "table_1",
      },
      {
        name: dict?.["Widget card"]?.[language],
        key: "card_1",
      },
    ];

    return (
      <main
        style={{ maxHeight: "calc( 100vh - 200px )", overflowY: "auto" }}
        className="container_light background_white outline_black"
      >
        <main className="container_light margin_bottom">
          <section
            className="two_container_list margin_bottom"
            onClick={this.on_toggle.bind(this, "is_use_image")}
          >
            <button
              className={is_use_image ? "checklist_active" : "checklist_not"}
            />
            <p className="text_medium">{dict?.["Show image"]?.[language]}</p>
          </section>

          {is_use_image && (
            <section className="margin_bottom">
              <p className="text_medium">
                {dict?.["Select the image column"]?.[language]}
              </p>
              <div
                style={{ maxHeight: "calc( 50vh - 200px )", overflowY: "auto" }}
              >
                <table className="table full_width">
                  <tbody>
                    {fields_image.map((item, idx) => {
                      return (
                        <tr key={idx}>
                          <td style={{ width: "20px" }}>
                            <button
                              className={
                                array_image_field_to_show.includes(item?.key)
                                  ? "checklist_active"
                                  : "checklist_not"
                              }
                              onClick={this.on_toggle_array_item.bind(
                                this,
                                "array_image_field_to_show",
                                item?.key
                              )}
                            />
                          </td>
                          <td>{item?.name}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </section>
          )}
        </main>

        <section className="container_light margin_bottom">
          <p className="text_medium">
            {dict?.["Displayed column attribute options"]?.[language]}
          </p>
          <select
            name="field_show_mode"
            value={field_show_mode}
            onChange={this.on_change}
          >
            {mode_array.map((item, idx) => {
              return (
                <option key={idx} value={item.key}>
                  {item.name}
                </option>
              );
            })}
          </select>
        </section>

        {field_show_mode === "only_first_n_field" ? (
          <section className="container_light margin_bottom">
            <p className="text_medium">
              {dict?.["The number of columns displayed"]?.[language]}
            </p>
            <input
              type="number"
              name="n_field_to_show"
              value={n_field_to_show}
              onChange={this.on_change}
            />
          </section>
        ) : (
          <section className="container_light margin_bottom">
            <p className="text_medium">
              {dict?.["Field to be displayed"]?.[language]}
            </p>
            <div
              style={{ maxHeight: "calc( 50vh - 200px )", overflowY: "auto" }}
            >
              <table className="table full_width">
                <tbody>
                  {fields_text.map((item, idx) => {
                    return (
                      <tr key={idx}>
                        <td style={{ width: "20px" }}>
                          <button
                            className={
                              array_text_to_show.includes(item?.key)
                                ? "checklist_active"
                                : "checklist_not"
                            }
                            onClick={this.on_toggle_array_item.bind(
                              this,
                              "array_text_to_show",
                              item?.key
                            )}
                          />
                        </td>
                        <td>{item?.name}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </section>
        )}

        <section className="container_light margin_bottom">
          <p className="text_medium">
            {dict?.["Select visual mode"]?.[language]}
          </p>
          <select
            name="mode_layout"
            value={mode_layout}
            onChange={this.on_change}
          >
            {mode_layout_array.map((item, idx) => {
              return (
                <option key={idx} value={item.key}>
                  {item.name}
                </option>
              );
            })}
          </select>
        </section>

        {loading_item_array.includes("push_layer_sidebar_config_list") ||
        loading_item_array.includes("edit_layer_sidebar_config") ? (
          <div className="button background_blue">
            {dict?.["Saving"]?.[language]}...
          </div>
        ) : (
          <button className="button background_blue" onClick={this.on_submit}>
            {dict?.["Save"]?.[language]}
          </button>
        )}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  bi: state.bi,
  project: state.project,
  loading_reducer: state.loading_reducer,
});

export default connect(mapStateToProps, {
  push_layer_sidebar_config_list,
  edit_layer_sidebar_config,
  edit_inside_object,
})(BI_EDIT_LAYER_CONFIG);
