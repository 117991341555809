/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";
import "react-datasheet/lib/react-datasheet.css";
import io from "socket.io-client";
import TablePagination from '@mui/material/TablePagination';

/*COMPONENT*/
import Modal from "../common_modal/Modal";
import CellReturnValue from "./CellReturnValue";
import FlyTo from "./FlyTo";
import { FormatA } from "../trivial_components/FormatA";
import { FormatB } from "../trivial_components/FormatB";
import { CitarumA } from "../trivial_components/CitarumA";
import { CitarumB } from "../trivial_components/CitarumB";

/*REDUX*/
import {
  pushFeature,
  pushField,
  editProperties,
  deleteFeature,
  setTabelStatus,
  setFlyFeatures,
} from "../../App/actions/layerActions";
import {
  editGeometryTracking,
  getRoutesTracking,
  deleteDriverTracking,
} from "../../App/actions/layerNewActions";
import { set_value_layer } from "../../App/actions/layerActions";
import { set_feature_key_array } from "../../App/actions/dataActions";

/*PICTURE*/

/*FUNCTION*/
import isEmpty from "../../App/validation/is-empty";
import { calculateWithoutConvert } from "../../App/validation/convert_data";

/*DATA*/
import dict from "../../Data/dict.json";

/*CONST*/
const ENDPOINT = "wss://wss.mapid.io:443";
const rowsPerPageList = [25, 50, 100, 500, 1000, 5000, 10000, 50000, 100000];

class TABLE_VIEWER extends Component {
  constructor(props) {
    super(props);
    this.state = {
      key_selected: "",
      feature: {},
      modalImagePreview: false,
      modalTextPreview: false,
      image: "",
      text: "",
      feature_key: false,

      // pagination
      rowsPerPage: 100,
      currentPage: 1,
      lastPage: 0,
      isPrevBtnActive: false,
      isNextBtnActive: true,
      pageBound: 3,
    };
  }

  componentWillUnmount() {
    this.props.setTabelStatus(false);
  }

  setRowsPerPage = (value) => {
    this.setState({
      rowsPerPage: value,
    });
  };

  toggle_table = () => {
    this.props.set_value_layer({
      key: "modal_table",
      value: !this.props.layer.modal_table,
    });
  };

  async toggleFormatA(feature) {
    if (feature) {
      const { geo_layer } = this.props.layer.geo_layer;
      const features = geo_layer?.geojson?.features
        ? geo_layer?.geojson?.features
        : [];
      const index = features.findIndex((f) => f.key === feature.key);
      await FormatA(feature, index);
    }
  }

  toggleImagePreview = () => {
    this.setState({ modalImagePreview: !this.state.modalImagePreview });
  };

  toggleTextPreview = () => {
    this.setState({ modalTextPreview: !this.state.modalTextPreview });
  };

  toggleFormatB = (feature) => {
    if (feature) {
      const { geo_layer } = this.props.layer.geo_layer;
      const features = geo_layer?.geojson?.features
        ? geo_layer?.geojson?.features
        : [];
      const index = features.findIndex((f) => f.key === feature.key);
      FormatB(feature, index);
    }
  };

  async toggleCitarumA(feature) {
    if (feature) {
      const { geo_layer } = this.props.layer.geo_layer;
      const features = geo_layer?.geojson?.features
        ? geo_layer?.geojson?.features
        : [];
      const index = features.findIndex((f) => f.key === feature.key);
      await CitarumA(feature, index);
    }
  }

  toggleCitarumB = (feature) => {
    if (feature) {
      const { geo_layer } = this.props.layer.geo_layer;
      const features = geo_layer?.geojson?.features
        ? geo_layer?.geojson?.features
        : [];
      const index = features.findIndex((f) => f.key === feature.key);
      CitarumB(feature, index);
    }
  };

  flyTo = (feature) => {
    const { table_full } = this.props.layer;
    if (!table_full) {
      this.props.setFlyFeatures(feature);
      this.setState({ key_selected: feature.key });
    }
  };

  realtimeTracking = (trackId, featureId) => {
    let socket = io(ENDPOINT);
    socket.on(`public/tracking/${trackId}`, async (message) => {
      const d = JSON.parse(message);
      const features = this.props.layer.geo_layer.geojson.features;
      const { geometry } = features.find((x) => x.key === trackId);
      const lastLng = geometry.coordinates[0][0];
      const lastLat = geometry.coordinates[0][1];
      const newLng = d.longitude;
      const newLat = d.latitude;

      const distance = this.calDistance({ lastLat, lastLng, newLat, newLng });

      if (distance > 100) {
        const route = await getRoutesTracking({
          lastLat,
          lastLng,
          newLat,
          newLng,
        });

        if (route) {
          const arrGeometry = route.features[0].geometry.coordinates;

          arrGeometry.forEach((element, i) => {
            setTimeout(() => {
              this.props.editGeometryTracking({
                trackId,
                featureId,
                lat: element[1],
                long: element[0],
              });
            }, 300 * i);
          });
        }
      }
    });
  };

  calDistance = (params) => {
    const lat1 = params.lastLat,
      lon1 = params.lastLng;
    const lat2 = params.newLat,
      lon2 = params.newLng;
    const R = 6371e3; // metres
    const φ1 = (lat1 * Math.PI) / 180; // φ, λ in radians
    const φ2 = (lat2 * Math.PI) / 180;
    const Δφ = ((lat2 - lat1) * Math.PI) / 180;
    const Δλ = ((lon2 - lon1) * Math.PI) / 180;
    const a =
      Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
      Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c; // in metres
    return distance;
  };

  deleteDriver = (featuresKey) => {
    const { geo_layer } = this.props.layer.geo_layer;
    const { geo_project } = this.props.project;
    this.props.deleteDriverTracking(
      featuresKey,
      geo_layer._id,
      geo_project._id
    );
  };

  handlePreviewImage = (value) => {
    this.setState({
      image: value,
    });
  };

  handlePreviewText = (value) => {
    this.setState(
      {
        text: value,
      },
      () => {
        this.toggleTextPreview();
      }
    );
  };

  handlePageChange(newPage) {
    let listid = newPage + 1;
    this.setState(
      {
        currentPage: listid
      }
    );
  }

  render() {
    //local storage
    const language = localStorage?.language || "ina";

    //local state
    const { modal_format_a, modal_format_b } = this.state;

    //global props
    let { geo_layer_list, layer_id, param_filter } = this.props.layer;

    //content
    const layer = geo_layer_list.find((l) => l.geo_layer?._id === layer_id);
    const geo_layer = layer?.geo_layer;
    const fields_test = geo_layer?.fields ? geo_layer.fields.slice() : [];
    let citarum_test = fields_test.filter((f) => f.key === "citarum_form_a_b");
    citarum_test = citarum_test.length > 0;
    let fields = geo_layer?.fields ? geo_layer?.fields : [];
    //fields general,
    /*
    syarat tampil:
    !f.isHide : tidak sedang dihide
    !f.isStyle: bukan atribut style
    !f.parent_uuid: bukan atribut children dari suatu table (karena memiliki parent_uuid)
    */
    const fields_general = fields.filter(
      (f) => !f.isHide && !f.isStyle && !f.parent_uuid && f.type !== "section"
    );
    let features = geo_layer?.geojson_filtered?.features || [];
    if (!isEmpty(param_filter)) {
      features = features.filter(
        (f) =>
          String(f.properties[param_filter.key]).toLowerCase() ===
          String(param_filter.value).toLowerCase()
      );
    }
    let filtered_features = features.slice();
    param_filter.forEach((prm) => {
      filtered_features = filtered_features.filter(
        (f) =>
          String(f.properties[prm.key]).toLowerCase() ===
          String(prm.value).toLowerCase()
      );
    });
    const source = "TABLE_VIEWER lain";
    calculateWithoutConvert({
      features: filtered_features,
      fields: fields_general,
      source,
    });

    // pagination
    const { currentPage, rowsPerPage } =
      this.state;
    const indexOfLastTodo = currentPage * rowsPerPage;
    const indexOfFirstTodo = indexOfLastTodo - rowsPerPage;
    const current_features = filtered_features?.slice(
      indexOfFirstTodo,
      indexOfLastTodo
    );
    
    // Logic for displaying page numbers
    const pageNumbers = [];
    for (
      let i = 1;
      i <= Math.ceil(filtered_features.length / rowsPerPage);
      i++
    ) {
      pageNumbers.push(i);
    }


    const content = (
      <main>
        <table
          className="table_lite"
        >
          <thead>
            <tr style={{ textAlign: "left", zIndex: "1000" }}>
              <th
                style={{
                  textAlign: "center",
                  minWidth: "50px",
                }}
              >
                <section className="tabel_top">
                  {this.props.layer.feature_key_array.length !== 0 && (
                    <button
                      className="button inline"
                      id="red"
                      onClick={() => this.reset_features_key_active()}
                    >
                      {dict?.["Reset"]?.[language]}
                    </button>
                  )}
                </section>
              </th>
              {fields_general.map((field, idx) => {
                return (
                  <th key={idx} style={{ minWidth: "55px" }}>
                    {field.name}
                  </th>
                );
              })}
            </tr>
            <tr>
              <td>
                <section>
                  {this.props.layer.feature_key_array.length !== 0 && (
                    <button
                      className="button inline"
                      id="red"
                      onClick={() => this.reset_features_key_active()}
                    >
                      {dict?.["Reset"]?.[language]}
                    </button>
                  )}
                </section>
              </td>
              {fields_general.map((field, idx) => {
                return <td key={idx}>{field.type}</td>;
              })}
            </tr>
          </thead>
          <tbody>
            {current_features.map((feature, idx) => {
              const view_tabel = this.props.layer.feature_key_array.includes(
                feature.key
              );
              return (
                <tr
                  key={idx}
                  style={{
                    backgroundColor: view_tabel
                      ? "#e5eefd"
                      : idx % 2 !== 0
                        ? "#FFFFFF"
                        : "#FFFFFF",
                  }}
                >
                  <th
                    style={{
                      backgroundColor: view_tabel
                        ? "#dbe3f1"
                        : idx % 2 !== 0
                          ? "#FFFFFF"
                          : "#FFFFFF",
                      color: "#000",
                      fontWeight: "bold",
                      textAlign: "center",
                      width: "10px",
                    }}
                  >
                    <FlyTo
                      view_tabel={view_tabel}
                      feature={feature}
                      indexOfFirstTodo={indexOfFirstTodo}
                      idx={idx}
                    />
                    {geo_layer.status === "brantas_form" && (
                      <section>
                        <button
                          className="button_standard_tight"
                          onClick={this.toggleFormatA.bind(this, feature)}
                          style={{ margin: "3px" }}
                        >
                          Unduh Format A
                        </button>
                        <button
                          className="button_standard_tight"
                          onClick={this.toggleFormatB.bind(this, feature)}
                          style={{ margin: "3px" }}
                        >
                          Unduh Format B
                        </button>
                      </section>
                    )}
                    {citarum_test && (
                      <section>
                        <button
                          className="button_standard_tight"
                          onClick={this.toggleCitarumA.bind(this, feature)}
                          style={{ margin: "3px" }}
                        >
                          Unduh Format A
                        </button>
                        <button
                          className="button_standard_tight"
                          onClick={this.toggleCitarumB.bind(this, feature)}
                          style={{ margin: "3px" }}
                        >
                          Unduh Format B
                        </button>
                      </section>
                    )}
                  </th>

                  {fields_general.map((field, idx) => {
                    const value = feature?.properties[field?.key] || "";

                    const content = (
                      <CellReturnValue
                        value={value}
                        field={field}
                        feature={feature}
                        handlePreviewImage={this.handlePreviewImage}
                        handlePreviewText={this.handlePreviewText}
                        toggleImagePreview={this.toggleImagePreview}
                        deleteDriver={this.deleteDriver}
                      />
                    );

                    return (
                      <td key={idx}>
                        <div
                          style={{
                            maxHeight: "50px",
                            minWidth: "100px",
                            overflowY: "auto",
                            maxWidth: "400px",
                          }}
                        >
                          {content}
                        </div>
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        <style>{`
        table {
          border-collapse: collapse;
        }
        td,
        th {
          padding: 0.25em;
        }
        thead th {
          position: -webkit-sticky; /* for Safari */
          position: sticky;
          top: 0;
          background: #0575e6;
          color: #FFF;
        }
        thead th:first-child {
          left: 0;
          z-index: 1;
        }
        tbody th {
          position: -webkit-sticky; /* for Safari */
          position: sticky;
          left: 0;
          background: #FFF;
        }
        `}</style>
      </main>
    );

    const modal_format_a_content = modal_format_a && (
      <Modal
        modalSize="medium"
        id="modal"
        isOpen={modal_format_a}
        onClose={this.toggleFormatA}
      >
        <div className="box-body">
          <FormatA feature={this.state.feature} />
        </div>
      </Modal>
    );

    const modal_format_b_content = modal_format_b && (
      <Modal
        modalSize="medium"
        id="modal"
        isOpen={modal_format_b}
        onClose={this.toggleFormatB}
      >
        <div className="box-body">
          <FormatB feature={this.state.feature} />
        </div>
      </Modal>
    );

    const modalImagePreview = this.state.modalImagePreview && (
      <Modal
        modalSize="large"
        id="modal"
        isOpen={this.state.modalImagePreview}
        onClose={this.toggleImagePreview}
      >
        <div
          className="box-body"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={this.state.image}
            alt="modal"
            style={{
              width: "1150px",
              maxHeight: "80vh",
              objectFit: "contain",
            }}
          />
        </div>
      </Modal>
    );

    const modalTextPreview = this.state.modalTextPreview && (
      <Modal
        modalSize="large"
        id="modal"
        isOpen={this.state.modalTextPreview}
        onClose={this.toggleTextPreview}
      >
        <div
          className="box-body"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <p>{this.state.text}</p>
        </div>
      </Modal>
    );

    return (
      <section>
        <div>
          {modal_format_a_content}
          {modal_format_b_content}
          {modalImagePreview}
          {modalTextPreview}

          {modal_format_a_content}
          {modal_format_b_content}
          {modalImagePreview}
          {modalTextPreview}

          {content}


          <div style={{ width: "100%", height: "80px" }} />
        </div >
        <div
          className="bg_white fixed bottom w_full"
          style={{ justifyContent: "flex-start" }}
        >
          <TablePagination
            component="div"
            count={filtered_features.length}
            page={currentPage - 1}
            onPageChange={(_, newPage) => { this.handlePageChange(newPage) }}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={(e) => { this.setRowsPerPage(parseInt(e.target.value)) }}
            rowsPerPageOptions={rowsPerPageList}
          />
        </div>
      </section>
    )
  }
}

const mapStateToProps = (state) => ({
  project: state.project,
  layer: state.layer,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  pushFeature,
  pushField,
  editProperties,
  deleteFeature,
  setTabelStatus,
  setFlyFeatures,
  editGeometryTracking,
  deleteDriverTracking,
  set_value_layer,
  set_feature_key_array,
})(TABLE_VIEWER);
