/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*COMPONENT*/

/*REDUX*/

/*PICTURE*/

/*FUNCTION*/

/*DATA*/

/*CONST*/

class BI_FEATURES_LIST_TABLE extends Component {
  state = {};

  render() {
    //local storage

    //local state

    //global props
    const { fields, features, layer_sidebar_config } = this.props;

    //content
    let { field_show_mode, n_field_to_show, array_text_to_show } =
      layer_sidebar_config;
    let fields_sliced = [];
    if (field_show_mode === "only_first_n_field") {
      n_field_to_show = !isNaN(n_field_to_show) ? n_field_to_show : 3;
      fields_sliced = fields.filter(
        (item) => item.type !== "image" && !item?.isStyle
      );
      fields_sliced = fields_sliced.slice(0, n_field_to_show);
    } else {
      fields_sliced = fields.filter((item) =>
        array_text_to_show.includes(item.key)
      );
    }

    return (
      <main>
        <table className="table full_width text_small">
          <thead>
            <tr>
              <th>No.</th>
              {fields_sliced.map((item, idx) => {
                return <th key={idx}>{item?.name}</th>;
              })}
            </tr>
          </thead>
          <tbody>
            {features.map((feature, idx_feature) => {
              return (
                <tr key={idx_feature}>
                  <td>{idx_feature + 1}</td>
                  {fields_sliced.map((field, idx_field) => {
                    let value = feature?.properties?.[field.key];
                    if (
                      ["number", "math_operators", "currency"].includes(
                        field.type
                      ) &&
                      !isNaN(value)
                    ) {
                      value = new Intl.NumberFormat("id-ID", {
                        style: "decimal",
                      }).format(value);
                    }
                    return <td key={idx_field}>{value}</td>;
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(BI_FEATURES_LIST_TABLE);
