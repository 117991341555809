/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*COMPONENT*/
import SpinnerSimpleBlue from "../common_spinner/SpinnerSimpleBlue";
import Modal from "../common_modal/Modal";
import CAPEX_EDITOR from "./CAPEX_EDITOR";

/*REDUX*/
import { editFolder } from "../../App/actions/projectActions";
import { reset_resume_kai } from "../../App/actions/propertiesActions";

/*PICTURE*/

/*FUNCTION*/

/*DATA*/
import dict from "../../Data/dict.json";
import domain_list from "../../Data/domain_list";

/*CONST*/

class EditFolder extends Component {
  state = {
    folder: {},
    modal_edit_capex: false,
    modal_reset_capex: false,
  };

  componentDidMount() {
    const folder = { ...this.props.folder };
    this.setState({ folder });
  }

  on_reset_resume_kai = () => {
    const { geo_project } = this.props.project;
    const { folder } = this.state;
    const body = {
      geo_project_id: geo_project._id,
      folder_id: folder._id,
    };
    this.props.reset_resume_kai(body);
    this.setState({ modal_reset_capex: false });
  };

  toggle_edit_capex = () => {
    this.setState({ modal_edit_capex: !this.state.modal_edit_capex });
  };

  toggle_reset_capex = () => {
    this.setState({ modal_reset_capex: !this.state.modal_reset_capex });
  };

  //Local change
  on_change = (e) => {
    e.preventDefault();
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    let folder = this.state.folder;
    folder[name] = value;
    this.setState({
      folder,
    });
  };

  on_submit = (e) => {
    e.preventDefault();
    const { geo_project } = this.props.project;
    const { folder } = this.state;
    const { _id, name, parent_folder } = folder;
    const body = {
      geo_project_id: geo_project._id,
      folder_id: _id,
      name,
      parent_folder,
      isPublic: false,
    };
    this.props.editFolder(body);
    this.props.toggle_edit_folder();
  };

  render() {
    //local storagge
    const language = localStorage?.language ? localStorage?.language : "ina";

    //local state
    const { folder, modal_edit_capex, modal_reset_capex } = this.state;

    //global props
    const { loadingProcess, geo_project } = this.props.project;
    const { domain } = this.props.auth;

    //content
    const is_domain_kai = domain_list?.[domain]?.is_domain_kai;
    let folders = geo_project?.folders || [];
    folders = folders.filter((item) => item._id !== folder._id);

    let content;
    let button_content;

    if (loadingProcess) {
      button_content = (
        <SpinnerSimpleBlue
          width={40}
          unik="loading_create_layer"
          marginTop="0px"
        />
      );
    } else {
      button_content = (
        <button
          type="submit"
          className="button background_blue"
          style={{ marginTop: "1rem" }}
        >
          {dict["Save"][language]}
        </button>
      );
    }

    const modal_edit_capex_content = modal_edit_capex && (
      <Modal
        modalSize="medium"
        id="modal"
        isOpen={modal_edit_capex}
        onClose={this.toggle_edit_capex}
      >
        <div className="box-body">
          <CAPEX_EDITOR folder={folder} />
        </div>
      </Modal>
    );

    const modal_reset_capex_content = modal_reset_capex && (
      <Modal
        modalSize="small"
        id="modal"
        isOpen={modal_reset_capex}
        onClose={this.toggle_reset_capex}
      >
        <div className="box-body">
          <h1 className="text_header">Reset pengaturan CAPEX</h1>
          <p className="text_small">
            Apakah anda yakin untuk mereset semua pengaturan data CAPEX? Semua
            pengaturan akan dihapus dan anda harus melakukan pengaturan dan
            pemilihan layer CAPEX dari awal.
          </p>

          <br />
          <button
            className="button background_red"
            onClick={this.on_reset_resume_kai}
          >
            RESET CAPEX
          </button>
        </div>
      </Modal>
    );

    content = (
      <div style={{ textAlign: "left" }}>
        <h1 className="text_header margin_bottom">Edit Folder</h1>
        <br />
        <form onSubmit={this.on_submit.bind(this)}>
          <p className="text_small">Nama folder</p>
          <input
            type="text"
            name="name"
            id="name"
            value={folder?.name || ""}
            onChange={this.on_change}
          />
          <br />
          <br />
          <p className="text_small">Masukkan folder ke dalam folder lain</p>
          <select
            onChange={this.on_change}
            value={folder?.parent_folder || ""}
            name="parent_folder"
          >
            <option value="">Pilih folder</option>
            {folders.map((item, idx) => {
              return (
                <option key={idx} value={item._id}>
                  {item.name.slice(0, 20)}
                </option>
              );
            })}
          </select>
          <br />
          <br />
          {is_domain_kai && (
            <section className="margin_bottom">
              <section className="container_light">
                <p className="text_small">Atur data resume plan train</p>
                <button
                  className="button background_orange margin_bottom"
                  type="button background_blue"
                  onClick={this.toggle_edit_capex}
                >
                  Buka pengaturan data-data CAPEX
                </button>
              </section>
              <br />
              <button
                className="button background_red"
                type="button background_blue"
                onClick={this.toggle_reset_capex}
              >
                Reset pengaturan CAPEX
              </button>
              <br />
            </section>
          )}

          {button_content}
        </form>
      </div>
    );

    return (
      <div>
        {modal_edit_capex_content}
        {modal_reset_capex_content}
        {content}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  layer: state.layer,
  project: state.project,
  auth: state.auth,
});

export default connect(mapStateToProps, { editFolder, reset_resume_kai })(
  EditFolder
);
