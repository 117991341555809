//Library Modul
import React, { Component } from "react";
import { connect } from "react-redux";

//Personal Component

//Redux Function
import { edit_radius_pembanding } from "../../App/actions/propertiesActions";

//Picture Asset

//General Function

class EditRadiusPembanding extends Component {
  state = {
    radius_pembanding: 500, //meter
  };

  componentDidMount() {
    const { layer_id, geo_layer_list } = this.props.layer;
    const layer = geo_layer_list.find(
      (item) => item.geo_layer._id === layer_id
    )?.geo_layer;
    const radius_pembanding = layer?.radius_pembanding;
    if (radius_pembanding) {
      this.setState({ radius_pembanding });
    }
  }

  on_change = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value });
  };

  on_submit = () => {
    const { layer_id } = this.props.layer;
    const { radius_pembanding } = this.state;
    const body = {
      geo_layer_id: layer_id,
      radius_pembanding,
    };

    this.props.edit_radius_pembanding(body);
    this.props.toggle_edit_radius_pembanding();
  };

  render() {
    const { radius_pembanding } = this.state;
    return (
      <div>
        <h1 className="text_header">Edit radius data pembanding</h1>
        <p className="text_inferior">
          Edit radius untuk membatasi data pembanding yang akan ditampilkan pada
          submit data pembanding oleh surveyor.
        </p>
        <br />
        <br />
        <p className="text_inferior">Radius data pembanding dalam meter</p>
        <input
          id="radius_pembanding"
          name="radius_pembanding"
          value={radius_pembanding}
          onChange={this.on_change}
        />
        <br />
        <div className="button background_blue" onClick={this.on_submit}>
          Simpan
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  layer: state.layer,
});

export default connect(mapStateToProps, { edit_radius_pembanding })(
  EditRadiusPembanding
);
