//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

//Personal Component
import TextFieldGroup from "../common_input/TextFieldGroup";

//Redux function
import { loginUser, loginUserKAI } from "../../App/actions/authActions";

//Picture Asset

//General Function
import dict from "../../Data/dict.json";
import domain_list from "../../Data/domain_list";

class Login extends Component {
  constructor() {
    super();
    this.state = {
      name: "",
      password: "",
      errors: {},
      show_password: false,
      is_remember_me: false,
    };
  }

  static getDerivedStateFromProps(nextProps) {
    if (nextProps.errors) {
      return {
        errors: nextProps.errors,
      };
    } else {
      return null;
    }
  }

  toggle_remember_me = () => {
    const { is_remember_me } = this.state;
    this.setState({
      is_remember_me: !is_remember_me,
    });
  };

  toggleShowPassword = () => {
    this.setState({ show_password: !this.state.show_password });
  };

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onSubmit = (e) => {
    e.preventDefault();

    const domain_w_port = window.location.host;
    const is_login_kai = domain_list?.[domain_w_port]?.is_login_kai;

    if (is_login_kai) {
      const userData = {
        username: this.state.name,
        password: this.state.password,
      };
      this.props.loginUserKAI(userData);
    } else {
      const isNav = this.props.isNav ? this.props.isNav : null;
      const userData = {
        name: this.state.name,
        password: this.state.password,
        isNav,
      };
      this.props.loginUser(userData);
    }
  };

  handleKeyDown = (e) => {
    if (e?.key === "Enter") {
      this.onSubmit(e);
    }
  };

  render() {
    const language = localStorage?.language ? localStorage?.language : "ina";
    const domain_w_port = window.location.host;
    const is_login_kai = domain_list[domain_w_port]?.is_login_kai;

    const { errors, name, password, show_password } = this.state;

    const { loading } = this.props.auth;

    let iconButton = (
      <button
        style={{ height: "59px", fontSize: "0.88em" }}
        className="marginTop_20 rounded_5 bg_black text_white w_full"
        onClick={(e) => this.onSubmit(e)}
        data-mapid="clickLogin"
      >
        {dict["Sign in"][language]}
      </button>
    );
    if (loading) {
      iconButton = (
        <button
          style={{ height: "59px", fontSize: "0.88em" }}
          className="button_inactive rounded_5 bg_black text_white w_full h_50"
        >
          {dict["Sign in..."][language]}
        </button>
      );
    }

    let content = (
      <div //noValidate onSubmit={this.onSubmit}
      >
        <TextFieldGroup
          Title={is_login_kai ? "NIPP" : "E-mail / Username"}
          placeholder={
            dict[
              is_login_kai ? "Enter your NIPP" : "Enter your email/username"
            ][language]
          }
          name="name"
          id="name"
          value={name}
          onChange={this.onChange}
          error={errors.name}
          className="input_border"
          handleKeyDown={this.handleKeyDown}
        />
        <br />
        <TextFieldGroup
          type={show_password ? "text" : "password"}
          isPassword={true}
          Title={dict["Password"][language]}
          placeholder={dict["Enter Your Password"][language]}
          name="password"
          id="password"
          value={password}
          onChange={this.onChange}
          toggleShowPassword={this.toggleShowPassword}
          pattern="[0-9a-zA-Z]{8,}"
          error={errors.password}
          className="input_border"
          handleKeyDown={this.handleKeyDown}
        />
        <div className="flex justify_end marginTop_20 marginBottom_20">
          <Link to="/forget-password">
            <p
              onClick={this.toggleForgetPassword}
              data-mapid="clickForgotPassword"
            >
              {dict["Forgot Password"][language]}?
            </p>
          </Link>
        </div>
        {iconButton}
      </div>
    );

    return <main>{content}</main>;
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  loginUser,
  loginUserKAI,
})(Login);
