/*LIBRARY*/
import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import Menu from "@mui/material/Menu";

/*COMPONENT*/
import Modal from "../common_modal/Modal";
import TEXT_AREA_CHAT from "./TEXT_AREA_CHAT";
import MARKDOWN_RENDERER from "./MARKDOWN_RENDERER";

/*REDUX*/
import {
  get_ai_parent_list,
  set_value_sini,
  get_ai_child_list,
  get_ai_parent_object,
  delete_ai_item,
  fly_init_update,
} from "../../App/actions/sini_v2";
import { post_ai_chat } from "../../App/actions/post_ai_chat";

/*PICTURE*/

/*FUNCTION*/

/*DATA*/
import dict from "../../Data/dict.json";

/*CONST*/

class SINI_AI_PAGE extends Component {
  state = {
    request_id_state: "",
    autogenerate_title_state: "",
    anchor: null,
    modal_menu: false,
    modal_delete: false,
    modal_rename: false,
  };

  componentDidMount() {
    this.props.get_ai_parent_list();
  }

  // Create a ref for the scroll container
  scrollContainerRef = createRef();

  on_scroll_down = () => {
    if (this.scrollContainerRef.current) {
      this.scrollContainerRef.current.scrollTop =
        this.scrollContainerRef.current.scrollHeight;
    }
  };

  on_delete_ai_item = () => {
    this.setState({
      modal_delete: false,
    });
    const { request_id_state } = this.state;
    const query = {
      request_id: request_id_state,
    };
    this.props.delete_ai_item(query);

    //delete marker from map
    const marker_id = `marker_center_${request_id_state}`;
    const marker_element = document.getElementById(marker_id);
    if (marker_element) {
      marker_element.remove();
    }

    //delete layer from map
    const { map_object } = this.props.layer;
    const layer_id_fill_isochrone = `geojson_polygon_${request_id_state}`;
    const layer_id_line_isochrone = `geojson_polygon_line_${request_id_state}`;
    const layer_id_poi = `poi_${request_id_state}`;

    if (map_object.getLayer(layer_id_fill_isochrone)) {
      map_object.removeLayer(layer_id_fill_isochrone);
    }
    if (map_object.getLayer(layer_id_line_isochrone)) {
      map_object.removeLayer(layer_id_line_isochrone);
    }
    if (map_object.getLayer(layer_id_poi)) {
      map_object.removeLayer(layer_id_poi);
    }
  };

  toggle_delete = () => {
    this.setState({
      modal_menu: false,
      modal_delete: !this.state.modal_delete,
    });
  };

  toggle_rename = () => {
    this.setState({
      modal_menu: false,
      modal_rename: !this.state.modal_rename,
    });
  };

  toggle_menu = (event, request_id, autogenerate_title) => {
    this.setState({ modal_menu: !this.state.modal_menu }, () => {
      if (this.state.modal_menu) {
        this.setState({
          request_id_state: request_id,
          autogenerate_title_state: autogenerate_title,
        });
      }
    });
    if (event) {
      this.setState({ anchor: event.currentTarget });
    }
  };

  toggle_ai_page = () => {
    this.props.set_value_sini({
      key: "modal_ai_page",
      value: !this.props.sini.modal_ai_page,
    });
  };

  on_click_item = (request_id) => {
    this.props.set_value_sini({
      key: "request_id_active",
      value: request_id,
    });
    const { ai_parent_child_list, ai_parent_detail_list } = this.props.sini;
    const ai_child_list =
      ai_parent_child_list.find((item) => item?.request_id === request_id)
        ?.ai_child_list || [];
    const sini_db_object_test = ai_parent_detail_list.find(
      (item) => item.request_id === request_id
    )?.request_id;
    const params = {
      request_id: request_id,
    };
    if (ai_child_list.length === 0) {
      this.props.get_ai_child_list(params);
    }
    if (!sini_db_object_test) {
      this.props.get_ai_parent_object(params);
    } else {
      this.props.fly_init_update();
    }
  };

  on_post_ai_chat = () => {
    const {
      sini_v2_list,
      request_id_active,
      user_message,
      ai_parent_detail_list,
    } = this.props.sini;
    const merge_array = [...sini_v2_list, ...ai_parent_detail_list];
    const sini_object = merge_array.find(
      (item) => item.request_id === request_id_active
    );
    const body = {
      request_id: request_id_active,
      user_message,
      sini_object,
    };
    this.props.post_ai_chat(body);
    this.props.set_value_sini({
      key: "user_message",
      value: "",
    });
    this.props.set_value_sini({
      key: "rows_text_area",
      value: 1,
    });
  };

  render() {
    //local storage
    const language = localStorage?.language || "ina";
    const isMobile = window.innerWidth <= 650;

    //local state
    const {
      anchor,
      modal_menu,
      modal_delete,
      autogenerate_title_state,
      modal_rename,
    } = this.state;

    //global props
    const {
      ai_parent_list,
      request_id_active,
      user_message,
      ai_parent_child_list,
    } = this.props.sini;
    const { loading_item_array } = this.props.loading_reducer;

    //content
    let content;
    if (isMobile) {
      content = (
        <main>
          <p className="text_bold">
            Untuk sementara halaman ini hanya optimal untuk perangkat berukuran
            besar (PC & tablet)
          </p>
          <br />
          <br />
          <button
            className="button_big margin_bottom background_black"
            onClick={this.toggle_ai_page}
          >
            Back to map
          </button>
        </main>
      );
    } else {
      const ai_child_list =
        ai_parent_child_list.find(
          (item) => item?.request_id === request_id_active
        )?.ai_child_list || [];

      const modal_menu_content = modal_menu && (
        <Menu
          id="basic-menu"
          anchorEl={anchor}
          open={modal_menu}
          onClose={this.toggle_menu}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <main style={{ padding: "10px" }}>
            {/* <button
              className="button_big button_transparent margin_bottom"
              onClick={this.toggle_rename}
            >
              Rename
            </button>
            <br /> */}
            <button
              className="button_big button_transparent"
              onClick={this.toggle_delete}
            >
              Delete
            </button>
          </main>
        </Menu>
      );

      const modal_delete_content = modal_delete && (
        <Modal
          modalSize="small"
          isOpen={modal_delete}
          onClose={this.toggle_delete}
        >
          <div className="box-body" id="box-body">
            <p className="text_large margin_bottom">Hapus percakapan ini?</p>
            <hr />
            <p className="text_medium margin_bottom">
              Aksi ini akan menghapus <b>{autogenerate_title_state}</b>
            </p>
            <br />
            <br />
            <button
              className="button margin_right background_grey"
              onClick={this.toggle_delete}
            >
              Batal
            </button>
            <button
              className="button background_red"
              onClick={this.on_delete_ai_item}
            >
              Hapus
            </button>
          </div>
        </Modal>
      );

      const modal_rename_content = modal_rename && (
        <Modal
          modalSize="small"
          isOpen={modal_rename}
          onClose={this.toggle_rename}
        >
          <div className="box-body" id="box-body">
            <p className="text_medium margin_bottom">Rename is coming soon</p>
            <hr />
            <p className="text_medium margin_bottom">
              Renaming chat history with SINI AI is coming soon
            </p>
            <br />
            <br />
          </div>
        </Modal>
      );

      let history_content = (
        <div className="text_small container_light margin_bottom">
          Belum ada SINI AI chat history
        </div>
      );

      if (ai_parent_list.length > 0) {
        history_content = (
          <>
            {ai_parent_list.map((item, idx) => {
              const { request_id, autogenerate_title, title_1, title_2 } = item;
              let title = autogenerate_title
                ? autogenerate_title
                : title_1 + ", " + title_2;
              return (
                <section
                  key={idx}
                  className={`button_flex button_big full_width text_left margin_bottom ${
                    request_id === request_id_active
                      ? "background_blue_light"
                      : "background_grey"
                  }`}
                  title={autogenerate_title}
                >
                  <div
                    className="item_left full_width max_one_lines"
                    onClick={this.on_click_item.bind(this, request_id)}
                  >
                    {title}
                  </div>
                  <div
                    onClick={(e) => {
                      this.toggle_menu(e, request_id, title);
                    }}
                    id={request_id}
                    className={`noselect item_right ${
                      request_id === request_id_active ? "show" : ""
                    }`}
                  >
                    ...
                  </div>
                </section>
              );
            })}
          </>
        );
      }

      content = (
        <main className="ai_page_container">
          {modal_rename_content}
          {modal_delete_content}
          {modal_menu_content}
          <section className="ai_page_left_sidebar">
            <section
              style={{
                position: "sticky",
                top: "-50px",
                width: "calc( 100% + 20px )",
                backgroundColor: "transparent",
                // backgroundColor: "#fff",
                zIndex: 2,
                paddingTop: "50px",
                paddingBottom: "10px",
              }}
            >
              <button
                className="button_big margin_bottom background_black"
                onClick={this.toggle_ai_page}
                data-mapid="clickBack"
              >
                Back to map
              </button>
            </section>
            <br />
            <div className="badge_pill margin_bottom background_blue">
              SINI AI chat history
            </div>
            <br />
            {history_content}
          </section>
          <section className="ai_page_chat_list">
            <div
              className="sini_full_parent"
              style={{ maxWidth: "100%", width: "667px", margin: "auto" }}
            >
              <section
                className="sini_full_scroll_area"
                ref={this.scrollContainerRef}
              >
                {!ai_child_list.length && (
                  <section className="container_light outline_transparent margin_bottom background_dark_blue">
                    Mulai <i>brainstorm</i> dengan SINI AI tentang ide Anda
                    dengan mengetikkan <i>prompt</i> di bawah
                  </section>
                )}
                {ai_child_list.map((item, idx) => {
                  let final_content;
                  if (item.role === "assistant" && item.content) {
                    final_content = (
                      <section
                        key={idx}
                        id={`ai_sini_${idx}`}
                        style={{
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        <div
                          className={`container_light outline_transparent margin_bottom background_dark_blue`}
                        >
                          <MARKDOWN_RENDERER markdownContent={item.content} />
                        </div>
                      </section>
                    );
                  } else {
                    final_content = (
                      <section
                        key={idx}
                        id={`ai_sini_${idx}`}
                        style={{ right: 0, whiteSpace: "pre-wrap" }}
                      >
                        <div
                          className={`container_light outline_transparent margin_bottom max_width_300 background_white`}
                        >
                          {item.content}
                        </div>
                      </section>
                    );
                  }
                  return final_content;
                })}
                {loading_item_array.includes("post_ai_chat") && (
                  <div
                    className={`container_light outline_transparent margin_bottom background_dark_blue`}
                  >
                    {dict?.["SINI AI is answering"]?.[language]}...
                  </div>
                )}
              </section>
              {request_id_active && (
                <section className="ai_input_text_area">
                  <TEXT_AREA_CHAT
                    scrollContainerRef={this.scrollContainerRef}
                    on_scroll_down={this.on_scroll_down}
                  />
                  {user_message.length > 0 ? (
                    <button
                      className="button background_blue"
                      onClick={this.on_post_ai_chat}
                    >
                      {dict?.["Start asking"]?.[language]}
                    </button>
                  ) : (
                    <div className="button background_grey">
                      {dict?.["Start asking"]?.[language]}
                    </div>
                  )}
                </section>
              )}
            </div>
          </section>
        </main>
      );
    }

    return <>{content}</>;
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  sini: state.sini,
  loading_reducer: state.loading_reducer,
  layer: state.layer,
});

export default connect(mapStateToProps, {
  get_ai_parent_list,
  set_value_sini,
  get_ai_child_list,
  get_ai_parent_object,
  delete_ai_item,
  post_ai_chat,
  fly_init_update,
})(SINI_AI_PAGE);
