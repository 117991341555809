/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/
import { deleteLayerAttach } from "../../App/actions/layerNewActions";

/*PICTURE ASSET*/

/*GENERAL*/
import dict from "../../Data/dict.json";

/*NON IMPORT*/

class DeleteLayer extends Component {
  handleSubmit = () => {
    const { geo_project } = this.props.project;
    const { geo_layer_wo_geojson } = this.props.layer;

    const geo_layer_id = geo_layer_wo_geojson?.geo_layer?._id;
    const geo_project_id = geo_project._id;

    if (geo_layer_id && geo_project_id) {
      const body = {
        geo_layer_id: geo_layer_id,
        geo_project_id: geo_project_id,
      };
      this.props.toggle_delete_layer();
      this.props.deleteLayerAttach(body);

      //hapus layer dari map
      const { map_object } = this.props.layer;
      if (map_object) {
        //layer original
        if (map_object.getLayer(geo_layer_id)) {
          map_object.removeLayer(geo_layer_id);
        }
        //layer line outline untuk layer poligon
        if (map_object.getLayer(geo_layer_id + "_line_polygon")) {
          map_object.removeLayer(geo_layer_id + "_line_polygon");
        }
        //layer cluster untuk point
        if (map_object.getLayer(geo_layer_id + "_cluster")) {
          map_object.removeLayer(geo_layer_id + "_cluster");
        }
        //layer cluster container untuk point
        if (map_object.getLayer(geo_layer_id + "clusters_container")) {
          map_object.removeLayer(geo_layer_id + "clusters_container");
        }
        //layer cluster_count_number untuk point
        if (map_object.getLayer(geo_layer_id + "cluster_count_number")) {
          map_object.removeLayer(geo_layer_id + "cluster_count_number");
        }
        //source original
        if (map_object.getSource(geo_layer_id)) {
          map_object.removeSource(geo_layer_id);
        }
        //source cluster untuk point
        if (map_object.getSource(geo_layer_id + "_cluster")) {
          map_object.removeSource(geo_layer_id + "_cluster");
        }
      }
    }
  };

  render() {
    const language = localStorage?.language || "ina";
    const { geo_layer_wo_geojson } = this.props.layer;
    const { loadingProcess } = this.props.layer;
    var itemContent;
    var buttonContent;
    if (loadingProcess) {
      buttonContent = (
        <div
          className="button background_blue"
          id="deleteBright"
          style={{ marginTop: "1rem" }}
        >
          {dict["Process deletion"][language]}...
        </div>
      );
    } else {
      buttonContent = (
        <button
          className="button background_blue"
          id="deleteBright"
          style={{ marginTop: "1rem" }}
          onClick={this.handleSubmit}
        >
          {dict["Process deletion"][language]}
        </button>
      );
    }
    itemContent = (
      <div style={{ textAlign: "center" }}>
        <div className="text_medium">
          {dict["Are you sure to unattach this layer?"][language]} <br />
          {
            dict[
              "The layer at the origin of the project will not be affected."
            ][language]
          }
          <br />
        </div>
        <br />
        <div className="text_bold">{geo_layer_wo_geojson?.geo_layer?.name}</div>
        <br />
        <div className="text_medium">
          {dict["This process can't be undone."][language]}
        </div>
        {buttonContent}
      </div>
    );
    return <div>{itemContent}</div>;
  }
}

const mapStateToProps = (state) => ({
  layer: state.layer,
  auth: state.auth,
  project: state.project,
});

export default connect(mapStateToProps, {
  deleteLayerAttach,
})(DeleteLayer);
