/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";
import points_within_polygon from "@turf/points-within-polygon";
import circle from "@turf/circle";

/*PERSONAL COMPONENT*/
import Modal from "../common_modal/Modal";

/*REDUX FUNCTION*/
import { set_geojson_filtered } from "../../App/actions/toolboxActions";

/*PICTURE ASSET*/
import close from "../../Assets/svg/close.svg";

/*GENERAL FUNCTION & DATA*/

/*NON IMPORT*/

class TOOLBOX_DETAIL_AREA extends Component {
  state = {
    modal_warn: false,
    modal_warn_no_layer_selected: false,
  };

  toggle_warn = () => {
    this.setState({ modal_warn: !this.state.modal_warn });
  };

  toggle_warn_no_layer_selected = () => {
    this.setState({
      modal_warn_no_layer_selected: !this.state.modal_warn_no_layer_selected,
    });
  };

  on_filter = () => {
    const { layer_id, geo_layer_list } = this.props.layer;
    const { features_radius, features_area } = this.props.toolbox;

    const features_radius_polygon = features_radius.map((feature_point) => {
      const polygon = circle(
        feature_point,
        feature_point.properties.radius_km,
        {
          steps: 100,
          units: "kilometers",
          properties: feature_point.properties,
        }
      );
      return polygon;
    });
    const geojson_radius_polygon = {
      type: "FeatureCollection",
      features: [...features_radius_polygon, ...features_area],
    };
    const layer_object = geo_layer_list?.find(
      (item) => item?.geo_layer?._id === layer_id
    )?.geo_layer;
    const geojson_point = layer_object.geojson;
    const geojson_filtered = points_within_polygon(
      geojson_point,
      geojson_radius_polygon
    );
    const features_filtered = geojson_filtered.features;
    const body = {
      geo_layer_id: layer_id,
      features_filtered,
    };
    this.props.set_geojson_filtered(body);
  };

  on_reset = () => {
    const { layer_id, geo_layer_list } = this.props.layer;
    const layer_object = geo_layer_list?.find(
      (item) => item?.geo_layer?._id === layer_id
    )?.geo_layer;
    const features_filtered = layer_object?.geojson?.features || [];
    if (layer_id) {
      const body = {
        geo_layer_id: layer_id,
        features_filtered,
      };
      this.props.set_geojson_filtered(body);
    }
  };

  render() {
    //local storage

    //local state
    const { modal_warn, modal_warn_no_layer_selected } = this.state;

    //global props
    const { features_area } = this.props.toolbox;
    const { layer_id, geo_layer_list } = this.props.layer;

    //content
    const layer_object = geo_layer_list?.find(
      (item) => item?.geo_layer?._id === layer_id
    )?.geo_layer;

    const layer_type = layer_object?.type;

    const modal_warn_content = modal_warn && (
      <Modal modalSize="small" isOpen={modal_warn} onClose={this.toggle_warn}>
        <div className="box-body" id="box-body">
          <p className="text_bold">Draw an area first</p>
        </div>
      </Modal>
    );

    const modal_warn_no_layer_selected_content =
      modal_warn_no_layer_selected && (
        <Modal
          modalSize="small"
          isOpen={modal_warn_no_layer_selected}
          onClose={this.toggle_warn_no_layer_selected}
        >
          <div className="box-body" id="box-body">
            <p className="text_medium">
              {layer_type ? (
                <>
                  The layer you selected is not <b>Point</b> but instead{" "}
                  <b>{layer_type}</b>.
                  <br />
                  <br />
                  For now, this feature is only for Point type layers.
                </>
              ) : (
                <>Select one layer (point type) first</>
              )}
            </p>
          </div>
        </Modal>
      );

    return (
      <section className="container_light margin_bottom background_grey_light outline_black">
        {modal_warn_content}
        {modal_warn_no_layer_selected_content}
        <p className="text_bold">Area</p>
        <p className="text_small">
          {`Draw polygon and measure the area

Clip your data with polygon you draw.
Only works with point type layers.`}
        </p>

        <section className="container_light background_white margin_bottom">
          <div className="badge_pill background_blue">Area</div> x{" "}
          <div className="badge_pill background_blue margin_top">
            {layer_object?.name || "Belum pilih layer"}
          </div>
          <br />
        </section>
        <table className="full_width">
          <tbody>
            <tr>
              <td>
                {features_area.length === 0 ? (
                  <button
                    className="button background_black"
                    onClick={this.toggle_warn}
                  >
                    Filter
                  </button>
                ) : layer_type !== "Point" ? (
                  <button
                    className="button background_black"
                    onClick={this.toggle_warn_no_layer_selected}
                  >
                    Filter
                  </button>
                ) : (
                  <button
                    className="button background_blue"
                    onClick={this.on_filter}
                  >
                    Filter
                  </button>
                )}
              </td>
              <td className="text_right">
                <img
                  className="cursor_pointer"
                  alt="close"
                  src={close}
                  style={{ height: "20px" }}
                  onClick={this.on_reset}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  layer: state.layer,
  toolbox: state.toolbox,
});

export default connect(mapStateToProps, { set_geojson_filtered })(
  TOOLBOX_DETAIL_AREA
);
