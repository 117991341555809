/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/
import BI_ADD_LAYER_DATA from "./BI_ADD_LAYER_DATA";
import BI_EDIT_BASEMAP from "./BI_EDIT_BASEMAP";

/*REDUX FUNCTION*/
import {
  get_list_layer_bi,
  push_setting,
  edit_setting,
  set_value_bi,
} from "../../App/actions/biActions";

/*PICTURE ASSET*/

/*GENERAL FUNCTION & DATA*/
import dict from "../../Data/dict.json";
import { set_value_map } from "../../App/actions/mapActions";
import basemap_styles from "../../App/validation/basemap_styles";
import { domain_mapid_basemap } from "../../App/validation/features_domain";

/*NON IMPORT*/

class BI_ADD_SETTING extends Component {
  componentDidMount() {
    this.props.set_value_bi({
      key: "add_setting_current_step",
      value: 0,
    });
    const { project_object_selected, bi_object } = this.props.bi;
    const layer_list = project_object_selected?.layer_list || [];
    if (layer_list.length === 0) {
      const query = {
        _id: project_object_selected._id,
        link: project_object_selected.link,
      };
      this.props.get_list_layer_bi(query);
    }
    const setting_list = bi_object?.setting_list || [];
    const setting_add_object =
      setting_list.find(
        (item) => item.project_id === project_object_selected?._id
      ) || {};
    if (!!setting_add_object?._id) {
      this.props.set_value_bi({
        key: "setting_add_object",
        value: setting_add_object,
      });
    }
  }

  on_set_add_setting_current_step = (step) => {
    this.props.set_value_bi({
      key: "add_setting_current_step",
      value: step,
    });
  };

  toggle_layer_load = () => {
    this.props.set_value_bi({
      key: "modal_layer_load",
      value: !this.props.bi.modal_layer_load,
    });
    this.props.set_value_bi({
      key: "modal_layer_view_map",
      value: false,
    });
  };

  toggle_layer_view_map = () => {
    this.props.set_value_bi({
      key: "modal_layer_view_map",
      value: !this.props.bi.modal_layer_view_map,
    });
    this.props.set_value_bi({
      key: "modal_layer_load",
      value: false,
    });
  };

  on_save = () => {
    const { bi_object, project_object_selected, setting_add_object } =
      this.props.bi;
    const { domain } = this.props.auth;

    if (setting_add_object?._id) {
      const body = {
        ...setting_add_object,
        _id: bi_object._id,
        setting_id: setting_add_object._id,
        project_id: project_object_selected._id,
      };
      this.props.edit_setting(body);
    } else {
      const body = {
        ...setting_add_object,
        _id: bi_object._id,
        project_id: project_object_selected._id,
      };
      this.props.push_setting(body);
    }
    this.props.toggle_add_setting();

    const basemap_mode = setting_add_object?.basemap_mode;
    this.props.set_value_map({
      key: "basemap_mode",
      value: basemap_mode,
    });

    let basemap_props = basemap_styles().find(
      (item) => item?.value === basemap_mode
    );

    const active_basemap = domain_mapid_basemap.includes(domain)
      ? "mapid"
      : "mapbox";
    const basemap_api = basemap_props?.api?.[active_basemap];

    this.props.set_value_map({
      key: "basemap_api",
      value: basemap_api,
    });
  };

  render() {
    //local storage
    const language = localStorage?.language || "ina";

    //local state

    //global props
    const {
      bi_object,
      project_object_selected,
      loading_status,
      loading_item,
      add_setting_current_step,
    } = this.props.bi;

    //content

    const step_array = [
      {
        name: dict?.["Data loaded when click"]?.[language],
      },
      {
        name: dict?.["Data displayed on the map"]?.[language],
      },
      {
        name: dict?.["Other settings"]?.[language],
      },
    ];

    const setting_list = bi_object?.setting_list || [];
    const setting_object = setting_list.find(
      (item) => item?.project_id === project_object_selected?._id
    );

    return (
      <main className="max_height">
        <h1 className="text_bold margin_bottom">
          {
            dict?.[
              "Add settings for data first loaded and data displayed in the map"
            ]?.[language]
          }
        </h1>
        {!setting_object?._id ? (
          <section
            className="container_light margin_bottom background_red_bright"
            style={{ width: "200px" }}
          >
            {dict?.["No settings saved yet"]?.[language]}
          </section>
        ) : (
          <section
            className="container_light margin_bottom"
            style={{ width: "200px" }}
          >
            {dict?.["There are already saved settings"]?.[language]}
          </section>
        )}
        <br />
        {step_array.map((item, idx) => {
          const is_open = add_setting_current_step === idx;
          return (
            <button
              key={idx}
              className={`button_pill margin_bottom margin_right ${
                is_open ? "background_blue" : "background_teal"
              }`}
              onClick={this.on_set_add_setting_current_step.bind(this, idx)}
            >
              <span className="badge_circle background_green margin_right">
                {idx + 1}
              </span>
              {item?.name}
            </button>
          );
        })}

        {loading_status && loading_item === "get_list_layer_bi" && (
          <section
            className="container_light margin_bottom"
            style={{ width: "200px" }}
          >
            <p>{dict?.["Loading layers in the project"]?.[language]}...</p>
          </section>
        )}
        <BI_ADD_LAYER_DATA />
        {add_setting_current_step === 2 && <BI_EDIT_BASEMAP />}
        <br />
        <br />
        <br />
        {add_setting_current_step === 2 && (
          <button className="button background_blue" onClick={this.on_save}>
            {dict?.["Save the BI settings for this project"]?.[language]}
          </button>
        )}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  bi: state.bi,
  project: state.project,
});

export default connect(mapStateToProps, {
  push_setting,
  get_list_layer_bi,
  set_value_bi,
  edit_setting,
  set_value_map,
})(BI_ADD_SETTING);
