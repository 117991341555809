/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*COMPONENT*/
import BI_SIDEBAR_LAYER_LIST from "./BI_SIDEBAR_LAYER_LIST";

/*REDUX*/
import { set_value_bi, get_list_layer_bi } from "../../App/actions/biActions";

/*PICTURE*/

/*FUNCTION*/
import dict from "../../Data/dict.json";

/*DATA*/

/*CONST*/

class BI_PROJECT_LIST extends Component {
  state = {};

  toggle_edit_project = (project_object_selected) => {
    this.props.set_value_bi({
      key: "modal_edit_project",
      value: !this.props.bi.modal_edit_project,
    });
    if (
      this?.props?.bi?.project_object_selected?._id ===
      project_object_selected?._id
    ) {
      this.props.set_value_bi({
        key: "project_object_selected",
        value: {},
      });
    } else {
      const layer_list = project_object_selected?.layer_list || [];
      if (layer_list.length === 0) {
        const query = {
          _id: project_object_selected._id,
          link: project_object_selected.link,
        };
        this.props.get_list_layer_bi(query);
      }
      this.props.set_value_bi({
        key: "project_object_selected",
        value: project_object_selected,
      });
      const { bi_object } = this.props.bi;
      const setting_list = bi_object?.setting_list || [];
      const default_group =
        setting_list.find(
          (item) => item?.project_id === project_object_selected?._id
        )?.default_group || "";

      this.props.set_value_bi({
        key: "default_group",
        value: default_group,
      });
      this.props.set_value_bi({
        key: "group_card_id_selected",
        value: default_group,
      });
    }
  };

  render() {
    //local storage
    const language = localStorage?.language || "ina";

    //local state

    //global props
    const { project_list, project_object_selected } = this.props.bi;
    const project_id_list = this.props.project_id_list;
    const folder_list_child = this.props.folder_list_child;

    //content
    const project_list_final = project_list.filter((item) =>
      project_id_list.includes(item?._id)
    );
    //checklist_active || checklist_not
    let content = null;
    if (project_id_list.length === 0 && folder_list_child.length === 0) {
      content = (
        <section className="container_light background_white margin_bottom">
          {dict?.["There are no projects in this folder"]?.[language]}
        </section>
      );
    } else if (project_id_list.length > 0) {
      content = (
        <section className="container_light background_white margin_bottom">
          {project_list_final.map((item, idx) => {
            const open_status = project_object_selected?._id === item?._id;
            return (
              <section
                key={idx}
                className="two_container_button_right_small cursor_pointer margin_bottom"
                style={{ borderRadius: "5px" }}
              >
                <p
                  onClick={this.toggle_edit_project.bind(this, item)}
                  className="text_medium max_one_lines"
                >
                  {item?.name}
                </p>
                <div
                  onClick={this.toggle_edit_project.bind(this, item)}
                  className={open_status ? "checklist_active" : "checklist_not"}
                />
                {open_status && <BI_SIDEBAR_LAYER_LIST />}
              </section>
            );
          })}
        </section>
      );
    }

    return content;
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  bi: state.bi,
});

export default connect(mapStateToProps, { set_value_bi, get_list_layer_bi })(
  BI_PROJECT_LIST
);
