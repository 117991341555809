/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*COMPONENT*/

/*REDUX*/
import { set_value_bi, edit_inside_object } from "../../App/actions/biActions";

/*PICTURE*/

/*FUNCTION*/

/*DATA*/
import dict from "../../Data/dict.json";

/*CONST*/

class BI_ADD_STEP_5_DEFAULT_VALUE extends Component {
  state = {};

  on_change_card_add_object = (e) => {
    const id_key = e.target.name;
    const value = e.target.value;
    this.props.edit_inside_object({
      key: "card_add_object",
      id_key: id_key,
      value: value,
    });
  };

  on_prev_step = () => {
    const { add_card_current_step } = this.props.bi;
    this.props.set_value_bi({
      key: "add_card_current_step",
      value: add_card_current_step - 1,
    });
  };

  on_next_step = () => {
    const { add_card_current_step } = this.props.bi;
    this.props.set_value_bi({
      key: "add_card_current_step",
      value: add_card_current_step + 1,
    });
  };

  render() {
    //local storage
    const language = localStorage?.language || "ina";

    //local state

    //global props
    const { card_add_object } = this.props.bi;

    //content
    const { default_value } = card_add_object;

    return (
      <main>
        <h1 className="text_bold margin_bottom">
          {dict?.["Enter default value"]?.[language]}
        </h1>
        <p className="text_small">
          {dict?.["Default value if data has not been filled in"]?.[language]}
        </p>
        <p className="text_bold">
          {dict?.["If there is no default value, fill it with 0"]?.[language]}
        </p>
        <br />
        <input
          placeholder="Jika tidak ada nilai default, isi dengan 0"
          type="text"
          name="default_value"
          value={default_value}
          onChange={this.on_change_card_add_object}
        />
        <button
          onClick={this.on_prev_step}
          className="button margin_bottom margin_right inline background_blue"
        >
          {dict?.["Previous step"]?.[language]}
        </button>
        <button onClick={this.on_next_step} className="button background_blue">
          {dict?.["Next step"]?.[language]}
        </button>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  bi: state.bi,
});

export default connect(mapStateToProps, {
  set_value_bi,
  edit_inside_object,
})(BI_ADD_STEP_5_DEFAULT_VALUE);
