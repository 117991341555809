/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*COMPONENT*/

/*REDUX*/

/*PICTURE*/

/*FUNCTION*/

/*DATA*/

/*CONST*/

class FOLDER_COLLAB extends Component {
  state = {};

  render() {
    //local storage

    //local state

    //global props

    //content
    return <main>FOLDER_COLLAB</main>;
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(FOLDER_COLLAB);
