/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*COMPONENT*/
import BI_CARD_LIST from "./BI_CARD_LIST";

/*REDUX*/
import { set_value_bi } from "../../App/actions/biActions";

/*PICTURE*/

/*FUNCTION*/

/*DATA*/
import dict from "../../Data/dict.json";

/*CONST*/

class BI_CARD_LIST_MODE_SELECTION extends Component {
  state = {};

  on_change_group = (e) => {
    const group_id = e.target.value;
    this.props.set_value_bi({
      key: "group_card_id_selected",
      value: group_id,
    });
  };

  render() {
    //local storage
    const language = localStorage?.language || "ina";

    //local state

    //global props
    let { bi_object, project_object_selected, group_card_id_selected } =
      this.props.bi;

    //content
    const setting_list = bi_object?.setting_list || [];
    const setting_object = setting_list.find(
      (item) => item?.project_id === project_object_selected?._id
    );

    const group_card_order = setting_object?.group_card_order || [];
    const group_card_show_other =
      setting_object?.group_card_show_other || "show";
    let group_card_list = setting_object?.group_card_list || [];
    if (group_card_list.length > 0) {
      group_card_list = group_card_order.map((group_id) => {
        return group_card_list.find((element) => element._id === group_id);
      });
    }

    let card_list = bi_object?.card_list || [];
    card_list = card_list.filter(
      (item) => item.project_id === project_object_selected?._id
    );
    if (group_card_id_selected) {
      const group_card_object_selected = group_card_list.find(
        (item) => item?._id === group_card_id_selected
      );
      const card_id_list = group_card_object_selected?.card_id_list || [];
      card_list = card_list.filter((item) => card_id_list.includes(item?._id));
    }

    const layer_load_list = setting_object?.layer_load_list || [];
    const layer_view_map_list = setting_object?.layer_view_map_list || [];
    let layer_final_list = [...layer_load_list];
    layer_view_map_list.forEach((item) => {
      if (!layer_load_list.includes(item)) layer_final_list.push(item);
    });

    return (
      <main>
        <section>
          {group_card_list.length > 0 && (
            <div>
              <select
                name="group_card_id_selected"
                value={group_card_id_selected}
                onChange={this.on_change_group}
                style={{ width: "200px" }}
                className="margin_right margin_bottom"
              >
                {group_card_list.map((item, idx) => {
                  return (
                    <option key={idx} value={item?._id}>
                      {item?.name}
                    </option>
                  );
                })}
                {group_card_show_other === "show" && (
                  <option value="">{dict?.["All"]?.[language]}</option>
                )}
              </select>

              <br />
              <br />
            </div>
          )}
          <BI_CARD_LIST card_list={card_list} mode={this.props.mode} />
        </section>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  bi: state.bi,
  properties: state.properties,
});

export default connect(mapStateToProps, { set_value_bi })(
  BI_CARD_LIST_MODE_SELECTION
);
