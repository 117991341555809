import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import domain_list from "../../Data/domain_list";
import { setLanguage } from "../../App/actions/authActions";

import GeomapidVersion from "./GeomapidVersion";

class LandingAuth extends Component {
  state = {};

  onSetLanguage = () => {
    const language = localStorage?.language ? localStorage?.language : "ina";
    if (language === "ina") {
      this.props.setLanguage("eng");
    } else {
      this.props.setLanguage("ina");
    }
  };

  render() {
    const language = localStorage?.language ? localStorage?.language : "ina";
    let {
      nav,
      content,
      auth,
      registration_step_progress,
      // from
    } = this.props;

    let { domain } = auth;
    const width = window.innerWidth;
    // domain = "befa.mapid.io";
    // const [modal_version, setModal_version] = useState(false);

    // const is_background = domain_list?.[domain]?.background !== "";
    const background = `url(${domain_list?.[domain]?.background})`;

    const language_content = (
      <div className="auth_language">
        <div className="flex_between gap_10">
          <button
            onClick={() => this.onSetLanguage()}
            style={{
              width: "100px",
              height: "50px",
            }}
            className={`button_language ${language === "eng" ? "active" : ""}`}
          >
            <img
              src="https://mapid.co.id/img/icon/uk-flag.png"
              alt="language"
              height="28px"
              className="inline"
            />
            <label className="inline">EN</label>
          </button>

          <div className="v_line h_30" />

          <button
            onClick={() => this.onSetLanguage()}
            style={{
              width: "100px",
              height: "50px",
            }}
            className={`button_language ${language === "ina" ? "active" : ""}`}
          >
            <img
              src="https://mapid.co.id/img/icon/indo-flag.png"
              alt="language"
              height="28px"
            />
            <label className="inline">ID</label>
          </button>
        </div>
      </div>
    );

    // const parent_content = (
    //   <>
    //     {registration_step_progress}
    //     {language_content}
    //   </>
    // );

    return (
      <>
        {/* {modal_version && (
          <Modal
            modalSize="medium"
            id="modal"
            isOpen={modal_version}
            onClose={this.toggle_version}
          >
            <div className="box-body">
              <VersionLog />
            </div>
          </Modal>
        )} */}
        <main className="landing_auth">
          <section>
            <div>
              {nav}
              <section className="auth_content">
                <div
                  className="auth_left"
                  style={{
                    // backgroundImage: is_background ? background : default_style
                    backgroundImage: background,
                  }}
                >
                  {window.innerWidth > 800 && (
                    <div className="paddingLeft_20">
                      <GeomapidVersion {...this.props} />
                    </div>
                  )}
                </div>

                <div className="auth_right">
                  {width < 800 && (
                    <div className="w_full center_perfect marginTop_10">
                      <hr
                        className="reset_all"
                        style={{
                          width: "50px",
                          borderTop: "3px solid #ABABAB",
                          marginTop: "15px",
                        }}
                      />
                    </div>
                  )}

                  <div
                    className={`flex flex_col align_end h_50`}
                    style={{
                      paddingTop: width < 800 ? "50px" : "0px",
                      marginLeft: "35px",
                    }}
                  >
                    {width < 800 &&
                      domain_list?.[domain]?.["tag_line_image"] && (
                        <div
                          className="connected_future_auth"
                          // style={{ margin: "0 20px 20px 0" }}
                        >
                          <Link to="/">
                            <img
                              src={domain_list?.[domain]?.["tag_line_image"]}
                              alt="Connected Future"
                            />
                          </Link>
                        </div>
                      )}

                    {/* {from === "register" ? (
                      <div className={"w_full flex_end"}>
                        {language_content}
                      </div>
                    ) : ( */}
                    {/* )} */}

                    <div className={`w_full flex_end `}>{language_content}</div>
                  </div>

                  <div
                    style={{
                      marginBottom: width < 800 ? "120px" : "0px",
                    }}
                    className={`${
                      width < 800 ? "flex justify_center" : "center_perfect"
                    }`}
                  >
                    <div
                      style={{
                        // marginLeft: width > 800 ? "112px" : "35px",
                        marginTop: width < 800 ? "100px" : "0px",
                      }}
                      className="auth_content_right gap_20"
                    >
                      {registration_step_progress}
                      {content}
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </section>

          <style>{`
  body{
    padding-top: 0px;
    overflow-y: auto;
  }
  .navbarDefault{ visibility: hidden;}
  #top_nav{ visibility: hidden;}
  #sidebar_home{ visibility: hidden;}
  `}</style>
        </main>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {
  setLanguage,
})(LandingAuth);
