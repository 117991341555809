import React, { Component } from "react";

import dict from "../../Data/dict.json";
import { define_extension } from "../../App/validation/handle_file";

export default class UploadFileGeoOrCsv extends Component {
  constructor(props) {
    super(props);
    this.state = {
      latitudeColumn: "",
      longitudeColumn: "",
      step: 0,
    };
  }

  defineExtension = async (e) => {
    const result = await define_extension(e);
    const { toggleStep, setFile } = this.props;

    if (result?.status === "raw") {
      toggleStep("file-step-1");
      setFile({
        ...result,
      });
    } else if (result?.status === "geojson") {
      const { is_valid_in_general } = result;
      toggleStep("geojson-step-1");
      setFile({
        ...result,
      });
      if (is_valid_in_general === false) {
        toggleStep("invalid-file-content");
      }
    }
  };

  handleBulk = () => {
    const { toggleStep } = this.props;
    toggleStep("bulk-step-1");
  };

  render() {
    const language = localStorage?.language || "ina";
    return (
      <div>
        {/* <Upload /> */}
        <section
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "10px",
          }}
        >
          <label className="fileContainer button_block background_blue">
            <div>{dict?.["Upload file"]?.[language]}</div>
            <input
              style={{ display: "none" }}
              type="file"
              accept=".csv, .xlsx, .geojson, .GeoJSON, .json, .JSON, .zip, .kml"
              onChange={this.defineExtension}
              data-mapid="UploadSingle"
            />
            <div>{this.state.childFileName}</div>
          </label>

          <label className="fileContainer button_block background_blue">
            <div
              style={{
                fontSize: "16px",
              }}
              onClick={() => this.handleBulk()}
              data-mapid="clickUploadBulk"
            >
              Geojson Batch Upload
            </div>
          </label>
        </section>

        <section>
          <h3 className="text_medium marginTop_10">
            {dict?.["Note"]?.[language]}:
          </h3>
          <p className="margin_left">
            ●{" "}
            {
              dict?.[
                "The accepted coordinate type is latitude longitude of type WGS84 / CGS in decimal degrees."
              ]?.[language]
            }
          </p>
          <p className="margin_left">
            ●{" "}
            {
              dict?.[
                "For a shapefile file, it must contain 4 files in it (prj, shp, shx, dbf) which are combined into a .zip."
              ]?.[language]
            }
          </p>
          <p className="margin_left">
            ●{" "}
            {
              dict?.[
                "The maximum file size is 14 MB but can be smaller if the geometry size in one feature is too large."
              ]?.[language]
            }
          </p>
          <p className="margin_left">
            ●{" "}
            {
              dict?.[
                "Support .csv, .xlsx, .geojson, .GeoJSON, .json, .JSON, .shp, .zip, and .kml extension."
              ]?.[language]
            }
          </p>
          <p className="margin_left">
            ●{" "}
            {
              dict?.["For xlsx files, make sure the data is on Sheet1"]?.[
                language
              ]
            }
          </p>
          <p className="margin_left">
            ●{" "}
            {
              dict?.["Decimal must be separated by dot. Ex: 106.8858778"]?.[
                language
              ]
            }
          </p>
        </section>
      </div>
    );
  }
}
