/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*COMPONENT*/

/*REDUX*/
import { set_value_properties } from "../../App/actions/propertiesActions";
import { status_action } from "../../App/actions/mapActions";
import { status_toolbox } from "../../App/actions/toolboxActions";

/*PICTURE*/

/*FUNCTION*/
import { generate_paint } from "../../Functions/map_style/generate_paint";

/*FUNCTION*/
import dict from "../../Data/dict.json";

/*CONST*/

class RANGE_NUMBER_STEP extends Component {
  state = {};

  on_change = (e) => {
    const name = e.target.name;
    let value = e.target.value;
    const type = e.target.type;
    if (type === "number" || type === "range") {
      value = Number(value);
    }
    if (name === "color_logic_state") {
      this.setState({ [name]: value });
    } else {
      const { style_parameter } = this.props.properties;
      style_parameter[name] = value;
      this.props.set_value_properties({
        key: "style_parameter",
        value: style_parameter,
      });
    }
  };

  on_regenerate_paint = () => {
    const { style_parameter, paint_object_edited } = this.props.properties;
    const style_parameter_new = {
      ...style_parameter,
      paint_object_edited,
    };
    const paint_object_new = generate_paint(style_parameter_new);
    this.props.set_value_properties({
      key: "paint_object_edited",
      value: paint_object_new,
    });
    this.props.status_action();
    this.props.status_toolbox();
  };

  render() {
    //local storage
    const language = localStorage?.language || "ina";

    //local state

    //global props
    const { style_parameter, paint_object_edited } = this.props.properties;

    //content
    const { style_type, min, max, steps } = style_parameter;
    let color_key;
    if (style_type === "circle") {
      color_key = "circle-color";
    } else if (style_type === "line") {
      color_key = "line-color";
    } else if (style_type === "fill") {
      color_key = "fill-color";
    }

    let rows = [];
    let array_number_step = paint_object_edited?.[color_key] || [];

    array_number_step = array_number_step.slice(2, array_number_step.length);

    // const test_1 = " ≤ x < ";
    const test_2 = " - ";

    // const test_mid_1 = "x < ";
    const test_mid_2 = " < ";

    // const test_big_1 = "x ≥ ";
    const test_big_2 = "≥ ";

    array_number_step.forEach((item, idx) => {
      if (idx % 2 === 1) {
        rows.push(
          <tr key={idx}>
            <td className="text_right">
              {array_number_step?.[idx - 2] !== undefined
                ? `${array_number_step?.[idx - 2]}${test_2}`
                : test_mid_2}
              {item}
            </td>
            <td style={{ width: "20px", verticalAlign: "middle" }}>
              <div
                style={{
                  backgroundColor: array_number_step?.[idx - 1], // Color is at the next index
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  margin: "auto",
                }}
              />
            </td>
          </tr>
        );
      }
    });

    rows.push(
      <tr key="last">
        <td className="text_right">
          {test_big_2}
          {array_number_step?.[array_number_step.length - 2]}
        </td>
        <td style={{ width: "20px", verticalAlign: "middle" }}>
          <div
            style={{
              backgroundColor:
                array_number_step?.[array_number_step.length - 1],
              width: "20px",
              height: "20px",
              border: "1px solid black",
              margin: "auto",
            }}
          />
        </td>
      </tr>
    );

    return (
      <main>
        <section className="margin_bottom">
          <p className="text_medium">
            {dict?.["Ganti jumlah range"]?.[language]}
          </p>
          <input
            className="margin_bottom"
            type="number"
            name="steps"
            value={steps}
            onChange={this.on_change}
          />
          <p className="text_medium">{dict?.["Change min"]?.[language]}</p>
          <input
            className="margin_bottom"
            type="number"
            name="min"
            value={min}
            onChange={this.on_change}
          />
          <p className="text_medium">{dict?.["Change max"]?.[language]}</p>
          <input
            className="margin_bottom"
            type="number"
            name="max"
            value={max}
            onChange={this.on_change}
          />
          <button
            className="button_small background_black"
            onClick={this.on_regenerate_paint}
          >
            {dict?.["Parameter replacement process"]?.[language]}
          </button>
        </section>
        <hr />
        <section style={{ maxHeight: "250px", overflowY: "scroll" }}>
          <table className="table full_width">
            <thead>
              <tr>
                <th>Range</th>
                <th>{dict?.["Color"]?.[language]}</th>
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </table>
        </section>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  layer: state.layer,
  properties: state.properties,
  loading_reducer: state.loading_reducer,
});

export default connect(mapStateToProps, {
  set_value_properties,
  status_action,
  status_toolbox,
})(RANGE_NUMBER_STEP);
