/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";
import Menu from "@mui/material/Menu";

/*COMPONENT*/
import INPUT_DEMOGRAPHY from "./INPUT_DEMOGRAPHY";
import Modal from "../common_modal/Modal";
import INPUT_FORMULA_CONTENT from "./INPUT_FORMULA_CONTENT";

/*REDUX*/
import {
  set_value_insight,
  insight_update,
} from "../../App/actions/insight_v2";
import { importLayer } from "../../App/actions/layerNewActions";

/*PICTURE*/
import ICON_ARROW_LEFT_LINE from "../../Assets/jsx/ICON_ARROW_LEFT_LINE";
import ICON_ARROW_RIGHT_LINE from "../../Assets/jsx/ICON_ARROW_RIGHT_LINE";
import ICON_ARROW_DOWN from "../../Assets/jsx/ICON_ARROW_DOWN";
import ICON_ARROW_UP from "../../Assets/jsx/ICON_ARROW_UP";
import expand from "../../Assets/svg/expand.svg";

/*FUNCTION*/
import run_scoring from "../../Functions/insight/run_scoring";
import uuid from "../../App/validation/uuid";
import format_date_formal from "../../App/validation/format_date_formal";

/*DATA*/

/*CONST*/

class INPUT_FORMULA_SHORT extends Component {
  state = {
    anchor: null,
    modal_edit_weight: false,
    key_selected: "",
    weight_score_selected: 1,
    modal_full_screen: false,
  };

  toggle_full_screen = () => {
    this.setState({ modal_full_screen: !this.state.modal_full_screen });
    this.props.set_value_insight({
      key: "step_formula",
      value: 0,
    });
  };

  on_save = () => {
    this.props.set_value_insight({
      key: "sidebar_content",
      value: "home",
    });

    const {
      array_formula_insight,
      array_group_insight,
      config_final_score,
      provinsi,
      kota,
      kecamatan,
      kelurahan,
      tipe_1_array,
      tipe_2_array,
      tipe_3_array,
      tipe_level,
      tipe_1_all,
      total_poi_initial,
      grid_mode,
      grid_counts,
      grid_width_meters,
      total_square_meters,
    } = this.props.insight_reducer;
    const { geo_project } = this.props.project;
    const { domain } = this.props.auth;

    let features_grid = this.props.insight_reducer.features_grid;
    features_grid = features_grid.map((feature, idx) => {
      feature.idx = idx;
      feature.key = idx;
      feature.properties.key = idx;
      feature.properties.id = idx;
      feature.properties.ID_GRID = idx;
      return feature;
    });

    let fields = [];

    //fields auto genegerate ALL_POI_COUNT, FINAL_NUMBER_SCORE, FINAL_STATUS_SCORE,
    fields.push({
      uuid: uuid(),
      key: "ID_GRID",
      name: "ID_GRID",
      description: "",
      unit: "",
      type: "text",
      isHighlight: false,
      isRequired: true,
      isHide: false,
      isStyle: false,
    });
    fields.push({
      uuid: uuid(),
      key: "ALL_POI_COUNT",
      name: "ALL_POI_COUNT",
      description: "",
      unit: "",
      type: "number",
      isHighlight: false,
      isRequired: true,
      isHide: false,
      isStyle: false,
    });
    fields.push({
      uuid: uuid(),
      key: "FINAL_NUMBER_SCORE",
      name: "FINAL_NUMBER_SCORE",
      description: "",
      unit: "",
      type: "number",
      isHighlight: false,
      isRequired: true,
      isHide: false,
      isStyle: false,
    });
    fields.push({
      uuid: uuid(),
      key: "FINAL_STATUS_SCORE",
      name: "FINAL_STATUS_SCORE",
      description: "",
      unit: "",
      type: "text",
      isHighlight: false,
      isRequired: true,
      isHide: false,
      isStyle: false,
    });

    //fields dari formula (poi dan demografi) array_formula_insight
    array_formula_insight.forEach((item) => {
      fields.push({
        uuid: uuid(),
        key: item.key,
        name: item.key,
        description: "",
        unit: "",
        type: "number",
        isHighlight: false,
        isRequired: true,
        isHide: false,
        isStyle: false,
      });
    });

    const field_key = "FINAL_STATUS_SCORE";
    const style_array = [
      {
        key: "insight_color",
        field_key: field_key,
        paint_object: {
          "fill-opacity": 0.6,
          "fill-outline-color": "transparent",
          "line-color": "#000000",
          "line-width": 1,
          "line-opacity": 1,
          "fill-color": [
            "match",
            ["get", field_key],
            "Sangat Sesuai",
            "rgb(0,107,61)",
            "Sesuai",
            "rgb(128,181,30)",
            "Cukup Sesuai",
            "rgb(255,255,0)",
            "Tidak Sesuai",
            "rgb(246,137,11)",
            "Sangat Tidak Sesuai",
            "rgb(237,19,22)",
            "#FFFFFF",
          ],
        },
        case_recomendation: "text",
        color_logic: "by_text",
        color_mode: "green_to_yellow_to_red",
        value_array: [
          "Sangat Sesuai",
          "Sesuai",
          "Cukup Sesuai",
          "Tidak Sesuai",
          "Sangat Tidak Sesuai",
        ],
        type_of_object: {
          string: 5,
        },
        type_of_array: [
          {
            key: "string",
            value: 5,
          },
        ],
        min: 0,
        max: 100,
        is_number_count: 0,
        steps: 5,
        constant_color: "#a0d062",
        style_type: "fill",
      },
    ];

    const insight_object = {
      is_insight: true,
      version: 2,
      array_formula_insight,
      array_group_insight,
      config_final_score,
      provinsi,
      kota,
      kecamatan,
      kelurahan,
      tipe_1_array,
      tipe_2_array,
      tipe_3_array,
      tipe_level,
      tipe_1_all,
      total_poi_initial,
      grid_mode,
      grid_counts,
      grid_width_meters,
      total_square_meters,
    };
    const geojson = {
      type: "FeatureCollection",
      features: features_grid,
    };
    const body = {
      name: `[INSIGHT V2] ${kota} ${format_date_formal(Date.now())}`,
      description: "",
      type: "Polygon",
      user_id: this?.props?.auth?.user?._id,
      folder: "",
      geo_project_id: geo_project?._id,
      fields,
      geojson,
      status: "digitasi",
      domain,
      style_array,
      default_style_key: "insight_color",
      is_cluster: false,
      is_use_new_style: true,
      is_show_and_fly: false,
      insight_object,
    };
    this.props.importLayer(body, false);
  };

  on_click_step = (step_formula) => {
    this.props.set_value_insight({
      key: "step_formula",
      value: step_formula,
    });
    if (step_formula === 1) {
      this.on_run_scoring();
    }
  };

  on_run_scoring = () => {
    let {
      features_grid,
      array_formula_insight,
      array_group_insight,
      config_final_score,
    } = this.props.insight_reducer;
    run_scoring({
      features_grid,
      array_formula_insight,
      array_group_insight,
      config_final_score,
      set_value_insight: this.props.set_value_insight,
      insight_update: this.props.insight_update,
    });
  };

  on_back = () => {
    this.props.set_value_insight({
      key: "sidebar_content",
      value: "home",
    });
  };

  on_change_weight = (event) => {
    const name = event.target.name;
    const value = Number(event.target.value);
    this.setState(
      {
        weight_score_selected: value,
      },
      () => {
        if (value > 0) {
          const { array_formula_insight } = this.props.insight_reducer;
          const object = array_formula_insight.find(
            (item) => item.key === name
          );
          object.weight_score = value;
          this.props.set_value_insight({
            key: "array_formula_insight",
            value: array_formula_insight,
          });
        }
      }
    );
  };

  toggle_is_directly_proportional = (key) => {
    const { array_formula_insight } = this.props.insight_reducer;
    const object = array_formula_insight.find((item) => item.key === key);
    object.is_directly_proportional = !object.is_directly_proportional;
    this.props.set_value_insight({
      key: "array_formula_insight",
      value: array_formula_insight,
    });
  };

  toggle_edit_weight = (event) => {
    this.setState(
      {
        modal_edit_weight: !this.state.modal_edit_weight,
        anchor: event.currentTarget,
      },
      () => {
        const { modal_edit_weight } = this.state;
        if (modal_edit_weight) {
          const key_selected = event.target.id;
          const { array_formula_insight } = this.props.insight_reducer;
          const object = array_formula_insight.find(
            (item) => item.key === key_selected
          );
          const weight_score_selected = object.weight_score;
          this.setState({ key_selected, weight_score_selected });
        }
      }
    );
  };

  render() {
    //local storage

    //local state
    const {
      anchor,
      modal_edit_weight,
      key_selected,
      weight_score_selected,
      modal_full_screen,
    } = this.state;

    //global props
    const { array_formula_insight, array_group_insight, step_formula } =
      this.props.insight_reducer;

    //content
    const modal_edit_weight_content = modal_edit_weight && (
      <Menu
        id="basic-menu"
        anchorEl={anchor}
        open={modal_edit_weight}
        onClose={this.toggle_edit_weight}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        style={{ borderRadius: "15px" }}
      >
        <div style={{ padding: "7px 15px 0px 15px", width: "100px" }}>
          <input
            type="number"
            name={key_selected}
            value={weight_score_selected}
            onChange={this.on_change_weight}
          />
        </div>
      </Menu>
    );

    const modal_full_screen_content = modal_full_screen && (
      <Modal
        modalSize="full"
        is_hide="show"
        backgroundColor="rgba(255, 255, 255, 1);"
        use_blur={false}
        id="modal_open_formula"
        isOpen={modal_full_screen}
        onClose={this.toggle_full_screen}
      >
        <div className="box-body" id="box-body">
          <INPUT_FORMULA_CONTENT toggle_full_screen={this.toggle_full_screen} />
        </div>
      </Modal>
    );

    return (
      <main className="parent_section_full">
        {modal_edit_weight_content}
        {modal_full_screen_content}
        <div className="section_1">
          <section className="two_container_auto margin_bottom">
            <div>
              <button
                className="button_pill background_grey_light"
                onClick={this.on_back}
              >
                <span className="badge_circle background_black margin_right">
                  <ICON_ARROW_LEFT_LINE fill="#fff" />
                </span>
                <span className="margin_right">Kembali</span>
              </button>
            </div>
            <div className="text_right">
              <button
                className="button_big background_black"
                title="Simpan hasil skoring sebagai layer baru"
                onClick={this.on_save}
                data-mapid="clickSimpan"
              >
                Simpan
              </button>
            </div>
          </section>
          {step_formula === 1 ? (
            <div>
              <section className="margin_bottom">
                <div className="two_container_auto">
                  <h1 className="text_header margin_bottom">
                    Jalankan formula
                  </h1>
                  <div className="text_right">
                    <img
                      className="cursor_pointer"
                      alt="expand"
                      src={expand}
                      style={{ height: "25px" }}
                      onClick={this.toggle_full_screen}
                    />
                  </div>
                </div>

                <p className="text_small">
                  Edit parameter di bawah dan klik "Jalankan formula".
                </p>
                <p className="text_small">
                  Klik "Simpan" untuk menyimpan hasil skoring sebagai layer
                  baru.
                </p>
              </section>

              <section className="two_container_auto margin_bottom_extra">
                <div>
                  <button
                    className="button_pill button_white outline_black"
                    onClick={this.on_click_step.bind(this, 0)}
                  >
                    <span className="badge_circle background_black margin_right">
                      <ICON_ARROW_LEFT_LINE fill="#fff" />
                    </span>
                    <span className="margin_right">Pilih demografi</span>
                  </button>
                </div>
                <div className="text_right">
                  <button
                    className="button_pill button_white outline_black"
                    onClick={this.on_run_scoring}
                  >
                    <span className="margin_right margin_left">
                      Jalankan formula
                    </span>
                    <span className="badge_circle background_black">
                      <ICON_ARROW_RIGHT_LINE fill="#fff" />
                    </span>
                  </button>
                </div>
              </section>
            </div>
          ) : (
            <div>
              <section className="margin_bottom">
                <div className="two_container_auto">
                  <h1 className="text_header margin_bottom">Pilih demografi</h1>
                  <div className="text_right">
                    <img
                      className="cursor_pointer"
                      alt="expand"
                      src={expand}
                      style={{ height: "25px" }}
                      onClick={this.toggle_full_screen}
                    />
                  </div>
                </div>

                <p className="text_small">
                  Pilih parameter demografi yang akan dilibatkan dalam skoring
                </p>
                <p className="text_small">
                  Klik "Simpan" untuk menyimpan hasil skoring sebagai layer
                  baru.
                </p>
              </section>

              <section className="two_container_auto margin_bottom_extra">
                <div></div>
                <div className="text_right">
                  <button
                    className="button_pill button_white outline_black"
                    onClick={this.on_click_step.bind(this, 1)}
                    data-mapid="clickEditFormula"
                  >
                    <span className="margin_right margin_left">
                      Edit formula
                    </span>
                    <span className="badge_circle background_black">
                      <ICON_ARROW_RIGHT_LINE fill="#fff" />
                    </span>
                  </button>
                </div>
              </section>
            </div>
          )}
        </div>

        <section className="section_2">
          {step_formula === 1 ? (
            <div>
              <section>
                {array_group_insight.map((parent) => {
                  const child_array = array_formula_insight.filter(
                    (child) => child.group === parent.key
                  );
                  return (
                    <article key={parent.key} className="margin_bottom_extra">
                      <span className="button_small background_black margin_bottom">
                        {parent.key}
                      </span>
                      <div>
                        {child_array.map((child) => {
                          const { is_directly_proportional, weight_score } =
                            child;
                          return (
                            <article key={child.key}>
                              <span className="badge background_grey_light outline_grey margin_bottom">
                                <section
                                  style={{
                                    display: "grid",
                                    gridTemplateColumns: "20px 30px auto",
                                    gridGap: "10px",
                                    alignItems: "center",
                                  }}
                                >
                                  <button
                                    className={`badge_circle ${
                                      is_directly_proportional
                                        ? "background_green"
                                        : "background_red"
                                    }`}
                                    onClick={this.toggle_is_directly_proportional.bind(
                                      this,
                                      child.key
                                    )}
                                  >
                                    {is_directly_proportional ? (
                                      <ICON_ARROW_UP line_color="#fff" />
                                    ) : (
                                      <ICON_ARROW_DOWN line_color="#fff" />
                                    )}
                                  </button>
                                  <button
                                    className="button_small button_white outline_grey"
                                    id={child.key}
                                    onClick={this.toggle_edit_weight}
                                  >
                                    {weight_score}
                                  </button>
                                  <p className="text_small text_left">
                                    {child.key}
                                  </p>
                                </section>
                              </span>
                            </article>
                          );
                        })}
                      </div>
                    </article>
                  );
                })}

                <table className="table">
                  <tbody>
                    <tr>
                      <td>Simbol</td>
                      <td>Arti</td>
                      <td>Keterangan</td>
                    </tr>
                    <tr>
                      <td>
                        <div className="badge_circle background_green">
                          <ICON_ARROW_UP line_color="#fff" />
                        </div>
                      </td>
                      <td>Berbanding lurus</td>
                      <td>
                        Jika nilai dari parameter besar maka akan memperbesar
                        skor akhir, gunakan ini jika ingin mencari area yang
                        paling banyak mengandung nilai ini di dalamnya.
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="badge_circle background_red">
                          <ICON_ARROW_DOWN line_color="#fff" />
                        </div>
                      </td>
                      <td>Berbanding berbalik</td>
                      <td>
                        Jika nilai dari parameter kecil maka akan memperbesar
                        skor akhir, gunakan ini jika ingin mencari area yang
                        paling sedikit mengandung nilai ini di dalamnya.
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <button className="button button_white outline_grey">
                          1
                        </button>
                      </td>
                      <td>Bobot parameter</td>
                      <td>
                        Jika angka yang dimasukkan melebihi dari parameter
                        lainnya maka pengaruhnya akan lebih besar dalam
                        perhitungan skor akhir (begitu juga sebaliknya).
                      </td>
                    </tr>
                  </tbody>
                </table>
              </section>
            </div>
          ) : (
            <div>
              <INPUT_DEMOGRAPHY />
            </div>
          )}
        </section>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  insight_reducer: state.insight_reducer,
  auth: state.auth,
  layer: state.layer,
  map: state.map,
  project: state.project,
  payment: state.payment,
  sini: state.sini,
  properties: state.properties,
  license_reducer: state.license_reducer,
  loading_reducer: state.loading_reducer,
});

export default connect(mapStateToProps, {
  set_value_insight,
  insight_update,
  importLayer,
})(INPUT_FORMULA_SHORT);
