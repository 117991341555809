import React, { Component } from "react";

export default class AddButtonBetweenList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      is_hover: false,
    };
  }

  handleHover = (value) => {
    this.setState({ is_hover: value });
  };

  render() {
    const addButton = (
      <main
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          paddingBottom: "10px",
        }}
      >
        <section
          onClick={() => {
            this.props.toggle_push_in_the_middle(
              this.props.field_element.uuid,
              this.props.idx
            );
          }}
          onMouseLeave={() => this.handleHover(false)}
          className="button_small background_blue"
          style={{
            fontSize: "20px",
          }}
        >
          +
        </section>
      </main>
    );

    const neutral = (
      <main
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "10px",
          marginBottom: "10px",
        }}
      >
        <section onMouseEnter={() => this.handleHover(true)}>...</section>
      </main>
    );

    return <>{this.state.is_hover ? addButton : neutral}</>;
  }
}
