/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*COMPONENT*/

/*REDUX*/
import { set_value_mapid_ai } from "../../App/actions/mapid_ai_actions";

/*PICTURE*/

/*FUNCTION*/

/*DATA*/

/*CONST*/

class MAPID_AI_CONTENT extends Component {
  state = {
    is_dragging: false,
    startY: 0,
    height: 500,
  };

  handle_drag_start = (event) => {
    const target = event.target;
    const dragArea = document.getElementById("drag_area");
    if (target !== dragArea && !dragArea.contains(target)) {
      return;
    }
    const clientY = event?.touches
      ? event?.touches?.[0]?.clientY
      : event?.clientY;
    this.setState({ is_dragging: true, startY: clientY });
    document.addEventListener("mousemove", this.handle_drag_move);
    document.addEventListener("touchmove", this.handle_drag_move);
    document.addEventListener("mouseup", this.handle_drag_end);
    document.addEventListener("touchend", this.handle_drag_end);
    document.body.style.cursor = "grabbing";
  };

  handle_drag_move = (event) => {
    if (!this.state.is_dragging) return;
    const clientY = event.touches ? event.touches[0].clientY : event.clientY;
    const deltaY = clientY - this.state.startY;
    this.setState((prevState) => {
      const newHeight = Math.max(
        100,
        Math.min(window.innerHeight - 50, prevState.height - deltaY)
      );
      this.props.set_value_mapid_ai({
        key: "height",
        value: newHeight,
      });
      return {
        height: newHeight,
        startY: clientY,
      };
    });
  };

  handle_drag_end = () => {
    this.setState({ is_dragging: false });
    document.removeEventListener("mousemove", this.handle_drag_move);
    document.removeEventListener("touchmove", this.handle_drag_move);
    document.removeEventListener("mouseup", this.handle_drag_end);
    document.removeEventListener("touchend", this.handle_drag_end);
    document.body.style.cursor = "default";
  };

  render() {
    const { height } = this.state;

    return (
      <main
        style={{
          width: "100vw",
          height: `${height}px`,
          overflowX: "hidden",
          overflowY: "auto",
          position: "fixed",
          bottom: 0,
          right: 0,
          paddingBottom: "35px",
          backgroundColor: "white",
          boxShadow: "0px 0px 10px 10px rgba(0, 0, 0, 0.071)",
          transition: this.state.is_dragging
            ? "none"
            : "height 0.4s cubic-bezier(0.25, 1, 0.5, 1)",
          userSelect: "none",
          borderRadius: "0px 0px 0px 0px",
        }}
        onMouseDown={this.handle_drag_start}
        onTouchStart={this.handle_drag_start}
        id="parent_bottom_mobile"
      >
        <section
          style={{
            width: "100%",
            backgroundColor: "#fff",
            cursor: "grab",
            touchAction: "none",
            userSelect: "none",
            textAlign: "center",
            padding: "7px",
            position: "sticky",
            top: 0,
          }}
          id="drag_area"
        >
          <div
            style={{
              height: "5px",
              width: "30vw",
              borderRadius: "4px",
              margin: "auto",
            }}
            className="background_grey_light"
          />
        </section>
        <section
          style={{
            marginRight: "10px",
            marginLeft: "10px",
          }}
          id="scroll_area"
        >
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi turpis
          velit, semper eget tempus vitae, auctor in elit. Duis fermentum turpis
          vitae lorem interdum placerat. Sed eget interdum felis. Donec a
          pharetra arcu. Mauris tempor sagittis blandit. Nulla molestie, ex in
          posuere suscipit, eros turpis suscipit metus, eget faucibus purus
          augue ut sem. Etiam rhoncus nisi eget fermentum interdum. Morbi
          rhoncus risus sit amet urna dapibus venenatis. Aenean et ante a magna
          rhoncus laoreet. Donec aliquet nisl quis quam consequat, ac viverra
          purus tincidunt. Aliquam semper luctus tortor, posuere egestas sem
          blandit ac. Sed congue massa elit, in ultrices nibh tempor sit amet.
          Etiam molestie augue nibh, eu accumsan eros rhoncus sit amet. Integer
          porta malesuada vestibulum. Proin suscipit turpis dolor, in vulputate
          nunc finibus ac. Vestibulum ante ipsum primis in faucibus orci luctus
          et ultrices posuere cubilia curae; Duis pellentesque pharetra quam non
          tincidunt. Proin commodo, orci vel viverra dictum, sem ligula
          ullamcorper ante, in varius metus ex et risus. Vivamus elementum
          turpis vel augue condimentum lobortis. Donec ullamcorper facilisis
          dolor, id vestibulum tellus sollicitudin in. Nullam eu nulla dolor.
          Vestibulum in molestie nibh. Praesent scelerisque velit sit amet nibh
          auctor placerat. Nam sed ligula ut mi auctor varius. Etiam efficitur
          purus et dapibus imperdiet. Donec facilisis augue ut nisi tristique,
          vitae tristique risus lobortis. Mauris tristique sit amet dolor sed
          feugiat. Fusce at sapien justo. Ut elementum mauris risus, ut laoreet
          erat accumsan id. Donec porta rutrum justo quis lobortis. Nam
          vestibulum vestibulum pretium. Nam ac rutrum mauris. Pellentesque
          pellentesque imperdiet tempus. Nulla facilisi. Etiam ante diam,
          aliquam eget sem non, pharetra ullamcorper erat. Lorem ipsum dolor sit
          amet, consectetur adipiscing elit. Vestibulum ac lacus elit.
          Suspendisse vitae venenatis ligula, vitae bibendum mi. Donec in
          sagittis mauris. Praesent auctor justo at auctor maximus. Aenean
          tincidunt, nibh nec tristique lobortis, lectus neque efficitur nulla,
          sed tristique lectus justo vitae nulla. Sed facilisis sapien augue, in
          vehicula lectus laoreet sed. Integer ac dolor in turpis cursus dapibus
          ut semper odio. In ullamcorper pretium sapien non consectetur.
          Curabitur ornare eros ac enim accumsan dignissim. Mauris ut ex non
          quam interdum elementum posuere nec quam. Nunc sodales ultricies
          tortor, at blandit nunc laoreet tempus. Pellentesque pharetra
          vulputate consequat. Donec ultricies nulla sit amet laoreet imperdiet.
          Suspendisse lacinia libero orci, nec ultrices magna eleifend non.
          Nulla facilisi. Ut tristique molestie massa, id aliquet odio aliquam
          et. Ut ultrices porttitor pharetra. Donec nec massa vel eros
          vestibulum egestas ac ac libero. Proin elementum eu orci non dapibus.
          Vestibulum eu libero porta quam fringilla convallis. Donec dignissim
          non nibh eu condimentum. Nam sagittis augue dolor, quis tempus nibh
          vehicula eu. Sed ut dui ut neque elementum interdum eget ac risus.
          Morbi venenatis, nisl quis tincidunt pulvinar, dui nisi tincidunt
          tellus, pretium ornare odio dolor ut magna. Mauris ultricies, est sed
          tincidunt dapibus, est arcu rutrum libero, vitae sodales nulla turpis
          sit amet lacus. Curabitur bibendum tortor cursus est commodo
          efficitur.
        </section>
      </main>
    );
  }
}

const map_state_to_props = (state) => ({
  mapid_ai_reducer: state.mapid_ai_reducer,
});

export default connect(map_state_to_props, { set_value_mapid_ai })(
  MAPID_AI_CONTENT
);
