/*LIBRARY*/
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

/*COMPONENT*/
import ModalGroup from "./ModalGroup";
import Modal from "../common_modal/Modal";

/*REDUX*/
import { createProjectNew } from "../../App/actions/layerNewActions";

/*PICTURE*/

/*FUNCTION*/
import isEmpty from "../../App/validation/is-empty";
import useLanguage from "../common_hooks/useLanguage";

/*DATA*/

/*CONST*/

function CreateNewProject({
  set_restore_page,
  push_recently_created_projects,
}) {
  const dispatch = useDispatch();
  const { dict, language } = useLanguage();

  const { auth } = useSelector((state) => {
    return {
      auth: state.auth,
    };
  });

  const [modalGroup, setModalGroup] = useState(false);
  const [modalLicense] = useState(false);
  const [modalWarningNotAdmin, setModalWarningNotAdmin] = useState(false);
  const [isOrg, setIsOrg] = useState(false);
  const [name, set_name] = useState("");
  const [description, set_description] = useState("");

  const [group, set_group] = useState({});
  const [is_personal, set_is_personal] = useState(false);

  useEffect(() => {
    const value = !is_personal && !isEmpty(group);
    setIsOrg(value);
  }, [is_personal, group?._id]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = () => {
    const { user, domain } = auth;
    const group_id = isOrg ? group._id : "";
    const user_id = isOrg ? group.creator_id : user._id;
    const newItem = {
      date: Date.now(),
      name,
      description,
      user_id,
      isPublic: false,
      group_id,
      domain,
    };

    dispatch(createProjectNew(newItem)).then((result) => {
      push_recently_created_projects(result?.data);
      set_restore_page(1);
    });
  };

  const handle_change = (e) => {
    const value = e?.target.value;
    const target_name = e?.target.name;
    if (target_name === "name") {
      set_name(value);
    } else if (target_name === "description") {
      set_description(value);
    }
  };

  const set_personal = (value) => {
    set_is_personal(value);
    if (value === true) {
      set_group({});
    }
  };

  const toggle_group = () => {
    setModalGroup(!modalGroup);
  };

  const toggleWarningNotAdmin = () => {
    setModalWarningNotAdmin(!modalWarningNotAdmin);
  };

  const handle_group = (value) => {
    set_group(value);
  };

  const modalGroupContent = modalGroup && (
    <Modal
      modalSize="medium"
      id="modal"
      isOpen={modalGroup}
      onClose={toggle_group}
    >
      <ModalGroup
        handle_group={handle_group}
        set_is_personal={set_is_personal}
        group={group}
        modalLicense={modalLicense}
        modalWarningNotAdmin={modalWarningNotAdmin}
        toggleWarningNotAdmin={toggleWarningNotAdmin}
      />
    </Modal>
  );

  const modalWarningNotAdminContent = modalWarningNotAdmin && (
    <Modal
      modalSize="small"
      id="warning modal"
      isOpen={modalWarningNotAdmin}
      onClose={toggleWarningNotAdmin}
    >
      <div className="box-body" style={{ textAlign: "center" }}>
        <main className="text_bold">
          {
            dict[
              "You are only viewer in this group, only admin can add group to the project."
            ][language]
          }
        </main>
      </div>
    </Modal>
  );

  return (
    <main>
      <header className="flex justify_start gap_10">
        <button
          onClick={() => set_restore_page(1)}
          className="rounded_full padding_2 bg_blue button_inactive text_white h_30 w_30 bold center_perfect"
        >
          {`<`}
        </button>
        <div className="header">{dict["Create a new project"][language]}</div>
      </header>
      <input
        type="text"
        name="name"
        id="name"
        placeholder={dict["Project name"][language]}
        value={name}
        onChange={(e) => handle_change(e)}
        className="input_square"
      />
      <textarea
        type="text"
        name="description"
        className="input_square"
        id="description"
        placeholder={dict["Project description"][language]}
        value={description}
        onChange={handle_change}
        rows="4"
      />
      <section className="center_perfect">
        <div
          onClick={() => set_personal(true)}
          className="button background_blue"
          id={!isOrg ? "on" : "off"}
          style={{ width: "150px", maxWidth: "40%", marginRight: "10px" }}
        >
          Personal
        </div>
        <div
          onClick={toggle_group}
          className="button background_blue"
          id={isOrg ? "on" : "off"}
          style={{ width: "150px", maxWidth: "40%" }}
        >
          Team
        </div>
      </section>
      <div style={{ marginTop: "50px" }}>
        <button
          onClick={handleSubmit}
          type="submit"
          className="button_block background_blue"
        >
          {dict["Create Project"][language]}
        </button>
      </div>
      {modalGroupContent}
      {modalWarningNotAdminContent}
    </main>
  );
}

export default CreateNewProject;
