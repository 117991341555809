/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

/*COMPONENT*/
import Modal from "../../Components/common_modal/Modal";
import SpinnerSimpleBlue from "../../Components/common_spinner/SpinnerSimpleBlue";
import ErrorFree from "../../Components/user/ErrorFree";

/*REDUX*/
import { logoutUser, openModal } from "../../App/actions/authActions";
import {
  addGroup,
  getGroups,
  deleteGroup,
  editGroup,
  searchUser,
  addMember,
} from "../../App/actions/groupActions";
import { getUserPaymentList } from "../../App/actions/paymentActions";
import {
  verify_license_user,
  verify_license_group,
} from "../../App/actions/license_actions";
import { set_value_user } from "../../App/actions/authActions";

/*PICTURE*/
import ICON_PLUS from "../../Assets/jsx/ICON_PLUS";
import IconRenameText from "../../Components/common_icon/IconRenameText";
import IconDeleteText from "../../Components/common_icon/IconDeleteText";

/*GENERAL*/
import isEqual from "../../App/validation/is-equal";
import isEmpty from "../../App/validation/is-empty";
import formatDate from "../../App/validation/format-date";

/*DATA*/
import dict from "../../Data/dict.json";

/*CONST*/

class GROUP_LIST_DASHBOARD extends Component {
  state = {
    user: {},
    modal_add_group: false,
    modal_delete: false,
    modal_edit: false,
    modal_add_member: false,
    name: "",
    group_state: {},
    search_user: "",
    member_id: "",
    modal_device: false,
  };

  componentDidMount() {
    this.props.getUserPaymentList();
    this.props.getGroups();
    this.props.verify_license_user();
    this.props.verify_license_group();
  }

  componentDidUpdate(prevProps) {
    //close modal_add_group
    const { groups } = this.props.auth;
    const groups_prev = prevProps.auth.groups;
    if (!isEqual(groups, groups_prev)) {
      this.setState({ modal_add_group: false, modal_edit: false });
    } else {
      return null;
    }
    //prevent user error when token expired
    const prev_user =
      prevProps.auth && prevProps.auth.user ? prevProps.auth.user : {};
    const this_user =
      this.props.auth && this.props.auth.user ? this.props.auth.user : {};
    if (!isEqual(prev_user, this_user)) {
      this.setState({ user: this_user });
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const next_user =
      nextProps.auth && nextProps.auth.user ? nextProps.auth.user : {};
    const this_user = prevState.user;
    if (!isEqual(next_user, this_user)) {
      return {
        user: this_user,
      };
    } else return null;
  }

  toggle_payment_universal = () => {
    const { modal_payment_universal } = this.props.auth;
    this.props.set_value_user(
      "modal_payment_universal",
      !modal_payment_universal
    );
  };

  toggle_login = () => {
    const { modal_login } = this.props.auth;
    this.props.set_value_user("modal_login", !modal_login);
  };

  onOpenModal = (name) => {
    this.props.openModal(name);
  };

  toggle_edit = (group) => {
    const { license_user_status, license_group_status } =
      this.props.license_reducer;
    const payment_type = license_user_status?.payment_type;
    const logic_1 = ![
      // "license_academy",
      // "license_1",
      "license_2",
      "license_3",
    ].includes(payment_type);
    const logic_2 = !license_group_status?.is_has_license;
    if (logic_1 && logic_2) {
      this.toggle_payment_universal();
    } else {
      if (group) {
        this.setState({ group_state: group, name: group.name });
      }
      this.setState({
        modal_edit: !this.state.modal_edit,
        modal_add_member: false,
      });
    }
  };

  toggle_delete = (group) => {
    if (group) {
      this.setState({ group_state: group });
    }
    this.setState({
      modal_delete: !this.state.modal_delete,
      modal_add_member: false,
    });
  };

  onDeleteClick = () => {
    const { group_state } = this.state;
    const content = {
      group_id: group_state._id,
    };
    this.props.deleteGroup(content);
    this.toggle_delete();
  };

  handleChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    if (name === "search_user") {
      this.setState({
        [name]: value,
      });
      const searchFunction = () => {
        this.props.searchUser(this.state.search_user);
      };
      setTimeout(function () {
        searchFunction();
      }, 2000);
    } else {
      this.setState({
        [name]: value,
      });
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { name } = this.state;
    const content = {
      name,
    };
    this.props.addGroup(content);
  };

  handleSubmitEdit = (e) => {
    e.preventDefault();
    const { name, group_state } = this.state;
    const content = {
      group_name: name,
      group_id: group_state._id,
    };
    this.props.editGroup(content);
    this.setState({ modal_edit: false });
  };

  toggle_add_group = () => {
    const { license_user_status, license_group_status } =
      this.props.license_reducer;
    const payment_type = license_user_status?.payment_type;
    const logic_1 = ![
      // "license_academy",
      // "license_1",
      "license_2",
      "license_3",
    ].includes(payment_type);
    const logic_2 = !license_group_status?.is_has_license;
    if (logic_1 && logic_2) {
      this.toggle_payment_universal();
    } else {
      this.setState({
        modal_add_group: !this.state.modal_add_group,
      });
    }
  };

  toggleDevice = () => {
    this.setState({
      modal_device: !this.state.modal_device,
    });
  };

  render() {
    //local storage
    const language = localStorage?.language || "ina";

    //local state
    const { name, group_name } = this.state;

    //global props
    const { loading, loadingDetail, groups, user } = this.props.auth;
    const { license_user_status, license_group_status } =
      this.props.license_reducer;

    //local variable
    let group_list_content;
    let icon_edit_content;
    let loading_content;

    //content
    let icon_content = (
      <button
        className="button background_blue"
        onClick={this.handleSubmit}
        type="submit"
        data-mapid="clickSave"
      >
        {dict["Save"][language]}
      </button>
    );

    if (loadingDetail && isEmpty(groups)) {
      loading_content = (
        <SpinnerSimpleBlue
          width={200}
          unik="loading_item_list"
          marginTop="0px"
          center={true}
          border={0.1}
        />
      );
    } else if (!isEmpty(groups)) {
      if (loading) {
        icon_content = (
          <SpinnerSimpleBlue
            width={48}
            unik="loading_item_list"
            marginTop="0px"
            center={true}
            border={0.1}
          />
        );
        icon_edit_content = (
          <SpinnerSimpleBlue
            width={48}
            unik="loading_item_list"
            marginTop="0px"
            center={true}
            border={0.1}
          />
        );
      } else {
        icon_edit_content = (
          <button
            className="button background_blue margin_top"
            type="submit"
            onClick={this.handleSubmitEdit}
            data-mapid="clickSaveEdit"
          >
            {dict["Save"][language]}
          </button>
        );
      }

      group_list_content = (
        <main
          style={{
            display: "inline-block",
            verticalAlign: "top",
          }}
        >
          {groups.map((group, idx) => {
            const { name, members, _id, payment } = group;
            let organizationContent = null;
            if (!isEmpty(payment)) {
              const { date_in } = payment;
              const multiple_month = payment.multiple_month
                ? payment.multiple_month
                : 1;
              //86400 unix = 1 day
              //1 month = 30.5 day
              const month_unix = 86400 * 30.5;
              const day_unix = 86400;
              const date_finish_unix =
                parseInt((new Date(date_in).getTime() / 1000).toFixed(0)) +
                month_unix * multiple_month;
              const date_now_unix = parseInt(
                (new Date(Date.now()).getTime() / 1000).toFixed(0)
              );
              const delta_unix =
                (date_finish_unix - date_now_unix) * multiple_month;
              const delta_day = parseInt(delta_unix / day_unix);
              const date_finish = new Date(date_finish_unix * 1000);
              if (delta_day > 0) {
                if (delta_day > 30) {
                  organizationContent = (
                    <div className="badge_normal background_grey">
                      <b>
                        {dict["Team license active until"][language]}{" "}
                        {formatDate(date_finish)}
                      </b>
                    </div>
                  );
                } else {
                  organizationContent = (
                    <div className="badge_normal background_grey">
                      <b>
                        {dict["Team license active for"][language]} {delta_day}{" "}
                        {dict["days"][language]}
                      </b>
                    </div>
                  );
                }
              } else {
                organizationContent = (
                  <div className="badge_normal background_grey">
                    <b>{dict["Team license exp."][language]}</b>
                  </div>
                );
              }
            }
            const member = members.filter(
              (member) =>
                (member?.user?._id ? member?.user?._id : member?.user) ===
                user._id
            )[0];
            const statusUser =
              member && member?.status && member?.status !== "accepted"
                ? member.status
                : "viewer";
            let deleteButton = null;
            let editButton = null;
            if (statusUser === "creator") {
              deleteButton = (
                <div
                  onClick={this.toggle_delete.bind(this, group)}
                  data-mapid="clickDelete"
                  style={{
                    marginTop: "10px",
                    cursor: "pointer",
                    backgroundColor: "#fff",
                    borderRadius: "2rem",
                    width: "33px",
                    height: "33px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    boxShadow: "0px 0px 3px 3px rgba(0, 0, 0, 0.071)",
                  }}
                >
                  <IconDeleteText color="#ff5555" />
                </div>
              );
              editButton = (
                <div
                  onClick={this.toggle_edit.bind(this, group)}
                  data-mapid="clickEditGroup"
                  style={{
                    marginTop: "10px",
                    cursor: "pointer",
                    backgroundColor: "#fff",
                    borderRadius: "2rem",
                    width: "33px",
                    height: "33px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    boxShadow: "0px 0px 3px 3px rgba(0, 0, 0, 0.071)",
                  }}
                >
                  <IconRenameText color="#52606e" />
                </div>
              );
            } else if (statusUser === "admin") {
              editButton = (
                <div
                  onClick={this.toggle_edit.bind(this, group)}
                  style={{
                    marginTop: "10px",
                    cursor: "pointer",
                    backgroundColor: "#fff",
                    borderRadius: "2rem",
                    width: "33px",
                    height: "33px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    boxShadow: "0px 0px 3px 3px rgba(0, 0, 0, 0.071)",
                  }}
                >
                  <IconRenameText color="#52606e" />
                </div>
              );
            }
            return (
              <main
                className="container_project background_white outline_grey"
                key={idx}
              >
                <main className="container_square">
                  <section
                    className="container_first_half_blog"
                    style={{ width: "100%" }}
                  >
                    <div className="two_container_small_right">
                      <section>
                        <h1 className="title_project">{name}</h1>
                        <div className="sub_title_project">
                          {organizationContent}
                        </div>
                      </section>
                      <section>
                        {editButton}
                        {deleteButton}
                      </section>
                    </div>
                  </section>
                  <section className="container_card_footer">
                    <div
                      style={{
                        fontSize: "13px",
                        color: "#52606e",
                        marginBottom: "10px",
                      }}
                    >
                      <div>
                        {members.length}{" "}
                        {members.length > 1 ? "members" : "member"}
                      </div>
                      <div>
                        {dict["You"][language]}: {statusUser}
                      </div>
                    </div>
                    <Link
                      to={`/group/${_id}`}
                      className="button background_blue"
                      data-mapid="clickOpen"
                    >
                      {dict["Open"][language]}
                    </Link>
                  </section>
                </main>
              </main>
            );
          })}
        </main>
      );
    }

    const modalDelete = this.state.modal_delete && (
      <Modal
        modalSize="small"
        isOpen={this.state.modal_delete}
        onClose={this.toggle_delete}
      >
        <div className="box-body" style={{ textAlign: "center" }}>
          <p>{dict["Are you sure to delete this group?"][language]}</p>
          <br />
          <h2>{group_name}</h2>
          <br />
          <button
            className="button_very_small background_red"
            data-mapid="clickDeleteModal"
            onClick={this.onDeleteClick}
          >
            {dict["Delete"][language]}
          </button>
        </div>
      </Modal>
    );

    const modalEdit = this.state.modal_edit && (
      <Modal
        modalSize="small"
        isOpen={this.state.modal_edit}
        onClose={this.toggle_edit}
      >
        <div className="box-body" style={{ textAlign: "center" }}>
          <form>
            <input
              className="input_square"
              type="text"
              name="name"
              id="name"
              value={name}
              onChange={this.handleChange}
              data-mapid="editInputGroup"
            />
            <div>{icon_edit_content}</div>
          </form>
        </div>
      </Modal>
    );

    let create_group_content = <p>Pending</p>;

    if (
      license_user_status?.is_has_license ||
      license_group_status?.is_has_license
    ) {
      create_group_content = (
        <main>
          <h1 className="text_header">
            {dict["Create a new group"][language]}
          </h1>
          <br />
          <br />
          <form>
            <div className="form-group">
              <input
                className="input_square"
                type="text"
                name="name"
                id="name"
                value={name}
                onChange={this.handleChange}
                data-mapid="inputNamaGroup"
              />
              <div className="text-center">{icon_content}</div>
            </div>
          </form>
        </main>
      );
    } else {
      create_group_content = <ErrorFree />;
    }

    const modal_add_group = this.state.modal_add_group && (
      <Modal
        modalSize="small"
        id="deleteModal"
        isOpen={this.state.modal_add_group}
        onClose={this.toggle_add_group}
      >
        <div className="box-body">{create_group_content}</div>
      </Modal>
    );

    return (
      <main className="main_container footer_margin">
        <section className="header_medium" style={{ marginBottom: "20px" }}>
          {dict["Your Collaboration Group"][language]}
        </section>

        <button
          onClick={this.toggle_add_group}
          className="button_pill background_white margin_bottom margin_right"
          data-mapid="clickNewGroup"
        >
          <span className="badge_circle background_green margin_right">
            <ICON_PLUS />
          </span>
          {dict["New Group"][language]}
        </button>

        <br />
        {loading_content}
        {group_list_content}
        {modal_add_group}
        {modalDelete}
        {modalEdit}
        <Helmet>
          <title>{dict["Group"][language]}</title>
          <meta name="description" content={dict["Group"][language]} />
        </Helmet>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  payment: state.payment,
  license_reducer: state.license_reducer,
});

export default connect(mapStateToProps, {
  addGroup,
  getGroups,
  deleteGroup,
  editGroup,
  searchUser,
  addMember,
  logoutUser,
  getUserPaymentList,
  openModal,
  verify_license_user,
  verify_license_group,
  set_value_user,
})(GROUP_LIST_DASHBOARD);
