/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";
import Menu from "@mui/material/Menu";

/*COMPONENT*/

/*REDUX*/
import { set_value_layer } from "../../App/actions/layerActions";
import { set_value_properties } from "../../App/actions/propertiesActions";
import { set_value_user } from "../../App/actions/authActions";
import { status_action } from "../../App/actions/mapActions";
import { set_value_insight } from "../../App/actions/insight_v2";

/*PICTURE*/
import icon_edit_point from "../../Assets/svg/icon_edit_point.svg";
import icon_edit_line from "../../Assets/svg/icon_edit_line.svg";
import icon_edit_polygon from "../../Assets/svg/icon_edit_polygon.svg";
import icon_delete_geometry from "../../Assets/svg/icon_delete_geometry.svg";
import icon_tool_new from "../../Assets/svg/icon_tool_new.svg";
import icon_legends from "../../Assets/svg/icon_legends.svg";
import icon_setting from "../../Assets/svg/icon_setting.svg";
import map_2d from "../../Assets/png_jpg/map_2d.png";
import map_3d from "../../Assets/png_jpg/map_3d.png";
import map_light from "../../Assets/png_jpg/map_light.png";
import map_dark from "../../Assets/png_jpg/map_dark.png";
import map_sat from "../../Assets/png_jpg/map_sat.png";
import map_sat_square from "../../Assets/png_jpg/map_sat_square.png";
import lang_ina from "../../Assets/png_jpg/lang_ina.png";
import lang_eng from "../../Assets/png_jpg/lang_eng.png";

/*FUNCTION*/

/*DATA*/

/*CONST*/
const type_to_draw_mode = {
  Polygon: "draw_polygon",
  Point: "draw_point",
  LineString: "draw_line_string",
};

class MAP_CONTROL_BASIC extends Component {
  state = {
    geo_location_status: "pending", //pending, loading,  error, browser_not_supported, success
    geo_location_error: "",
    modal_change_map: false,
    modal_setting_map: false,
    anchor: null,
  };

  componentDidUpdate(prevProps) {
    const geometryStatus_after = this.props.layer.geometryStatus;
    const geometryStatus_before = prevProps.layer.geometryStatus;
    if (geometryStatus_after !== geometryStatus_before) {
      let sidebar_right_button_map = document.getElementById(
        "sidebar_right_button_map"
      );
      let legend_map = document.getElementById("legend_map");
      let change_basemap_button_map = document.getElementById(
        "change_basemap_button_map"
      );
      let change_language_button_map = document.getElementById(
        "change_language_button_map"
      );
      let setting_map = document.getElementById("setting_map");

      if (geometryStatus_after) {
        sidebar_right_button_map.style.top = "205px";
        legend_map.style.top = "240px";
        change_basemap_button_map.style.top = "275px";
        change_language_button_map.style.top = "310px";
        setting_map.style.top = "345px";
      } else {
        sidebar_right_button_map.style.top = "135px";
        legend_map.style.top = "170px";
        change_basemap_button_map.style.top = "205px";
        change_language_button_map.style.top = "240px";
        setting_map.style.top = "275px";
      }
    }
  }

  on_reset_mode_input_parent = () => {
    this.props.set_value_insight({
      key: "mode_input_parent",
      value: "",
    });
    localStorage.setItem("mode_input_parent", "");
  };

  toggle_is_pause_zoom_map = () => {
    this.props.set_value_layer({
      key: "is_pause_zoom_map",
      value: !this.props.layer.is_pause_zoom_map,
    });
  };

  toggle_change_map = (event) => {
    this.setState({
      modal_change_map: !this.state.modal_change_map,
      anchor: event.currentTarget,
    });
  };

  toggle_setting_map = (event) => {
    this.setState({
      modal_setting_map: !this.state.modal_setting_map,
      anchor: event.currentTarget,
    });
  };

  on_set_style_map = (basemap_used) => {
    this.setState({ modal_change_map: false });
    const basemap_url = process.env.REACT_APP_MAPID_BASEMAP;
    const basemap_key = process.env.REACT_APP_BASEMAP_KEY;
    let style_url = "";
    switch (basemap_used) {
      case "2d":
        style_url = `${basemap_url}/styles/street-2d-building/style.json?key=${basemap_key}`;
        break;
      case "3d":
        style_url = `${basemap_url}/styles/basic/style.json?key=${basemap_key}`;
        break;
      case "light":
        style_url = `${basemap_url}/styles/light/style.json?key=${basemap_key}`;
        break;
      case "dark":
        style_url = `${basemap_url}/styles/dark/style.json?key=${basemap_key}`;
        break;
      case "sat":
        style_url = `${basemap_url}/styles/satellite/style.json?key=${basemap_key}`;
        break;
      default:
        style_url = `${basemap_url}/styles/street-2d-building/style.json?key=${basemap_key}`;
        break;
    }
    const { map_object } = this.props.layer;
    if (map_object) {
      map_object.setStyle(style_url);
    }
    setTimeout(() => {
      this.props.set_value_properties({
        key: "basemap_used",
        value: basemap_used,
      });
    }, 1000);
  };

  toggle_language = () => {
    const { language } = this.props.auth;
    let new_language = "ina";
    if (language === "ina") {
      new_language = "eng";
    } else if (language === "eng") {
      new_language = "ina";
    }
    localStorage.setItem("language", new_language);
    this.props.set_value_user("language", new_language); //masih pakai format lama
  };

  /*
  left --> kiri, list layer
  right --> kanan, detail layer, pie chart dsb
  */
  toggle_sidebar_right = () => {
    const { sidebar_right_status } = this.props.properties;
    this.props.set_value_properties({
      key: "sidebar_right_status",
      value: !sidebar_right_status,
    });
  };

  toggle_legend = () => {
    this.props.set_value_properties({
      key: "legend_status",
      value: !this.props.properties.legend_status,
    });
  };

  on_create_geometry = () => {
    const { map_object, current_type } = this.props.layer;
    const draw_mode = type_to_draw_mode?.[current_type] || "";
    if (map_object && draw_mode) {
      console.log("ADA map_object && draw_mode");
      map_object.getCanvas().style.cursor = "crosshair";
      map_object._controls[1].changeMode(draw_mode);
      this.props.set_value_layer({
        key: "draw_active",
        value: true,
      });
    } else {
      console.log("TIDAK ADA map_object && draw_mode");
    }
  };

  on_delete_geometry = () => {
    const { map_object } = this.props.layer;
    const feature_selected =
      map_object?._controls?.[1]?.getSelected()?.features?.[0];
    const feature_id = feature_selected?.id;
    if (feature_id && map_object) {
      map_object?._controls?.[1]?.trash(feature_id);
    }
  };

  render() {
    const language = localStorage?.language || "ina";

    const { modal_change_map, modal_setting_map, anchor } = this.state;

    const { geometryStatus, current_type, draw_active, is_pause_zoom_map } =
      this.props.layer;
    const { basemap_used } = this.props.properties;

    const modal_change_map_content = modal_change_map && (
      <Menu
        anchorEl={anchor}
        open={modal_change_map}
        onClose={this.toggle_change_map}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <div style={{ padding: "7px 15px 0px 15px", width: "120px" }}>
          <section className="text_right margin_bottom">
            <button
              className="button_small background_black"
              onClick={this.toggle_change_map}
            >
              X
            </button>
          </section>
          <img
            onClick={this.on_set_style_map.bind(this, "2d")}
            className="cursor_pointer margin_right margin_bottom inline"
            alt="map_2d"
            src={map_2d}
            height={50}
            style={{
              border: `3px solid ${
                basemap_used === "2d" ? "#21a5f6" : "#dedede"
              }`,
            }}
          />
          <img
            onClick={this.on_set_style_map.bind(this, "3d")}
            className="cursor_pointer margin_bottom inline"
            alt="map_3d"
            src={map_3d}
            height={50}
            style={{
              border: `3px solid ${
                basemap_used === "3d" ? "#21a5f6" : "#dedede"
              }`,
            }}
          />
          <br />
          <img
            onClick={this.on_set_style_map.bind(this, "light")}
            className="cursor_pointer margin_right margin_bottom inline"
            alt="map_light"
            src={map_light}
            height={50}
            style={{
              border: `3px solid ${
                basemap_used === "light" ? "#21a5f6" : "#dedede"
              }`,
            }}
          />
          <img
            onClick={this.on_set_style_map.bind(this, "dark")}
            className="cursor_pointer margin_bottom inline"
            alt="map_dark"
            src={map_dark}
            height={50}
            style={{
              border: `3px solid ${
                basemap_used === "dark" ? "#21a5f6" : "#dedede"
              }`,
            }}
          />
          <br />
          <img
            onClick={this.on_set_style_map.bind(this, "sat")}
            className="cursor_pointer margin_right margin_bottom inline"
            alt="map_sat"
            src={map_sat}
            height={53}
            style={{
              border: `3px solid ${
                basemap_used === "sat" ? "#21a5f6" : "#dedede"
              }`,
            }}
          />
        </div>
      </Menu>
    );

    const modal_setting_map_content = modal_setting_map && (
      <Menu
        anchorEl={anchor}
        open={modal_setting_map}
        onClose={this.toggle_setting_map}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <main style={{ padding: "7px 15px 0px 15px", width: "150px" }}>
          <section className="text_right">
            <button
              className="button_small background_black"
              onClick={this.toggle_setting_map}
            >
              X
            </button>
          </section>
          <table>
            <tbody>
              <tr>
                <td>
                  <button
                    className={
                      is_pause_zoom_map ? "checklist_active" : "checklist_not"
                    }
                    onClick={this.toggle_is_pause_zoom_map}
                  />
                </td>
                <td className="text_small">Pause map zoom</td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <button
                    className="button_small background_blue"
                    onClick={this.on_reset_mode_input_parent}
                  >
                    Reset mode insight
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </main>
      </Menu>
    );

    return (
      <main>
        {modal_change_map_content}
        {modal_setting_map_content}
        {geometryStatus && (
          <>
            <button
              className={`map_button_control create_geometry ${
                draw_active ? "control_active" : ""
              }`}
              onClick={this.on_create_geometry}
            >
              {current_type === "Point" ? (
                <img alt="A" src={icon_edit_point} height={15} />
              ) : current_type === "LineString" ? (
                <img alt="A" src={icon_edit_line} height={15} />
              ) : current_type === "Polygon" ? (
                <img alt="A" src={icon_edit_polygon} height={15} />
              ) : (
                "?"
              )}
            </button>
            <button
              className="map_button_control delete_geometry"
              onClick={this.on_delete_geometry}
            >
              <img alt="A" src={icon_delete_geometry} width={15} />
            </button>
          </>
        )}
        <button
          className="map_button_control sidebar_right_button_map"
          id="sidebar_right_button_map"
          onClick={this.toggle_sidebar_right}
        >
          <img alt="Tool" src={icon_tool_new} width={25} />
        </button>
        <button
          className="map_button_control legend_map"
          id="legend_map"
          onClick={this.toggle_legend}
        >
          <img alt="Legend" src={icon_legends} width={25} />
        </button>
        <button
          className="map_button_control change_basemap_button_map"
          id="change_basemap_button_map"
          onClick={this.toggle_change_map}
        >
          <img
            src={
              basemap_used === "2d"
                ? map_2d
                : basemap_used === "3d"
                ? map_3d
                : basemap_used === "light"
                ? map_light
                : basemap_used === "dark"
                ? map_dark
                : basemap_used === "sat"
                ? map_sat_square
                : map_2d
            }
            height={30}
            alt="sat"
            style={{
              borderRadius: "5px",
            }}
          />
        </button>
        <button
          className="map_button_control change_language_button_map"
          id="change_language_button_map"
          onClick={this.toggle_language}
        >
          <img
            src={language === "ina" ? lang_ina : lang_eng}
            height={25}
            alt={language}
          />
        </button>
        <button
          className="map_button_control setting_map"
          id="setting_map"
          onClick={this.toggle_setting_map}
        >
          <img src={icon_setting} height={25} alt="Set" />
        </button>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  layer: state.layer,
  properties: state.properties,
});

export default connect(mapStateToProps, {
  set_value_layer,
  set_value_properties,
  set_value_user,
  status_action,
  set_value_insight,
})(MAP_CONTROL_BASIC);
