/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*COMPONENT*/
import MAP_VIEWER from "../../Components/viewer_map/MAP_VIEWER";
import NO_LICENSE from "../../Components/blocked_page/NO_LICENSE";
import LOADING_PAGE from "../../Components/blocked_page/LOADING_PAGE";

/*REDUX*/
import { getUserPaymentList } from "../../App/actions/paymentActions";
import {
  getProjectDetailByLink,
  clearProject,
} from "../../App/actions/projectActions";
import { clearLayer } from "../../App/actions/layerActions";
import { getLayerOnly } from "../../App/actions/layerNewActions";
import { set_value_user } from "../../App/actions/authActions";
import { getGroups, getGroupsProject } from "../../App/actions/groupActions";
import { getDetailLayerById } from "../../App/actions/layerNewActions";
import {
  set_value_properties,
  get_detail_many_layer,
} from "../../App/actions/propertiesActions";
import { get_layer_by_share_link } from "../../App/actions/sharedLinkActions";
import {
  verify_license_user,
  verify_license_group,
} from "../../App/actions/license_actions";
import { set_value_layer } from "../../App/actions/layerActions";

/*PICTURE*/

/*FUNCTION*/
import decode_params_to_object from "../../App/validation/decode_params_to_object";

/*DATA*/
import dict from "../../Data/dict.json";

/*CONST*/

class PARENT_MAP_VIEWER extends Component {
  state = {};

  componentDidMount() {
    this.props.set_value_layer({
      key: "page_layer_mode",
      value: "viewer",
    });
    this.props.verify_license_user();
    this.props.verify_license_group();
    const geo_project_link = this?.props?.match?.params?.geo_project_link;
    const geo_layer_link = this?.props?.match?.params?.geo_layer_link;
    if (geo_project_link === "data") {
      this.props.getLayerOnly(geo_layer_link);
    } else if (geo_project_link === "share") {
      const search = window?.location?.search?.substring(1);
      let object_param = {};
      let share_link;
      if (search) {
        object_param = JSON.parse(
          '{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}',
          function (key, value) {
            return key === "" ? value : decodeURIComponent(value);
          }
        );
        share_link = object_param?.link;
        this.props.get_layer_by_share_link({ link: share_link });
      }
    } else {
      this.props.getProjectDetailByLink(geo_project_link);
      this.props.getGroupsProject(geo_project_link);
    }
    this.props.getUserPaymentList();
    this.props.getGroups();

    this.props.set_value_properties({
      key: "sidebar_right_status",
      value: false,
    });
  }

  componentDidUpdate(prevProps) {
    const isAuthenticated_after = this.props.auth.isAuthenticated;
    const isAuthenticated_before = prevProps.auth.isAuthenticated;

    if (isAuthenticated_after !== isAuthenticated_before) {
      const geo_project_link = this?.props?.match?.params?.geo_project_link;
      const geo_layer_link = this?.props?.match?.params?.geo_layer_link;
      if (geo_project_link === "data") {
        this.props.getLayerOnly(geo_layer_link);
      } else {
        this.props.getProjectDetailByLink(geo_project_link);
      }
      this.props.getUserPaymentList();
      this.props.getGroupsProject(geo_project_link);
      this.props.getGroups();
    }

    if (
      this.props.properties.get_layer_list_status !==
        prevProps.properties.get_layer_list_status &&
      this.props.properties.get_layer_list_status === "done"
    ) {
      const { geo_project } = this.props.project;
      const { geo_layer_list } = this.props.layer;
      const params = window?.location?.search?.substring(1);
      let object_params = {};
      let layer_id;
      let folder_id;
      let sidebar_right_status = false;

      let layer_list_load_first = geo_project?.layer_list_load_first || [];
      layer_list_load_first = layer_list_load_first.filter((item) => !!item);

      if (params) {
        object_params = decode_params_to_object(params);
        layer_id = object_params?.layer_id;
        folder_id = object_params?.folder_id;
        sidebar_right_status =
          object_params?.sidebar_right_status === "true" ? true : false;
      }

      if (layer_id) {
        const folder_id = geo_layer_list.find(
          (item) => item.geo_layer._id === layer_id
        )?.folder;
        const body = {
          geo_layer_id: layer_id,
          geo_project_id: geo_project._id,
          flyStatus: true,
          folder_id,
        };
        this.props.getDetailLayerById(body);
      }

      if (folder_id) {
        const folder = geo_project.folders.find(
          (item) => item._id === folder_id
        );
        if (folder) {
          this.props.set_value_properties({
            key: "sidebar_right_status",
            value: true,
          });
          this.props.set_value_properties({
            key: "folder_kai_timeseries",
            value: folder,
          });
          const layer_list = geo_layer_list
            .filter(
              (element) =>
                element?.folder === folder_id &&
                !element?.geo_layer?.geojson?.features?.length
            )
            .map((item) => item?.geo_layer?._id);
          if (layer_list.length > 0) {
            const params = {
              layer_list,
              geo_project_id: geo_project._id,
              folder_id: folder_id,
            };
            this.props.get_detail_many_layer(params);
          }
        }
      }

      if (sidebar_right_status === true) {
        this.props.set_value_properties({
          key: "sidebar_right_status",
          value: true,
        });
      }

      if (layer_list_load_first.length > 0) {
        const params = {
          layer_list: layer_list_load_first,
          geo_project_id: geo_project._id,
          folder_id: "",
        };
        this.props.get_detail_many_layer(params);
      }
    }
  }

  toggle_login = () => {
    const { modal_login } = this.props.auth;
    this.props.set_value_user("modal_login", !modal_login);
  };

  componentWillUnmount() {
    this.props.clearLayer();
    this.props.clearProject();
  }

  render() {
    //local storage
    const language = localStorage?.language || "ina";

    //local state
    const geo_layer_link = this?.props?.match?.params?.geo_layer_link;
    const geo_project_link = this?.props?.match?.params?.geo_project_link;
    const mode = this?.props?.match?.params?.mode;

    //global props
    const { loadingProcess, itemLoading, chatStatus } = this.props.layer;
    let { geo_project } = this.props.project;
    const { license_user_status, license_group_status } =
      this.props.license_reducer;
    const { loading_item_array } = this.props.loading_reducer;

    //content

    let content;
    /*
    Kemungkinan-kemungkinan state:

    //1. Loading
    //2. white list
    //3. lolos authorisasi
    //4. data publik --> hanya berlaku di viewer, tidak dieditor
    //5. lainnya belum login
    //6. lainnya sudah login    
    */

    if (
      loading_item_array.includes("verify_license_user") ||
      loading_item_array.includes("verify_license_group")
    ) {
      content = <LOADING_PAGE />;
    } else if (
      !license_user_status.is_has_license &&
      !license_group_status.is_has_license &&
      !geo_project?.isPublic
    ) {
      content = <NO_LICENSE />;
    } else {
      content = (
        <MAP_VIEWER
          geo_project_link={geo_project_link}
          geo_layer_link={geo_layer_link}
          mode={mode}
        />
      );
    }

    let api_status = "";

    geo_project.api_status = api_status;

    let textLoading = "Saving...";
    switch (itemLoading) {
      case "createLayer":
        textLoading = dict["Creating layer"][language];
        break;
      case "importLayer":
        textLoading = dict["Importing layer"][language];
        break;
      case "uploadLayer":
        textLoading = dict["Importing layer"][language];
        break;
      case "edit_general":
        textLoading = dict["Saving changes"][language];
        break;
      case "editAllFields":
        textLoading = dict["Saving changes"][language];
        break;
      case "editLayerForm":
        textLoading = dict["Saving changes"][language];
        break;
      case "pushFeature":
        textLoading = dict["Adding feature"][language];
        break;
      case "push_project_attach":
        textLoading = dict["Attaching layer"][language];
        break;
      case "delete_project_attach":
        textLoading = dict["Deleting attached layer"][language];
        break;
      case "edit_geometry":
        textLoading = dict["Saving geometry changes"][language];
        break;
      case "edit_properties":
        textLoading = dict["Saving attribute value"][language];
        break;
      case "delete_feature":
        textLoading = dict["Deleting"][language];
        break;
      case "hide_feature":
        textLoading = dict["Hiding feature"][language];
        break;
      case "push_field":
        textLoading = dict["Adding column"][language];
        break;
      case "editField":
        textLoading = dict["Saving column"][language];
        break;
      case "deleteField":
        textLoading = dict["Deleting column"][language];
        break;
      case "delete_layer":
        textLoading = dict["Deleting layer"][language];
        break;
      case "get_detail":
        textLoading = dict["Getting GIS data"][language];
        break;
      case "genangan":
        textLoading = "Menjalankan algoritma genangan";
        break;
      default:
        textLoading = itemLoading;
        break;
    }

    return (
      <div>
        {!!textLoading && !chatStatus && loadingProcess && (
          <div
            style={{
              zIndex: "20",
              backgroundColor: "#0ca5eb",
              color: "#fff",
              position: "fixed",
              top: "0px",
              right: "0px",
              width: "100vw",
              textAlign: "center",
              paddingTop: "5px",
              paddingBottom: "5px",
              boxShadow: "0px 0px 7px 7px rgba(0, 0, 0, 0.09)",
              fontSize: "15px",
            }}
          >
            {textLoading}
          </div>
        )}
        {content}
        <div className="main_container"></div>
        <div className="sidebar_home"></div>
        <div className="search_bar"></div>
        {/* jangan dihapus ya wan, ini aku butuh biar sidebar ga error */}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  payment: state.payment,
  project: state.project,
  layer: state.layer,
  properties: state.properties,
  license_reducer: state.license_reducer,
  loading_reducer: state.loading_reducer,
});

export default connect(mapStateToProps, {
  getUserPaymentList,
  getProjectDetailByLink,
  clearLayer,
  getGroups,
  getGroupsProject,
  getLayerOnly,
  clearProject,
  set_value_user,
  getDetailLayerById,
  set_value_properties,
  get_detail_many_layer,
  get_layer_by_share_link,
  verify_license_user,
  verify_license_group,
  set_value_layer,
})(PARENT_MAP_VIEWER);
