import card_add_object_constant from "../../Data/card_add_object_constant.json";
import group_card_add_object_constant from "../../Data/group_card_add_object_constant.json";
import layer_sidebar_config_constant from "../../Data/layer_sidebar_config_constant.json";
import setting_bi_constant from "../../Data/setting_bi_constant.json";

const initial_state = {
  //loading
  loading_item_array: [],

  //edit bi basic
  trigger_rerender: 0,
  trigger_rerender_css_map: 0,
  trigger_fly_map: 0,
  total_progress_layers: 1,
  current_progress_layers: 0,
  bi_object: {},
  bi_list: [],
  folder_list: [],
  project_list: [],
  bi_selected: {},
  folder_id_open_list: [],

  //modal
  modal_add_parent: false,
  modal_edit_project: false,
  modal_layer_load: false,
  modal_layer_view_map: false,
  modal_add_card: false,
  modal_delete_card: false,
  modal_edit_css: false,
  modal_add_group_card: false,
  modal_edit_group_card: false,
  modal_edit_layer_config: false,
  full_card: false,
  full_map: false,
  modal_sini: false,

  //edit project
  project_object_selected: {},
  folder_object_selected: {},
  add_setting_current_step: 0,
  add_card_current_step: 0,

  //edit card
  setting_add_object: { ...setting_bi_constant },
  card_add_object: { ...card_add_object_constant },
  group_card_add_object: { ...group_card_add_object_constant },
  group_card_id_selected: "",
  layer_sidebar_config_object: { ...layer_sidebar_config_constant },
  layer_id: "",
};

export default function biReducer(state = initial_state, action) {
  switch (action.type) {
    case "set_loading_bi":
      return {
        ...state,
        loading_item_array: [...state.loading_item_array, action.payload],
      };

    case "clear_loading_bi":
      return {
        ...state,
        loading_item_array: state.loading_item_array.filter(
          (item) => item !== action.payload
        ),
      };

    case "reset_bi":
      return {
        ...initial_state,
      };

    case "trigger_rerender":
      return {
        ...state,
        trigger_rerender: state.trigger_rerender + 1,
      };

    case "trigger_rerender_css_map":
      return {
        ...state,
        trigger_rerender_css_map: state.trigger_rerender_css_map + 1,
      };

    case "trigger_fly_map":
      return {
        ...state,
        trigger_fly_map: state.trigger_fly_map + 1,
      };

    case "set_value_bi":
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };

    case "get_layer_detail_bi":
      return {
        ...state,
        ...get_layer_detail_bi(state.project_list, action.payload),
      };

    case "push_value_bi":
      return {
        ...state,
        [action.payload.key]: [
          ...state[action.payload.key],
          action.payload.value,
        ],
      };

    case "push_value_bi_to_start":
      return {
        ...state,
        [action.payload.key]: [
          action.payload.value,
          ...state[action.payload.key],
        ],
      };

    case "push_many_values_bi":
      return {
        ...state,
        [action.payload.key]: [
          ...state[action.payload.key],
          ...action.payload.value,
        ],
      };

    case "pull_value_bi_string":
      return {
        ...state,
        ...pull_value_bi_string(
          action.payload.key,
          state[action.payload.key],
          action.payload.value
        ),
      };

    case "pull_value_bi_object":
      return {
        ...state,
        ...pull_value_bi_object(
          action.payload.key,
          state[action.payload.key],
          action.payload.value,
          action.payload.id
        ),
      };

    case "pull_many_item_bi_object":
      return {
        ...state,
        ...pull_many_item_bi_object(
          action.payload.key,
          state[action.payload.key],
          action.payload.value_list,
          action.payload.id
        ),
      };

    case "edit_value_bi":
      return {
        ...state,
        ...edit_value_bi(
          action.payload.key,
          state[action.payload.key],
          action.payload.value,
          action.payload.id_key,
          action.payload.id_value
        ),
      };

    case "push_array_inside_object":
      return {
        ...state,
        ...push_array_inside_object(
          action.payload.key,
          state[action.payload.key],
          action.payload.id_key,
          action.payload.value
        ),
      };

    case "delete_array_inside_object":
      return {
        ...state,
        ...delete_array_inside_object(
          action.payload.key,
          state[action.payload.key],
          action.payload.id_key,
          action.payload.id_key_child,
          action.payload.id_value
        ),
      };

    case "delete_array_string_inside_object":
      return {
        ...state,
        ...delete_array_string_inside_object(
          action.payload.key,
          state[action.payload.key],
          action.payload.id_key,
          action.payload.value
        ),
      };

    case "edit_array_inside_object":
      return {
        ...state,
        ...edit_array_inside_object(
          action.payload.key,
          state[action.payload.key],
          action.payload.id_key,
          action.payload.id_key_child,
          action.payload.id_value,
          action.payload.object_edit
        ),
      };

    case "edit_inside_object":
      return {
        ...state,
        ...edit_inside_object(
          action.payload.key,
          state[action.payload.key],
          action.payload.id_key,
          action.payload.value
        ),
      };

    case "edit_inside_array":
      return {
        ...state,
        ...edit_inside_array(
          action.payload.key,
          state[action.payload.key],
          action.payload._id,
          action.payload.id_key,
          action.payload.value
        ),
      };

    case "push_array_inside_array":
      return {
        ...state,
        ...push_array_inside_array({
          object: state[action.payload.key_parent],
          ...action.payload,
        }),
      };

    case "edit_array_inside_array":
      return {
        ...state,
        ...edit_array_inside_array({
          object: state[action.payload.key_parent],
          ...action.payload,
        }),
      };

    case "delete_array_inside_array":
      return {
        ...state,
        ...delete_array_inside_array({
          object: state[action.payload.key_parent],
          ...action.payload,
        }),
      };

    case "delete_string_inside_array":
      return {
        ...state,
        ...delete_string_inside_array({
          object: state[action.payload.key_parent],
          ...action.payload,
        }),
      };

    default:
      return state;
  }
}

const pull_value_bi_string = (key, list, value) => {
  list = list.filter((item) => item !== value);
  return { [key]: list };
};

const pull_value_bi_object = (key, list, value, id) => {
  list = list.filter((item) => item[id] !== value);
  return { [key]: list };
};

const edit_value_bi = (key, list, value, id_key, id_value) => {
  const index = list.findIndex((item) => item[id_key] === id_value);
  list[index] = value;
  return { [key]: list };
};

const pull_many_item_bi_object = (key, list, value_list, id) => {
  list = list.filter((item) => !value_list.includes(item?.[id]));
  return { [key]: list };
};

const push_array_inside_object = (key, object, id_key, value) => {
  let new_array = object?.[id_key] || [];
  new_array.push(value);
  object[id_key] = new_array;
  return { [key]: object };
};

const delete_array_inside_object = (
  key,
  object,
  id_key,
  id_key_child,
  id_value
) => {
  let new_array = object?.[id_key] || [];
  new_array = new_array.filter((item) => {
    return item[id_key_child] !== id_value;
  });
  object[id_key] = new_array;
  return { [key]: object };
};

const delete_array_string_inside_object = (key, object, id_key, value) => {
  let new_array = object[id_key];
  new_array = new_array.filter((item) => item !== value);
  object[id_key] = new_array;
  return { [key]: object };
};
const edit_array_inside_object = (
  key,
  object,
  id_key,
  id_key_child,
  id_value,
  object_edit
) => {
  let new_array = object[id_key];
  const index = new_array.findIndex((item) => item[id_key_child] === id_value);
  let object_child = new_array[index];
  object_child = { ...object_child, ...object_edit };
  new_array[index] = object_child;
  object[id_key] = new_array;
  return { [key]: object };
};

const edit_inside_object = (key, object, id_key, value) => {
  object[id_key] = value;
  return { [key]: object };
};

const edit_inside_array = (key, list, _id, id_key, value) => {
  let object = list.find((item) => item._id === _id);
  const index = list.findIndex((item) => item._id === _id);
  if (index > -1) {
    object[id_key] = value;
    list[index] = object;
  }
  return { [key]: list };
};

const get_layer_detail_bi = (list, body) => {
  const { project_id, layer_id, geojson } = body;
  let project_index = list.findIndex((item) => item?._id === project_id);
  let project_object = list[project_index];
  let layer_list = project_object?.layer_list || [];
  let layer_index = layer_list.findIndex(
    (item) => item?.geo_layer?._id === layer_id
  );
  if (project_index > -1 && layer_index > -1) {
    let layer_object = layer_list[layer_index];
    layer_object.geo_layer.geojson = geojson;
    layer_list[layer_index] = layer_object;
    project_object.layer_list = layer_list;
    list[project_index] = project_object;
  }
  return { project_list: list, project_object_selected: project_object };
};

const push_array_inside_array = ({
  object,
  key_parent,
  key_child_1,
  find_by,
  find_value,
  key_child_2,
  value,
}) => {
  /*
  object: bi_object,
  key_parent: "bi_object",
  key_child_1: "setting_list",
  find_by: "project_id",
  find_value: project_id,
  key_child_2: "group_card_list",
  value: group_card_object
  */
  let array_parent = object?.[key_child_1] || [];
  let object_child = array_parent.find((item) => item[find_by] === find_value);
  let array_child = object_child?.[key_child_2] || [];
  array_child.push(value);
  object_child[key_child_2] = array_child;
  object[key_child_1] = array_parent;
  return { [key_parent]: object };
};

const edit_array_inside_array = ({
  object,
  key_parent,
  key_child_1,
  find_by,
  find_value,
  find_by_child,
  find_value_child,
  key_child_2,
  value_object,
}) => {
  // Get the parent array
  let array_parent = object?.[key_child_1] || [];
  // Find the parent object
  let object_child = array_parent.find((item) => item[find_by] === find_value);
  if (object_child) {
    // Get the child array
    let array_child = object_child?.[key_child_2] || [];
    // Find the index of the object in the child array to edit
    let index_child = array_child.findIndex(
      (item) => item[find_by_child] === find_value_child
    );
    if (index_child !== -1) {
      // Update the object in the child array
      array_child[index_child] = {
        ...array_child[index_child],
        ...value_object,
      };
    }
    // Assign the updated child array back to the parent object
    object_child[key_child_2] = array_child;
  }
  // Assign the updated parent array back to the main object
  object[key_child_1] = array_parent;
  // Return the updated object
  return { [key_parent]: object };
};

const delete_array_inside_array = ({
  object,
  key_parent,
  key_child_1,
  find_by,
  find_value,
  key_child_2,
  find_by_child,
  find_value_child,
}) => {
  let array_parent = object?.[key_child_1] || [];
  let object_child = array_parent.find((item) => item[find_by] === find_value);
  let array_child = object_child?.[key_child_2] || [];
  array_child = array_child.filter(
    (item) => item[find_by_child] !== find_value_child
  );
  object_child[key_child_2] = array_child;
  object[key_child_1] = array_parent;
  return { [key_parent]: object };
};

const delete_string_inside_array = ({
  object,
  key_parent,
  key_child_1,
  find_by,
  find_value,
  key_child_2,
  find_value_child,
}) => {
  let array_parent = object?.[key_child_1] || [];
  let object_child = array_parent.find((item) => item[find_by] === find_value);
  let array_child = object_child?.[key_child_2] || [];
  console.log("array_child before=", array_child);
  array_child = array_child.filter((item) => item !== find_value_child);
  console.log("array_child after=", array_child);
  object_child[key_child_2] = array_child;
  object[key_child_1] = array_parent;
  return { [key_parent]: object };
};
