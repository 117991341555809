/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*COMPONENT*/
import RANGKING_CHART from "./RANKING_CHART";
import MARKDOWN_RENDERER from "../sini_v2_full/MARKDOWN_RENDERER";
import ProgressBar from "../common_spinner/ProgressBar";
import Modal from "../common_modal/Modal";

/*REDUX*/
import {
  // get_ai_appraisal_summary,
  get_ai_sheet_chat_list,
  get_ai_sini_sheet,
} from "../../App/actions/ai_excel";
import { push_ai_sheet_chat } from "../../App/actions/push_ai_sheet_chat";
import { get_sini_data } from "../../App/actions/get_sini_data";
import { generate_ai_appraisal_summary, get_ai_appraisal_summary, get_data_pembanding } from "../../App/actions/generate_ai_appraisal_summary";

/*PICTURE*/

/*FUNCTION*/
import { convertExcelDataToHandsontableFormat } from "../../App/validation/convert_data";
import uuid from "../../App/validation/uuid";
import MAP_EXCEL from "./MAP_EXCEL";
import PARENT_SINI from "../sini_v2/PARENT_SINI";
import { convertGeoJsonToSheetPembanding } from "../../App/validation/kai_appraisal_function";

/*DATA*/

/*CONST*/

class EXCEL_APPRAISAL extends Component {
  state = {
    sheet_array: [],
    user_message: "",
    modal_re_appraisal: false,
    user_message_appraisal: "",
    is_chat_ai_show: false,
  };

  componentDidMount() {
    //basic
    const { worksheet } = this.props;

    const sheet_array = worksheet.map((sheet) => {
      const selectedSheet = sheet?.metadata?.id_ori;
      const { data } = convertExcelDataToHandsontableFormat({
        sheet,
        selectedSheet,
        rawData: worksheet,
      });
      return data;
    });
    this.setState({
      sheet_array,
    });
    const { geo_layer_id, feature_key, field_key } = this.props;
    const params = {
      geo_layer_id,
      feature_key,
      field_key,
    };
    this.props.get_ai_appraisal_summary(params);
    // this.props.get_ai_sheet_chat_list(params);
    //advance
    const { feature_object_selected } = this.props.layer;
    const long = feature_object_selected?.geometry?.coordinates?.[0];
    const lat = feature_object_selected?.geometry?.coordinates?.[1];
    const params_sini = {
      geo_layer_id,
      feature_key,
      field_key,
      long,
      lat,
      request_id: uuid(),
      is_from_excel: true,
      sini_data_mode: "sini_general",
      show_survey_1: true,
    };
    this.props.get_ai_sini_sheet(params_sini);
  }

  toggle_re_appraisal = () => {
    this.setState({
      modal_re_appraisal: !this.state.modal_re_appraisal,
    });
  };

  on_change = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({
      [name]: value,
    });
  };

  on_generate_ai_appraisal_summary = async () => {
    const { sheet_array, user_message_appraisal } = this.state;
    const { geo_layer_id, feature_key, field_key, worksheet } = this.props;
    const { feature_object_selected } = this.props.layer;
    const { ai_parent_detail_list, sheet_object_selected } = this.props.sini;
    const sini_object = ai_parent_detail_list.find(
      (item) => item.request_id === sheet_object_selected.request_id
    );

    // const nam_data_pembanding = ["Data Pembanding 1", "Data Pembanding 2", "Data Pembanding 3"]
    // let sheetIndexDataObject = -1
    // let sheetIndexDataPembanding = []


    // for (const [index, sheet] of worksheet?.entries()) {
    //   if (sheet?.metadata?.id_ori === "Data Objek") { sheetIndexDataObject = index }
    //   else if (nam_data_pembanding.includes(sheet?.metadata?.id_ori)) { sheetIndexDataPembanding.push(index) }
    // }

    // let tipeProperti = "";
    // let luasTanah = 0;
    // let tahunDibangun = 0;

    // worksheet?.[sheetIndexDataObject]?.value?.forEach((item) => {
    //   if (item?.p === "E6") {
    //     tipeProperti = item?.v;
    //   } else if (item?.p === "E10") {
    //     luasTanah = item?.v;
    //   } else if (item?.p === "E26") {
    //     tahunDibangun = item?.v;
    //   }
    // })

    const [longitude, latitude] = feature_object_selected?.geometry?.coordinates

    // const res_data_pembanding = await this.props.get_data_pembanding({
    //   latitude, longitude, tipeProperti, luasTanah, tahunDibangun
    // });

    // if (res_data_pembanding?.status === 200) {
    //   const sheet_data_pembanding = convertGeoJsonToSheetPembanding({
    //     features: res_data_pembanding?.data,
    //     geo_layer_id,
    //     feature_key,
    //     field_key
    //   });

    //   for (const [index_for_dataPembanding, index_for_worksheet] of sheetIndexDataPembanding.entries()) {
    //     worksheet[index_for_worksheet] = sheet_data_pembanding?.[index_for_dataPembanding];
    //   }
    // }

    // const new_sheet_array = worksheet.map((sheet) => {
    //   const selectedSheet = sheet?.metadata?.id_ori;
    //   const { data } = convertExcelDataToHandsontableFormat({
    //     sheet,
    //     selectedSheet,
    //     rawData: worksheet,
    //   });
    //   return data;
    // });

    const body = {
      worksheet: sheet_array,
      geo_layer_id,
      feature_key,
      field_key,
      sini_object,
      user_message_appraisal,
    };

    // console.log("body",body)

    await this.props.generate_ai_appraisal_summary(body);
    this.setState({
      user_message_appraisal: "",
      modal_re_appraisal: false,
    });
  };

  reset_ai_appraisal_summary = () => {
    const { sheet_array } = this.state;
    const { geo_layer_id, feature_key, field_key } = this.props;
    const { ai_parent_detail_list, sheet_object_selected } = this.props.sini;
    const sini_object = ai_parent_detail_list.find(
      (item) => item.request_id === sheet_object_selected.request_id
    );
    const body = {
      worksheet: sheet_array,
      geo_layer_id,
      feature_key,
      field_key,
      sini_object,
      user_message_appraisal: "",
    };
    this.props.generate_ai_appraisal_summary(body);
    this.setState({
      user_message_appraisal: "",
      modal_re_appraisal: false,
    });
  };

  on_regenerate_ai_appraisal_summary = () => {
    const { user_message_appraisal, sheet_array } = this.state;
    const { geo_layer_id, feature_key, field_key } = this.props;
    const {
      ai_parent_detail_list,
      sheet_object_selected,
      ai_appraisal_summary_object,
    } = this.props.sini;
    const sini_object = ai_parent_detail_list.find(
      (item) => item.request_id === sheet_object_selected.request_id
    );
    const body = {
      // worksheet: ai_appraisal_summary_object,
      worksheet: sheet_array,
      geo_layer_id,
      feature_key,
      field_key,
      sini_object,
      user_message_appraisal,
    };
    this.props.generate_ai_appraisal_summary(body);
    this.setState({
      user_message_appraisal: "",
      modal_re_appraisal: false,
    });
  };

  on_push_ai_sheet_chat = () => {
    const { user_message } = this.state;
    const { geo_layer_id, feature_key, field_key } = this.props;
    const {
      ai_parent_detail_list,
      sheet_object_selected,
      ai_appraisal_summary_object,
    } = this.props.sini;
    const sini_object = ai_parent_detail_list.find(
      (item) => item.request_id === sheet_object_selected.request_id
    );
    const body = {
      ai_appraisal_summary_object,
      user_message,
      geo_layer_id,
      feature_key,
      field_key,
      sini_object,
    };
    this.props.push_ai_sheet_chat(body);
    this.setState({
      user_message: "",
    });
  };

  toggle_chat_ai = () => {
    this.setState({
      is_chat_ai_show: !this.state?.is_chat_ai_show
    })
  }

  render() {
    //local storage

    //local state
    const { user_message, modal_re_appraisal, user_message_appraisal, is_chat_ai_show } = this.state;

    //global props
    const { is_mobile } = this.props.properties;
    const { loading_item_array } = this.props.loading_reducer;
    const {
      total_step,
      current_step,
      current_topic_loading,
      ai_appraisal_summary_object,
      ai_sheet_chat_list,
      ai_sini_sheet_object,
    } = this.props.sini;

    const { ai_parent_detail_list, sheet_object_selected } = this.props.sini;
    const sini_object = ai_parent_detail_list.find(
      (item) => item.request_id === sheet_object_selected.request_id
    );
    const properties = sini_object?.demography_one?.properties || {};
    const { DESA, KECAMATAN, KABKOT, PROVINSI } = properties;

    //content
    const modal_re_appraisal_content = modal_re_appraisal && (
      <Modal
        modalSize="small"
        isOpen={modal_re_appraisal}
        onClose={this.toggle_re_appraisal}
      >
        <div className="box-body" id="box-body">
          <textarea
            className="margin_bottom"
            rows={6}
            placeholder="Masukkan pesan untuk pembaruan summary"
            value={user_message_appraisal}
            name="user_message_appraisal"
            onChange={this.on_change}
          />
          <br />
          <button
            className="button_big background_black margin_right margin_bottom"
            onClick={this.on_regenerate_ai_appraisal_summary}
          >
            Perbarui Summary
          </button>
          <button
            className="button_big background_black"
            onClick={this.reset_ai_appraisal_summary}
          >
            Reset
          </button>
        </div>
      </Modal>
    );

    const summary_result = ai_appraisal_summary_object?.summary_result;
    let summary_content;
    if (!summary_result) {
      summary_content = (
        <section className="text_center container_light outline_transparent background_grey_light">
          <p className="text_bold margin_bottom">
            Belum dilakukan perangkuman Appraisal
          </p>
          <br />
          {loading_item_array.includes("generate_ai_appraisal_summary") ? (
            <button className="button_big background_grey">
              AI SEDANG MELAKUKAN SUMMARY...
            </button>
          ) : !ai_sini_sheet_object?.request_id ? (
            <button className="button_big background_grey">Wait</button>
          ) : (
            <button
              className="button_big background_black"
              onClick={this.on_generate_ai_appraisal_summary}
            >
              Generate Appraisal Summary
            </button>
          )}
          <br />
        </section>
      );
    } else {
      // const appraisal_best_alternative = summary_result?.appraisal_best_alternative || "";
      // const appraisal_best_alternative_reason =
      //   summary_result?.appraisal_best_alternative_reason || "";
      // const appraisal_best_alternative_notes =
      //   summary_result?.appraisal_best_alternative_notes || "";
      // const appraisal_alternative_array = summary_result?.appraisal_alternative_array || [];

      const {
        data_object_appraisal = "",
        data_pembanding = [],
        ringkasan_penilaian = {},
        pendekatan_pasar,
        pendekatan_biaya
      } = summary_result || {};

      const { bangunan, tanah, sarana, nilai_pasar } = ringkasan_penilaian
      const { indikasi_nilai_pasar_properti_m2, indikasi_nilai_pasar_properti, range } = pendekatan_pasar
      const {
        replacement_cost_new_rcn,
        total_luas_bangunan,
        biaya_pengganti_baru_bangunan_dan_sarpras_sesuai_fisik_m2,
        total_biaya_pengganti_baru_bangunan_dan_sarpras_sesuai_fisik
      } = pendekatan_biaya

      summary_content = (
        <section
          style={{
            overflowY: "auto",
            height: "100%",
            gap: "10px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <h1 className="margin_bottom">Appraisal SUMMARY</h1>
          <section className="margin_bottom">
            {loading_item_array.includes("generate_ai_appraisal_summary") ? (
              <button className="button_big background_grey">
                AI SEDANG MELAKUKAN SUMMARY...
              </button>
            ) : !ai_sini_sheet_object?.request_id ? (
              <button className="button_big background_grey">WAIT</button>
            ) : (
              <button
                className="button_big background_black"
                onClick={this.toggle_re_appraisal}
              >
                Perbarui Ringkasan Appraisal dengan Prompt
              </button>
            )}
          </section>

          <aside className="container_column">
            <div className="child_tile container_light background_grey_light outline_transparent">
              <h1 className="margin_bottom">
                <label>Nama object: </label>
                <p className="text_bold">{data_object_appraisal}</p>
              </h1>
              <div className="margin_bottom">
                <label className="text_small">Provinsi</label>
                <p className="text_bold">{PROVINSI}</p>
              </div>
              <div className="margin_bottom">
                <label className="text_small">Kabupaten atau Kota</label>
                <p className="text_bold">{KABKOT}</p>
              </div>
              <div className="margin_bottom">
                <label className="text_small">Kecamatan</label>
                <p className="text_bold">{KECAMATAN}</p>
              </div>
              <div className="margin_bottom">
                <label className="text_small">Desa atau Kelurahan</label>
                <p className="text_bold">{DESA}</p>
              </div>
            </div>

            <div className="child_tile card_uji_legal_hbu container_light background_grey_light outline_transparent">
              <h1 className="text_bold margin_bottom">
                Ringkasan Penilaian
              </h1>
              <div className="margin_bottom">
                <label className="text_small">Tanah</label>
                <p className="text_bold">{tanah}</p>
              </div>
              <div className="margin_bottom">
                <label className="text_small">Bangunan</label>
                <p className="text_bold">{bangunan}</p>
              </div>
              <div className="margin_bottom">
                <label className="text_small">Sarana</label>
                <p className="text_bold">{sarana}</p>
              </div>
              <hr />
              <div className="margin_bottom">
                <label className="text_small">Nilai Pasar</label>
                <p className="text_bold">{nilai_pasar}</p>
              </div>

            </div>


            {
              data_pembanding.map((pembanding, idx) => {
                const { tipe_properti, jumlah_lantai, luas_bangunan, luas_tanah, alamat, indikasi_nilai_tanah_m2 } = pembanding
                return (
                  <div className="child_tile container_light background_grey_light outline_transparent">
                    <h1 className="text_bold margin_bottom">
                      {`Data Pembanding ${idx + 1}`}
                    </h1>
                    <div className="margin_bottom">
                      <label className="text_small">Tipe Properti</label>
                      <p className="text_bold">{tipe_properti}</p>
                    </div>
                    <div className="margin_bottom">
                      <label className="text_small">Alamat</label>
                      <p className="text_bold">{alamat}</p>
                    </div>
                    <div className="margin_bottom">
                      <label className="text_small">Luas Tanah</label>
                      <p className="text_bold">{luas_tanah}</p>
                    </div>
                    <div className="margin_bottom">
                      <label className="text_small">Luas Bangunan</label>
                      <p className="text_bold">{luas_bangunan}</p>
                    </div>
                    <div className="margin_bottom">
                      <label className="text_small">Jumlah Lantai</label>
                      <p className="text_bold">{jumlah_lantai}</p>
                    </div>
                    <div className="margin_bottom">
                      <label className="text_small">Indikasi nilai tanah /m2</label>
                      <p className="text_bold">{indikasi_nilai_tanah_m2}</p>
                    </div>
                  </div>
                )
              })
            }

            <div className="child_tile container_light background_grey_light outline_transparent">
              <h1 className="text_bold margin_bottom">
                Pendekatan Pasar
              </h1>
              <div className="margin_bottom">
                <label className="text_small">Indikasi Nilai Pasar Properti /m2</label>
                <p className="text_bold">{indikasi_nilai_pasar_properti_m2}</p>
              </div>
              <div className="margin_bottom">
                <label className="text_small">Indikasi Nilai Pasar Properti</label>
                <p className="text_bold">{indikasi_nilai_pasar_properti}</p>
              </div>

              <div className="margin_bottom flex flex_col gap_5">
                <label className="text_small">Range:</label>
                <ul className="paddingLeft_20 flex flex_col gap_5">
                  <li>
                    <div className="flex">
                      <label className="text_small">{`Max:  `}</label>
                      <label className="bold">{range.max}</label>
                    </div>
                  </li>
                  <li>
                    <div className="flex">
                      <label className="text_small">{`Min:  `}</label>
                      <label className="bold">{range.min}</label>
                    </div>
                  </li>
                  <li>
                    <div className="flex">
                      <label className="text_small">{`Deviasi:  `}</label>
                      <label className="bold">{range.deviasi}</label>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div className="child_tile card_uji_legal_hbu container_light background_grey_light outline_transparent">
              <h1 className="text_bold margin_bottom ">
                Pendekatan Biaya
              </h1>
              <div className="margin_bottom">
                <label className="text_small">Replacement Cost New (RCN)</label>
                <p className="text_bold">{replacement_cost_new_rcn}</p>
              </div>
              <div className="margin_bottom">
                <label className="text_small">Total Luas Bangunan</label>
                <p className="text_bold">{total_luas_bangunan}</p>
              </div>

              <div className="margin_bottom flex flex_col gap_5">
                <label className="text_small">Biaya Pengganti Baru Bangunan & SARPARAS Sesuai Fisik /m2</label>
                <ul className="paddingLeft_20 flex flex_col gap_5">
                  <li>
                    <div className="flex align_center">
                      <label className="text_small">{`RCN:  `}</label>
                      <label className="text_bold">{biaya_pengganti_baru_bangunan_dan_sarpras_sesuai_fisik_m2.rcn}</label>
                    </div>
                  </li>
                  <li>
                    <div className="flex align_center">
                      <label className="text_small">{`Nilai Pasar:  `}</label>
                      <p className="text_bold">{biaya_pengganti_baru_bangunan_dan_sarpras_sesuai_fisik_m2.nilai_pasar}</p>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="margin_bottom flex flex_col gap_5">
                <label className="text_small">Total Biaya Pengganti Baru Bangunan & SARPARAS Sesuai Fisik</label>
                <ul className="paddingLeft_20 flex flex_col gap_5">
                  <li>
                    <div className="flex align_center">
                      <label className="text_small">{`RCN:  `}</label>
                      <p className="text_bold">{total_biaya_pengganti_baru_bangunan_dan_sarpras_sesuai_fisik.rcn}</p>
                    </div>
                  </li>
                  <li>
                    <div className="flex align_center">
                      <label className="text_small">{`Nilai Pasar:  `}</label>
                      <p className="text_bold">{total_biaya_pengganti_baru_bangunan_dan_sarpras_sesuai_fisik.nilai_pasar}</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>


          </aside>


        </section>
      );
    }

    const chat_content = (
      <main className="parent_section_full container_light outline_transparent background_grey_light">
        <section className="section_1">
          <p className="badge background_black">
            AI CHAT ({!ai_sini_sheet_object?.request_id ? "Load..." : "Ready"})
          </p>
        </section>
        <section
          className="section_2"
          style={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            position: "relative",
            overflow: "hidden",
          }}
        >
          <div
            style={{
              flexGrow: 1,
              overflowY: "auto",
              padding: "10px",
              maxHeight: "calc( 80vh - 150px )",
            }}
          >
            <div>
              {total_step !== current_step && (
                <ProgressBar
                  current_number={current_step}
                  total_number={total_step}
                  name={current_topic_loading}
                />
              )}
            </div>
            {ai_sheet_chat_list.map((chat, idx) => {
              let final_content;
              if (chat.role === "assistant" && chat.content) {
                final_content = (
                  <section key={idx} id={`ai_sini_${idx}`}>
                    <div className="container_light outline_transparent margin_bottom background_dark_blue">
                      <MARKDOWN_RENDERER markdownContent={chat.content} />
                    </div>
                  </section>
                );
              } else {
                final_content = (
                  <section key={idx} id={`ai_sini_${idx}`}>
                    <div
                      className="container_light outline_transparent margin_bottom max_width_300 background_white"
                      style={{ right: 0, whiteSpace: "pre-wrap" }}
                    >
                      {chat.content}
                    </div>
                  </section>
                );
              }
              return final_content;
            })}
          </div>
          <div
            id="chat_dialog"
            style={{
              position: "absolute",
              bottom: 0,
              left: 0,
              right: 0,
              display: "flex",
              flexDirection: "column",
              gap: "5px",
            }}
          >
            <textarea
              name="user_message"
              value={user_message}
              onChange={this.on_change}
              rows={6}
              style={{
                width: "100%",
                padding: "8px",
                borderRadius: "4px",
                border: "1px solid #ccc",
              }}
            />
            {loading_item_array.includes("push_ai_sheet_chat") ? (
              <button
                className="button background_grey"
                style={{
                  alignSelf: "flex-start",
                }}
              >
                Sending...
              </button>
            ) : !ai_sini_sheet_object?.request_id ? (
              <button
                className="button background_grey"
                style={{
                  alignSelf: "flex-start",
                }}
              >
                Wait
              </button>
            ) : (
              <button
                className="button background_blue"
                style={{
                  alignSelf: "flex-start",
                }}
                onClick={this.on_push_ai_sheet_chat}
              >
                Chat
              </button>
            )}
          </div>
        </section>
      </main>
    );
    let content;

    const { summary_sini = "" } = summary_result || {}

    if (!is_mobile) {
      content = (
        <main
          style={{ display: "flex", gap: "10px", height: "calc(100vh - 75px)" }}
        >
          <section
            style={{
              width: "50%",
            }}
          >
            {summary_content}
          </section>

          <section
            style={{
              display: "flex",
              height: "100%",
              width: "50%",
              gap: "15px",
            }}
          >
            <div
              style={{
                height: "100%",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "10px"
              }}
            >
              <div style={{ height: "50%" }}>
                <MAP_EXCEL />
              </div>
              <div
                className="child_tile container_light background_grey_light outline_transparent"
                style={{ height: "50%" }}>
                <div>
                  <label className="text_bold"> Summary Appraisal </label>
                  <p>{summary_sini}</p>
                </div>
              </div>
            </div>
            <div
              style={{
                width: "300px",
                overflowY: "auto",
              }}
            >
              <PARENT_SINI from="ai_appraisal" />
            </div>
          </section>

          <section
            style={{
              visibility: is_chat_ai_show ? "visible" : "hidden",
              width: is_chat_ai_show ? "300px" : "0px",
              flexShrink: 0,
              flexGrow: 0,
              height: "100%",
            }}
          >
            {chat_content}
          </section>
        </main>
      );
    } else {
      content = <main>Mode mobile not supported yet</main>;
    }

    const button_ai_chat = <button
      className="floating_button circle_button"
      onClick={this.toggle_chat_ai}
    >AI</button>

    return (
      <main>
        {modal_re_appraisal_content}
        {content}
        {button_ai_chat}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  loading_reducer: state.loading_reducer,
  sini: state.sini,
  properties: state.properties,
  layer: state.layer,
});

export default connect(mapStateToProps, {
  generate_ai_appraisal_summary,
  get_ai_appraisal_summary,
  push_ai_sheet_chat,
  get_ai_sheet_chat_list,
  get_ai_sini_sheet,
  get_sini_data,
  get_data_pembanding
})(EXCEL_APPRAISAL);
