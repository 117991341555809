/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*COMPONENT*/
import Modal from "../common_modal/Modal";
import IMPORT_LAYER_VIA_SEARCH from "./IMPORT_LAYER_VIA_SEARCH";

/*REDUX*/
import { getDetailLayerById } from "../../App/actions/layerNewActions";
import { set_value_properties } from "../../App/actions/propertiesActions";
import { get_pda, get_pch } from "../../App/actions/iotActions";
import { get_layer_detail_custom_api } from "../../App/actions/openApiActions";
import { view_layer, set_layer_id } from "../../App/actions/dataActions";
import {
  setLayer,
  setTabelStatus,
  setEditGeometry,
  setRealtimeStatus,
  setLayerWOgeojson,
  setChatStatus,
  getDetailLayerByLinkWOgeojson,
  setViewLayer,
} from "../../App/actions/layerActions";
import { getProjectList } from "../../App/actions/projectActions";

/*PICTURE*/
import icon_edit_properties from "../../Assets/svg/icon_edit_properties.svg";
import icon_edit_properties_white from "../../Assets/svg/icon_edit_properties_white.svg";
import icon_view from "../../Assets/svg/icon_view.svg";
import icon_view_white from "../../Assets/svg/icon_view_white.svg";
import icon_import from "../../Assets/svg/icon_import.svg";
import icon_search from "../../Assets/svg/icon_search_new.svg";

/*FUNCTION*/
import getStatusMember from "../../App/validation/get-status-member";

/*DATA*/
import dict from "../../Data/dict.json";

/*CONST*/

class SEARCH_LAYER extends Component {
  state = {
    modal_import: false,
  };

  on_search_inside = (geo_layer_id, geo_layer_name) => {
    this.props.set_value_properties({
      key: "mode_search",
      value: "search_inside",
    });
    this.props.set_value_properties({
      key: "layer_id_search_inside",
      value: geo_layer_id,
    });
    this.props.set_value_properties({
      key: "layer_name_search_inside",
      value: geo_layer_name,
    });
  };

  toggle_import = (geo_layer_id) => {
    this.setState({ modal_import: !this.state.modal_import }, () => {
      if (this.state.modal_import) {
        const { isAuthenticated } = this.props.auth;
        if (isAuthenticated) {
          this.props.getProjectList();
        }
        this.props.set_layer_id(geo_layer_id);
        this.setState({ import_progress: false });
      }
    });
  };

  on_view_layer = (geo_layer_item, value, folder_id) => {
    this.props.on_deactivate_input_status();
    //layer yang saat ini sedang aktif (layer yang diklik bisa jadi sama, bisa jadi beda)
    const { layer_id } = this?.props?.layer;
    if (
      (layer_id !== geo_layer_item?._id &&
        geo_layer_item?.geojson?.features?.length === 0) ||
      !geo_layer_item?.geojson?.features
    ) {
      //kemungkinan 1: belum ada geojson, ambil geojson dulu, kemudian aktifkan view
      if (geo_layer_item?.type_2 === "pch") {
        this.props.get_pch(geo_layer_item);
      } else if (geo_layer_item?.type_2 === "pda") {
        this.props.get_pda(geo_layer_item);
      } else {
        if (geo_layer_item?.is_custom_endpoint) {
          this.props.get_layer_detail_custom_api(geo_layer_item._id);
        } else {
          const { geo_project } = this.props.project;
          const body = {
            geo_layer_id: geo_layer_item._id,
            geo_project_id: geo_project._id,
            folder_id: folder_id,
            capex_value: this.props.properties.capex_value,
            geo_layer_item,
          };
          this.props.getDetailLayerById(body);
        }
      }
    } else {
      //kemungkinan 2: sudah ada geojson, langsung toggle view
      const body = { geo_layer_id: geo_layer_item._id, value };
      this.props.view_layer(body);
    }
  };

  toggle_edit_property = (geo_layer_item, folder_id) => {
    this.props.on_deactivate_input_status();
    const { tableStatus, layer_id } = this.props.layer;
    const { geo_project } = this.props.project;
    //Jika layer yang diklik bukanlah layer yang sedang aktif--> aktifkan layer
    if (layer_id !== geo_layer_item._id) {
      //Jika layer belum memiliki geojson, ambil geojson via API
      if (
        geo_layer_item?.geojson?.features?.length === 0 ||
        !geo_layer_item?.geojson?.features
      ) {
        this.props.setTabelStatus(true);
        //kemungkinan 1: belum ada geojson, ambil geojson dulu, kemudian aktifkan view
        if (geo_layer_item?.type_2 === "pch") {
          this.props.get_pch(geo_layer_item);
        } else if (geo_layer_item?.type_2 === "pda") {
          this.props.get_pda(geo_layer_item);
        } else {
          if (geo_layer_item?.is_custom_endpoint) {
            this.props.get_layer_detail_custom_api(geo_layer_item._id);
          } else {
            const body = {
              geo_layer_id: geo_layer_item._id,
              geo_project_id: geo_project._id,
              folder_id,
              capex_value: this.props.properties.capex_value,
              tableStatus: true,
              geo_layer_item,
            };
            this.props.getDetailLayerById(body);
          }
        }
      } else {
        //Jika layer sudah memiliki geojson --> aktifkan layer tanpa mengambil geojson lagi
        this.props.set_layer_id(geo_layer_item._id);
        this.props.setTabelStatus(true);
      }
    } else {
      //Jika layer yang diklik adalah layer yang sedang aktif
      //Jika layer awalnya kosong, coba cek ke API lagi, barangkali sekarang ada isinya
      if (
        geo_layer_item?.geojson?.features?.length === 0 ||
        !geo_layer_item?.geojson?.features
      ) {
        this.props.setTabelStatus(!tableStatus);
        if (geo_layer_item?.type_2 === "pch") {
          this.props.get_pch(geo_layer_item);
        } else if (geo_layer_item?.type_2 === "pda") {
          this.props.get_pda(geo_layer_item);
        } else {
          if (geo_layer_item?.is_custom_endpoint) {
            this.props.get_layer_detail_custom_api(geo_layer_item._id);
          } else {
            const body = {
              geo_layer_id: geo_layer_item._id,
              geo_project_id: geo_project._id,
              folder_id,
              capex_value: this.props.properties.capex_value,
              tableStatus: !tableStatus,
              geo_layer_item,
            };
            this.props.getDetailLayerById(body);
          }
        }
      } else {
        this.props.set_layer_id(geo_layer_item._id);
        this.props.setTabelStatus(!tableStatus);
      }
    }
  };

  render() {
    //local storage
    const language = localStorage?.language || "ina";

    //local state
    const { modal_import } = this.state;

    //global props
    const { layer_id_filtered, search_input, shared_link_check } =
      this.props.properties;
    const { tableStatus, geo_layer_list, layer_id, layer_id_active } =
      this.props.layer;
    const { isAuthenticated } = this.props.auth;

    //content
    const role = shared_link_check?.role;
    let is_author = false;
    if (isAuthenticated) {
      let userCurrentStatus;
      const { user } = this.props.auth;
      const { geo_project } = this.props.project;
      const groups = geo_project?.groups || [];
      if (geo_project?._id) {
        userCurrentStatus = getStatusMember(groups, user?._id);
      }
      is_author =
        !!userCurrentStatus || !!(geo_project?.user?._id === user?._id);
    }

    let layer_final;

    if (!search_input) {
      layer_final = geo_layer_list;
    } else {
      layer_final = geo_layer_list.filter((item) =>
        layer_id_filtered.includes(item?.geo_layer?._id)
      );
    }

    const content = (
      <section
        style={{
          overflowY: "auto",
          height: "500px",
          maxHeight: "calc((100vh - 300px))",
          marginTop: "20px",
        }}
      >
        {layer_final.map((layer, idx) => {
          const geo_layer = layer?.geo_layer ? layer.geo_layer : {};
          const folder_id = layer?.folder || "";
          const { _id, name, description } = geo_layer;
          const viewStatus = layer_id_active.includes(geo_layer?._id);
          return (
            <main
              key={idx}
              className="container_light button_white margin_bottom outline_grey"
            >
              <p className="text_bold margin_bottom">{name}</p>
              {!!description && (
                <p className="text_small max_two_lines">{description}</p>
              )}
              <section>
                <button
                  onClick={this.toggle_edit_property.bind(
                    this,
                    geo_layer,
                    folder_id
                  )}
                  className="buttonCenter margin_right"
                  style={{
                    background: `${
                      _id === layer_id && tableStatus
                        ? "#606f8dff"
                        : "#e5eefdff"
                    }`,
                  }}
                  title={dict["View Table"][language]}
                >
                  <img
                    src={
                      _id === layer_id && tableStatus
                        ? icon_edit_properties_white
                        : icon_edit_properties
                    }
                    alt="edit properties"
                    height="18px"
                    style={{ margin: "auto" }}
                  />
                </button>

                {viewStatus ? (
                  <button
                    onClick={this.on_view_layer.bind(
                      this,
                      geo_layer,
                      false,
                      folder_id
                    )}
                    className="buttonCenter margin_right"
                    style={{
                      background: "#606f8dff",
                    }}
                    title={dict["View Layer"][language]}
                  >
                    <img
                      src={icon_view_white}
                      alt="edit properties"
                      height="18px"
                      style={{ margin: "auto" }}
                    />
                  </button>
                ) : (
                  <button
                    onClick={this.on_view_layer.bind(
                      this,
                      geo_layer,
                      true,
                      folder_id
                    )}
                    className="buttonCenter margin_right"
                    style={{
                      background: "#e5eefdff",
                    }}
                    title={dict["View Layer"][language]}
                  >
                    <img
                      src={icon_view}
                      alt="edit properties"
                      height="18px"
                      style={{ margin: "auto" }}
                    />
                  </button>
                )}

                {(is_author ||
                  role === "viewer_download" ||
                  role === "editor") && (
                  <button
                    onClick={this.on_search_inside.bind(this, _id, name)}
                    className="buttonCenter margin_right"
                    style={{
                      background: "#e5eefdff",
                    }}
                    title={dict["Search"][language]}
                  >
                    <img
                      src={icon_search}
                      alt="edit properties"
                      height="18px"
                      style={{ margin: "auto" }}
                    />
                  </button>
                )}

                {(is_author ||
                  role === "viewer_download" ||
                  role === "editor") && (
                  <button
                    onClick={this.toggle_import.bind(
                      this,
                      layer?.geo_layer?._id
                    )}
                    className="buttonCenter margin_right"
                    style={{
                      background: "#e5eefdff",
                    }}
                    title={dict["Import"][language]}
                  >
                    <img
                      src={icon_import}
                      alt="edit properties"
                      height="18px"
                      style={{ margin: "auto" }}
                    />
                  </button>
                )}
              </section>
            </main>
          );
        })}
      </section>
    );

    const modal_import_content = modal_import && (
      <Modal
        modalSize="large"
        isOpen={modal_import}
        onClose={this.toggle_import}
      >
        <div className="box-body" id="box-body">
          <IMPORT_LAYER_VIA_SEARCH />
        </div>
      </Modal>
    );

    return (
      <main>
        {modal_import_content}
        {!search_input && (
          <p className="text_small margin_bottom">
            {
              dict?.[
                "Search for layers in this project by typing their names or descriptions as keywords"
              ]?.[language]
            }
          </p>
        )}
        {!search_input && layer_id_filtered.length === 0 && (
          <section>
            <span className="button_pill_small background_blue margin_right inline">
              {geo_layer_list.length}
            </span>
            <p className="text_medium inline">{dict?.["Layers"]?.[language]}</p>
          </section>
        )}
        {layer_id_filtered.length === 0 && !!search_input && (
          <p className="text_medium">{dict?.["No result"]?.[language]}</p>
        )}
        {layer_id_filtered.length > 0 && (
          <section>
            <span className="button_pill_small background_blue margin_right inline">
              {layer_id_filtered.length}
            </span>
            <p className="text_medium inline">{dict?.["Result"]?.[language]}</p>
          </section>
        )}
        {content}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  layer: state.layer,
  project: state.project,
  map: state.map,
  properties: state.properties,
});

export default connect(mapStateToProps, {
  getDetailLayerById,
  set_value_properties,
  get_pda,
  get_pch,
  get_layer_detail_custom_api,
  view_layer,
  set_layer_id,
  setLayer,
  setTabelStatus,
  setEditGeometry,
  setRealtimeStatus,
  setLayerWOgeojson,
  setChatStatus,
  getDetailLayerByLinkWOgeojson,
  setViewLayer,
  getProjectList,
})(SEARCH_LAYER);
