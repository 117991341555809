import React, { Component } from "react";
import dict from "../../Data/dict.json";

export default class GeojsonDetails extends Component {
  render() {
    const language = localStorage?.language ? localStorage?.language : "ina";
    const index = this.props.index;
    const geojson_list = this.props.geojson_list;

    let geojson;
    if (!isNaN(index)) {
      geojson = geojson_list[+index];
    }

    let {
      is_valid_in_general,
      error,
      invalid_features,
      valid_number,
      invalid_number,
      total_original_features,
      // filtered_features,
    } = geojson?.validation_result;
    const fileName = geojson?.fileName;

    return (
      <div>
        <h3 className="center_perfect marginBottom_10">{fileName}</h3>
        <table>
          <tbody>
            <tr>
              <td>Status</td>
              <td>:</td>
              <td className="text_right">
                {is_valid_in_general ? "Valid" : "Invalid"}
              </td>
            </tr>
            <tr>
              <td>{dict?.["Error Message"]?.[language]}</td>
              <td>:</td>
              <td className="text_right">
                <div className="scroll_y_auto max_h_100">{error || "-"}</div>
              </td>
            </tr>
            <tr>
              <td>{dict?.["Total of row"]?.[language]}</td>
              <td>:</td>
              <td className="text_right">{total_original_features}</td>
            </tr>
            <tr>
              <td>{dict?.["Number of valid row"]?.[language]}</td>
              <td>:</td>
              <td className="text_right">{valid_number}</td>
            </tr>
            <tr>
              <td>{dict?.["Number of invalid row"]?.[language]}</td>
              <td>:</td>
              <td className="text_right">{invalid_number}</td>
            </tr>
            <tr>
              <td>{dict?.["List of invalid row"]?.[language]}</td>
              <td>:</td>
              <td></td>
            </tr>
          </tbody>
        </table>
        <div className="flex justify_center marginTop_10 h_200 overflow_auto">
          <table className="table">
            <thead>
              <tr className="border">
                <th>{dict?.["Row Index"]?.[language]}</th>
                <th>{dict?.["Error Message"]?.[language]}</th>
              </tr>
            </thead>
            <tbody>
              {invalid_features.map((feature, index_feature) => (
                <tr key={index_feature}>
                  <td className="text_right">{feature.index + 1}</td>
                  <td>{feature?.error}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}
