/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*COMPONENT*/

/*REDUX*/
import {
  push_setting,
  edit_setting,
  set_value_bi,
  edit_inside_object,
} from "../../App/actions/biActions";

/*PICTURE*/

/*FUNCTION*/

/*DATA*/
import dict from "../../Data/dict.json";

/*CONST*/

class BI_ADD_STEP_1_LAYER_CARD extends Component {
  state = {};

  on_change_card_add_object = (id_key, value) => {
    this.props.edit_inside_object({
      key: "card_add_object",
      id_key: id_key,
      value: value,
    });
  };

  on_next_step = () => {
    const { add_card_current_step } = this.props.bi;
    this.props.set_value_bi({
      key: "add_card_current_step",
      value: add_card_current_step + 1,
    });
  };

  on_prev_step = () => {
    const { add_card_current_step } = this.props.bi;
    this.props.set_value_bi({
      key: "add_card_current_step",
      value: add_card_current_step - 1,
    });
  };

  render() {
    //local storage
    const language = localStorage?.language || "ina";

    //local state

    //global props
    const { project_object_selected, card_add_object, bi_object } =
      this.props.bi;

    //content
    let layer_list = project_object_selected?.layer_list || [];
    const layer_id = card_add_object?.layer_id;
    const setting_list = bi_object?.setting_list || [];
    const setting_object = setting_list.find(
      (item) => item.project_id === project_object_selected?._id
    );
    const layer_load_list = setting_object?.layer_load_list || [];
    const layer_view_map_list = setting_object?.layer_view_map_list || [];

    layer_list = layer_list.filter(
      (item) =>
        layer_load_list.includes(item?.geo_layer?._id) ||
        layer_view_map_list.includes(item?.geo_layer?._id)
    );

    return (
      <main>
        <h1 className="text_bold margin_bottom">
          {dict?.["Select the layer to fill in the data"]?.[language]}
        </h1>
        <section className="container_light margin_bottom">
          <div style={{ maxHeight: "calc(85vh - 400px)", overflowY: "auto" }}>
            <table className="full_width margin_bottom">
              <tbody>
                {layer_list.map((item, idx) => {
                  let is_open = item?.geo_layer?._id === layer_id;
                  return (
                    <tr
                      className="cursor_pointer"
                      key={idx}
                      onClick={this.on_change_card_add_object.bind(
                        this,
                        "layer_id",
                        item?.geo_layer?._id
                      )}
                    >
                      <td>
                        <div
                          className={
                            is_open ? "checklist_active" : "checklist_not"
                          }
                        />
                      </td>
                      <td>{item?.geo_layer?.name}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </section>
        <button
          onClick={this.on_prev_step}
          className="button margin_bottom margin_right inline background_blue"
        >
          Sebelumnya
        </button>
        {layer_id ? (
          <button
            onClick={this.on_next_step}
            className="button margin_bottom background_blue"
          >
            {dict?.["Next step"]?.[language]}
          </button>
        ) : (
          <button className="button background_grey margin_bottom">
            {dict?.["Next step"]?.[language]}
          </button>
        )}
        {!layer_id && (
          <>
            <br />
            <p className="text_small text_red">
              {dict?.["Select one layer first"]?.[language]}
            </p>
          </>
        )}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  bi: state.bi,
});

export default connect(mapStateToProps, {
  push_setting,
  edit_setting,
  set_value_bi,
  edit_inside_object,
})(BI_ADD_STEP_1_LAYER_CARD);
