//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";

//Personal Component
import ChartTemplate from "./ChartTemplate";

//Redux function
import {
  filter_isi_layer,
  undo_filter_isi_layer,
} from "../../App/actions/dataActions";
import {
  push_apply_color,
  delete_apply_color,
} from "../../App/actions/layerNewActions";

//Picture Asset
import checklist from "../../Assets/svg/checklist.svg";
import checklist_grey from "../../Assets/svg/checklist_grey.svg";

//General Function
import dict from "../../Data/dict.json";

class PieChart extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentWillUnmount() {
    this.props.chartReference.current = [];
  }

  on_filter_isi_layer = (field_key, value) => {
    // const { layer_id } = this.props.layer;
    let { layer_id, geo_layer_list } = this.props.layer;
    let geo_layer = geo_layer_list.find((e) => e?.geo_layer?._id === layer_id);
    geo_layer = geo_layer?.geo_layer ? geo_layer?.geo_layer : {};
    let fields = geo_layer?.fields ? geo_layer?.fields : [];
    const filter_chart = fields?.find((d) => d?.key === field_key);
    const value_stirng = value.toString();
    const filter_data = filter_chart?.array_value_filter?.find(
      (f) => f === value_stirng
    );
    const body = {
      geo_layer_id: layer_id,
      field_key,
      value,
    };

    if (filter_data === undefined) {
      this.props.filter_isi_layer(body);
    } else {
      this.props.undo_filter_isi_layer(body);
    }
  };

  handle_color = (data) => {
    const { layer_id } = this.props.layer;
    const body = {
      layer_id,
      config: {
        field_key: data.key,
        array_name: data.name,
        array_color: data.data,
      },
    };
    this.props.push_apply_color(body);
  };

  handle_reset = (data) => {
    const { layer_id } = this.props.layer;
    const body = {
      layer_id,
      config: {
        field_key: data.key,
        array_name: data.name,
        array_color: data.data,
      },
    };
    this.props.delete_apply_color(body);
  };

  render() {
    //local storage
    const language = localStorage?.language ? localStorage?.language : "ina";
    let { layer_id, geo_layer_list } = this.props.layer;
    let geo_layer = geo_layer_list.find((e) => e?.geo_layer?._id === layer_id);
    geo_layer = geo_layer?.geo_layer ? geo_layer?.geo_layer : {};
    let fields = geo_layer?.fields ? geo_layer?.fields : [];
    fields = fields.map((f) => {
      let array_value_filter = f?.array_value_filter
        ? f?.array_value_filter
        : [];
      f.array_value_filter = array_value_filter;
      return f;
    });

    fields = fields.filter((f) => {
      let logic = f?.array_value_filter?.length > 0;
      if (f.type === "date") {
        logic = f.start_date;
      } else {
        logic = f?.array_value_filter?.length > 0;
      }
      return logic;
    });
    //local state

    //global props
    const { chart_data } = this.props;
    const { layer_apply_color } = this.props.layer;

    //content

    const content = (
      <main>
        {chart_data.length > 0 ? (
          chart_data.map((dt, idx) => {
            const filter_chart = fields?.find(
              (d) => d?.uuid === dt?.field?.uuid
            );

            const key = dt.key;

            // const isAppliedColor = appliedColor?.key === dt.key;
            const values = dt.data.datasets[0].data;
            const colors = dt.data.datasets[0].backgroundColor;
            const labels = dt.data.labels;
            const newdata = colors.map((color, idx) => {
              return {
                label: labels[idx],
                color: color,
                value: values[idx],
              };
            });

            const includes_key = layer_apply_color.find(
              (value) => dt.key === value.config.field_key
            );

            return (
              <main
                key={`chart-${idx}`}
                style={{ marginBottom: "20px", padding: 5 }}
              >
                <section
                  className="text_medium"
                  style={{ textAlign: "center", marginBottom: "10px" }}
                >
                  {dt.name}
                </section>
                <section style={{ width: "70%", margin: "auto" }}>
                  <ChartTemplate
                    type="pie"
                    width={100}
                    height={100}
                    options={{
                      animation: false,
                      maintainAspectRatio: true,
                      responsive: true,
                      legend: {
                        display: false,
                      },
                      title: {
                        display: false,
                        text: dt.name,
                      },
                      plugins: {
                        legend: {
                          display: false,
                        },
                      },
                      onClick: (e, elmt) => {
                        try {
                          const idxEl = elmt[0].index;
                          const data = {
                            key: dt.key,
                            field_key: dt.name,
                            value: labels[idxEl],
                          };
                          this.on_filter_isi_layer(data.key, data.value);
                        } catch (e) {
                          console.log(e.message);
                        }
                      },
                    }}
                    data={dt.data}
                  />
                </section>

                <section style={{ maxHeight: "400px", overflowY: "auto" }}>
                  {newdata.map((data, idx) => {
                    const string = data?.label?.toString();
                    const filter_data = filter_chart?.array_value_filter?.find(
                      (f) => f === string
                    );

                    return data.label !== undefined ? (
                      <main
                        key={idx}
                        style={{
                          marginBottom: "5px",
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                        }}
                        onClick={this.on_filter_isi_layer.bind(
                          this,
                          key,
                          data.label
                        )}
                        // onClick={
                        //   filter_data
                        //     ? this.on_filter_isi_layer.bind(
                        //         this,
                        //         key,
                        //         data.label
                        //       )
                        //     : this.undo_filter_isi_layer.bind(
                        //         this,
                        //         key,
                        //         data.label
                        //       )
                        // }
                      >
                        <img
                          src={filter_data ? checklist : checklist_grey}
                          alt="icon"
                          width={20}
                          height={20}
                        />
                        <section
                          style={{
                            width: "20px",
                            height: "20px",
                            backgroundColor: data.color,
                          }}
                        />
                        <section style={{ display: "inline-block" }}>
                          {data.label} : <b>{data.value}</b>
                        </section>
                      </main>
                    ) : null;
                  })}
                </section>

                <section style={{ textAlign: "right" }}>
                  {includes_key ? (
                    <button
                      className="button background_blue"
                      id="red"
                      onClick={() => this.handle_reset(dt)}
                    >
                      {dict["Reset"][language]}
                    </button>
                  ) : (
                    <button
                      className="button background_blue"
                      id="blue"
                      onClick={() => this.handle_color(dt)}
                    >
                      {dict["Apply Color"][language]}
                    </button>
                  )}
                </section>
              </main>
            );
          })
        ) : (
          <div className="text_medium">
            {dict["No available chart data"][language]}
          </div>
        )}
      </main>
    );
    return <div>{content}</div>;
  }
}

const mapStateToProps = (state) => ({
  layer: state.layer,
});

export default connect(mapStateToProps, {
  filter_isi_layer,
  push_apply_color,
  delete_apply_color,
  undo_filter_isi_layer,
})(PieChart);
