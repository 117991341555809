import axios from "axios";
import { geoServerBaseUrl } from "./baseUrl";
import unique_list_layer_by_id from "../validation/unique_list_layer_by_id";

const SERVER_URL = geoServerBaseUrl;

/*NON API*/

export const reset_bi = () => {
  return {
    type: "reset_bi",
  };
};

export const trigger_fly_map = () => {
  return {
    type: "trigger_fly_map",
  };
};

export const trigger_rerender = () => {
  return {
    type: "trigger_rerender",
  };
};

export const trigger_rerender_css_map = () => {
  return {
    type: "trigger_rerender_css_map",
  };
};

export const set_loading_bi = (loading_item) => {
  return {
    type: "set_loading_bi",
    payload: loading_item,
  };
};

export const clear_loading_bi = (loading_item) => {
  return {
    type: "clear_loading_bi",
    payload: loading_item,
  };
};

export const set_value_bi = (body) => {
  return {
    type: "set_value_bi",
    payload: body,
  };
};

export const push_value_bi = (body) => {
  return {
    type: "push_value_bi",
    payload: body,
  };
};

export const pull_value_bi_string = (body) => {
  return {
    type: "pull_value_bi_string",
    payload: body,
  };
};

/**
 * @payload : key, id_key, value
 */
export const push_array_inside_object = (body) => {
  return {
    type: "push_array_inside_object",
    payload: body,
  };
};

/**
 * @payload : key, id_key, id_key_child, id_value
 */
export const delete_array_inside_object = (body) => {
  return {
    type: "delete_array_inside_object",
    payload: body,
  };
};

/**
 * @payload : key, id_key, value
 */
export const delete_array_string_inside_object = (body) => {
  return {
    type: "delete_array_string_inside_object",
    payload: body,
  };
};

/**
 * @payload : key, id_key, value
 */
export const edit_inside_object = (body) => {
  return {
    type: "edit_inside_object",
    payload: body,
  };
};

/*GET METHODE*/

/**
 * @name : get_bi_object_by_link
 * @endpoint : /bis/get_bi_object_by_link
 * @body :
 * @query :
 * @methode : GET
 */
export const get_bi_object_by_link = (query) => async (dispatch) => {
  try {
    dispatch(set_loading_bi("get_bi_object_by_link"));
    const { link } = query;
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    const res = await axios.get(
      SERVER_URL + `/bis/get_bi_object_by_link?link=${link}`,
      config
    );

    const data = res?.data || {};
    const bi_object = data?.bi_object || {};
    const folder_list = data?.folder_list || [];
    const project_list = data?.project_list || [];

    dispatch({
      type: "set_value_bi",
      payload: {
        key: "bi_object",
        value: bi_object,
      },
    });
    dispatch({
      type: "set_value_bi",
      payload: {
        key: "folder_list",
        value: folder_list,
      },
    });
    dispatch({
      type: "set_value_bi",
      payload: {
        key: "project_list",
        value: project_list,
      },
    });
    dispatch(clear_loading_bi("get_bi_object_by_link"));
  } catch (e) {
    dispatch(clear_loading_bi("get_bi_object_by_link"));
  }
};

/**
 * @route : get_bi_list
 * @endpoint : /bis/get_bi_list
 * @methode : GET
 */
export const get_bi_list = () => async (dispatch) => {
  try {
    dispatch(set_loading_bi("get_bi_list"));
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    const res = await axios.get(SERVER_URL + "/bis/get_bi_list", config);
    const data = res?.data || [];
    dispatch({
      type: "set_value_bi",
      payload: {
        key: "bi_list",
        value: data,
      },
    });
    dispatch(clear_loading_bi("get_bi_list"));
  } catch (e) {
    dispatch(clear_loading_bi("get_bi_list"));
  }
};

/**
 * @route : get_detail_by_link_new
 * @endpoint : /projects/get_detail_by_link_new
 * @methode : GET
 * @query : link dari project
 */
export const get_list_layer_bi = (query) => async (dispatch) => {
  try {
    const { _id, link } = query;
    dispatch(set_loading_bi("get_list_layer_bi"));
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    const res = await axios.get(
      SERVER_URL + `/projects/get_detail_by_link_new/${link}?limit=1&skip=0`,
      config
    );
    const layers = res?.data?.layers || [];
    let layer_paginations = layers;
    let loop = true;
    let skip = 1,
      limit = 50;
    while (loop) {
      const res = await axios.get(
        SERVER_URL +
          `/projects/get_detail_by_link_new/${link}?limit=${limit}&skip=${skip}`,
        config
      );
      const layers = res?.data?.layers ? res?.data?.layers : [];
      if (layers.length === 0) {
        loop = false;
        dispatch({
          type: "edit_inside_array",
          payload: {
            key: "project_list",
            _id: _id,
            id_key: "layer_list",
            value: layer_paginations,
          },
        });
        dispatch({
          type: "edit_inside_object",
          payload: {
            key: "project_object_selected",
            id_key: "layer_list",
            value: layer_paginations,
          },
        });
        dispatch(clear_loading_bi("get_list_layer_bi"));
      } else {
        layer_paginations = [...layer_paginations, ...layers];
        layer_paginations = unique_list_layer_by_id(layer_paginations);
        skip += limit;
      }
    }
  } catch (e) {
    dispatch(clear_loading_bi("get_list_layer_bi"));
  }
};

/*POST METHODE*/

/**
 * @route : create_bi
 * @endpoint : /bis/create_bi
 * @methode : POST
 */
export const create_bi = (body) => async (dispatch) => {
  try {
    dispatch(set_loading_bi("create_bi"));
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    const res = await axios.post(SERVER_URL + "/bis/create_bi", body, config);
    dispatch({
      type: "push_value_bi_to_start",
      payload: {
        key: "bi_list",
        value: res.data,
      },
    });
    dispatch(clear_loading_bi("create_bi"));
  } catch (e) {
    dispatch(clear_loading_bi("create_bi"));
  }
};

/**
 * @route : push_folder
 * @endpoint : /bis/push_folder
 * @variable_reducer : folder_list
 * @methode : POST
 * @body : _id, folder_id
 */
export const push_folder = (body) => async (dispatch) => {
  try {
    dispatch(set_loading_bi("push_folder"));
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    await axios.post(SERVER_URL + "/bis/push_folder", body, config);
    const query = {
      link: body.link,
    };
    dispatch(get_bi_object_by_link(query));
    dispatch(clear_loading_bi("push_folder"));
  } catch (e) {
    dispatch(clear_loading_bi("push_folder"));
  }
};

/**
 * @route : push_project
 * @endpoint : /bis/push_project
 * @variable_reducer : project_list
 * @methode : POST
 */
export const push_project = (body) => async (dispatch) => {
  try {
    dispatch(set_loading_bi("push_project"));
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    await axios.post(SERVER_URL + "/bis/push_project", body, config);
    let value = body.project_objct;
    dispatch({
      type: "push_array_inside_object",
      payload: {
        key: "bi_object",
        id_key: "project_list",
        value: value,
      },
    });
    dispatch(clear_loading_bi("push_project"));
  } catch (e) {
    dispatch(clear_loading_bi("push_project"));
  }
};

/**
 * @route : push_setting
 * @endpoint : /bis/push_setting
 * @variable_reducer : setting_list
 * @methode : POST
 * @body : _id, project_id, layer_load_mode, layer_view_map_mode, layer_load_list, layer_view_map_list, basemap_mode
 */
export const push_setting = (body) => async (dispatch) => {
  try {
    dispatch(set_loading_bi("push_setting"));
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    const res = await axios.post(
      SERVER_URL + "/bis/push_setting",
      body,
      config
    );
    let value = res?.data || {};
    dispatch({
      type: "push_array_inside_object",
      payload: {
        key: "bi_object",
        id_key: "setting_list",
        value: value,
      },
    });
    dispatch(clear_loading_bi("push_setting"));
    dispatch(trigger_rerender_css_map());
    dispatch(trigger_rerender());
  } catch (e) {
    dispatch(clear_loading_bi("push_setting"));
  }
};

/**
 * @route : push_card
 * @endpoint : /bis/push_card
 * @variable_reducer : card_list
 * @methode : POST
 * @body : _id, project_id, layer_id, name, mode, feature_key, field_key_value, field_key_selection, default_value
 */
export const push_card = (body) => async (dispatch) => {
  try {
    dispatch(set_loading_bi("push_card"));
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    const res = await axios.post(SERVER_URL + "/bis/push_card", body, config);
    let value = res?.data || {};
    dispatch({
      type: "push_array_inside_object",
      payload: {
        key: "bi_object",
        id_key: "card_list",
        value: value,
      },
    });
    dispatch(clear_loading_bi("push_card"));
  } catch (e) {
    dispatch(clear_loading_bi("push_card"));
  }
};

/**
 * @route : push_group_card
 * @endpoint : /bis/push_group_card
 * @variable_reducer :
 * @methode : post
 * @body : _id, name, project_id, card_id_list
 * @query : -
 */
export const push_group_card = (body) => async (dispatch) => {
  try {
    dispatch(set_loading_bi("push_group_card"));
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    const res = await axios.post(
      SERVER_URL + "/bis/push_group_card",
      body,
      config
    );
    let value = res?.data || {};
    const project_id = body.project_id;
    const group_id = value._id;
    dispatch({
      type: "push_array_inside_array",
      payload: {
        key_parent: "bi_object",
        key_child_1: "setting_list",
        find_by: "project_id",
        find_value: project_id,
        key_child_2: "group_card_list",
        value: value,
      },
    });
    dispatch({
      type: "push_array_inside_array",
      payload: {
        key_parent: "bi_object",
        key_child_1: "setting_list",
        find_by: "project_id",
        find_value: project_id,
        key_child_2: "group_card_order",
        value: group_id,
      },
    });
    dispatch(clear_loading_bi("push_group_card"));
  } catch (e) {
    dispatch(clear_loading_bi("push_group_card"));
  }
};

/**
 * @route : push_layer_sidebar_config_list
 * @endpoint : /bis/push_layer_sidebar_config_list
 * @variable_reducer : layer_sidebar_config_list
 * @methode : post
 * @body : _id, layer_sidebar_config
 * @query : -
 */
export const push_layer_sidebar_config_list = (body) => async (dispatch) => {
  try {
    //layer_sidebar_config_object
    dispatch({
      type: "set_loading_action",
      payload: "push_layer_sidebar_config_list",
    });
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    await axios.post(
      SERVER_URL + "/bis/push_layer_sidebar_config_list",
      body,
      config
    );
    dispatch({
      type: "push_array_inside_object",
      payload: {
        key: "bi_object",
        id_key: "layer_sidebar_config_list",
        value: body.layer_sidebar_config,
      },
    });
    dispatch({
      type: "set_value_bi",
      payload: {
        key: "layer_sidebar_config_object",
        value: body.layer_sidebar_config,
      },
    });
    dispatch({
      type: "clear_loading_action",
      payload: "push_layer_sidebar_config_list",
    });
  } catch (e) {
    dispatch({
      type: "clear_loading_action",
      payload: "push_layer_sidebar_config_list",
    });
  }
};

/*PUT METHODE*/

/**
 * @route : rename_bi
 * @endpoint : /bis/rename_bi
 * @methode : PUT
 * @body : _id, name
 */
export const rename_bi = (body) => async (dispatch) => {
  try {
    dispatch(set_loading_bi("rename_bi"));
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    await axios.put(SERVER_URL + "/bis/rename_bi", body, config);
    dispatch({
      type: "edit_inside_object",
      payload: {
        key: "bi_object",
        id_key: "name",
        value: body.name,
      },
    });
    dispatch({
      type: "edit_inside_array",
      payload: {
        key: "bi_list",
        _id: body._id,
        id_key: "name",
        value: body.name,
      },
    });
    dispatch(clear_loading_bi("rename_bi"));
  } catch (e) {
    dispatch(clear_loading_bi("rename_bi"));
  }
};

/**
 * @route : edit_group_config
 * @endpoint : /bis/edit_group_config
 * @methode : PUT
 * @body : _id, project_id, group_card_mode, group_card_show_other, default_group
 */
export const edit_group_config = (body) => async (dispatch) => {
  try {
    dispatch(set_loading_bi("edit_group_config"));
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    await axios.put(SERVER_URL + "/bis/edit_group_config", body, config);
    const project_id = body.project_id;
    dispatch({
      type: "edit_array_inside_array",
      payload: {
        key_parent: "bi_object",
        key_child_1: "setting_list",
        find_by: "project_id",
        find_value: project_id,
        value_object: body,
      },
    });
    dispatch(clear_loading_bi("edit_group_config"));
  } catch (e) {
    dispatch(clear_loading_bi("edit_group_config"));
  }
};

/**
 * @route : reorder_group_card_list
 * @endpoint : /bis/reorder_group
 * @variable_reducer : group_card_order
 * @methode : PUT
 * @body : _id, project_id, group_card_order
 */
export const reorder_group = (body) => async (dispatch) => {
  try {
    dispatch(set_loading_bi("reorder_group"));
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    await axios.put(SERVER_URL + "/bis/reorder_group", body, config);
    delete body._id;
    dispatch({
      type: "edit_array_inside_object",
      payload: {
        key: "bi_object",
        id_key: "setting_list",
        id_key_child: "project_id",
        id_value: body.project_id,
        object_edit: body,
      },
    });
    dispatch(clear_loading_bi("reorder_group"));
  } catch (e) {
    dispatch(clear_loading_bi("reorder_group"));
  }
};

/**
 * @route : edit_setting
 * @endpoint : /bis/edit_setting
 * @variable_reducer : setting_list
 * @methode : PUT
 * @body :  _id, setting_id, layer_load_mode, layer_view_map_mode, layer_load_list, layer_view_map_list, basemap_mode
 */
export const edit_setting = (body) => async (dispatch) => {
  try {
    dispatch(set_loading_bi("edit_setting"));
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    await axios.put(SERVER_URL + "/bis/edit_setting", body, config);
    delete body._id;
    dispatch({
      type: "edit_array_inside_object",
      payload: {
        key: "bi_object",
        id_key: "setting_list",
        id_key_child: "_id",
        id_value: body.setting_id,
        object_edit: body,
      },
    });
    dispatch(clear_loading_bi("edit_setting"));
    dispatch(trigger_rerender_css_map());
    dispatch(trigger_rerender());
  } catch (e) {
    dispatch(clear_loading_bi("edit_setting"));
  }
};

/**
 * @route : edit_card
 * @endpoint : /bis/edit_card
 * @reducer_variable : card_list
 * @methode : put
 * @body : _id, card_id, name, mode, feature_key, field_key_value, field_key_selection, default_value, conditional_statement_list
 * @query :
 */
export const edit_card = (body) => async (dispatch) => {
  try {
    dispatch(set_loading_bi("edit_card"));
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    await axios.put(SERVER_URL + "/bis/edit_card", body, config);
    delete body._id;
    dispatch({
      type: "edit_array_inside_object",
      payload: {
        key: "bi_object",
        id_key: "card_list",
        id_key_child: "_id",
        id_value: body.card_id,
        object_edit: body,
      },
    });
    dispatch(clear_loading_bi("edit_card"));
  } catch (e) {
    dispatch(clear_loading_bi("edit_card"));
  }
};

/**
 * @route : edit_group_card
 * @endpoint : /bis/edit_group_card
 * @reducer_variable : group_card_list
 * @methode : put
 * @body : _id, group_card_id, name, card_id_list
 * @query : -
 */
export const edit_group_card = (body) => async (dispatch) => {
  try {
    dispatch(set_loading_bi("edit_group_card"));
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    await axios.put(SERVER_URL + "/bis/edit_group_card", body, config);
    delete body._id;
    const project_id = body.project_id;
    const group_card_id = body.group_card_id;

    console.table({ project_id, group_card_id });

    dispatch({
      type: "edit_array_inside_array",
      payload: {
        key_parent: "bi_object",
        key_child_1: "setting_list",
        find_by: "project_id",
        find_value: project_id,
        key_child_2: "group_card_list",
        value_object: body,
        find_by_child: "_id",
        find_value_child: group_card_id,
      },
    });
    dispatch(clear_loading_bi("edit_group_card"));
  } catch (e) {
    dispatch(clear_loading_bi("edit_group_card"));
  }
};

/**
 * @route : edit_css_bi
 * @endpoint : /bis/edit_css_bi
 * @body :  _id, css_string
 * @query :
 * @methode : put
 */
export const edit_css_bi = (body) => async (dispatch) => {
  try {
    dispatch(set_loading_bi("edit_css_bi"));
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    await axios.put(SERVER_URL + "/bis/edit_css_bi", body, config);
    dispatch({
      type: "edit_inside_object",
      payload: {
        key: "bi_object",
        id_key: "css_string",
        value: body.css_string,
      },
    });
    dispatch(clear_loading_bi("edit_css_bi"));
    dispatch(trigger_rerender_css_map());
  } catch (e) {
    dispatch(clear_loading_bi("edit_css_bi"));
  }
};

/**
 * @route : edit_layer_sidebar_config
 * @endpoint : /bis/edit_layer_sidebar_config
 * @variable_reducer : layer_sidebar_config_list
 * @methode : PUT
 * @body :  _id, layer_sidebar_config, layer_id
 */
export const edit_layer_sidebar_config = (body) => async (dispatch) => {
  try {
    dispatch({
      type: "set_loading_action",
      payload: "edit_layer_sidebar_config",
    });
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    await axios.put(
      SERVER_URL + "/bis/edit_layer_sidebar_config",
      body,
      config
    );
    dispatch({
      type: "edit_array_inside_object",
      payload: {
        key: "bi_object",
        id_key: "layer_sidebar_config_list",
        id_key_child: "layer_id",
        id_value: body.layer_id,
        object_edit: body.layer_sidebar_config,
      },
    });
    dispatch({
      type: "clear_loading_action",
      payload: "edit_layer_sidebar_config",
    });
  } catch (e) {
    dispatch({
      type: "clear_loading_action",
      payload: "edit_layer_sidebar_config",
    });
  }
};

/*DELETE METHODE*/

/**
 * @route : delete_folder
 * @endpoint : /bis/delete_folder
 * @variable_reducer :
 * @methode : DELETE
 * @query : link, _id, folder_id
 */
export const delete_folder = (query) => async (dispatch) => {
  try {
    const { link, _id, folder_id } = query;
    dispatch(set_loading_bi("delete_folder"));
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    await axios.delete(
      SERVER_URL + `/bis/delete_folder?_id=${_id}&folder_id=${folder_id}`,
      config
    );
    query = {
      link,
    };
    dispatch(get_bi_object_by_link(query));
    dispatch(clear_loading_bi("delete_folder"));
  } catch (e) {
    dispatch(clear_loading_bi("delete_folder"));
  }
};

/**
 * @route : delete_project
 * @endpoint : /bis/delete_project
 * @variable_reducer :
 * @methode : DELETE
 * @query : _id, project_id
 */
export const delete_project = (query) => async (dispatch) => {
  try {
    const { link, _id, project_id } = query;
    dispatch(set_loading_bi("delete_project"));
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    await axios.delete(
      SERVER_URL + `/bis/delete_project?_id=${_id}&project_id=${project_id}`,
      config
    );
    query = {
      link,
    };
    dispatch(get_bi_object_by_link(query));
    dispatch(clear_loading_bi("delete_project"));
  } catch (e) {
    dispatch(clear_loading_bi("delete_project"));
  }
};

/**
 * @route : delete_setting
 * @endpoint : /bis/delete_setting
 * @variable_reducer : setting_list, card_list
 * @methode : DELETE
 */
export const delete_setting = (query) => async (dispatch) => {
  try {
    const { project_id } = query;
    dispatch(set_loading_bi("delete_setting"));
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    await axios.delete(
      SERVER_URL + `/bis/delete_setting?project_id=${project_id}`,
      config
    );
    dispatch({
      type: "delete_array_inside_object",
      payload: {
        key: "bi_object",
        id_key: "setting_list",
        id_key_child: "project_id",
        id_value: project_id,
      },
    });
    dispatch({
      type: "delete_array_inside_object",
      payload: {
        key: "bi_object",
        id_key: "card_list",
        id_key_child: "project_id",
        id_value: project_id,
      },
    });
    dispatch(clear_loading_bi("delete_setting"));
  } catch (e) {
    dispatch(clear_loading_bi("delete_setting"));
  }
};

/**
 * @route : delete_card
 * @endpoint : /bis/delete_card
 * @variable_reducer : card_list
 * @methode : DELETE
 * @query : _id, card_id
 */
export const delete_card = (query) => async (dispatch) => {
  try {
    const { _id, card_id } = query;
    dispatch(set_loading_bi("delete_card"));
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    await axios.delete(
      SERVER_URL + `/bis/delete_card?_id=${_id}&card_id=${card_id}`,
      config
    );
    dispatch({
      type: "delete_array_inside_object",
      payload: {
        key: "bi_object",
        id_key: "card_list",
        id_key_child: "_id",
        id_value: card_id,
      },
    });
    dispatch(clear_loading_bi("delete_card"));
  } catch (e) {
    dispatch(clear_loading_bi("delete_card"));
  }
};

/**
 * @route : delete_group_card
 * @endpoint : /bis/delete_group_card
 * @variable_reducer : group_card_list
 * @methode : DELETE
 * @body : -
 * @query : _id, group_card_id
 */
export const delete_group_card = (query) => async (dispatch) => {
  try {
    const { _id, group_card_id, project_id } = query;
    dispatch(set_loading_bi("delete_group_card"));
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    await axios.delete(
      SERVER_URL +
        `/bis/delete_group_card?_id=${_id}&group_card_id=${group_card_id}&project_id=${project_id}`,
      config
    );
    dispatch({
      type: "delete_array_inside_array",
      payload: {
        key_parent: "bi_object",
        key_child_1: "setting_list",
        find_by: "project_id",
        find_value: project_id,
        key_child_2: "group_card_list",
        find_by_child: "_id",
        find_value_child: group_card_id,
      },
    });
    dispatch({
      type: "delete_string_inside_array",
      payload: {
        key_parent: "bi_object",
        key_child_1: "setting_list",
        find_by: "project_id",
        find_value: project_id,
        key_child_2: "group_card_order",
        find_value_child: group_card_id,
      },
    });
    dispatch(clear_loading_bi("delete_group_card"));
  } catch (error) {
    console.log(error);
    dispatch(clear_loading_bi("delete_group_card"));
  }
};

/**
 * @route : delete_bi
 * @endpoint : /bis/delete_bi
 * @methode : DELETE
 */
export const delete_bi = (query) => async (dispatch) => {
  try {
    const { _id } = query;
    dispatch(set_loading_bi("delete_bi"));
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    await axios.delete(SERVER_URL + `/bis/delete_bi?_id=${_id}`, config);
    dispatch({
      type: "pull_value_bi_object",
      payload: {
        key: "bi_list",
        value: _id,
        id: "_id",
      },
    });
    dispatch(clear_loading_bi("delete_bi"));
  } catch (e) {
    dispatch(clear_loading_bi("delete_bi"));
  }
};
