import axios from "axios";
import { get_sini_data } from "./get_sini_data";
import { get_ai_parent_object } from "./sini_v2";

// const bun_server = "http://localhost:4004";
const bun_server = "https://geoserver.mapid.io";

/**
 * @route : get_ai_hbu_summary
 * @endpoint : /sini_v2/ai_excel/get_ai_hbu_summary
 * @methode : get
 * @params : geo_layer_id, feature_key, field_key,
 * @body : -
 */
export const get_ai_hbu_summary = (params) => async (dispatch) => {
  try {
    dispatch({
      type: "set_loading_action",
      payload: "get_ai_hbu_summary",
    });
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    const { geo_layer_id, feature_key, field_key } = params;
    const res = await axios.get(
      bun_server +
        `/sini_v2/ai_excel/get_ai_hbu_summary?geo_layer_id=${geo_layer_id}&feature_key=${feature_key}&field_key=${field_key}`,
      config
    );

    dispatch({
      type: "set_value_sini",
      payload: {
        key: "ai_hbu_summary_object",
        value: res.data,
      },
    });
    dispatch({
      type: "clear_loading_action",
      payload: "get_ai_hbu_summary",
    });
  } catch (error) {
    dispatch({
      type: "clear_loading_action",
      payload: "get_ai_hbu_summary",
    });
  }
};

/**
 * @route : get_ai_sheet_chat_list
 * @endpoint : /sini_v2/ai_excel/get_ai_sheet_chat_list
 * @methode : get
 * @params : geo_layer_id, feature_key, field_key
 * @body :
 */
export const get_ai_sheet_chat_list = (params) => async (dispatch) => {
  try {
    dispatch({
      type: "set_loading_action",
      payload: "get_ai_sheet_chat_list",
    });
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    const { geo_layer_id, feature_key, field_key } = params;
    const res = await axios.get(
      bun_server +
        `/sini_v2/ai_excel/get_ai_sheet_chat_list?geo_layer_id=${geo_layer_id}&feature_key=${feature_key}&field_key=${field_key}`,
      config
    );
    dispatch({
      type: "set_value_sini",
      payload: {
        key: "ai_sheet_chat_list",
        value: res.data,
      },
    });
    dispatch({
      type: "clear_loading_action",
      payload: "get_ai_sheet_chat_list",
    });
  } catch (error) {
    dispatch({
      type: "clear_loading_action",
      payload: "get_ai_sheet_chat_list",
    });
  }
};

/**
 * @route : create_ai_sini_sheet
 * @endpoint : /sini_v2/ai_excel/create_ai_sini_sheet
 * @methode : post
 * @params : -
 * @body : geo_layer_id, feature_key, field_key, request_id
 */
export const create_ai_sini_sheet = (body) => async (dispatch) => {
  try {
    dispatch({
      type: "set_loading_action",
      payload: "create_ai_sini_sheet",
    });
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    const res = await axios.post(
      bun_server + `/sini_v2/ai_excel/create_ai_sini_sheet`,
      body,
      config
    );
    dispatch({
      type: "set_value_sini",
      payload: {
        key: "ai_sini_sheet_object",
        value: res.data,
      },
    });
    dispatch({
      type: "clear_loading_action",
      payload: "create_ai_sini_sheet",
    });
  } catch (error) {
    dispatch({
      type: "clear_loading_action",
      payload: "create_ai_sini_sheet",
    });
  }
};

/**
 * @route : get_ai_sini_sheet
 * @endpoint : /sini_v2/ai_excel/get_ai_sini_sheet
 * @methode : get
 * @params : geo_layer_id, feature_key, field_key, lat, long, request_id
 * @body :
 */
export const get_ai_sini_sheet = (params) => async (dispatch) => {
  try {
    dispatch({
      type: "set_loading_action",
      payload: "get_ai_sini_sheet",
    });
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };

    dispatch({
      type: "set_value_sini",
      payload: {
        key: "is_sini_visible",
        value: true,
      },
    });

    const { geo_layer_id, feature_key, field_key } = params;
    const res = await axios.get(
      bun_server +
        `/sini_v2/ai_excel/get_ai_sini_sheet?geo_layer_id=${geo_layer_id}&feature_key=${feature_key}&field_key=${field_key}`,
      config
    );
    let data = res.data;
    if (!data?.request_id) {
      params.is_from_excel = true;
      dispatch(get_sini_data(params));
    } else {
      data.is_from_excel = true;
      dispatch(get_ai_parent_object(data));
    }
    dispatch({
      type: "set_value_sini",
      payload: {
        key: "ai_sini_sheet_object",
        value: data,
      },
    });
    dispatch({
      type: "clear_loading_action",
      payload: "get_ai_sini_sheet",
    });
  } catch (error) {
    dispatch({
      type: "clear_loading_action",
      payload: "get_ai_sini_sheet",
    });
  }
};
